import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MainFrameComponent } from './main-frame.component';

import { DashboardFooterComponent } from './dashboard-footer.component';
import { DashboardHeaderComponent } from './dashboard-header.component';
import { DashboardNavComponent } from './dashboard-nav.component';

import { HomeComponent } from '../home/home.component';
import { HomeSidebarComponent } from '../home/home-sidebar.component';
import { HomeMainContentComponent } from '../home/home-main-content.component';


import { RouterModule } from '@angular/router';
import { ProductsComponent } from '../products/products.component';
import { BillingComponent } from '../billing/billing.component';
import { AffiliateComponent } from '../affiliate/affiliate.component';
import { AccountComponent } from '../account/account.component';
import { HelpComponent } from '../help/help.component';
import { ProfileComponent } from '../profile/profile.component';
import { ProfileMainComponent } from '../profile/profile-main.component';
import { ProfileSidebarComponent } from '../profile/profile-sidebar.component';



import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ReferralsComponent } from '../referrals/referrals.component';
import { ReferComponent } from '../referrals/refer/refer.component';
import { StatsComponent } from '../referrals/stats/stats.component';
import { PayoutsComponent } from '../referrals/payouts/payouts.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ChartModule, DateTimeService } from '@syncfusion/ej2-angular-charts';

@NgModule({
    declarations: [MainFrameComponent, DashboardFooterComponent, DashboardNavComponent, DashboardHeaderComponent
        , HomeComponent, HomeSidebarComponent, HomeMainContentComponent,
        AccountComponent, ProductsComponent, BillingComponent, AffiliateComponent, HelpComponent, ProfileComponent
        , ProfileMainComponent, ProfileSidebarComponent, ReferralsComponent, ReferComponent, StatsComponent, PayoutsComponent],
    imports: [CommonModule, RouterModule, HttpClientModule, HttpClientJsonpModule, FormsModule, ReactiveFormsModule, MatTabsModule, ChartModule],
    exports: [MainFrameComponent],
    providers: [ DateTimeService ]
})

export class MainFrameModule {

}