import { Component, HostBinding, inject, ViewChild } from "@angular/core";
import { LimeProxiesService } from "../../services/limeproxies.service";
import { DialogService } from "../../services/dialog.service";
import { HelpersService } from "../../services/helpers.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../../services/user.service";
import { AccountSidebarComponent } from "../account/account-sidebar.component";
import { Observable } from "rxjs";

declare var analytics: any;

@Component({
  selector: "products-manage",
  templateUrl: "../../templates/products/products-manage.template.html",
})
export class ProductsManageComponent {
  @HostBinding("class") myClass = "page__main-col";
  @ViewChild("accountSidebar", { static: true })
  accountSidebar: AccountSidebarComponent;

  public products: Observable<any>;
  public residentialProducts: Observable<any>;
  public _refresh = {
    show: false,
    id: null,
    reason: "",
    location: "",
    product: {
      product: "",
    },
  };
  public _terminate = {
    show: false,
    id: null,
    reason: "",
    date: null,
  };

  public terminateReasons = [
    "Proxies are blocked",
    "Slow Speed",
    "Time Out Issues",
    "Wrong Geo Location",
    "Support Issues",
    "Product is expensive",
    "Need more subnets",
    "Looking for a different product",
    "Other issues (please provide details in comment)",
  ];

  public noProducts = false;
  public noResidentialProducts = false;
  public configPage = 0;
  public animateId = 0;
  public currentUser = [];
  public role = "";
  public inactiveProducts: any = [];
  public proxyUsageOpt = [];
  public websitesList = [];
  public showProxiesProduct = 0;

  constructor(
    public limeProxiesService: LimeProxiesService,
    public dialogService: DialogService,
    public helpersService: HelpersService,
    public router: Router,
    public userService: UserService,
    public activatedRoute: ActivatedRoute
  ) {}

  public user_acc: any;

  public selected_auth_method;
  public selected_access_type;
  public selected_quota;

  public access_type_btn_name = "REQUEST UPDATE";
  public quota_btn_name = "REQUEST UPDATE";

  public quota;
  public quota_size = "mb";
  public user_auth_method = "";
  public user_access_type;

  public successMessage;
  public errorMessage;

  public privateLocations = [];
  public premiumLocations = [];

  public type = "dedicated";

  ngOnInit(): void {
    let curObject = this;

    this.activatedRoute.params.subscribe((params) => {
      this.type = params["type"] != "residential" ? "dedicated" : "residential";
    });

    this.userService.getUser().subscribe((user) => {
      curObject.role = user.role;
    });

    this.getProducts();

    if (
      this.limeProxiesService.environment["third_parties"]["analytics"][
        "enabled"
      ]
    ) {
      analytics.page("Products");
    }

    this.getProxyUsage();

    this.userService.getUser().subscribe((user) => {
      curObject.currentUser = user;
    });

    this.limeProxiesService.getUserAccount().subscribe((usr_acc_resp) => {
      const usr_acc_local: any = usr_acc_resp;
      this.user_acc = usr_acc_resp;
      this.selected_auth_method = usr_acc_resp.auth_method;
      this.selected_access_type = usr_acc_resp.access_type;
      this.selected_quota = usr_acc_resp.quota;

      if (usr_acc_local.user_request !== undefined) {
        if (usr_acc_local.user_request.access_type !== undefined) {
          this.access_type_btn_name = "PENDING UPDATE";
          this.selected_access_type = usr_acc_local.user_request.access_type;
        } else {
          this.access_type_btn_name = "REQUEST UPDATE";
          this.selected_access_type = usr_acc_resp.access_type;
        }
        if (usr_acc_local.user_request.quota !== undefined) {
          this.quota_btn_name = "PENDING UPDATE";
          this.selected_quota = usr_acc_local.user_request.quota;
        } else {
          this.quota_btn_name = "REQUEST UPDATE";
          this.selected_quota = usr_acc_resp.quota;
        }
      }
      this.user_auth_method = usr_acc_resp.auth_method;
      this.user_access_type = usr_acc_resp.access_type;

      localStorage.setItem("user_auth_method", this.user_auth_method);
      localStorage.setItem("user_access_type", this.user_access_type);

      this.setProfileQuotas(this.selected_quota);
    });

    this.limeProxiesService.getProxyAuthorizedIps().subscribe((ips) => {
      const arr = ips.authorized_list.split(" ");
      console.log(arr, "arr");
      if (arr && arr.length > 0 && arr[0].length > 0) {
        this.limeProxiesService.userSubjectIPUpdate.emit({
          type: "authorized_ips",
          value: arr.length,
        });
      }
    });

    this.limeProxiesService.getLocations().subscribe((locations) => {
      this.privateLocations = locations["private"]["locations"].filter(
        (obj) => obj.state != "" || obj.country != ""
      );
      this.premiumLocations = locations["premium"]["locations"].filter(
        (obj) => obj.state != "" || obj.country != ""
      );
    });
  }

  public profile_quotas = [
    { display: "100 MB", value: "100" },
    { display: "250 MB", value: "250" },
    { display: "500 MB", value: "500" },
    { display: "1 GB", value: "1000" },
    { display: "5 GB", value: "5000" },
    { display: "1 TB", value: "1000000" },
    { display: "Unlimited", value: "unlimited" },
  ];

  setProfileQuotas(get_quota) {
    const new_quotas = [];
    if (get_quota === "unlimited") {
      this.profile_quotas = [{ display: "Unlimited", value: "unlimited" }];
      return;
    }
    for (const quota of this.profile_quotas) {
      if (parseInt(quota.value, null) >= parseInt(get_quota, null)) {
        new_quotas.push(quota);
      }
    }
    this.profile_quotas = new_quotas;
  }

  getProducts() {
    this.limeProxiesService.getAllProducts().subscribe({
      next: (data) => {
        this.products = data.filter(
          (obj) => !obj.product.includes("Residential")
        );

        this.residentialProducts = data.filter((obj) =>
          obj.product.includes("Residential")
        );

        if (
          data.filter((obj) => !obj.product.includes("Residential")).length ===
          0
        ) {
          this.noProducts = true;
        }

        if (
          data.filter((obj) => obj.product.includes("Residential")).length === 0
        ) {
          this.noResidentialProducts = true;
        }
      },
      error: () => {
        this.limeProxiesService.logout();
      },
    });
  }

  getProxyUsage = (): void => {
    this.limeProxiesService.get_proxy_usage_opt().subscribe(
      (data) => {
        this.proxyUsageOpt = [];
        data.data.forEach((value) => {
          this.proxyUsageOpt.push({ text: value.name, value: value.slug });
        });
        this.proxyUsageOpt.push({ text: "Others", value: "others" });

        this.getInactiveProducts();
      },
      (error) => {
        this.getInactiveProducts();
      }
    );

    this.limeProxiesService
      .get_proxy_website_usage_opt()
      .subscribe((websites) => {
        this.websitesList = [];
        websites.data.forEach((website) => {
          this.websitesList.push({
            item_id: website.id,
            item_text: website.name,
          });
        });
      });

    // this.limeProxiesService.get_proxy_tag_opt().subscribe(tags => {
    //     this.tagsList = [];
    //     // tags.data.forEach(website => {
    //     //     this.tagsList.push({ item_id: website.name, item_text: website.name });
    //     // });

    //     console.log('tags', tags);
    // });
  };

  getInactiveProducts = (): void => {
    this.limeProxiesService
      .getInactiveProductsByUserId()
      .subscribe((products) => {
        this.inactiveProducts = products.data
          .filter((obj: { status: string }) => obj.status === "inactive")
          .map(
            (
              product: {
                no: number;
                usage: string;
                otherUsage: any;
                proxies: any[];
              },
              pi: any
            ) => {
              product.no = 0;

              let usaCase = this.proxyUsageOpt
                .filter((obj) => obj.value === product.usage)
                .shift();

              if (usaCase === undefined) {
                product.otherUsage = product.usage;
                product.usage = "others";
              } else {
                product.otherUsage = usaCase.text;
              }

              product.proxies.forEach((subnet: { proxies: string | any[] }) => {
                product.no = product.no + subnet.proxies.length;
              });

              return product;
            }
          )
          // .filter((obj: { no: number; }) => obj.no > 0 )
          .sort(function (
            a: { product_id: number },
            b: { product_id: number }
          ) {
            return a.product_id < b.product_id ? 1 : -1;
          });
      });
  };

  showProxies = (product) => {
    if (this.showProxiesProduct === product.product_id) {
      this.showProxiesProduct = 0;
    } else {
      this.showProxiesProduct = product.product_id;
    }
  };

  repurchase = (product) => {
    this.limeProxiesService.repurchase(product.product_id).subscribe(
      (res) => {
        this.limeProxiesService.showMessage(res);
        this.getProducts();
      },
      (error) => {
        let userMessage = error.error._user_message;

        if (error.error._user_message.includes("need to add $")) {
          userMessage =
            userMessage + ' <a href="/#/new-billing/payment">Add Credit</a>';
        }

        this.limeProxiesService.notifyBarNew(userMessage, "error");
      }
    );
  };

  public switchSocksWorking: boolean = false;

  switchSocks(item: { socks: boolean }) {
    if (!this.switchSocksWorking) {
      this.switchSocksWorking = true;
      item.socks = !item.socks;
      this.limeProxiesService.changeSock.next(item);
      this.limeProxiesService.changeSock.subscribe(
        (success) => {
          this.switchSocksWorking = false;
        },
        (error) => {
          this.switchSocksWorking = false;
        }
      );
    }
  }

  refreshProduct() {
    let location = this._refresh.location.trim();
    let reason = this._refresh.reason.trim();

    if (location.length > 0 && reason.length <= 250 && reason.length > 0) {
      this.limeProxiesService
        .refreshProduct(this._refresh.id, location, reason)
        .subscribe((data) => {
          this.getProducts();
          this.closeRefresh();
          this.limeProxiesService.showMessage(data);
        });
    } else if (!(location.length > 0)) {
      this.limeProxiesService.notifyBarNew(
        "Kindly select location for refresh request",
        "error"
      );
    } else if (reason == "") {
      this.limeProxiesService.notifyBarNew(
        "Kindly provide the reason for refresh request",
        "error"
      );
    } else if (reason.length > 250) {
      this.limeProxiesService.notifyBarNew(
        "Refresh request reason exceeding the limit of 250 characters.",
        "error"
      );
    }
  }

  showConfig(id = null) {
    if (!id) {
      this.animateId = 0;
      setTimeout(() => {
        this.configPage = 0;
      }, 500);
    } else {
      this.configPage = id;
      setTimeout(() => {
        this.animateId = id;
      }, 50);
    }
  }

  closeRefresh() {
    this._refresh.show = false;
    this._refresh.reason = "";
  }

  closeTerminate() {
    this._terminate.show = false;
    this._terminate.reason = "";
  }

  showRefresh(product) {
    this._refresh.id = product.id;
    this._refresh.product = product;
    this._refresh.show = true;
  }

  getRefreshLocations = () => {
    return this._refresh.product.product.includes("Private")
      ? this.privateLocations
      : this.premiumLocations;
  };

  showRenew(id: number) {
    this.limeProxiesService.showRenew(id).subscribe(
      (data) => {
        this.limeProxiesService.showMessage(data);
        if (data["_user_message_type"] === "success") {
          this.limeProxiesService._updateUserNotifications.next();
          this.products = this.limeProxiesService.getAllProducts();
        }
      },
      (error) => console.log(error)
    );
  }

  showTerminationReasonText = false;

  selectRefreshLocation(value: string) {
    this._refresh.location = value;
  }

  selectTerminationReason(value: string) {
    if (value == "Other issues (please provide details in comment)") {
      this._terminate.reason = "";
      this.showTerminationReasonText = true;
    } else {
      this._terminate.reason = value;
      this.showTerminationReasonText = false;
    }
  }

  terminateProduct() {
    if (this._terminate.reason != "") {
      this._terminate.date = this.helpersService.dateFormatChange(new Date());
      this.limeProxiesService
        .terminateProduct(
          this._terminate.reason,
          this._terminate.date,
          this._terminate.id
        )
        .subscribe(
          (data) => {
            this._terminate.show = false;
            this.limeProxiesService.notifyBarNew(data._user_message, "info");
            this.getProducts();
          },
          (error) => {
            this.limeProxiesService.notifyBarNew(error, "error");
          }
        );
    } else {
      this.limeProxiesService.notifyBarNew(
        "Kindly provide the reason for termination",
        "info"
      );
    }
  }

  showTerminate(id: number) {
    this._terminate.id = id;
    this._terminate.show = true;
  }

  checkStatus(id: any) {
    this.limeProxiesService.checkProductStatus(id).subscribe((data: any[]) => {
      const table = `<table class="table table--highlight current-products">
            <thead class="table__header table__header--small">
                <tr>
                    <th class="table__cell">Proxy</th>
                    <th class="table__cell">Location</th>
                    <th class="table__cell">Maxmind</th>
                    <th class="table__cell">Status</th>
                </tr>
            </thead>
            <tbody class="table__body">
            ${data
              .map((row) => {
                return `<tr>
                            <td class="table__cell">${row.proxy}</td>
                            <td class="table__cell">
								<div class="flag-icon">
									<img src="assets/images/countries/${row.location.toLowerCase()}.png">
								</div>
							</td>
                            <td class="table__cell">${row.maxmind}</td>
                            <td class="table__cell"><img src="assets/images/${
                              row.status ? "green" : "red"
                            }dot.png"></td>
                        </tr>`;
              })
              .join("")}
            </tbody>
        </table>`;
      this.dialogService.confirm("Status", table, {
        isConfirm: false,
        style: { height: 410 + "px" },
      });
    });
  }

  downloadProduct(id: any) {
    this.limeProxiesService.downloadProduct(id).subscribe(
      (data) => {
        let string = "";
        if (
          data &&
          typeof data["length"] !== "undefined" &&
          data["length"] > 0
        ) {
          for (let i = 0; i < data["length"]; i++) {
            string += data[i];
          }
          const blob = new Blob([string], { type: "text/plain" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";
          a.href = url;
          a.download = `product-${id}.txt`;
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          this.limeProxiesService.notifyBarNew(
            "No proxies list received for this product. Please contact support.",
            "error"
          );
        }
      },
      (error) => {
        this.limeProxiesService.showMessage(error);
      }
    );
  }

  public switchRenewalWorking: boolean = false;

  switchRenewal(item: { auto: boolean; id: any }) {
    if (!this.switchRenewalWorking) {
      this.switchRenewalWorking = true;
      const temp = !item.auto;
      this.limeProxiesService
        .setRenewal({ state: temp, id: item.id })
        .subscribe(
          (success) => {
            if (success["_user_message_type"] === "success") {
              item.auto = temp;
            }
            this.limeProxiesService.showMessage(success);
            console.log(success, "switchRenewal success");
            this.switchRenewalWorking = false;
          },
          (error) => {
            this.limeProxiesService.showMessage(error);
            console.log(error, "switchRenewal error");
            this.switchRenewalWorking = false;
          }
        );
      // this.limeProxiesService.changeRenewal.next({state: temp, id: item.id});
    }
  }

  canTerminate(product: { state: string }): boolean {
    return product.state == "Active";
  }

  isResidentialProxy = (item) => {
    return item.product.includes("Residential");
  };

  isResidentialProxyByName = (item) => {
    return item.name.includes("Residential");
  };
}
