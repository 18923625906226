import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewCardComponent } from './components/new-billing/new-card.component';
import { ShippingAddressComponent } from './components/new-billing/shipping-address.component';

@NgModule({
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule
    ],
    declarations: [
        NewCardComponent, ShippingAddressComponent
    ],
    exports: [
        NewCardComponent, ShippingAddressComponent
    ]
})

export class SharedModule { }