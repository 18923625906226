import {Component, Input, OnInit} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import {LimeProxiesService} from '../../services/limeproxies.service';
import { Router } from '@angular/router';

@Component({
  selector: '.home-main',
  templateUrl: '../../templates/home/home-main-content.template.html'
})

export class HomeMainContentComponent implements OnInit {

  @Input() active;
  @Input() credit;
  @Input() renewal;
  @Input() validatePhone;
  @Input() products;
  public shouldBeVisible = false;
  public isPhoneVerificated = false;
  private counter = 0;
  public user:any = [];
  public subnets: any = [];

  constructor(
    public limeProxiesService: LimeProxiesService, 
    public userService: UserService,
    public router: Router) {
  }
  
  ngOnInit() {
    let curObject = this;
    this.userService.getUser().subscribe(user => {
      if (user.role == 'Admin' || user.role == 'Owner') {
        curObject.shouldBeVisible = true;
      }

      this.user = user;
    });
  }

  ngOnChanges() {
	  
      if (this.counter === 1 && (this.userService.currentUser.role == 'Admin' || this.userService.currentUser.role == 'Owner')) {
          this.shouldBeVisible = true;
          this.isPhoneVerificated = this.validatePhone;
      }
      
      ++this.counter;

      this.limeProxiesService.getSubnets().subscribe(subnets => this.subnets = subnets);
  }

  focusOnPassword() {
      this.limeProxiesService.setFocusOnNewPassword(true);
  }
	
	showProductLocation(products, product) {
		for (let i = 0; i < products.length; i++) {
			if (products[i]['id'] == product.id && products[i]['showLocation'] == false) {
				product.showLocation = true;
			} else {
				products[i]['showLocation'] = false;
			}
		}
  }

  canAssignTestProxies = () => {
    return this.user.sms_verified;
  }

  assignTestProxies = () => {
    let usProxy = this.subnets.filter(obj => obj.geo_mm_cc == 'US' && obj.type.includes('Premium')).shift();
    let gbProxy = this.subnets.filter(obj => obj.geo_mm_cc == 'GB' && obj.type.includes('Premium')).shift();

    let proxies = [
      {
        lid: usProxy.lid, 
        location: usProxy.geo_maxmind, 
        no: 1, 
        sid: usProxy.sid, 
        subnet: usProxy.subnets
      },
      {
        lid: gbProxy.lid, 
        location: gbProxy.geo_maxmind, 
        no: 1, 
        sid: gbProxy.sid, 
        subnet: gbProxy.subnets
      }
    ];

    this.limeProxiesService.assignTestProxies(proxies).subscribe(res => {
      this.limeProxiesService.notifyBarNew('Congratulations! We have successfully assigned test proxies in your account', 'success');
      this.router.navigate(['/products/', 'manage']);
    });
  }
}
