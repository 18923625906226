<div class="breadcrumbs">
    <a [routerLink]="['/profile/']" class="breadcrumbs__item" routerLinkActive="breadcrumbs__item--active">My Profile</a>
</div>
<div class="warn-container">
    <p>
        Please set the new password in the following window:
        <span (click)="hide_pass_message()"><sup>x</sup></span>
    </p>
</div>
<form class="block" [formGroup]="profileForm">
    <h4 class="block__title">Your profile details</h4>
    <h4 class="block__desc" style="color:green;">{{successMessage}}</h4>
    <h4 class="block__desc" style="color:red;">{{errorMessage}}</h4>
    <p class="block__desc">Change your profile  details, by clicking directly in the field you want to update.</p>

    <div class="row">
        <div class="col-xs-6 input-wrapper" [ngClass]="{'has-error':!profileForm.controls['firstName'].valid && profileForm.controls['firstName'].touched}">
            <input type="text" class="input-field" name="profile_fname" placeholder="First name" [(ngModel)]="userProfile.first_name" [formControl]="profileForm.controls['firstName']">
            <div *ngIf="profileForm.controls['firstName'].hasError('required') && profileForm.controls['firstName'].touched" class="alert alert-danger">First Name is required</div>
            <div *ngIf="profileForm.controls['firstName'].hasError('maxlength') && profileForm.controls['firstName'].touched" class="alert alert-danger">Length must be less than 50</div>
        </div>
        <div class="col-xs-6 input-wrapper">
            <input type="text" class="input-field" name="profile_lname" placeholder="Last name" [(ngModel)]="userProfile.last_name" [formControl]="profileForm.controls['lastName']">
            <div *ngIf="profileForm.controls['lastName'].hasError('required') && profileForm.controls['lastName'].touched" class="alert alert-danger">Last Name is required</div>
            <div *ngIf="profileForm.controls['lastName'].hasError('maxlength') && profileForm.controls['lastName'].touched" class="alert alert-danger">Length must be less than 50</div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6 input-wrapper">
             <select [ngModelOptions]="{standalone: true}" name="profile_country" class="input-field profile_country_select autocomplete " [(ngModel)]="selected_country" (change)="selectCountry($event)">
                 <option value="" disabled selected>Country</option>
                 <option [ngValue]='country.name' [attr.code]="country.code" *ngFor="let country of limeProxiesService.countryWithPhone">{{country.name}} ({{country.code}})</option>
            </select>
        </div>
        <div class="col-xs-6">
            <div class="row">
                <div class="col-xs-7 input-wrapper">
                    <input type="text" class="input-field" name="profile_city" [(ngModel)]="userProfile.city" placeholder="City" [formControl]="profileForm.controls['city']">
                    <div *ngIf="profileForm.controls['city'].hasError('maxlength') && profileForm.controls['city'].touched" class="alert alert-danger">Length must be less than 50</div>
                </div>
                <div class="col-xs-5 input-wrapper">
                    <input type="text" maxlength="10" class="input-field" name="profile_zip" [(ngModel)]="userProfile.zip" placeholder="ZIP Code" [formControl]="profileForm.controls['zipCode']">
                    <div *ngIf="profileForm.controls['zipCode'].hasError('maxlength') && profileForm.controls['zipCode'].touched" class="alert alert-danger">Length must be less than 50</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 input-wrapper">
            <input type="text" class="input-field" name="profile_address" placeholder="Address" [(ngModel)]="userProfile.address" [formControl]="profileForm.controls['address']">
             <div *ngIf="profileForm.controls['address'].hasError('maxlength') && profileForm.controls['address'].touched" class="alert alert-danger">Length must be less than 50</div>
        </div>
      </div>
      <div class="row">
          <div class="new-st">
              <div class="input-group">
                  <input type="tel" class="tel-input"
                         (keyup)="phone_entered($event)"
                         (change)="phone_entered($event)"
                  >
              </div>
          </div>
    </div>
    <div class="row">
        <div class="col-xs-6 input-wrapper">
            <input type="text" class="input-field" name="profile_company" placeholder="Company name" [(ngModel)]="userProfile.company_name" [formControl]="profileForm.controls['companyName']">
             <div *ngIf="profileForm.controls['companyName'].hasError('maxlength') && profileForm.controls['companyName'].touched" class="alert alert-danger">Length must be less than 50</div>
        </div>
      <div class="col-xs-6 input-wrapper">
        <!--<button type="submit" class="btn btn&#45;&#45;full" (click)="updateUserProfile()">Update Profile</button>-->
        <button type="submit" class="btn btn--full" (click)="updateUserProfile()" [disabled]="!profileForm.valid || !selected_country || !valid_phone" [class.btn--gray]="(!profileForm.valid || !selected_country)">Update Profile</button>
      </div>
    </div>
</form>
<div class="popup phone-verification-modal">
    <div class="popup__overlay popup__dismiss"></div>
    <div class="popup__inner popup__inner--padding">
        <div class="popup__header">
        </div>
        <div class="popup__body">
            <h1 class="text-center">Validate Your Phone Number</h1>
            <div class="main_block_wrap__text text-center">
                <p>Enter OTP you have received on your number</p>
            </div>
            <form>
                <label class="text-left">
                    OTP
                    <input [(ngModel)]="phone_valid_code.value" [ngModelOptions]="{standalone: true}" type="number" (keyup)="opt_entered($event)" (change)="opt_entered($event)" placeholder="Enter your code">
                    <span *ngIf="phone_valid_code.has_error" class="validation">Please enter your OTP</span>
                </label>
                <button (click)="verify_otp()" type="button">Verify</button>
            </form>
        </div>
    </div>
</div>
