import { Component, OnInit, Input } from '@angular/core';
import { LimeProxiesService } from 'src/app/services/limeproxies.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.css']
})
export class UsageComponent implements OnInit {
  @Input() pid: any;
  @Input() proxyUsageOpt: any;

  notes = new FormControl('');

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  constructor(private limeProxiesService: LimeProxiesService) { }

  ngOnInit(): void {
    this.getProxyUsage();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): any {
    if (value !== null && value !== '' && typeof value !== 'object') {
      console.log(typeof value);
      const filterValue = value.toLowerCase();    
      return this.proxyUsageOpt.filter(option => option.text.toLowerCase().includes(filterValue));
    } else {
      return this.proxyUsageOpt;
    }
  }

  usageDisplayfn = (option): string => {
    if (typeof option === 'object' && option !== null && option !== '') {
      this.setProxyUsage(option.value);
    }
  
    return option ? option.text : option;
  }

  getProxyUsage = () => {
    this.limeProxiesService.getProductUsage(this.pid).subscribe(res => {
      if (res.use_case !== undefined && res.use_case !== null) {
        let usage = this.proxyUsageOpt.filter(obj => obj.value === res.use_case);

        if (usage.length === 1) {
          this.myControl.setValue(usage.shift());
        } 
      }
    });
  }
  
  setProxyUsage = (val: string) => {
    this.limeProxiesService.setProductUsage(this.pid, val).subscribe(res => {
      // Usage Saved
    })
  }
}
