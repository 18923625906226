<div *ngIf="templateType === 'new'">

    <div class="toggle-header" [class.open]="showShipping" (click)="toggleMenu()">
        <h3 class="title">Enter billing information(optional) <svg class="icon-arrow"><use xlink:href="#arrow"></use></svg></h3>
    </div>
    <div class="toggle-content" [class.open]="showShipping">
        <form class="additional">
            <label>
                <span>City</span>
                <div class="cc-input-group">
                    <input type="text" [(ngModel)]="data.billingCity" (ngModelChange)="fillShippingData($event)" [ngModelOptions]="{standalone: true}" name="shipping_city">
                </div>
            </label>
            <label>
                <span>State</span>
                <div class="cc-input-group">
                    <input type="text" [(ngModel)]="data.billingState" (ngModelChange)="fillShippingData($event)" [ngModelOptions]="{standalone: true}" name="shipping_state">
                </div>
            </label>
            <label>
                <span>Zip</span>
                <div class="cc-input-group">
                    <input type="text" [(ngModel)]="data.billingZip" (ngModelChange)="fillShippingData($event)" [ngModelOptions]="{standalone: true}" name="shipping_zip">
                </div>
            </label>
            <label>
                <span>Country</span>
                <div class="cc-input-group">
                    <input type="text" [(ngModel)]="data.billingCountry" (ngModelChange)="fillShippingData($event)" [ngModelOptions]="{standalone: true}" name="shipping_country">
                </div>
            </label>
        </form>
    </div>
</div>
<div *ngIf="templateType === 'payment'">
    <div class="toggle-header" [class.open]="showShipping" (click)="toggleMenu()">
        <h3 class="title">Enter billing information(optional) <svg class="icon-arrow"><use xlink:href="#arrow"></use></svg></h3>
    </div>
    <div class="toggle-content" [class.open]="showShipping">
        <div class="saved_info__form">
            <div>
                <input type="text" placeholder="City" [(ngModel)]="data.billingCity" (ngModelChange)="fillShippingData($event)" [ngModelOptions]="{standalone: true}" name="shipping_city">
            </div>
            <div>
                <input type="text" placeholder="State" [(ngModel)]="data.billingState" (ngModelChange)="fillShippingData($event)" [ngModelOptions]="{standalone: true}" name="shipping_state">
            </div>
            <div>
                <input type="text" placeholder="Zip" [(ngModel)]="data.billingZip" (ngModelChange)="fillShippingData($event)" [ngModelOptions]="{standalone: true}" name="shipping_zip">
            </div>
            <div>
                <input type="text" placeholder="Country" [(ngModel)]="data.billingCountry" (ngModelChange)="fillShippingData($event)" [ngModelOptions]="{standalone: true}" name="shipping_country">
            </div>
        </div>
    </div>
</div>