import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import {LimeProxiesService} from '../../services/limeproxies.service';
import * as tel_input from 'intl-tel-input';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
declare var regxName: any;
declare var regxPhone: any;

@Component({
    selector: '.profile-new',
    templateUrl: '../../templates/new_profile/new-profile.template.html'
})

export class NewProfileComponent implements OnInit, AfterViewInit {


    public pass_change = false;
    public child_info : string;
    public profile: any;
    public user: any = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        company_name: '',
        country: ''
    };
    public current_tab: any = 'profile';
    public iti: any;
    public newPassword: any = '';
    public confirmPassword: any = '';
    public oldPassword: any;
    public modalId: any;
    public showOtpField = false;
    public otpCode: any;
    public prevNotificationsState: any;

    public notifications: any = {
        assignment_of_proxies: {
            email: 0,
            sms: 0
        },
        termination_of_proxies: {
            email: 0,
            sms: 0
        },
        bandwidth_alert: {
            email: 0,
            sms: 0
        },
        outage_alert: {
            email: 0,
            sms: 0
        },
        product_renewal: {
            email: 0,
            sms: 0
        }
    };
    
    public user_auth_method;
    public user_access_type;

    public validation_step1: FormGroup;

    public estimatedBudget = [];
    public estimatedBandwidthUsageMonth = [];
    public userProductInterested = [];
    public proxy_usage_opt: any[] = [];
    public proxy_website_usage_opt: any[] = [];


    constructor(public limeProxiesService: LimeProxiesService,
                private activatedRoute: ActivatedRoute,
                private location: Location,
                private fb: FormBuilder) {
        limeProxiesService.setTitle('Profile');
        console.log(this.location, 'location');
    }

    ngAfterViewInit() {
        
    }

    getUsersNotifications() {
        this.limeProxiesService.get_user_notifications_settings().subscribe(
            success => {
                if (success['_user_message'] === 'success') {
                    if (typeof success['events']['product_renewal']['email'] !== 'undefined') {
                        this.notifications.product_renewal.email = success['events']['product_renewal']['email'];
                    }
                    if (typeof success['events']['product_renewal']['sms'] !== 'undefined') {
                        this.notifications.product_renewal.sms = success['events']['product_renewal']['sms'];
                    }
                    if (typeof success['events']['assignment_of_proxies']['email'] !== 'undefined') {
                        this.notifications.assignment_of_proxies.email = success['events']['assignment_of_proxies']['email'];
                    }
                    if (typeof success['events']['assignment_of_proxies']['sms'] !== 'undefined') {
                        this.notifications.assignment_of_proxies.sms = success['events']['assignment_of_proxies']['sms'];
                    }
                    if (typeof success['events']['termination_of_proxies']['email'] !== 'undefined') {
                        this.notifications.termination_of_proxies.email = success['events']['termination_of_proxies']['email'];
                    }
                    if (typeof success['events']['termination_of_proxies']['sms'] !== 'undefined') {
                        this.notifications.termination_of_proxies.sms = success['events']['termination_of_proxies']['sms'];
                    }
                    if (typeof success['events']['outage_alert']['email'] !== 'undefined') {
                        this.notifications.outage_alert.email = success['events']['outage_alert']['email'];
                    }
                    if (typeof success['events']['outage_alert']['sms'] !== 'undefined') {
                        this.notifications.outage_alert.sms = success['events']['outage_alert']['sms'];
                    }
                    if (typeof success['events']['bandwidth_alert']['email'] !== 'undefined') {
                        this.notifications.bandwidth_alert.email = success['events']['bandwidth_alert']['email'];
                    }
                    if (typeof success['events']['bandwidth_alert']['sms'] !== 'undefined') {
                        this.notifications.bandwidth_alert.sms = success['events']['bandwidth_alert']['sms'];
                    }

                    this.prevNotificationsState = this.notifications;
                }

            }, error => {
                console.log(error, 'getUsersNotifications');
                this.limeProxiesService.showMessage(error, 'error');
            }
        );
    }

    ngOnInit() {
        this.validation_step1 = this.fb.group({
            'target': ['', Validators.required],
            'other_target': ['',  Validators.compose([Validators.minLength(0), Validators.maxLength(25)])],
            'website': ['', Validators.required],
            'other_website': ['',  Validators.compose([Validators.minLength(0), Validators.maxLength(25)])],
            'estimated_quota': ['', Validators.required],
            'estimated_budget': ['', Validators.required],
            'user_product_interested': this.fb.array([], Validators.required),
            'preferred_locations': [],
            'comments': []
        });

        this.limeProxiesService.getUserAccount().subscribe(
            usr_acc_resp => {
                this.user_auth_method = usr_acc_resp.auth_method;
                this.user_access_type = usr_acc_resp.access_type;
            },
        );
        this.limeProxiesService.userSubject.subscribe(
            user => {
                this.user_auth_method = user.auth_method;
                this.user_access_type = user.access_type;
            },
            error => console.log(error)
        );

        this.activatedRoute.params.subscribe( params => {
            if (!params['slug']) {
                this.current_tab = 'profile';
            } else {
                if (params['slug'] === 'authentication' || params['slug'] === 'notifications' || params['slug'] === 'personalize') {
                    if (params['slug'] === 'notifications') {
                        if (this.limeProxiesService.environment['show_notifications']) {
                            this.current_tab = params['slug'];
                        } else {
                            this.current_tab = 'profile';
                        }
                    } else {
                        this.current_tab = params['slug'];
                    }
                }
            }
        });
        this.getUsersNotifications();
        this.limeProxiesService.getUserProfile()
            .subscribe(
                profile => {
                    this.profile = profile;

                    for (let field of Object.keys(this.user)) {
                        if (typeof this.profile[field] !== 'undefined') {
                            this.user[field] = this.profile[field];
                        }
                    }
					
					this.init_tel_input();

                    if (this.iti && this.profile.phone) {						
                        this.iti.setNumber(this.profile.phone);
                    }
                    this.validation_step1.controls['target'].setValue(this.profile.use_proxy_for);
                    this.validation_step1.controls['other_target'].setValue(this.profile.use_proxy_for);
                    this.validation_step1.controls['website'].setValue(this.profile.proxy_website_usage);
                    this.validation_step1.controls['other_website'].setValue(this.profile.proxy_website_usage);
                    this.validation_step1.controls['comments'].setValue(this.profile.comments);
                    this.validation_step1.controls['preferred_locations'].setValue(this.profile.preferred_locations);
                    this.validation_step1.controls['estimated_quota'].setValue(this.profile.estimated_quota);
                    this.validation_step1.controls['estimated_budget'].setValue(this.profile.estimated_budget);

                    // this.validation_step1.controls['user_product_interested'].setValue(this.profile.products_interested.split(','));

                    let checkArray: FormArray = this.validation_step1.get('user_product_interested') as FormArray;

                    this.profile.products_interested.split(',').forEach((value, index) => {
                        checkArray.push(new FormControl(value));
                    });
                }
            );

        this.estimatedBudget = this.limeProxiesService.estimatedBudget;
        this.estimatedBandwidthUsageMonth = this.limeProxiesService.estimatedBandwidthUsageMonth;
        this.userProductInterested = this.limeProxiesService.userProductInterested;

        this.limeProxiesService.get_proxy_usage_opt().subscribe(
            data => {
                let array = [];
                data.data.forEach(value => {
                    array.push({text: value.name, value: value.slug});
                });
                array.push({text: 'Others', value: 'others'});
                this.proxy_usage_opt = array;
            }, error => {
                console.log(error, 'get_proxy_usage_opt');
            }
        );
        this.limeProxiesService.get_proxy_website_usage_opt().subscribe(
            data => {
                let array = [];
                data.data.forEach(value => {
                    array.push({text: value.name, value: value.url});
                });
                array.push({text: 'Others', value: 'others'});
                this.proxy_website_usage_opt = array;
            }, error => {
                console.log(error, 'get_proxy_website_usage_opt');
            }
        );
    }

    isChecked = (value) => {
        return this.validation_step1.controls['user_product_interested'].value.includes(value);
    }

    onCheckboxChange = (e) => {
        const checkArray: FormArray = this.validation_step1.get('user_product_interested') as FormArray;

        if (e.target.checked) {
            checkArray.push(new FormControl(e.target.value));
        } else {
            let i: number = 0;

            checkArray.controls.forEach((item: FormControl) => {
                if (item.value === e.target.value) {
                    checkArray.removeAt(i);
                    return;
                }

                i++;
            });
        }
    }

    savePrefrences  = () => {

        let data = this.getFirstStepData();

        if (!data) {
            return false;
        }
        if (this.validation_step1.valid) {
            this.limeProxiesService.showApiLoader(true);
            this.limeProxiesService.wizardStepOne(data).subscribe((target_resp) => {
                this.limeProxiesService.notifyBarNew('Product Usage Preference has been updated successfully.', 'success');
                return false;
            });
        }
    }

    getFirstStepData() {
        let data = {
            traffic_type : this.validation_step1.value.target, traffic_type_other: this.validation_step1.value.other_target,
            website : this.validation_step1.value.website, other_website: this.validation_step1.value.other_website,
            estimated_quota: this.validation_step1.value.estimated_quota,
            estimated_budget: this.validation_step1.value.estimated_budget,
            user_product_interested: this.validation_step1.value.user_product_interested,
            comments: this.validation_step1.value.comments,
            preferred_locations: this.validation_step1.value.preferred_locations
        };

        if ((data.traffic_type == '' || data.traffic_type.length < 1)  
            || (data.traffic_type == 'others' && (data.traffic_type_other == '' || data.traffic_type_other.length < 1))) {
            this.limeProxiesService.notifyBarNew('Validation, mention why you use proxies.', 'error');
            return false;
        }

        if ((data.website == '' || data.website.length < 1) 
            ||  (data.website == 'others' && (data.other_website == '' || data.other_website.length < 1))) {
            this.limeProxiesService.notifyBarNew('Validation, mention which website you want to use.', 'error');
            return false;
        }

        if (data.estimated_quota == '' || data.estimated_quota.length < 1) {
            this.limeProxiesService.notifyBarNew('Validation, mention What\'s your estimated bandwidth usage/month.', 'error');
            return false;
        }

        if (data.estimated_budget == '' || data.estimated_budget.length < 1) {
            this.limeProxiesService.notifyBarNew('Validation, mention What\'s your monthly budget for the proxy subscription.', 'error');
            return false;
        }


        if (data.user_product_interested == '' || data.user_product_interested.length < 1) {
            this.limeProxiesService.notifyBarNew('Validation, mention the products interested.', 'error');
            return false;
        }

        return data;
    }

    setCurrentTab(tab: any) {
        this.current_tab = tab;
        this.location.replaceState(this.current_tab === 'profile' ? 'my-profile' : 'my-profile/' + this.current_tab);

        if (this.current_tab === 'profile') {
            setTimeout(() => {
               this.init_tel_input();
            }, 200);
        }
    }

    hide_pass_message($event) {
        this.pass_change = !($event === '1');
    }
	
	public updatePasswordProgress = false;

    updatePassword() {
		this.newPassword 		= this.newPassword.trim();
		this.confirmPassword 	= this.confirmPassword.trim();

        if (this.newPassword['length'] < 8) {
            this.limeProxiesService.notifyBarNew('Minimum password length is 8 characters', 'error');
        } else if (this.newPassword !== this.confirmPassword) {
            this.limeProxiesService.notifyBarNew('New and confirmation password must be the same', 'error');
        } else {
			if (this.updatePasswordProgress) {
				return false;
			}
	
			this.updatePasswordProgress = true;
			let curObj = this;

            const obj = {password: this.newPassword};
            this.limeProxiesService.updateUserProfile(obj)
                .subscribe(
                    success => {
						curObj.updatePasswordProgress = false;
                        // this.openModal('#update_auth');
                        // this.limeProxiesService.showMessage(success);

                        if (success['auth_token']) {
                            let user = JSON.parse(localStorage.getItem('userId'));

                            const time = new Date();
                            time.setTime(time.getTime() + (success['auth_ttl'] * 60 * 1000));
                            localStorage.setItem('userId', JSON.stringify({userId: user && user.userId, expire: time.getTime()}));
                            localStorage.setItem('token', JSON.stringify({token: success['auth_token'], expire: time.getTime()}));
                        }

                        this.openModal('#update_auth');
                        this.limeProxiesService.getUserNotification()
                            .subscribe(
                                success => {
                                    this.limeProxiesService.userNotifications = success;
                                },
                                error => this.limeProxiesService.checkStatusCodeOnError(error, this)
                            );
                        this.newPassword = '';
                        this.confirmPassword = '';
                    },
                    error => {
						curObj.updatePasswordProgress = false;
                        // this.limeProxiesService.showMessage(error);
						this.limeProxiesService.notifyBarNew('Minimum password length is 8 characters', 'error');
                    }
                );
        }
    }
	
	public updateProfileProcessing = false;

    updateProfile() {
		let curObject = this;
			
		if (curObject.updateProfileProcessing) {
			return false;
		}
			
        let fields = {};

        for (let field of Object.keys(this.user)) {
            if (typeof this.profile[field] !== 'undefined' && field !== 'phone' && field !== 'email') {
                if (this.profile[field] !== this.user[field]) {
                    fields[field] = this.user[field];
					
					switch (field) {
						case 'first_name':
							if (!regxName.test(this.user[field])) {
								this.limeProxiesService.notifyBarNew('Enter valid first name.', 'error');
								return false;
							}
							break;
						case 'last_name':
							if (!regxName.test(this.user[field])) {
								this.limeProxiesService.notifyBarNew('Enter valid last name.', 'error');
								return false;
							}
							break;
					}
                }
            }
        }

        if (this.profile.phone !== this.user.phone) {
			if (!regxPhone.test(this.user.phone) && !this.iti.isValidNumber()) {
				this.limeProxiesService.notifyBarNew('Enter valid phone number.', 'error');
				return false;
			}

			curObject.updateProfileProcessing = true;
            this.limeProxiesService.updateUserProfile({phone: this.user.phone, country: this.user.country['iso2']}).subscribe(
                success => {
					curObject.updateProfileProcessing = false;
                    if (success['_user_message_type'] === 'success') {
                        this.sendSmsCode();
                    } else {
                        this.limeProxiesService.showMessage(success);
                    }
                }, error => {
					console.log('error', error);
					curObject.updateProfileProcessing = false;
                    this.limeProxiesService.showMessage(error);
                }
            );
        } else if (this.profile.email !== this.user.email) {
            this.showOtpField = false;
			curObject.updateProfileProcessing = true;
            this.limeProxiesService.updateUserProfile({email: this.user.email}).subscribe(
                success => {
					curObject.updateProfileProcessing = false;
                    if (success['_user_message_type'] === 'success') {
                        this.openModal('#update_email');
                    } else {
                        this.limeProxiesService.showMessage(success);
                    }
                }, error => {
					curObject.updateProfileProcessing = false;
                    this.limeProxiesService.showMessage(error);
                }
            );
        } else if (Object.keys(fields).length > 0) {
            this.showOtpField = false;
			
			curObject.updateProfileProcessing = true;
            this.limeProxiesService.updateUserProfile(fields).subscribe(
                success => {
					curObject.updateProfileProcessing = false;
                    this.limeProxiesService.showMessage(success);
                }, error => {
					curObject.updateProfileProcessing = false;
                    this.limeProxiesService.showMessage(error);
                }
            );
        }
    }

    sendSmsCode() {
        this.limeProxiesService.resendSms({
            phone: this.user.phone,
        }).subscribe((data) => {
            if (data['_user_message_type'] === 'success') {
                this.showOtpField = true;
                this.openModal('#update_phone');
            } else {
                this.limeProxiesService.showMessage(data);
            }
        }, (error) => {
            this.limeProxiesService.showMessage(error);
        });
    }

    verifyOTP() {
        if (this.otpCode) {
            if (this.otpCode.length > 4) {
                this.limeProxiesService.notifyBarNew('OTP code must contains max 4 characters.', 'error');
            } else {
                this.limeProxiesService.validateCODE(this.otpCode).subscribe((data) => {
					if (data.valid) {
						this.showOtpField = false;
					}
                    this.limeProxiesService.showMessage(data);
                    
                }, (error) => {
                    this.limeProxiesService.showMessage(error);
                });
            }
        }
    }

    updateNotifications() {

        let temp = this.notifications;

        for (let key of Object.keys(this.notifications)) {
            for (let field of Object.keys(this.notifications[key])) {
                this.notifications[key][field] = (this.notifications[key][field] === true || this.notifications[key][field] === 1) ? 1 : 0;
            }
        }

        this.limeProxiesService.update_user_notifications({events: this.notifications}).subscribe(
            success => {
                // this.limeProxiesService.showMessage(success);
            }, error => {
                this.limeProxiesService.showMessage(error);
                this.notifications = this.prevNotificationsState;
            }
        );
    }

    openModal(modal: any) {
        this.modalId = modal;

        setTimeout(() => {
            let elem = document.querySelector('.open-modal') as HTMLElement;
            if (elem) {
                elem.click();
            }
        }, 300);
    }
	
	init_tel_input() {
        const tel_input_selector = document.querySelector('#phone');
        if (tel_input_selector) {
            if (this.iti) {
                this.iti.destroy();
            }
            this.iti = tel_input(tel_input_selector, {
                utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
				initialCountry: this.user['country']
            });
			
            if(this.profile.phone !== undefined && this.profile.phone !== null && this.profile.phone.trim() !== '') {
			    this.iti.setNumber(this.profile.phone);
            }
        } else {
            const __this = this;
            setTimeout(() => {
                __this.init_tel_input();
            }, 100);
        }
    }
	
    phone_entered(ev: any = null) {
        this.user.phone = this.iti.getNumber();
        this.user.country = this.iti.getSelectedCountryData();
    }
}