import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { UserAccount } from '../../entities/user-account';
import {AccountSidebarComponent} from './account-sidebar.component';
import {Router} from '@angular/router';
import { UserService } from 'src/app/services/user.service';
declare var analytics: any;

@Component({
    selector: 'my-account',
    templateUrl: '../../templates/account/my-account.template.html'
})

export class MyAccountComponent implements OnInit {

  @HostBinding('class') productsClass = 'page__main-col';
  @ViewChild('accountSidebar', { static: true }) accountSidebar: AccountSidebarComponent;


  public user_acc: UserAccount;

    public selected_auth_method;
    public selected_access_type;
    public selected_quota;

    public access_type_btn_name = 'REQUEST UPDATE';
    public quota_btn_name = 'REQUEST UPDATE';

    public quota;
    public quota_size = 'mb';
    public user_auth_method = '';
    public user_access_type;

    public successMessage;
    public errorMessage;

    public profiles_auth_methods = [
        {display: 'ip based', value: 'ip'}, {display: 'user based', value: 'user'}
    ];

    public profile_access_types = [
        {display: 'whitelist', value: 'whitelist'},
        {display: 'full access', value: 'blacklist'}
    ];

    public profile_quotas = [
        {display: '100 MB', value: '100'},
        {display: '250 MB', value: '250'},
        {display: '500 MB', value: '500'},
        {display: '1 GB', value: '1000'},
        {display: '5 GB', value: '5000'},
        {display: '1 TB', value: '1000000'},
        {display: 'Unlimited', value: 'unlimited'}
    ];


    constructor(public limeProxiesService: LimeProxiesService, private router: Router, public userService: UserService) {
        this.user_acc = new UserAccount(
            'whitelist',
            'ip',
            '0',
            '0',
            'no',
            '0',
            '0000-00-00 00:00:00'
        );
        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
            analytics.page('Account');
        }
    }

    updateAuthMethod() {
        const obj = {};
        if (this.selected_auth_method !== this.user_acc.auth_method) {obj['auth_method'] = this.selected_auth_method; }
        if (Object.keys(obj).length === 0) {return; }
        this.limeProxiesService.updateUserAccount(true, obj)
            .subscribe(
                success => {
                  this.user_auth_method = success['auth_method'];
                  this.accountSidebar.updateUserSubject();
                  this.limeProxiesService.showMessage(success);
                },
                error => {
                    this.limeProxiesService.showMessage(error);
                }
            );
    }

    requestUpdateAccessType() {
        const obj = {};
        if (this.selected_access_type !== this.user_acc.access_type) {obj['access_type'] = this.selected_access_type; }
        if (Object.keys(obj).length === 0) {return; }
        this.limeProxiesService.updateUserAccount(false, obj)
            .subscribe(
                success => {
                    this.accountSidebar.updateUserSubject();
                    this.user_auth_method = success['auth_method'];
                    this.limeProxiesService.showMessage(success);
                    if (success['_user_message'] !== 'no update required') {
                        this.access_type_btn_name = 'PENDING UPDATE';
                    }
                },
                error => {
                    this.limeProxiesService.showMessage(error, 'error');
                }
            );
    }

    requestUpdateQuota() {
        const obj = {};
        if (this.selected_quota !== this.user_acc.quota) {obj['quota'] = this.selected_quota; }
        if (Object.keys(obj).length === 0) {return; }
        this.limeProxiesService.updateUserAccount(false, obj)
            .subscribe(
                success => {
                    this.accountSidebar.updateUserSubject();
                    this.user_auth_method = success['auth_method'];
                    this.limeProxiesService.showMessage(success);
                    this.quota_btn_name = 'PENDING UPDATE';
                },
                error => {
                    this.limeProxiesService.showMessage(error, 'error');
                }
            );
    }

    setAuthMethodSelected(auth_method) {
        this.selected_auth_method = auth_method;
    }

    setAccessTypeSelected(access_type) {
        this.selected_access_type = access_type;
    }

    setQuotaSelected(quota) {
        this.selected_quota = quota;
    }

    setProfileQuotas(get_quota) {
        const new_quotas = [];
        if (get_quota === 'unlimited') {
            this.profile_quotas = [{display: 'Unlimited', value: 'unlimited'}];
            return;
        }
        for (const quota of this.profile_quotas) {
            if (parseInt(quota.value, null) >= parseInt(get_quota, null)) {new_quotas.push(quota); }
        }
        this.profile_quotas = new_quotas;
    }
	
	public currentUser = {
		role: ''
	};

    ngOnInit() {
		let curObject = this;

		this.userService.getUser().subscribe(user => {
			curObject.currentUser = user;
		});
		
        this.limeProxiesService.getUserAccount().subscribe(
            usr_acc_resp => {
                const usr_acc_local: any = usr_acc_resp;
                this.user_acc = usr_acc_resp;
                this.selected_auth_method = usr_acc_resp.auth_method;
                this.selected_access_type = usr_acc_resp.access_type;
                this.selected_quota = usr_acc_resp.quota;

                if (usr_acc_local.user_request !== undefined) {
                    if (usr_acc_local.user_request.access_type !== undefined) {
                        this.access_type_btn_name = 'PENDING UPDATE';
                        this.selected_access_type = usr_acc_local.user_request.access_type;
                    } else {
                        this.access_type_btn_name = 'REQUEST UPDATE';
                        this.selected_access_type = usr_acc_resp.access_type;
                    }
                    if (usr_acc_local.user_request.quota !== undefined) {
                        this.quota_btn_name = 'PENDING UPDATE';
                        this.selected_quota = usr_acc_local.user_request.quota;
                    } else {
                        this.quota_btn_name = 'REQUEST UPDATE';
                        this.selected_quota = usr_acc_resp.quota;
                    }
                }
                this.user_auth_method = usr_acc_resp.auth_method;
                this.user_access_type = usr_acc_resp.access_type;

                localStorage.setItem('user_auth_method', this.user_auth_method);
                localStorage.setItem('user_access_type', this.user_access_type);

                this.setProfileQuotas(this.selected_quota);
            },
        );

        this.limeProxiesService.getProxyAuthorizedIps()
            .subscribe(
                ips => {
                    const arr = ips.authorized_list.split(' ');
                    console.log(arr, 'arr');
                    if (arr && arr.length > 0 && arr[0].length > 0) {this.limeProxiesService.userSubjectIPUpdate.emit({type: 'authorized_ips', value: arr.length}); }
                }
            );
    }
}
