import {NgModule, ErrorHandler} from '@angular/core';
// import {RavenErrorHandler} from './ravenConfig';
import * as Sentry from "@sentry/angular";
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import {AuthGuard} from './auth.guard';

import {LoginModule} from './components/login/login.module';
import {InvoiceModule} from './components/invoice/invoice.module';
import {ReceiptModule} from './components/receipt/receipt.module';
import {MainFrameModule} from './components/main-frame/main-frame.module';

import {routing} from './app.routing';

import {AppComponent} from './components/app.component';
import {ErrorComponent} from './components/error.component';

import {LimeProxiesService} from './services/limeproxies.service';
import {AuthService} from './services/auth.service';

import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {Error500Component} from './components/main-frame/error-500.component';
import {ConfirmDialog} from './components/confirm-dialog.component';
import {DialogService} from './services/dialog.service';
import {HelpersService} from './services/helpers.service';
import {ProductsModule} from './components/products/products.module';
import {AccountModule} from './components/account/account.module';
import {BillingModule} from './components/billing/billing.module';
import {AffiliateModule} from './components/affiliate/affiliate.module';
import {HelpModule} from './components/help/help.module';
import {ApiModule} from './components/api/api.module';
import {BillingConfirmComponent} from './components/billing/billing-confirm.component';
import { NewProfileComponent } from './components/new_profile/new-profile.component';
import { NewBillingComponent } from './components/new-billing/new-billing.component';
// import { NewCardComponent } from './components/new-billing/new-card.component';
// import { ShippingAddressComponent } from './components/new-billing/shipping-address.component';
import { SharedModule } from './shared.module';


import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressBarModule} from '@angular/material';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LmpNotificationsComponent } from './components/lmp-notifications/lmp-notifications.component';
import { ChartModule, LegendService, DataLabelService, CategoryService, LineSeriesService, TooltipService } from '@syncfusion/ej2-angular-charts';

// import {MatProgressBarModule} from '@angular/material/progress-bar';
// import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    BrowserModule,
    routing,
    LoginModule,
    MainFrameModule,
    InvoiceModule,
    ReceiptModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    ApiModule,
    ProductsModule,
    AccountModule,
    BillingModule,
    AffiliateModule,
    HelpModule,
    // MatProgressBarModule
    BrowserAnimationsModule, 
    MatProgressBarModule,
    SharedModule,
    ChartModule 
  ],
  declarations: [
    AppComponent,
    ErrorComponent,
    Error500Component,
    ConfirmDialog,
    BillingConfirmComponent,
    NewProfileComponent,
    NewBillingComponent,
    LmpNotificationsComponent,
    // NewCardComponent,
    // ShippingAddressComponent
  ],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useClass: RavenErrorHandler
    // },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    AuthGuard,
    LimeProxiesService,
    AuthService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    DialogService,
    HelpersService,
	  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    CategoryService, 
    LineSeriesService,
    LegendService, 
    DataLabelService,
    TooltipService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
