import { Component, HostBinding, } from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';
@Component({
    selector: 'account',
    templateUrl: '../../templates/account/account.template.html'
})

export class AccountComponent {

    @HostBinding('class') productsClass = 'page__content-inner';

    constructor(public limeProxiesService: LimeProxiesService) {
        limeProxiesService.setTitle('Account');
    }
}