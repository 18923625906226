import { Component, HostBinding, OnInit, NgZone } from "@angular/core";
import { LimeProxiesService } from "../../services/limeproxies.service";
import { AuthService } from "../../services/auth.service";
import { UserCredentials } from "../../entities/user-credentials";
import { Router } from "@angular/router";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import * as tel_input from "intl-tel-input";
import { MyValidators } from "../../custom-validators/custom.validator";
declare var analytics: any;

@Component({
  selector: "login-wizard",
  templateUrl: "../../templates/login/login-wizard.template.html",
})
export class LoginWizardComponent implements OnInit {
  @HostBinding("class") myClass = "page__content-inner height-100";

  private creds: UserCredentials;
  private username;
  private password;
  public iti: any;
  private onRequest: any = false;
  private trialActivated = false;
  public ips = [
    {
      value: "",
      error: "",
    },
  ];
  public cc = {
    card_name: {
      value: "",
      has_error: false,
      test: /[a-zA-Z_ ]{2,}/,
      touched: false,
    },
    card_number: {
      value: "",
      has_error: false,
      test: /^((67\d{2})|(4\d{3})|(5[1-5]\d{2})|(6011))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7]\d{13}$/,
      touched: false,
    },
    card_exp: {
      value: "",
      has_error: false,
      test: /^((0[1-9])|(1[0-2]))[\/\.\-]*((0[8-9])|(1[1-9]))$/,
      touched: false,
    },
    cvv: {
      value: "",
      has_error: false,
      test: /^([0-9]{3,4})$/,
      touched: false,
    },
    valid: false,
  };
  public phone_number = {
    value: "",
    has_error: false,
    touched: false,
  };
  public phone_valid_code = {
    value: "",
    has_error: false,
    touched: false,
  };
  public getted_proxy = {
    type: "",
    ip: "",
    port: "",
    auth: "",
  };
  public phone_validation_stage = 0;
  public selected_country: any;
  public ip_validated = false;
  public valid_fails = true;
  public phone_validated = false;
  public steps_count = [1, 2, 3, 4];
  public stepsToShow = [];
  public current_step = 1;
  public validation_step1: FormGroup;
  public cc_form: FormGroup;
  public step_title = "";
  public step_title_array = [
    "Setup your Free Trial Proxy",
    "Setup your Proxy Access Authorization",
    "Verify your account",
    // 'Start your free trial',
    "Your Trial proxy is now assigned",
  ];
  public currentIndex = 1;
  // public support_logo = require('../../../assets/images/new_signup/icon_support.svg').default;
  // public phone_logo = require('../../../assets/images/new_signup/icon_validate_phone.svg').default;
  // public icon_payment = require('../../../assets/images/new_signup/icon_payment.svg').default;
  // public logo_visa = require('../../../assets/images/new_signup/logo_visa.png').default;
  // public logo_mastercard = require('../../../').default;
  // public social_ins = require('../../../assets/images/new_signup/icon_in.svg').default;
  // public social_in = require('../../../assets/images/new_signup/icon_ln.svg').default;
  public proxy_usage_opt = [];
  public proxy_website_usage_opt = [];
  public showPage = false;

  public estimatedBudget = [
    {
      value: "less than 10$",
      text: "less than 10$",
    },
    {
      value: "10$ to 100$",
      text: "10$ to 100$",
    },
    {
      value: "100$ to 1000$",
      text: "100$ to 1000$",
    },
    {
      value: "1000$ to 5000$",
      text: "1000$ to 5000$",
    },
    {
      value: "5000$ to 25,000$",
      text: "5000$ to 25,000$",
    },
    {
      value: "25,000$ +",
      text: "25,000$ +",
    },
  ];

  public estimatedBandwidthUsageMonth = [
    {
      value: "Upto 1 GB",
      text: "Upto 1 GB",
    },
    {
      value: "1 GB to 10 GB",
      text: "1 GB to 10 GB",
    },
    {
      value: "10 GB to 100 GB",
      text: "10 GB to 100 GB",
    },
    {
      value: "100 GB to 1 TB",
      text: "100 GB to 1 TB",
    },
    {
      value: "1 TB to 10 TB",
      text: "1 TB to 10 TB",
    },
    {
      value: "10 TB to 100 TB",
      text: "10 TB to 100 TB",
    },
    {
      value: "100 TB+",
      text: "100 TB+",
    },
    {
      value: "I dont know",
      text: "I dont know",
    },
  ];

  public userProductInterested = [
    {
      value: "Residential Rotating Proxies",
      text: "Residential Rotating Proxies",
    },
    {
      value: "Residential Static Proxies",
      text: "Residential Static Proxies",
    },
    {
      value: "Data center proxies",
      text: "Data center proxies",
    },
    {
      value: "Mobile Proxies",
      text: "Mobile Proxies",
    },
    {
      value: "Socks Proxies",
      text: "Socks Proxies",
    },
  ];

  constructor(
    public limeProxiesService: LimeProxiesService,
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private zone: NgZone
  ) {
    this.limeProxiesService.quizPage = 1;
    this.getInitialInfo();

    this.cc_form = fb.group({
      creditCard: ["", [MyValidators.validateCCNumber]],
      expirationDate: ["", [MyValidators.validateExpDate]],
      cvc: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      ],
      creditName: [
        "",
        [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      ],
    });

    this.validation_step1 = fb.group({
      target: ["", Validators.required],
      other_target: [
        "",
        Validators.compose([Validators.minLength(0), Validators.maxLength(25)]),
      ],
      website: ["", Validators.required],
      other_website: [
        "",
        Validators.compose([Validators.minLength(0), Validators.maxLength(25)]),
      ],
      estimated_quota: ["", Validators.required],
      estimated_budget: ["", Validators.required],
      user_product_interested: fb.array([], Validators.required),
      comments: [],
    });

    this.re_init_labels();
    this.limeProxiesService.showLoader(false);
    this.limeProxiesService.get_geo_info().subscribe(
      (resp) => {
        if (resp["ip"]) {
          this.ips[0].value = resp["ip"];
          this.validate_ip();
        }
      },
      (error) => {
        console.log(error, "get_geo_info");
      }
    );
    this.getScreenTwoDropDown();
  }

  ngOnInit() {}

  showSection: string = "ip"; // Variable to control which section is visible

  // Function to toggle the visibility of sections based on radio button selection
  toggleSection(section: string) {
    this.showSection = section;
  }

  validate_ip(event = null) {
    let valid = true;
    for (let i = 0; i < this.ips.length; ++i) {
      const ip = this.ips[i].value;
      if (!this.limeProxiesService.validateIPaddress(ip)) {
        valid = false;
        this.ips[i].error = "Please enter valid IP";
      } else {
        this.ips[i].error = "";
      }
    }
    this.ip_validated = valid;
  }

  getInitialInfo() {
    if (!this.onRequest) {
      this.onRequest = true;
      this.limeProxiesService.showApiLoader(true);
      this.limeProxiesService.getWizardInfo().subscribe(
        (success) => {
          this.onRequest = false;
          this.limeProxiesService.showApiLoader(false);
          if (typeof success["show"] !== "undefined" && success["show"]) {
            if (typeof success["trial"] !== "undefined") {
              this.trialActivated = success["trial"];
            }
            if (
              typeof success["website"] === "undefined" ||
              success["website"] === false ||
              success["website"] === null ||
              (typeof success["website"] === "string" &&
                success["website"].length === 0)
            ) {
              if (this.stepsToShow.indexOf(1) === -1) {
                this.stepsToShow.push(1);
              }
            }
            if (
              typeof success["traffic_type"] === "undefined" ||
              success["traffic_type"] === false ||
              success["traffic_type"] === null ||
              (typeof success["traffic_type"] === "string" &&
                success["traffic_type"].length === 0)
            ) {
              if (this.stepsToShow.indexOf(1) === -1) {
                this.stepsToShow.push(1);
              }
            }
            if (
              typeof success["authorized_list"] === "undefined" ||
              success["authorized_list"] === false ||
              success["authorized_list"] === null ||
              (typeof success["authorized_list"] === "string" &&
                success["authorized_list"].length === 0)
            ) {
              if (this.stepsToShow.indexOf(2) === -1) {
                this.stepsToShow.push(2);
              }
            }
            if (!success["sms_verified"]) {
              if (this.stepsToShow.indexOf(3) === -1) {
                this.stepsToShow.push(3);
              }
            }
            if (this.stepsToShow.length === 0) {
              this.router.navigate(["../home"]);
            } else {
              this.showPage = true;
              this.currentIndex = 0;
              this.limeProxiesService.quizPage = this.stepsToShow[0];
              if (this.limeProxiesService.quizPage === 3) {
                this.init_tel_input();
              }
            }
          } else {
            this.router.navigate(["../home"]);
          }
        },
        (error) => {
          this.onRequest = false;
          this.limeProxiesService.showApiLoader(false);
          this.router.navigate(["../home"]);
        }
      );
    }
  }

  addIp() {
    if (this.ips.length < 3) {
      this.ips.push({ value: "", error: "" });
    }
  }

  removeIp(index) {
    this.ips.splice(index, 1);
    if (!this.ips.length) {
      this.ip_validated = false;
    }
  }

  fail_validation() {
    this.phone_validation_stage = 0;
    this.re_init_labels();
  }

  re_init_labels() {
    this.step_title =
      this.step_title_array[this.limeProxiesService.quizPage - 1];
    this.phone_validation_stage = 0;
    if (this.limeProxiesService.quizPage === 3) {
      this.init_tel_input();
    }
  }

  init_tel_input() {
    const tel_input_selector = document.querySelector(".tel-input");
    if (tel_input_selector) {
      if (this.iti) {
        this.iti.destroy();
      }
      this.iti = tel_input(tel_input_selector, {
        preferredCountries: ["us", "gb"],
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        initialCountry: "auto",
        geoIpLookup: (success, failure) => {
          this.limeProxiesService.get_geo_info().subscribe(
            (data) => {
              const countryCode =
                data && data["country"] ? data["country"] : "";
              success(countryCode);
            },
            (error) => {
              console.log(error, "init_tel_input");
            }
          );
        },
      });
    } else {
      const __this = this;
      setTimeout(() => {
        __this.init_tel_input();
      }, 100);
    }
  }

  previuos() {
    this.limeProxiesService.quizPage--;
    this.re_init_labels();
  }

  next() {
    if (this.limeProxiesService.quizPage === 1) {
      this.update_profile();
    } else if (this.limeProxiesService.quizPage === 2) {
      this.update_ips();
    } else {
      if (this.limeProxiesService.quizPage < 4) {
        this.limeProxiesService.quizPage++;
        this.re_init_labels();
      }
    }
  }

  getScreenTwoDropDown() {
    this.limeProxiesService.get_proxy_usage_opt().subscribe(
      (data) => {
        let array = [];
        data.data.forEach((value) => {
          array.push({ text: value.name, value: value.slug });
        });
        array.push({ text: "Others", value: "others" });
        this.proxy_usage_opt = array;
      },
      (error) => {
        console.log(error, "get_proxy_usage_opt");
      }
    );
    this.limeProxiesService.get_proxy_website_usage_opt().subscribe(
      (data) => {
        let array = [];
        data.data.forEach((value) => {
          array.push({ text: value.name, value: value.url });
        });
        array.push({ text: "Others", value: "others" });
        this.proxy_website_usage_opt = array;
      },
      (error) => {
        console.log(error, "get_proxy_website_usage_opt");
      }
    );
  }

  update_profile() {
    if (this.limeProxiesService.isLoggedWithGoogle) {
      this.firstStepWithGoogle();
    } else {
      this.firstStepWithoutGoogle();
    }
  }

  getFirstStepData() {
    let data = {
      traffic_type: this.validation_step1.value.target,
      traffic_type_other: this.validation_step1.value.other_target,
      website: this.validation_step1.value.website,
      other_website: this.validation_step1.value.other_website,
      estimated_quota: this.validation_step1.value.estimated_quota,
      estimated_budget: this.validation_step1.value.estimated_budget,
      user_product_interested:
        this.validation_step1.value.user_product_interested,
      comments: this.validation_step1.value.comments,
    };

    if (
      data.traffic_type == "" ||
      data.traffic_type.length < 1 ||
      (data.traffic_type == "others" &&
        (data.traffic_type_other == "" || data.traffic_type_other.length < 1))
    ) {
      this.limeProxiesService.notifyBarNew(
        "Validation, mention why you use proxies.",
        "error"
      );
      return false;
    }

    if (
      data.website == "" ||
      data.website.length < 1 ||
      (data.website == "others" &&
        (data.other_website == "" || data.other_website.length < 1))
    ) {
      this.limeProxiesService.notifyBarNew(
        "Validation, mention which website you want to use.",
        "error"
      );
      return false;
    }

    if (data.estimated_quota == "" || data.estimated_quota.length < 1) {
      this.limeProxiesService.notifyBarNew(
        "Validation, mention What's your estimated bandwidth usage/month.",
        "error"
      );
      return false;
    }

    if (data.estimated_budget == "" || data.estimated_budget.length < 1) {
      this.limeProxiesService.notifyBarNew(
        "Validation, mention What's your monthly budget for the proxy subscription.",
        "error"
      );
      return false;
    }

    if (
      data.user_product_interested == "" ||
      data.user_product_interested.length < 1
    ) {
      this.limeProxiesService.notifyBarNew(
        "Validation, mention the products interested.",
        "error"
      );
      return false;
    }

    return data;
  }

  firstStepWithGoogle() {
    let data = this.getFirstStepData();
    if (!data) {
      return false;
    }
    if (!this.onRequest) {
      this.onRequest = true;
      this.limeProxiesService.showApiLoader(true);
      this.limeProxiesService.wizardStepOne(data).subscribe(
        (target_resp) => {
          this.limeProxiesService.showMessage(target_resp);
          if (
            target_resp["_user_message_type"] === "success" ||
            target_resp["_user_message_type"] === "info"
          ) {
            this.stepsToShow.splice(
              this.stepsToShow.indexOf(this.limeProxiesService.quizPage),
              1
            );
            if (this.stepsToShow.length > 0) {
              this.limeProxiesService.quizPage = this.stepsToShow[0];
            } else {
              this.router.navigate(["../home"]);
            }
            this.re_init_labels();
          }
          this.limeProxiesService.showApiLoader(false);
          this.onRequest = false;
        },
        (error) => {
          this.limeProxiesService.showApiLoader(false);
          this.onRequest = false;
          this.limeProxiesService.notifyBarNew(
            "Undefined error. Please try again later.",
            "error"
          );
        }
      );
    } else {
      this.limeProxiesService.notifyBarNew("Please wait...", "error");
    }
  }

  firstStepWithoutGoogle() {
    let data = this.getFirstStepData();

    if (!data) {
      return false;
    }
    if (this.validation_step1.valid) {
      if (!this.onRequest) {
        this.onRequest = true;
        this.limeProxiesService.showApiLoader(true);
        this.limeProxiesService.wizardStepOne(data).subscribe(
          (target_resp) => {
            this.limeProxiesService.showMessage(target_resp);
            if (
              target_resp["_user_message_type"] === "success" ||
              target_resp["_user_message_type"] === "info"
            ) {
              this.stepsToShow.splice(
                this.stepsToShow.indexOf(this.limeProxiesService.quizPage),
                1
              );
              if (this.stepsToShow.length > 0) {
                this.limeProxiesService.quizPage = this.stepsToShow[0];
              } else {
                this.router.navigate(["../home"]);
              }
              this.re_init_labels();
            }
            this.limeProxiesService.showApiLoader(false);
            this.onRequest = false;
          },
          (error) => {
            this.limeProxiesService.showApiLoader(false);
            this.onRequest = false;
            this.limeProxiesService.notifyBarNew(
              "Undefined error. Please try again later.",
              "error"
            );
          }
        );
      } else {
        this.limeProxiesService.notifyBarNew("Please wait...", "error");
      }
    }
  }

  update_ips() {
    if (this.showSection == "user") {
      const obj = {};
      obj["auth_method"] = this.showSection;
      if (Object.keys(obj).length === 0) {
        return;
      }
      this.limeProxiesService.updateUserAccount(true, obj).subscribe(
        (success) => {
          this.limeProxiesService.showMessage({
            _user_message: "Proxy Access Authorization updated successfully.",
          });
          if (
            this.limeProxiesService.environment["third_parties"]["analytics"][
              "enabled"
            ]
          ) {
            analytics.track("signup_ip_configured", {});
          }

          this.router.navigate(["../home"]);
        },
        (error) => {
          this.limeProxiesService.showMessage(error);
        }
      );
    } else {
      let ips_arr = [];
      for (let i = 0; i < this.ips.length; i++) {
        ips_arr.push(this.ips[i].value);
      }

      if (!this.onRequest) {
        this.onRequest = true;
        this.limeProxiesService.showApiLoader(true);
        this.limeProxiesService.wizardStepTwo(ips_arr).subscribe(
          (data) => {
            this.limeProxiesService.showMessage(data);
            if (
              data["_user_message_type"] == "success" ||
              data["_user_message_type"] == "info"
            ) {
              if (
                this.limeProxiesService.environment["third_parties"][
                  "analytics"
                ]["enabled"]
              ) {
                analytics.track("signup_ip_configured", {});
              }

              this.router.navigate(["../home"]);
              // this.stepsToShow.splice(this.stepsToShow.indexOf(this.limeProxiesService.quizPage), 1);
              // if (this.stepsToShow.length > 0) {
              //     this.limeProxiesService.quizPage = this.stepsToShow[0];
              // } else {
              //     this.router.navigate(['../home']);
              // }

              this.re_init_labels();
            } else {
              this.limeProxiesService.notifyBarNew(
                data["_user_message"],
                "error"
              );
            }
            this.limeProxiesService.showApiLoader(false);
            this.onRequest = false;
          },
          (error) => {
            this.limeProxiesService.showApiLoader(false);
            this.onRequest = false;
            this.limeProxiesService.notifyBarNew(
              "Undefined error. Please try again later.",
              "error"
            );
          }
        );
      } else {
        this.limeProxiesService.notifyBarNew("Please wait...", "error");
      }
    }
  }

  phone_entered(event: any) {
    this.phone_number.value = this.iti.getNumber();
    this.phone_number.has_error = !this.iti.isValidNumber();
    this.phone_number.touched = true;
    this.selected_country = this.iti.getSelectedCountryData();
  }

  opt_entered(event: any) {
    this.phone_valid_code.touched = true;
    this.phone_valid_code.has_error = false;

    if (this.phone_valid_code.value["length"] > 4) {
      this.limeProxiesService.notifyBarNew(
        "OTP code must contains max 4 characters.",
        "error"
      );
      this.phone_valid_code.has_error = true;
    }
  }

  request_otp(resend = false) {
    if (!this.phone_number.has_error && this.phone_number.touched) {
      if (!this.phone_number.has_error && this.phone_number.value) {
        if (!resend) {
          this.limeProxiesService
            .updateUserProfile({
              phone: this.phone_number.value,
              country: this.selected_country.iso2,
            })
            .subscribe(
              (data) => {
                if (
                  data["_user_message_type"] == "success" ||
                  data["_user_message_type"] == "info"
                ) {
                  this.sendSmsCode(true, resend);
                } else {
                  this.limeProxiesService.showMessage(data);
                }
              },
              (error) => {
                this.limeProxiesService.showMessage(error);
              }
            );
        } else {
          this.sendSmsCode();
        }
      }
    }
  }

  sendSmsCode(first: any = false, resend: any = false) {
    if (!this.onRequest) {
      this.onRequest = true;
      this.limeProxiesService.showApiLoader(true);
      this.limeProxiesService
        .resendSms({
          phone: this.phone_number.value,
        })
        .subscribe(
          (data) => {
            if (first) {
              this.limeProxiesService.showMessage(data);
              if (
                data["_user_message_type"] === "success" ||
                data["_user_message_type"] === "info"
              ) {
                if (data["_user_message"] === "no update required") {
                  this.phone_validation_stage++;
                  this.phone_valid_code.has_error = false;
                  this.phone_validated = true;
                } else {
                  if (!resend) {
                    this.phone_validation_stage++;
                  }
                }
              }
            } else {
              this.limeProxiesService.showMessage(data);
              if (
                data["_user_message_type"] === "success" ||
                data["_user_message_type"] === "info"
              ) {
              }
            }
            this.limeProxiesService.showApiLoader(false);
            this.onRequest = false;
          },
          (error) => {
            this.limeProxiesService.showApiLoader(false);
            this.onRequest = false;
            this.limeProxiesService.showMessage(error);
          }
        );
    } else {
      this.limeProxiesService.notifyBarNew("Please wait...", "error");
    }
  }

  verify_otp() {
    if (this.phone_valid_code.value && !this.phone_valid_code.has_error) {
      if (this.phone_valid_code.value["length"] > 4) {
        this.limeProxiesService.notifyBarNew(
          "OTP code must contains max 4 characters.",
          "error"
        );
      } else {
        if (!this.onRequest) {
          this.onRequest = true;
          this.limeProxiesService.showApiLoader(true);
          this.limeProxiesService
            .validateCODE(this.phone_valid_code.value)
            .subscribe(
              (data) => {
                this.limeProxiesService.showApiLoader(false);
                this.onRequest = false;
                this.limeProxiesService.showMessage(data);
                if (
                  data["_user_message_type"] === "success" ||
                  data["--"] === "info"
                ) {
                  this.phone_valid_code.has_error = false;
                  this.phone_validated = true;
                  if (!this.trialActivated) {
                    this.complete_register();
                  } else {
                    this.stepsToShow.splice(
                      this.stepsToShow.indexOf(
                        this.limeProxiesService.quizPage
                      ),
                      1
                    );
                    if (this.stepsToShow.length > 0) {
                      this.limeProxiesService.quizPage = this.stepsToShow[0];
                    } else {
                      this.router.navigate(["../home"]);
                    }
                  }
                  if (
                    this.limeProxiesService.environment["third_parties"][
                      "analytics"
                    ]["enabled"]
                  ) {
                    analytics.track("signup_phone_verified", {});
                  }
                } else {
                  this.valid_fails = true;
                }
              },
              (error) => {
                this.limeProxiesService.showApiLoader(false);
                this.onRequest = false;
                this.valid_fails = true;
                this.phone_validated = false;
                this.phone_valid_code.has_error = true;
                this.limeProxiesService.notifyBarNew(
                  "Undefined error. Please try again later.",
                  "error"
                );
              }
            );
        } else {
          this.limeProxiesService.notifyBarNew("Please wait...", "error");
        }
      }
    }

    return false;
  }

  complete_register() {
    if (!this.onRequest) {
      this.onRequest = true;
      this.limeProxiesService.showApiLoader(true);
      this.limeProxiesService.sendWizardProduct("premium").subscribe(
        (data) => {
          this.limeProxiesService.showMessage(data);
          if (
            data["_user_message_type"] === "success" ||
            data["_user_message_type"] === "info"
          ) {
            this.getted_proxy.type = data.proxy_type;
            this.getted_proxy.ip = data.proxy_ip;
            this.getted_proxy.port = data.proxy_port;
            this.getted_proxy.auth = data.proxy_auth;
            this.stepsToShow.splice(
              this.stepsToShow.indexOf(this.limeProxiesService.quizPage),
              1
            );
            this.stepsToShow = [];
            this.limeProxiesService.quizPage = 4;
            this.limeProxiesService.setUserProfile("register");
            this.step_title =
              this.step_title_array[this.limeProxiesService.quizPage - 1];
          }
          this.limeProxiesService.showApiLoader(false);
          this.onRequest = false;
        },
        (error) => {
          this.limeProxiesService.showApiLoader(false);
          this.onRequest = false;
          this.limeProxiesService.notifyBarNew(
            "Undefined error. Please try again later.",
            "error"
          );
        }
      );
    } else {
      this.limeProxiesService.notifyBarNew("Please wait...", "error");
    }
  }

  to_dashboard() {
    this.router.navigate(["../home"]);
  }

  cc_entered(key: string) {
    const obj = this.cc[key];
    this.cc[key].touched = true;
    this.cc[key].has_error = !obj.test.test(obj.value);

    const keys = Object.keys(this.cc);

    let valid_c = 0;

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] !== "valid") {
        if (!this.cc[keys[i]].has_error && this.cc[keys[i]].touched) {
          valid_c++;
        }
      }
    }

    this.cc.valid = valid_c === 4;
  }

  onCheckboxChange = (e) => {
    const checkArray: FormArray = this.validation_step1.get(
      "user_product_interested"
    ) as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;

      checkArray.controls.forEach((item: FormControl) => {
        if (item.value === e.target.value) {
          checkArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  };
}
