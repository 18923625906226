import { Component, HostBinding, OnInit} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { LimeProxiesService } from '../../services/limeproxies.service';

@Component({
    selector: 'auth-ip',
    templateUrl: '../../templates/account/auth-ip.template.html'
})

export class IpAuthComponent implements OnInit {

    @HostBinding('class') productsClass = 'page__main-col';

    public errorMessage = 'Current IP:';
    public successMessage;
    public user_auth_method;
    public user_access_type;
    public ip_address: any = '';
    public authorizedIPs = [];
    public tmpAuthorizedIPs = [];
    public isIpAuthorized = false;
    public numberOfAuthIps = 0;
    public numOfAuthFromGet;
    public authorizedUpdates = 0;
    public updatesLeft = 0;
    public ifDisabled = '';
    public noUpdatesLeft = false;

    constructor(public limeProxiesService: LimeProxiesService, public userService: UserService) {}

    ngOnInit() {
        this.getAuthorizedIPs();
        this.limeProxiesService.getUserAccount().subscribe(
            usr_acc_resp => {
                this.user_auth_method = usr_acc_resp.auth_method;
                this.user_access_type = usr_acc_resp.access_type;
            },
        );
        this.limeProxiesService.userSubject.subscribe(
            user => {
                this.user_auth_method = user.auth_method;
                this.user_access_type = user.access_type;
            },
            error => console.log(error)
        );
        this.limeProxiesService.getUserIpAddress()
            .subscribe(
                data => {
                    this.ip_address = data['ip'];
                },
                error => this.errorMessage = error
            );
    }

    Add(ip_address) {
        if (this.numberOfAuthIps === 0) {
            this.errorMessage = 'You have reached your max authorized IPs limit';
            return;
        }
        if (this.updatesLeft === 0) {
            this.errorMessage = 'You have reached your authorized IPs updates limit';
            return;
        }
        if (!this.limeProxiesService.validateIPaddress(ip_address)) {
            this.errorMessage = 'Invalid IP address';
            return;
        }
        let exists = false;
        for (const obj of this.authorizedIPs) {
            if (obj && obj.key === ip_address) {
                exists = true;
                break;
            }
        }
        if (!exists) {
            this.authorizedIPs.push(
                {
                    key: ip_address,
                    initialColor: '#1b4',
                    currentColor: '#1b4'
                }
            );
            this.checkIpAuthorized();
            this.numberOfAuthIps--;
            this.errorMessage = '';
            this.ip_address = '';
        } else {this.errorMessage = ip_address + ' already exists in your list!'; }
    }

    removeIp(obj) {
        const ip_address = obj.key;
        for (const element of this.authorizedIPs) {
            if (element && element.key === ip_address) {
                if (element.currentColor === '#f66') {
                    if (this.numberOfAuthIps === 0) {
                        this.errorMessage = 'You have reached you max authorized IPs limit';
                    } else {
                        element.currentColor = element.initialColor;
                        this.numberOfAuthIps--;
                    }
                } else {
                    element.currentColor = '#f66';
                    this.numberOfAuthIps++;
                }
            }
        }
    }

    authorizeIpAddress() {
        const arr = [];
        for (const element of this.authorizedIPs) { if (element && element.currentColor !== '#f66') {arr.push(element.key); } }
        this.updatesLeft--;
        if (this.updatesLeft === 0) {
            this.noUpdatesLeft = true;
            this.ifDisabled = 'btn--gray';
        } else {
            this.noUpdatesLeft = false;
            this.ifDisabled = '';
        }
        this.limeProxiesService.updateProxyAuthorizedIps(arr)
            .subscribe(
                success => {
                    this.limeProxiesService.showMessage(success);
                    this.authorizedIPs = [];
                    const arr = success.authorized_list.split(' ');
                    arr.forEach(element => {
                        this.authorizedIPs.push(
                            {
                                key: element,
                                initialColor: '#f0f4f7',
                                currentColor: '#f0f4f7'
                            }
                        );
                    });
                    this.limeProxiesService.userSubjectIPUpdate.emit({type: 'authorized_ips', value: this.authorizedIPs.length});
                    this.checkIpAuthorized();
                    this.updatesLeft = success['updates_left'];
                    this.authorizedUpdates = success['authorized_updates'];
                    this.numOfAuthFromGet = success['authorized_ips'];
                },
                error => {
                    this.limeProxiesService.showMessage(error, 'error');
                }
            );
    }

    getAuthorizedIPs() {
        this.limeProxiesService.getProxyAuthorizedIps()
            .subscribe(
                ips => {
					this.authorizedIPs = [];
                    const arr = ips.authorized_list.split(' ');
                    arr.forEach(element => {
                        this.authorizedIPs.push(
                            {
                                key: element,
                                initialColor: '#f0f4f7',
                                currentColor: '#f0f4f7'
                            }
                        );
                    });
                    if (arr && arr.length > 0 && arr[0].length > 0) {
                        this.limeProxiesService.userSubjectIPUpdate.emit({type: 'authorized_ips', value: this.authorizedIPs.length});
                    }
                    this.checkIpAuthorized();
                    this.numOfAuthFromGet = ips['authorized_ips'];
                    this.numberOfAuthIps = ips['authorized_ips'] - arr.length;
                    this.authorizedUpdates = ips['authorized_updates'];
                    this.updatesLeft = ips['updates_left'];
                    this.updatesLeft === 0 ? this.noUpdatesLeft = true : this.noUpdatesLeft = false;
                }
            );
    }

    checkIpAuthorized() {
        this.isIpAuthorized = false;
        this.tmpAuthorizedIPs = [];
        for (let i = 0; i < this.authorizedIPs.length; ++i) {
            if (this.authorizedIPs[i].key) {
                this.tmpAuthorizedIPs.push(this.authorizedIPs[i]);
                this.isIpAuthorized = true;
            }
        }
    }
}