<div class="breadcrumbs">
  <a [routerLink]="['/billing/new']" class="breadcrumbs__item" routerLinkActive="breadcrumbs__item--active">Add</a>
  <span class="separator separator--bullet"></span>
  <a [routerLink]="['/billing/history']" class="breadcrumbs__item"
     routerLinkActive="breadcrumbs__item--active">History</a>
</div>
<table class="block table table--highlight credit-history">
  <thead class="table__header">
  <tr>
    <th class="table__cell">Date</th>
    <th class="table__cell">Payment Method</th>
    <th class="table__cell">Status</th>
    <th class="table__cell">Transaction ID</th>
    <th class="table__cell table__cell--narrow">Value</th>
    <th class="table__cell">Payment receipt</th>
  </tr>
  </thead>
  <tbody *ngIf="historyFull" class="table__body">
  <tr *ngFor="let item of history | paginate: { itemsPerPage: itemsCount, currentPage: page }">
    <td class="table__cell table__cell_date">{{item.date}}</td>
    <td class="table__cell">{{item.method}}</td>
    <td class="table__cell">{{item.type}}</td>
    <td class="table__cell">{{item.transaction}}</td>
    <td class="table__cell table__cell--narrow">
      <span class="{{item.value > 0 ? 'text-green' : 'text-red'}}"
            [innerHTML]="(item.value > 0 ? '&#36;' + item.value : '-&#36;' + item.value * -1)"></span>
    </td>
    <td class="table__cell" *ngIf="item.reference">
      <a *ngIf="item.method == 'PayPal'" (click)="openReceipt(item)" class="btn-download-invoice">
        <img src="assets/images/invoice.png">
        View
      </a>
      <a *ngIf="item.method == 'FastSpring'" [href]="fastSpringInvoiceUrl + item.transaction"
         class="btn-download-invoice" target="_blank">
        <img src="assets/images/invoice.png">
        View
      </a>
    </td>
  </tr>
  </tbody>
  <tbody *ngIf="historyEmpty" class="table__body">
  <tr class="text-center no-transactions">
    <td class="table__cell" colspan="6">No transactions currently available, click <a [routerLink]="['/billing/new']">here</a>
      to add one.
    </td>
  </tr>
  </tbody>
</table>
<div *ngIf="historyFull" class="pagination-block">
  <pagination-controls (pageChange)="page = $event"></pagination-controls>
</div>