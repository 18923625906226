import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { MyAccountComponent } from './my-account.component';
import { MyTeamComponent } from './my-team.component';
import { IpAuthComponent } from './auth-ip.component';
import { UserWhitelistComponent } from './user-whitelist.component';
import { AuthorizedUserComponent } from './auth-user.component';


import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AccountSidebarComponent } from './account-sidebar.component';

@NgModule({
    declarations: [MyAccountComponent, IpAuthComponent, UserWhitelistComponent, AccountSidebarComponent, AuthorizedUserComponent, MyTeamComponent], // components, pipes and directives that are part of this module
    imports: [CommonModule, RouterModule, HttpClientModule, HttpClientJsonpModule, FormsModule, ReactiveFormsModule], // importing other modules
    exports: [AccountSidebarComponent]
})

export class AccountModule {

}