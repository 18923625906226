import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ReceiptComponent } from './receipt.component';
import { RouterModule } from '@angular/router';


@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, HttpClientJsonpModule, RouterModule],
    declarations: [ReceiptComponent],
    exports: [ReceiptComponent]
})
export class ReceiptModule {

}