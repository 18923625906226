import {Component} from '@angular/core';
import {DialogService} from '../services/dialog.service';
import {AuthService} from '../services/auth.service';
import { UserService } from '../services/user.service';

declare const Env: any;

@Component({
  selector: 'app-root',
  templateUrl: '../templates/app.template.html'
})


export class AppComponent {

  constructor(public dialogService: DialogService,
              private authService: AuthService,
              private userService: UserService) {
    this.authService.handleAuthentication();
	
	if (localStorage.getItem('userId') !== null) {
		this.userService.getUser().subscribe(user => {
		  this.userService.currentUser= user;
		});
	}

    if (Env && Env['third_parties'] && Env['third_parties']['analytics']) { // include segment analytics to application
        if (typeof Env['third_parties']['analytics']['enabled'] !== 'undefined' && Env['third_parties']['analytics']['enabled']) {
            let analyticsKey = Env['third_parties']['analytics']['key'];

            let analyticsString = '!function(){analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t){var e=document.createElement("script");e.type="text/javascript";e.async=!0;e.src=("https:"===document.location.protocol?"https://":"http://")+"cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};analytics.SNIPPET_VERSION="4.0.0";\n' +
                '          analytics.load("' + analyticsKey + '");\n' +
                '      }}();';
            let analyticsScript = document.createElement('script');
            analyticsScript.type = 'text/javascript';
            analyticsScript.innerHTML = analyticsString;
            document.head.appendChild(analyticsScript);
        }
    }
    if (Env && Env['third_parties'] && Env['third_parties']['chargebee']) { // include segment analytics to application
      if (typeof Env['third_parties']['chargebee']['enabled'] !== 'undefined' && Env['third_parties']['chargebee']['enabled']) {
          let chargebeeScript = document.createElement('script');
          chargebeeScript.setAttribute('data-cb-site', Env['third_parties']['chargebee']['cb_site']);
          chargebeeScript.src = Env['third_parties']['chargebee']['url'];
          document.head.appendChild(chargebeeScript);
      }
    }
  }
}
