import {AfterViewInit, Component, HostBinding} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';
declare const $: any;
declare const analytics: any;

@Component({
    selector: 'api',
    templateUrl: '../../templates/api/api.template.html'
})

export class ApiComponent implements AfterViewInit {

  @HostBinding('class') myClass = 'page__content-inner';

    public clipboardText = null;
    public apiToken: string | null;
    public apiTokenCallSuccess: boolean;

  constructor(public limeProxiesService: LimeProxiesService) {
    limeProxiesService.setTitle('Api');
    this.limeProxiesService.getNewToken()
      .subscribe(
          data => {
            this.apiToken = data.token;
          },
          err => {
            this.apiToken = null;
          },
      );
  }

  ngAfterViewInit() {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 173) {
        $('#link_menu_ul').addClass('link_menu');
      } else {
        $('#link_menu_ul').removeClass('link_menu');
      }
    });
    $('.api_popup-toggle').on('click', function(e) {
      e.preventDefault();
      const toggler = $(this),
      target = $(toggler.attr('data-popup-target'));
      target.addClass('popup--show');
    });

    $('.api_popup__dismiss').on('click', (e) => {
      e.preventDefault();
      this.apiTokenCallSuccess = false;
      $(e.target).closest('.popup').removeClass('popup--show');
    });
      if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
          analytics.track('account_check_api', {});
      }
  };

  getNewToken() {
    this.apiTokenCallSuccess = false;
    this.limeProxiesService.postNewToken()
        .subscribe(
            data => {
                this.apiTokenCallSuccess = true;
                this.clipboardText = data.token;
                $('#copyToClipboard').prop( 'disabled', false );
            }
        );
  }

  copyTextToClipboard() {
    const textArea: HTMLTextAreaElement = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    textArea.value = this.clipboardText;

    document.body.appendChild(textArea);

    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      this.limeProxiesService.notifyBarNew('Oops, unable to copy, Copy to clipboard: Ctrl+C', 'info');
    }

    document.body.removeChild(textArea);
  }
}
