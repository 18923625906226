<div class="breadcrumbs" *ngIf="role == 'Admin' || role == 'Owner'">
  <a
    routerLink="/products/new"
    class="breadcrumbs__item"
    routerLinkActive="breadcrumbs__item--active"
    >Add New</a
  >
  <span class="separator separator--bullet"></span>
  <a
    routerLink="/products/manage"
    class="breadcrumbs__item"
    routerLinkActive="breadcrumbs__item--active"
    >Manage</a
  >
</div>
<div class="page_block_inner">
  <form *ngIf="type == 'dedicated'" action="#" method="post">
    <h3>
      <a
        routerLink="/products/manage"
        class="breadcrumbs__item"
        style="background: #fff; padding: 10px"
        >Dedicated Proxies</a
      >
      <a routerLink="/products/manage/residential" class="breadcrumbs__item"
        >Residential Proxies</a
      >
    </h3>
    <table class="block table products-manage">
      <thead class="table__header table__header--small">
        <tr>
          <th class="table__cell">Product #</th>
          <th class="table__cell">Product Name</th>
          <th class="table__cell">Cost</th>
          <th class="table__cell">Proxy Number</th>
          <th class="table__cell">Renewal date</th>
          <th class="table__cell">Socks</th>
          <th class="table__cell table__cell--fit">Manage</th>
        </tr>
      </thead>
      <tbody class="table__body" *ngFor="let item of products; let i = index;">
        <tr>
          <td class="table__cell">{{item.id}}</td>
          <td class="table__cell">{{item.product}}</td>
          <td class="table__cell">${{item.cost}}</td>
          <td class="table__cell">{{item.no}}</td>
          <td class="table__cell">{{item.expiry | date}}</td>
          <td class="table__cell">
            <div
              (click)="switchSocks(item)"
              class="switch-toggle"
              [ngClass]="{'switch-toggle--on': item.socks}"
            >
              <label class="switch-toggle__item">
                <span class="switch-toggle__label">On</span>
              </label>
              <label class="switch-toggle__item">
                <span class="switch-toggle__label">Off</span>
              </label>
              <span class="switch-toggle__control"></span>
            </div>
          </td>
          <td class="table__cell table__cell--fit">
            <button
              type="button"
              class="btn btn--small btn--red"
              *ngIf="configPage == item.id"
              (click)="showConfig()"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn--small"
              *ngIf="configPage != item.id"
              (click)="showConfig(item.id)"
            >
              Configure
            </button>
            <ng-container *ngIf="!isResidentialProxy(item)">
              <button
                (click)="downloadProduct(item.id)"
                type="button"
                class="btn btn--small btn--dark-gray"
                *ngIf="canTerminate(item)"
              >
                Download
              </button>
            </ng-container>
          </td>
        </tr>
        <tr
          class="product-info"
          [style.display]="(configPage !== item.id) ? 'none' : 'table-row '"
          [class.showed]="animateId === item.id"
          *ngIf="canTerminate(item)"
        >
          <td class="table__cell" colspan="8">
            <div class="col-xs-12">
              <div class="row">
                <div class="col-xs-2">
                  <p class="col__desc">Auto Renewal</p>
                  <div
                    (click)="switchRenewal(item)"
                    class="switch-toggle"
                    [ngClass]="{'switch-toggle--on': item.auto}"
                  >
                    <label class="switch-toggle__item">
                      <span class="switch-toggle__label">On</span>
                    </label>
                    <label class="switch-toggle__item">
                      <span class="switch-toggle__label">Off</span>
                    </label>
                    <span class="switch-toggle__control"></span>
                  </div>
                </div>
                <div class="col-xs-10">
                  <ng-container *ngIf="!isResidentialProxy(item)">
                    <p class="col__desc">Options</p>
                    <button
                      type="button"
                      class="btn btn--small btn--dark-gray btn--action popup-toggle"
                      (click)="checkStatus(item.id)"
                    >
                      Check status
                    </button>
                    <button
                      type="button"
                      class="btn btn--small btn--dark-gray btn--action"
                      (click)="showRefresh(item)"
                    >
                      Refresh
                    </button>
                    <button
                      type="button"
                      *ngIf="!item.auto"
                      class="btn btn--small btn--dark-gray btn--action"
                      (click)="showRenew(item.id)"
                    >
                      Renew
                    </button>
                  </ng-container>
                  <ng-container *ngIf="isResidentialProxy(item)">
                    <div
                      class="row"
                      *ngIf="item.residential_status == 'Active'"
                    >
                      <div class="col-xs-4">
                        <p class="col__desc">Options</p>
                        <button
                          type="button"
                          *ngIf="canTerminate(item)"
                          class="btn btn--small btn--dark-gray btn--action"
                          (click)="showTerminate(item.id)"
                        >
                          Cancel
                        </button>
                      </div>
                      <div class="col-xs-8">
                        <p class="col__desc">Credentials</p>
                        Username: {{item.residential_username}}<br />
                        Password: {{item.residential_password}}
                      </div>
                    </div>
                    <div *ngIf="item.residential_status == 'Pending'">
                      Limeproxies team working on received proxy request,
                      credentials will be available in few. Contact
                      <a routerLink="/support/tickets">support</a> for further
                      information.
                    </div>
                    <div
                      *ngIf="item.residential_status == 'Expired' || item.residential_status == 'Inactive'"
                    >
                      Proxy is no longer available. Contact
                      <a routerLink="/support/tickets">support</a> for further
                      information.
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div
              class="col-xs-12"
              style="padding-top: 15px"
              *ngIf="isResidentialProxy(item)"
            >
              <app-tags [pid]="item.id"></app-tags>
            </div>
            <div
              class="col-xs-12"
              style="padding-top: 15px"
              *ngIf="!isResidentialProxy(item)"
            >
              <div class="row">
                <div class="col-xs-6">
                  <app-tags [pid]="item.id"></app-tags>
                </div>
                <div class="col-xs-6">
                  <app-usage
                    [pid]="item.id"
                    [proxyUsageOpt]="proxyUsageOpt"
                  ></app-usage>
                </div>
              </div>
            </div>
            <div
              class="col-xs-12"
              style="padding-top: 15px"
              *ngIf="!isResidentialProxy(item)"
            >
              <app-websites
                [pid]="item.id"
                [websitesList]="websitesList"
              ></app-websites>
            </div>
            <div class="col-xs-12" style="padding-top: 15px">
              <app-notes [pid]="item.id"></app-notes>
            </div>
          </td>
        </tr>
        <tr
          class="product-info"
          [style.display]="(configPage !== item.id) ? 'none' : 'table-row '"
          [class.showed]="animateId === item.id"
          *ngIf="!canTerminate(item)"
        >
          <td colspan="9">
            <div
              class="col-xs-12 validation"
              style="color: #f26d4f; font-weight: normal"
            >
              Product terminated
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="noProducts">
        <tr class="text-center no-products">
          <td class="table__cell" colspan="6">
            No active products, click
            <a [routerLink]="['/products/new']">here</a> to add one
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <form *ngIf="type == 'residential'" action="#" method="post">
    <h3>
      <a routerLink="/products/manage" class="breadcrumbs__item"
        >Dedicated Proxies</a
      >
      <a
        routerLink="/products/manage/residential"
        class="breadcrumbs__item"
        style="background: #fff; padding: 10px"
        >Residential Proxies</a
      >
    </h3>
    <table class="block table products-manage">
      <thead class="table__header table__header--small">
        <tr>
          <th class="table__cell">Product #</th>
          <th class="table__cell">Product Name</th>
          <th class="table__cell">Cost</th>
          <th class="table__cell">Bandwidth</th>
          <th class="table__cell">Renewal date</th>
          <th class="table__cell table__cell--fit">Manage</th>
        </tr>
      </thead>
      <tbody
        class="table__body"
        *ngFor="let item of residentialProducts; let i = index;"
      >
        <tr>
          <td class="table__cell">{{item.id}}</td>
          <td class="table__cell">{{item.product}}</td>
          <td class="table__cell">${{item.cost}}</td>
          <td class="table__cell">{{item.oProxies.bandwidth}}</td>
          <td class="table__cell">{{item.expiry | date}}</td>
          <td class="table__cell table__cell--fit">
            <button
              type="button"
              class="btn btn--small btn--red"
              *ngIf="configPage == item.id"
              (click)="showConfig()"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn--small"
              *ngIf="configPage != item.id"
              (click)="showConfig(item.id)"
            >
              Configure
            </button>
            <ng-container *ngIf="!isResidentialProxy(item)">
              <button
                (click)="downloadProduct(item.id)"
                type="button"
                class="btn btn--small btn--dark-gray"
                *ngIf="canTerminate(item)"
              >
                Download
              </button>
            </ng-container>
          </td>
        </tr>
        <tr
          class="product-info"
          [style.display]="(configPage !== item.id) ? 'none' : 'table-row '"
          [class.showed]="animateId === item.id"
          *ngIf="canTerminate(item)"
        >
          <td class="table__cell" colspan="8">
            <div class="col-xs-12">
              <div class="row">
                <div class="col-xs-2">
                  <p class="col__desc">Auto Renewal</p>
                  <div
                    (click)="switchRenewal(item)"
                    class="switch-toggle"
                    [ngClass]="{'switch-toggle--on': item.auto}"
                  >
                    <label class="switch-toggle__item">
                      <span class="switch-toggle__label">On</span>
                    </label>
                    <label class="switch-toggle__item">
                      <span class="switch-toggle__label">Off</span>
                    </label>
                    <span class="switch-toggle__control"></span>
                  </div>
                </div>
                <div class="col-xs-10">
                  <ng-container *ngIf="!isResidentialProxy(item)">
                    <p class="col__desc">Options</p>
                    <button
                      type="button"
                      class="btn btn--small btn--dark-gray btn--action popup-toggle"
                      (click)="checkStatus(item.id)"
                    >
                      Check status
                    </button>
                    <button
                      type="button"
                      class="btn btn--small btn--dark-gray btn--action"
                      (click)="showRefresh(item)"
                    >
                      Refresh
                    </button>
                    <button
                      type="button"
                      *ngIf="!item.auto"
                      class="btn btn--small btn--dark-gray btn--action"
                      (click)="showRenew(item.id)"
                    >
                      Renew
                    </button>
                  </ng-container>
                  <ng-container *ngIf="isResidentialProxy(item)">
                    <div
                      class="row"
                      *ngIf="item.residential_status == 'Active'"
                    >
                      <div class="col-xs-4">
                        <p class="col__desc">Options</p>
                        <button
                          type="button"
                          *ngIf="canTerminate(item)"
                          class="btn btn--small btn--dark-gray btn--action"
                          (click)="showTerminate(item.id)"
                        >
                          Cancel
                        </button>
                      </div>
                      <div class="col-xs-8">
                        <p class="col__desc">Credentials</p>
                        Username: {{item.residential_username}}<br />
                        Password: {{item.residential_password}}
                      </div>
                    </div>
                    <div *ngIf="item.residential_status == 'Pending'">
                      Limeproxies team working on received proxy request,
                      credentials will be available in few. Contact
                      <a routerLink="/support/tickets">support</a> for further
                      information.
                    </div>
                    <div
                      *ngIf="item.residential_status == 'Expired' || item.residential_status == 'Inactive'"
                    >
                      Proxy is no longer available. Contact
                      <a routerLink="/support/tickets">support</a> for further
                      information.
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div
              class="col-xs-12"
              style="padding-top: 15px"
              *ngIf="isResidentialProxy(item)"
            >
              <app-tags [pid]="item.id"></app-tags>
            </div>
            <div
              class="col-xs-12"
              style="padding-top: 15px"
              *ngIf="!isResidentialProxy(item)"
            >
              <div class="row">
                <div class="col-xs-6">
                  <app-tags [pid]="item.id"></app-tags>
                </div>
                <div class="col-xs-6">
                  <app-usage
                    [pid]="item.id"
                    [proxyUsageOpt]="proxyUsageOpt"
                  ></app-usage>
                </div>
              </div>
            </div>
            <div
              class="col-xs-12"
              style="padding-top: 15px"
              *ngIf="!isResidentialProxy(item)"
            >
              <app-websites
                [pid]="item.id"
                [websitesList]="websitesList"
              ></app-websites>
            </div>
            <div class="col-xs-12" style="padding-top: 15px">
              <app-notes [pid]="item.id"></app-notes>
            </div>
          </td>
        </tr>
        <tr
          class="product-info"
          [style.display]="(configPage !== item.id) ? 'none' : 'table-row '"
          [class.showed]="animateId === item.id"
          *ngIf="!canTerminate(item)"
        >
          <td colspan="9">
            <div
              class="col-xs-12 validation"
              style="color: #f26d4f; font-weight: normal"
            >
              Product terminated
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="noResidentialProducts">
        <tr class="text-center no-products">
          <td class="table__cell" colspan="6">
            No active products, click
            <a [routerLink]="['/products/new']">here</a> to add one
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <div
    *ngIf="_refresh.show"
    [style.display]="_refresh.show?'block':none"
    class="modal-overlay display"
  >
    <div class="modal modal-cancel-sub">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Refresh Proxies</h4>
          </div>
          <div class="modal-body">
            <div class="proxyUsageOpt-list">
              <label class="col-12 col-md-12 col-lg-12 padding0">
                <select
                  name="usage"
                  (change)="selectRefreshLocation($event.target.value)"
                  class="col-12 col-md-12 col-lg-12"
                  style="border: 1px solid #dedede"
                >
                  <option value="">Select Location</option>
                  <option
                    *ngFor="let item of getRefreshLocations()"
                    [value]="item.state"
                  >
                    {{ item.state }}
                  </option>
                </select>
              </label>
            </div>
            <div class="modal-txt">
              <label class="col-12 col-md-12 col-lg-12 padding0"
                >Please provide the reason for refresh request</label
              >

              <textarea
                [(ngModel)]="_refresh.reason"
                name="refreshReason"
                style="height: 125px"
                class="form-control"
              ></textarea>
              <div
                class="alert alert-danger"
                *ngIf="_refresh.reason?.length > 250"
              >
                Max length is 250 characters
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a
            (click)="closeRefresh()"
            style="width: 50%"
            class="modal-action modal-close waves-effect waves-green btn-flat"
            >Close</a
          >
          <a
            (click)="refreshProduct()"
            style="width: 50%"
            class="modal-action modal-close waves-effect waves-green btn-flat"
            >Request</a
          >
        </div>
      </form>
    </div>
  </div>

  <div
    *ngIf="_terminate.show"
    [style.display]="_terminate.show?'block':none"
    class="modal-overlay display"
  >
    <div class="modal modal-cancel-sub">
      <div class="modal-content" style="height: 100%">
        <div class="modal-header">
          <h4 class="modal-title">Please provide the reason for termination</h4>
        </div>
        <div class="modal-body">
          <div class="toggle-grid proxyUsageOpt-list">
            <label class="col-12 col-md-12 col-lg-12 padding0">
              <select
                name="usage"
                (change)="selectTerminationReason($event.target.value)"
                class="col-12 col-md-12 col-lg-12"
                style="height: 35px; border: 1px solid #dedede"
              >
                <option [value]="0">Select usage</option>
                <option *ngFor="let item of terminateReasons" [value]="item">
                  {{ item }}
                </option>
              </select>
            </label>
          </div>
          <div class="modal-txt" *ngIf="showTerminationReasonText">
            <textarea
              [(ngModel)]="_terminate.reason"
              name="terminateReason"
              style="height: 125px"
              class="form-control"
            ></textarea>
            <div
              class="alert alert-danger"
              *ngIf="_terminate.reason?.length > 250"
            >
              Max length is 250 characters
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a
          (click)="closeTerminate()"
          style="width: 50%"
          class="modal-action modal-close waves-effect waves-green btn-flat"
          >Close</a
        >
        <a
          (click)="terminateProduct()"
          style="width: 50%"
          class="modal-action modal-close waves-effect waves-green btn-flat"
          >Submit</a
        >
      </div>
    </div>
  </div>

  <ng-container *ngIf="type == 'dedicated' && inactiveProducts.length">
    <h3>Inactive Products</h3>
    <table class="block table table-responsive products-manage">
      <thead class="table__header table__header--small">
        <tr>
          <th class="table__cell block__title">Product</th>
          <th class="table__cell block__title" style="text-align: right">
            Expiry Date
          </th>
          <th class="table__cell block__title" style="text-align: right">
            Cost
          </th>
          <th class="table__cell table__cell--fit"></th>
        </tr>
      </thead>
      <tbody>
        <ng-template
          ngFor
          let-product
          [ngForOf]="inactiveProducts"
          let-i="index"
        >
          <tr>
            <td class="table__cell">{{product.name}}</td>
            <td class="table__cell" style="text-align: right">
              {{product.valid_till | date}}
            </td>
            <td class="table__cell" style="text-align: right">
              ${{product.cost}}
            </td>
            <td class="table__cell table__cell--fit">
              <button
                *ngIf="!isResidentialProxyByName(product)"
                class="btn btn--small btn--dark-gray"
                type="button"
                (click)="showProxies(product)"
              >
                Proxies
              </button>
              <button
                *ngIf="role == 'Admin' || role == 'Owner'"
                class="btn btn--small"
                type="button"
                (click)="repurchase(product)"
              >
                Restore
              </button>
            </td>
          </tr>
          <tr
            style="background: #ccc; font-size: 14px"
            *ngIf="showProxiesProduct === product.product_id"
          >
            <td class="table__cell" colspan="5" style="padding: 5px">
              <table class="block products-manage" style="width: 100%">
                <thead class="table__header table__header--small">
                  <tr>
                    <!-- <th class="table__cell">Product #</th> -->
                    <th>Location</th>
                    <th>Subnet</th>
                    <th>Proxies</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-template
                    ngFor
                    let-subnet
                    [ngForOf]="product.proxies"
                    let-i="sindex"
                  >
                    <tr>
                      <td width="15%">{{subnet.location}}</td>
                      <td width="35%">{{subnet.subnet}}</td>
                      <td width="60%">{{subnet.proxies.join(', ')}}</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </ng-container>
</div>
<div class="sidebar big-sidebar"><account-sidebar></account-sidebar></div>
