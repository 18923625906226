import {Inject, Injectable, EventEmitter} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserCredentials} from '../entities/user-credentials';
import {Message} from '../entities/message';
import {Observable, Subject, BehaviorSubject, throwError} from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, map, tap } from 'rxjs/operators';
import {Router} from '@angular/router';
import {UserAccount} from '../entities/user-account';
import {UserProfile} from '../entities/user-profile';
import * as moment from 'moment';
import {Ticket} from '../entities/ticket';
import {countryWithPhone} from './countryList.service';
import {DOCUMENT} from '@angular/common';

declare const $: any;
declare const Env: any;
declare const analytics: any;

@Injectable()
export class LimeProxiesService {

  private currentCall: any;
  public currentUser: any;
  public newPasswordFocus = false;
  public quizPage = 1;
  public showQuizPage = false;
  public showCardPayment = false;
  public showProductsSidebar: number | boolean | null = 1;
  public products: any;
  public codeVerificationInputActive = true;
  public changeSock: Subject<any> = new Subject<any>();
  public changeRenewal: Subject<any> = new Subject<any>();
  public _updateUserNotifications: Subject<any> = new Subject<any>();
  public countryWithPhone = countryWithPhone;
  public userData: any = {};
  public openTickets = [];
  public closedTickets = [];
  public emptyOpenTickets = false;
  public isLoggedWithGoogle = false;
  public emptyClosedTickets = false;
  public invoice: any;
  public on_register = false;
  public userNotifications = {
    full_name: '',
    credit: '',
    notifications: []
  };
  public userSubject = new BehaviorSubject<UserAccount>(
        new UserAccount(
            'whitelist',
            'ip',
            '0',
            '0',
            'no',
            '0',
            '2011-07-12 19:37:32'
        ));

  public userSubjectIPUpdate = new EventEmitter<any>();

  public profileSubject = new Subject<UserProfile>();

  public messageToDisplay = new BehaviorSubject<Message>(new Message('Nothing to display', false));
  public countriesAcronym = {
    Afghanistan: 'AF',
    Albania: 'AL',
    Algeria: 'DZ',
    Andorra: 'AD',
    Angola: 'AO',
    Anguilla: 'AI',
    Antigua_Barbuda: 'AG',
    Argentina: 'AR',
    Armenia: 'AM',
    Aruba: 'AW',
    Australia: 'AU',
    Austria: 'AT',
    Azerbaijan: 'AZ',
    Bahamas: 'BS',
    Bahrain: 'BH',
    Bangladesh: 'BD',
    Barbados: 'BB',
    Belarus: 'BY',
    Belgium: 'BE',
    Belize: 'BZ',
    Benin: 'BJ',
    Bermuda: 'BM',
    Bhutan: 'BT',
    Bolivia: 'BO',
    Bosnia_Herzegovina: 'BA',
    Botswana: 'BW',
    Brazil: 'BR',
    British_Virgin_Islands: 'VG',
    Brunei: 'BN',
    Bulgaria: 'BG',
    Burkina_Faso: 'BF',
    Burundi: 'BI',
    Cambodia: 'KH',
    Cameroon: 'CM',
    Cape_Verde: 'CV',
    Cayman_Islands: 'KY',
    Chad: 'TD',
    Chile: 'CL',
    China: 'CN',
    Colombia: 'CO',
    Congo: 'CG',
    Cook_Islands: 'CK',
    Costa_Rica: 'CR',
    Cote_D_Ivoire: 'CI',
    Croatia: 'HR',
    Cuba: 'CU',
    Cyprus: 'CY',
    Czech_Republic: 'CZ',
    Denmark: 'DK',
    Djibouti: 'DJ',
    Dominica: 'DM',
    Dominican_Republic: 'DO',
    Ecuador: 'EC',
    Egypt: 'EG',
    El_Salvador: 'SV',
    Equatorial_Guinea: 'GQ',
    Estonia: 'EE',
    Ethiopia: 'ET',
    Falkland_Islands: 'FK',
    Faroe_Islands: 'FO',
    Fiji: 'FJ',
    Finland: 'FI',
    France: 'FR',
    French_Polynesia: 'PF',
    French_West_Indies: 'TF',
    Gabon: 'GA',
    Gambia: 'GM',
    Georgia: 'GE',
    Germany: 'DE',
    Ghana: 'GH',
    Gibraltar: 'GI',
    Greece: 'GR',
    Greenland: 'GL',
    Grenada: 'GD',
    Guam: 'GU',
    Guatemala: 'GT',
    Guinea: 'GN',
    Guinea_Bissau: 'GW',
    Guyana: 'GY',
    Haiti: 'HT',
    Honduras: 'HN',
    Hong_Kong: 'HK',
    Hungary: 'HU',
    Iceland: 'IS',
    India: 'IN',
    Indonesia: 'ID',
    Iran: 'IR',
    Iraq: 'IQ',
    Ireland: 'IE',
    Isle_of_Man: 'IM',
    Israel: 'IL',
    Italy: 'IT',
    Jamaica: 'JM',
    Japan: 'JP',
    Jersey: 'JE',
    Jordan: 'JO',
    Kazakhstan: 'KZ',
    Kenya: 'KE',
    Kuwait: 'KW',
    Kyrgyz_Republic: 'KG',
    Laos: 'LA',
    Latvia: 'LV',
    Lebanon: 'LB',
    Lesotho: 'LS',
    Liberia: 'LR',
    Libya: 'LY',
    Liechtenstein: 'LI',
    Lithuania: 'LT',
    Luxembourg: 'LU',
    Macau: 'MO',
    Macedonia: 'MK',
    Madagascar: 'MG',
    Malawi: 'MW',
    Malaysia: 'MY',
    Maldives: 'MV',
    Mali: 'ML',
    Malta: 'MT',
    Mauritania: 'MR',
    Mauritius: 'MU',
    Mexico: 'MX',
    Moldova: 'MD',
    Monaco: 'MC',
    Mongolia: 'MN',
    Montenegro: 'ME',
    Montserrat: 'MS',
    Morocco: 'MA',
    Mozambique: 'MZ',
    Namibia: 'NA',
    Nepal: 'NP',
    Netherlands: 'NL',
    Netherlands_Antilles: 'AN',
    New_Caledonia: 'NC',
    New_Zealand: 'NZ',
    Nicaragua: 'NI',
    Niger: 'NE',
    Nigeria: 'NG',
    Norway: 'NO',
    Oman: 'OM',
    Pakistan: 'PK',
    Palestine: 'PS',
    Panama: 'PA',
    Papua_New_Guinea: 'PG',
    Paraguay: 'PY',
    Peru: 'PE',
    Philippines: 'PH',
    Poland: 'PL',
    Portugal: 'PT',
    Puerto_Rico: 'PR',
    Qatar: 'QA',
    Reunion: 'RE',
    Romania: 'RO',
    Russia: 'RU',
    Rwanda: 'RW',
    Saint_Pierre_Miquelon: 'SPM',
    Samoa: 'WS',
    San_Marino: 'SM',
    Satellite: 'ST',
    Saudi_Arabia: 'SA',
    Senegal: 'SN',
    Serbia: 'RS',
    Seychelles: 'SC',
    Sierra_Leone: 'SL',
    Singapore: 'SG',
    Slovakia: 'SK',
    Slovenia: 'SI',
    South_Africa: 'ZA',
    South_Korea: 'ZA',
    Spain: 'ES',
    Sri_Lanka: 'LK',
    St_Kitts_Nevis: 'KN',
    St_Lucia: 'LC',
    St_Vincent: 'VC',
    Sudan: 'SD',
    Suriname: 'SR',
    Swaziland: 'SZ',
    Sweden: 'SE',
    Switzerland: 'CH',
    Syria: 'SY',
    Taiwan: 'TW',
    Tajikistan: 'TJ',
    Tanzania: 'TZ',
    Thailand: 'TH',
    Timor_Leste: 'TL',
    Togo: 'TG',
    Tonga: 'TO',
    Trinidad_Tobago: 'TT',
    Tunisia: 'TN',
    Turkey: 'TR',
    Turkmenistan: 'TM',
    Turks_Caicos: 'TC',
    Uganda: 'UG',
    Ukraine: 'UA',
    United_Arab_Emirates: 'AE',
    United_Kingdom: 'UK',
    Uruguay: 'UY',
    Uzbekistan: 'UZ',
    Venezuela: 'VE',
    Vietnam: 'VN',
    Virgin_Islands_US: 'VI',
    Yemen: 'YE',
    Zambia: 'ZM',
    Zimbabwe: 'ZW'
  };

  public environment = Env;

    public limeproxiesUrl = this.environment['api']['url'];
    public limeproxiesApiUrl = 'https://api.limeproxies.com/v2/';

    private countryNames = {
        US: 'United States of America',
        GB: 'United Kingdom of Great Britain',
        ES: 'Spain',
        DK: 'Denmark',
        IE: 'Ireland',
        IT: 'Italy',
        DE: 'Germany',
        AU: 'Australia',
        BE: 'Belgium',
        NO: 'Norway',
        VN: 'Vietnam',
        RU: 'Russia',
        CA: 'Canada',
        SE: 'Sweden',
        AT: 'Austria',
        FR: 'France',
        ZA: 'South Africa',
        BR: 'Brazil',
        PL: 'Poland',
        CH: 'Switzerland',
        NL: 'Netherlands',
        KR: 'Korea',
        IN: 'India',
        PT: 'Portugal',
        JP: 'Japan',
        SG: 'Singapore',
        HK: 'Hong Kong'
    };

  static saveTokenAndAuthID(auth_id, auth_token, auth_ttl) {
      const time = new Date();
      time.setTime(time.getTime() + (auth_ttl * 60 * 1000));
      localStorage.setItem('userId', JSON.stringify({userId: auth_id, expire: time.getTime()}));
      localStorage.setItem('token', JSON.stringify({token: auth_token, expire: time.getTime()}));
  }

  static get authHeaders(): HttpHeaders {
    let headers = {
      'Content-Type': 'application/json',
      'X-Auth-UserID': '',
      'X-Auth-Token': ''
    };


    if (localStorage.getItem('userId') !== null) {
      const user = JSON.parse(localStorage.getItem('userId'));
      const userId = user && user.userId;
      const t = JSON.parse(localStorage.getItem('token'));
      const token = t && t.token;

      headers = {
        'Content-Type': 'application/json',
        'X-Auth-UserID': userId + '',
        'X-Auth-Token': token
      };
    }

    return new HttpHeaders(headers);
  }

  static handleError(error: any) {
      const errMsg = (error.message) ? error.message :
          error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      return throwError(errMsg);
  }

  getCountriesAcronym(country) {
    if (country) {
      if (country.includes(' ')) {
        country = country.replace(/ /g, '_');
      }
      return this.countriesAcronym[country];
    } else {
      return;
    }
  }

  completedOrder(first_name, last_name, email, amount) {
      if (this.environment['third_parties']['analytics']['enabled']) {
          analytics.track('Completed Order', {
              first_name: first_name,
              last_name: last_name,
              email: email,
              amount: amount
          });
      }
  }

  sendVerificationCode() {
      const __this = this;
    this.getUserProfile().subscribe(success => {
      this.sendSMSCode().subscribe((data) => {
        this.showMessage(data);
      }, (error) => this.checkStatusCodeOnError(error, __this));
    });
  }

  constructor(private http: HttpClient,
              private router: Router,
              @Inject(DOCUMENT) private document: any) {
    
    const __this = this;
    this.changeSock.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(data => this.setSock(data)),
        catchError(error => this.checkStatusCodeOnError(error, __this)))
      .subscribe(data => this.showMessage(data), error => console.log('aaa' + error));
    this.changeRenewal.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(data => this.setRenewal(data)),
        catchError(error => this.checkStatusCodeOnError(error, __this)))
      .subscribe(data => this.showMessage(data), error => console.log(error));

    this.userSubjectIPUpdate.subscribe((data) => {
      if (data['type'] === 'authorized_ips') {
          this.userSubject['authorized_ips'] = data['value'];
      } else if (data['type'] === 'user_auth_method') {
          this.userSubject['auth_method'] = data['value']['auth_method'];
          this.userSubject['access_type'] = data['value']['access_type'];
      }
    });
  }

  ngOnInit() {
    if (!this.products.length) {
      this.getProducts().subscribe((data) => {
        this.products = data;
      });
    }
  }

  getPhoneCC(country: string) {
    const res = this.countryWithPhone.find(value => {
      return value.name === country;
    });
    return res ? res.code : '';
  }

  showMessage(data, code: string = 'success') {
    this.notifyBarNew(data['_user_message'], data['_user_message_type'] || code);
  }

  setUserProfile(on_register?: string) {
    this.getUserProfile().subscribe((data) => {
      this.userData = data;
      let traits = {};
      if (typeof on_register !== 'undefined') {
        traits = {
            firstName: data.first_name,
            lastName: data.last_name,
            userId: data.uid,
            phone: data.phone,
            email: data.email,
            createdAt: new Date().toISOString(),
        };
      } else {
          traits = {
              firstName: data.first_name,
              lastName: data.last_name,
              userId: data.uid,
              phone: data.phone,
              email: data.email,
          };
      }
      if (this.environment['third_parties']['analytics']['enabled']) {
          analytics.alias(data.uid);
          analytics.identify(data.uid, traits);
      }
      if (this.environment['third_parties']['intercom'] && this.environment['third_parties']['intercom']['enabled']) {
          if (typeof (window as any).Intercom !== 'undefined') {
              (window as any).Intercom('boot', {
                  app_id: this.environment['third_parties']['intercom']['app_id'],
                  email: data.email,
                  user_id: data.uid,
                  created_at: 1234567890,
              });
          }
      }
    });
  }

  newTicket(type: string, subject: string, description: string) {
    if (!this.checkExpired()) {
      const body = {
        'type': type,
        'subject': subject,
        'body': description
      };
      const __this = this;
      const api_endpoint = this.limeproxiesUrl.concat(`support/ticket`);
      return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
          .pipe(
              map(this.extractData),
              catchError(error => this.checkStatusCodeOnError(error, __this))
          );
    }
  }

  get_geo_info() {
      return this.http.get('https://ipapi.co/json/');
      // return this.http.get('http://gd.geobytes.com/GetCityDetails?callback=?').pipe(map(res => res['json']()));
      // return this.http.get('https://ipinfo.io/json').pipe(map(res => res['json']()));
  }

  addToOpenTickets(ticket: Ticket) {
    this.openTickets.push(ticket);
  }

  closeTicket(id: any) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`support/ticket/${id}`);
    return this.http.delete(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  removeFromOpened(ticket: Ticket) {
    this.openTickets = this.openTickets.filter((obj) => {
      return obj.id !== ticket.id;
    });
    ticket.status = 'solved';
    this.closedTickets.push(ticket);
  }

  getTickets() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`support/tickets`);
    this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders}).pipe(
        catchError(error => this.checkStatusCodeOnError(error, __this))
      )
      .subscribe(data => {
        const allTickets = this.extractData(data);
        this.closedTickets = [];
        this.openTickets = [];
        if (typeof allTickets.tickets !== 'undefined') {
            for (const ticket of allTickets.tickets) {
                if (ticket.status === 'closed' || ticket.status === 'solved') {
                    this.closedTickets.push(ticket);
                } else {
                    this.openTickets.push(ticket);
                }
            }
        }
        if (this.closedTickets.length === 0) {
          this.emptyClosedTickets = true;
        }
        if (this.openTickets.length === 0) {
          this.emptyOpenTickets = true;
        }
      });
  }

  postNewToken() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('api/token');
    return this.http.post(api_endpoint, {}, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getNewToken() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('api/token');
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getTicket(id: number) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`support/ticket/${id}`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getSupportComments(id: number) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`support/comments/${id}`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  sendComment(id: number, comment: string) {
    const body = {
      'comment': comment
    };
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`support/comments/${id}`);
    return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  cacheTicket(ticket: Ticket) {
    localStorage.setItem('cachedTicket', JSON.stringify(ticket));
  }

  getCachedTicket() {
    return JSON.parse(localStorage.getItem('cachedTicket'));
  }

  cacheInvoice(invoice: any) {
    sessionStorage.setItem('cachedInvoice', JSON.stringify(invoice));
  }

  getCachedInvoice() {
    return JSON.parse(sessionStorage.getItem('cachedInvoice'));
  }

  getbillingHistory() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`billing/history`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getBillingConfirm(token: string) {
    let options;
    if (localStorage.getItem('token')) {
      options = {headers: LimeProxiesService.authHeaders};
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      options = {headers: headers};
    }
    const body = {
      'token': token
    };
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('billing/confirm');
    return this.http.post(api_endpoint, body, options)
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }
 
  orderProduct(no: number, type: string, locations: string, payment: string, usage: string, proxies: any, parent_id: any, custom: any, proxy_num: any) {
    const body = {
      'source': 'dashboard',
      'no': no,
      'type': (['premium', 'private', 'residential'].includes(type.toLowerCase())) ? type : 'Premium',
      'locations': locations,
      'payment': payment,
      'usage': usage,
      proxies: proxies,
      parent_id: parent_id,
      custom: custom,
      proxy_num: proxy_num
    };
	
	if (body.proxies == '') {
		body.proxies = [];
	}
	
	if (body.parent_id == '') {
		body.parent_id = 0;
	}

    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/order`);
    return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  addProduct(oid: string, subscription: string) {
	  const __this = this;
	  const api_endpoint = this.limeproxiesUrl.concat(`product/add`);
	  return this.http.put(api_endpoint, {
		'oid': oid,
		'subscription': subscription
	  }, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => {
              this.notifyBarNew(error, 'error');
              return this.checkStatusCodeOnError(error, __this);
          })
      );
  }

  repurchase = (pid: any): Observable<any> => {
	  return this.http.put(this.limeproxiesUrl.concat(`product/${pid}/restore`), {}, {headers: LimeProxiesService.authHeaders});
  }
  
  orderCreditProduct(oid: string) {
    const body = {
      'oid': oid
    };
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/add`);
    return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => {
              this.notifyBarNew('You don’t have sufficient credits to make this purchase. Kindly use other payment option or add credits.', 'error');
              return this.checkStatusCodeOnError(error, __this);
          })
      );
  }

  notifyBarNew(msg: string, error: string, permanent = null) {
    console.log('on notifyBarNew', error, msg);
      if (!$('.alert-box').length) {
          switch (error) {
              case 'error':
                  this.handleNotifyBarNew('error', msg, error, permanent);
                  break;
              case 'info':
                  this.handleNotifyBarNew('', msg, error, permanent);
                  break;
              case 'warning':
                  this.handleNotifyBarNew('warning', msg, error, permanent);
                  break;
              default:
                  this.handleNotifyBarNew('success', msg, error, permanent);
          }
      }
  };

  handleNotifyBarNew(class_name: string, msg: string, error: string, permanent = null) {
      const fade_time = 1000;
      const time_to_show = 5000;
      return permanent ? this.addNotificationBar(class_name, msg) : this.addNotificationBar(class_name, msg).delay(time_to_show).fadeOut(fade_time, () => {
          $('.alert-box').remove();
      });
  }

  addNotificationBar(class_name: string, msg: string) {
      $('.page__header').css('z-index', 'auto');
      $('.notify-top-popup').css('z-index', '999');
      return $('<div class="alert-box ' + class_name + ' " style="position:absolute; left: 33%; z-index:9999; ">' + msg + '</div>').prependTo($('.notify-top-popup'));
  }

  setSock(item) {
    const __this = this;
    return this.setSockRequest(item)
        .pipe(
            map(data => {
                const res = this.extractData(data);
                if (this.environment['third_parties']['analytics']['enabled']) {
                    analytics.track('sock changed', item);
                }
                item.socks = (res['_user_message_type'] === 'error') ? !item.socks : item.socks;
                return res;
            }),
            catchError(error => {
                item.socks = !item.socks;
                return this.checkStatusCodeOnError(error, __this);
            })
        );
  }

  setSockRequest(item) {
      const options = {headers: LimeProxiesService.authHeaders};
      let api_endpoint = this.limeproxiesUrl.concat(`product/${item.id}/socks`);
      if (item.socks) {
          return this.http.put(api_endpoint, '', options);
      } else {
          return this.http.delete(api_endpoint, options);
      }
  }

  setTitle(title: string) {
    this.document.title = 'Lime Proxies - ' + title;
  }

  wizardStepOne(data) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`user/wizard`);
    return this.http.put(api_endpoint, data, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  wizardStepTwo(ips: string[]) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`proxy/authorized`);
    return this.http.put(api_endpoint, {authorized_list: ips.join(' ')}, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  setRenewal(data) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/${data.id}/auto`);
    return this.http.put(api_endpoint, {state: data.state}, {headers: LimeProxiesService.authHeaders})
      .pipe(
          catchError(error => this.checkStatusCodeOnError(error, __this)),
          map(this.extractData),
          tap(data => {
              if (this.environment['third_parties']['analytics']['enabled']) {
                  analytics.track('Recover password sent', data);
              }
          })
      );
  }
z
  login(email: string, password: string): Observable<UserCredentials> {
    const api_endpoint = this.environment['api']['login_path'];
    return this.http.post(api_endpoint, {email, password})
      .pipe(
          map(this.getUserCreds),
          tap((creds: UserCredentials) => {LimeProxiesService.saveTokenAndAuthID(creds.userID, creds.token, creds.ttl); })
      );
  }

  authLogin(reqBody): Observable<UserCredentials> {
    const api_endpoint = this.environment['api']['login_path'];
    return this.http.post(api_endpoint, reqBody)
      .pipe(
          map(this.getUserCreds),
          tap((creds: UserCredentials) => {LimeProxiesService.saveTokenAndAuthID(creds.userID, creds.token, creds.ttl); })
      );
  }

  loginWithToken(recover_token) {
    const __this = this;
    const api_endpoint = this.environment['api']['login_path'];
	
    return this.http.post(api_endpoint, {recover_token})
      .pipe(
          map(this.getUserCreds),
          tap((creds: UserCredentials) => {LimeProxiesService.saveTokenAndAuthID(creds.userID, creds.token, creds.ttl); }),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  userPasswordRecover(email) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('user/recover');
    return this.http.put(api_endpoint, {email})
      .pipe(
          map(this.extractData),
          tap(data => {
                  if (this.environment['third_parties']['analytics']['enabled']) {
                      analytics.track('recover email sent', {user_id: email});
                  }
          }),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }


  getLocations = () => {
    const __this = this;
    return this.http.get(this.limeproxiesUrl.concat('locations.php'), {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getProducts() {
    const __this = this;

    return this.http.get('assets/json/products.json')
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getResidentialProxies = () => {
    const __this = this;
    return this.http.get(this.limeproxiesUrl.concat('residentialProxies.php'), {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getFullCountryName(shortName: any) {
    return this.countryNames[shortName.toUpperCase()];
  }

  downloadProduct(id) {
    const __this = this;
    if (!this.checkExpired()) {
      const api_endpoint = this.limeproxiesUrl.concat(`product/${id}/download`);
      return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
    }
  }

  checkProductStatus(id) {
    const __this = this;
    if (!this.checkExpired()) {
      const api_endpoint = this.limeproxiesUrl.concat(`product/${id}/status`);
      return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
          .pipe(
              map(this.extractData),
              catchError(error => this.checkStatusCodeOnError(error, __this))
          );
    }
  }

  getAllProducts() {
    const __this = this;
    if (this.checkExpired()) {
      // this.router.navigateByUrl('/login');
      this.logout();
      return throwError({});
    } else {
      const api_endpoint = this.limeproxiesUrl.concat('product/active');
      return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
    }
  }

  sendCoupon(product: string, code: string) {
    const __this = this;
    if (!this.checkExpired()) {
      const api_endpoint = this.limeproxiesUrl.concat('product/discount');
      return this.http.post(api_endpoint, {product, code}, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
    }
  }

  refreshProduct(id: number, location: string, reason: string) {
    const __this = this;
    if (!this.checkExpired()) {
      const api_endpoint = this.limeproxiesUrl.concat(`product/${id}/refresh`);
      return this.http.put(api_endpoint, {reason: reason, location: location}, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
    }
  }

  terminateProduct(reason: string, date: string, id: number) {
    const options = {
      headers: LimeProxiesService.authHeaders,
      body: {
        date: date,
        reason :reason
      }
    };
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product?pid=${id}`);
    return this.http.delete(api_endpoint, options)
    .pipe(
      map(this.extractData),
      catchError(error => this.checkStatusCodeOnError(error, __this))
    );
  }

  logout(byUser: any = false) {
      localStorage.clear();
      localStorage.removeItem('userId');
      localStorage.removeItem('token');
      this.router.navigateByUrl(byUser ? '/login/after' : '/login');
      // this.router.navigateByUrl('/login');

  }

  checkExpired(): boolean {
    if (!this.on_register) {
        let answer: boolean = false;
        const user = JSON.parse(localStorage.getItem('userId'));
        if (user) {
            const time = user.expire;
            answer = time < new Date().getTime();
            if (answer) {
                // this.messageToDisplay.next(new Message('Your session has expired', false));
                localStorage.removeItem('userId');
                localStorage.removeItem('token');
                if (localStorage.getItem('auth0')) {
                    localStorage.removeItem('auth0');
                    this.router.navigateByUrl('/auth0');
                    return answer;
                }
            }
        } else {
            answer = true;
        }
        if (answer) {
            if (!localStorage.getItem('auth0')) {
              this.logout();
                // this.router.navigateByUrl('/login');
            }
        }
        return answer;
    } else {
      return false;
    }
  }

  deleteMyAccount() {
    const __this = this;
    this.checkExpired();
    const api_endpoint = this.limeproxiesUrl.concat('user/account');
    return this.http.delete(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          tap(() => {
                  if (this.environment['third_parties']['analytics']['enabled']) {
                      analytics.track('account was deleted', this.userData);
                  }
          }),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }


  signUpUserAndSendValidationTokenToEmail({first_name, last_name, email, token, signup_token, referrerToken}, validate = false) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('user/signup');
    if ( first_name === '' || last_name === '' || email === '') {
      this.notifyBarNew('Please Enter Required Fields.', 'error');
    } else {
        return this.http.put(api_endpoint, {first_name, last_name, email, token, signup_token, referrerToken, validate})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
    }
  }

  loginViaGoogle(object) {
	const login_path = this.environment['api']['login_path'];
    const api_endpoint = login_path.substring(0, login_path.lastIndexOf('user/login') + 'user/login'.length) + '/google';

    return this.http.post(api_endpoint, object)
        .pipe(
            map(this.getUserCreds),
            tap((creds: UserCredentials) => {LimeProxiesService.saveTokenAndAuthID(creds.userID, creds.token, creds.ttl); })
        );
  }

  validateTokenFromEmail(token, revaidate: boolean = false) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(revaidate ? 'user/revalidate' : 'user/validate');
    return this.http.post(api_endpoint, {token})
      .pipe(
          map(this.extractData),
          tap(data => {localStorage.removeItem('segmentData'); }),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  _keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  _onlyInt(event: any) {
      event.target.value = isNaN(parseInt(event.target.value, null)) ? '' : parseInt(event.target.value, null);
  }

  toDate(date: string) {
    return moment(date).toDate();
  }

  getUserNotification() {
    if (this.checkExpired()) {
      return throwError({});
    }
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('user/notification');
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getNotification(id: string) {
    const __this = this;
    if (!this.checkExpired()) {
      const api_endpoint = this.limeproxiesUrl.concat('notification/' + id);
      return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
    }
  }

  deleteUserNotification(id: string) {
    const __this = this;
    this.checkExpired();
    const api_endpoint = this.limeproxiesUrl.concat('notification/' + id);
    return this.http.delete(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getUserProfile(): Observable<UserProfile> {
    if (localStorage.getItem('userId') !== null) {
      const __this = this;
      const api_endpoint = this.limeproxiesUrl.concat('user/profile');
      return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(res => {
                const val = this.extractData(res);
                this.profileSubject.next(val);
                return val;
            }),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
    }
  }
  
  downloadInvoice(reference): Observable<any> {
    const api_endpoint = this.limeproxiesUrl.concat('download/receipt/') + reference;
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders});
  }

  updateUserProfile(obj) {
    const __this = this;
    this.checkExpired();
    const api_endpoint = this.limeproxiesUrl.concat('user/profile');
    return this.http.put(api_endpoint, obj, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  resendSms(obj) {
      const __this = this;
      this.checkExpired();
      const api_endpoint = this.limeproxiesUrl.concat('phone/send');
      return this.http.put(api_endpoint, obj, {headers: LimeProxiesService.authHeaders})
          .pipe(
              map(this.extractData),
              catchError(error => this.checkStatusCodeOnError(error, __this))
          );
  }

  updateUserEmail(email) {
    const __this = this;
    this.checkExpired();
    const api_endpoint = this.limeproxiesUrl.concat('user/profile/email');
    return this.http.put(api_endpoint, {email}, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          tap(data => {
                  if (this.environment['third_parties']['analytics']['enabled']) {
                      analytics.track('update User Email', {email});
                  }
          }),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getUserOverview() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('user/overview');
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getUserAccount(): Observable<UserAccount> {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('user/account');
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(res => {
              const val = this.extractData(res);
              this.userSubject.next(val);
              return val;
          }),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  updateUserAccount(isAuth, obj) {
    const __this = this;
    this.checkExpired();
    const api_endpoint = this.limeproxiesUrl.concat('user/account');
    return this.http.put(api_endpoint, obj, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(res => {
              const val = this.extractData(res);
              if (isAuth) {this.userSubject.value.auth_method = val.auth_method; }
              return val;
          }),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getUserTraffic() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('user/traffic');
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getProxyAuthorizedIps() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('proxy/authorized');
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  updateProxyAuthorizedIps(new_authorizedIps_list) {
    this.checkExpired();
    const __this = this;
    const body = {
      'authorized_list': new_authorizedIps_list.toString().split(',').join(' ')
    };
    const api_endpoint = this.limeproxiesUrl.concat('proxy/authorized');
    return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getProxyPassword() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('proxy/password');
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  updateProxyPassword(password) {
    this.checkExpired();
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('proxy/password');
    return this.http.put(api_endpoint, {password}, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          tap(data => {
                  if (this.environment['third_parties']['analytics']['enabled']) {
                      analytics.track('update Proxy Password', {password});
                  }
          }),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  getMyWhitelist() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat('proxy/whitelist');
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  updateProxyWhitelist(white_list) {
    const __this = this;
    this.checkExpired();
    const body = {
      'white_list': white_list.toString().split(',').join(' ')
    };
    const api_endpoint = this.limeproxiesUrl.concat('proxy/whitelist');
    return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          tap(data => {
                  if (this.environment['third_parties']['analytics']['enabled']) {
                      analytics.track('update Proxy White list', white_list);
                  }
          }),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  sendSMSCode() {
    const __this = this;
    this.checkExpired();
    const api_endpoint = this.limeproxiesUrl.concat('phone/send');
    return this.http.put(api_endpoint, null, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  validateCODE(code) {
    const __this = this;
    this.checkExpired();
    const api_endpoint = this.limeproxiesUrl.concat('phone/validate');
    return this.http.put(api_endpoint, {code}, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  sendWizardProduct(product) {
    const __this = this;
    this.checkExpired();
    const api_endpoint = this.limeproxiesUrl.concat('user/wizard');
    return this.http.put(api_endpoint, {product}, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

    getWizardInfo() {
        const __this = this;
        this.checkExpired();
        const api_endpoint = this.limeproxiesUrl.concat('user/wizard');
        return this.http.get(api_endpoint,  {headers: LimeProxiesService.authHeaders})
            .pipe(
                map(this.extractData),
                catchError(error => this.checkStatusCodeOnError(error, __this))
            );
    }

  getUserIpAddress() {
    const __this = this;
    return this.http.get('https://ipinfo.io/json')
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  checkStatusCodeOnError(error, __this: any) {
    if (error.status === 401) {
      __this.logout();
      if (!error.message['_user_message']) {
          error.message = {
              _user_message: 'Your session has expired',
              _user_message_type: 'error'
          };
      }
    } else if (error.status === 500) {
      this.router.navigateByUrl('/500');
    } else if (error.status === 400) {
        if (!error.message['_user_message']) {
            error.message = {
                _user_message: this.environment['_400_message_body'],
                _user_message_type: this.environment['_400_message_type']
            };
        }
    } else if (error.status === 422) {
      error.message = {
          _user_message: error.error._user_message,
          _user_message_type: 'error'
      };
    }
  
    return LimeProxiesService.handleError(error);
  }

  //////////////####################################################////////////////
  ///////////////############ HELPER METHODS #####################////////////////
  //////////////####################################################////////////////

  validateIPaddress(ipaddress) {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress);
  }

  domainValidate(domain) {
    return/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain);
  }

  private extractData(res: any) {
    return res || {};
  }

  private getUserCreds(res: any) {
    const creds = new UserCredentials(res['auth_userid'], res['auth_token'], res['auth_ttl']);
    creds['_user_message'] = res['_user_message'];
    creds['_user_message_type'] = res['_user_message_type'];
    return creds || {};
  }

  showRenew(id: any) {
    const __this = this;
    this.checkExpired();
    const api_endpoint = this.limeproxiesUrl.concat(`product/${id}/renew`);
    return this.http.put(api_endpoint, null, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map(this.extractData),
          catchError(error => this.checkStatusCodeOnError(error, __this))
      );
  }

  public showLoader(show: boolean) {
    let loader = document.querySelector('.pre-loader-container');
    loader.classList[show ? 'remove' : 'add']('hide');
  }
  setFocusOnNewPassword(value: boolean) {
    this.newPasswordFocus = value;
  }

  getFocusOnNewPassword() {
    return this.newPasswordFocus;
  }

  chargeebee_request(object) {
    const api_endpoint = 'https://api.limeproxies.com/notification/chargebee/ipn.php';
    return this.http.post(api_endpoint, object);
  }

  charge_get_card() {
      this.checkExpired();
      const __this = this;
      const api_endpoint = this.limeproxiesUrl.concat('chargebee/card');
      // const api_endpoint = this.limeproxiesUrl.concat(`product/${id}/renew`);
      return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
          .pipe(
              map(this.extractData),
              catchError(error => this.checkStatusCodeOnError(error, __this))
          );
  }

  charge_make_payment(body) {
      const __this = this;
      const api_endpoint = this.limeproxiesUrl.concat(`chargebee/charge`);
      return this.http.post(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
          .pipe(
              map(this.extractData),
              catchError(error => this.checkStatusCodeOnError(error, __this))
          );
  }

  charge_payment_source(body) {
      const __this = this;
      const api_endpoint = this.limeproxiesUrl.concat(`chargebee/card`);
      return this.http.put<any>(api_endpoint, body, {headers: LimeProxiesService.authHeaders});
  }
 
  delete_payment_source(id) {
      const __this = this;
      const api_endpoint = this.limeproxiesUrl.concat(`chargebee/card/`).concat(id);
      return this.http.delete(api_endpoint, {headers: LimeProxiesService.authHeaders})
          .pipe(
              map(this.extractData),
              catchError(error => this.checkStatusCodeOnError(error, __this))
          );
  }

  get_proxy_usage_opt() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`proxy/usage`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  get_proxy_website_usage_opt() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`proxy/website`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  get_proxy_tag_opt() {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`tag`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  getProductUsage(pid : any) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/use_case/${pid}`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  setProductUsage(pid: any, useCase: string) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/use_case/${pid}`);
    return this.http.post(api_endpoint, {
      use_case: useCase
    }, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  getProductTags(pid : any) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/tags/${pid}`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  setProductTags(pid: any, tags: string[]) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/tags/${pid}`);
    return this.http.post(api_endpoint, {
      tags: tags
    }, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  getProductNotes(pid : any) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/notes/${pid}`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  getOtherWebsite(pid: any) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/otherWebsite/${pid}`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  setOtherWebsite(pid: any, otherWebsite: string) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/otherWebsite/${pid}`);
    return this.http.post(api_endpoint, {
      otherWebsite: otherWebsite
    }, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  setProductNotes(pid: any, notes: string) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/notes/${pid}`);
    return this.http.post(api_endpoint, {
      notes: notes
    }, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  getProductWebsites(pid : any) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/websites/${pid}`);
    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  setProductWebsites(pid: any, websiteIds: any) {
    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`product/websites/${pid}`);
    return this.http.post(api_endpoint, {
      websites: websiteIds
    }, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  // team members
  get_team_members_list() {
      const __this = this;
      const api_endpoint = this.limeproxiesUrl.concat(`user/team`);
      return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
          .pipe(
              map(this.extractData),
              catchError(error => this.checkStatusCodeOnError(error, __this))
          );
      // user_team.php
  }
    add_team_member(body) {
        const __this = this;
        const api_endpoint = this.limeproxiesUrl.concat(`user/team`);
        return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
            .pipe(
                map(this.extractData),
                catchError(error => this.checkStatusCodeOnError(error, __this))
            );
    }

    resendInvitation(body) {
		const __this = this;
        const api_endpoint = this.limeproxiesUrl.concat(`user/team`);
        return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
            .pipe(
                map(this.extractData),
                catchError(error => this.checkStatusCodeOnError(error, __this))
            );
    }

    edit_team_member(body) {
        const __this = this;
        const api_endpoint = this.limeproxiesUrl.concat(`user/team`);
        return this.http.post(api_endpoint, {
				tmid: body.tmid, 
				email: body.email, 
				rid: body.rid
			}, {headers: LimeProxiesService.authHeaders})
            .pipe(
                map(this.extractData),
                catchError(error => this.checkStatusCodeOnError(error, __this))
            );
    }
    get_user_notifications_settings() {
        const __this = this;
        const api_endpoint = this.limeproxiesUrl.concat(`user_notification_setting.php`);
        return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
            .pipe(
                map(this.extractData),
                catchError(error => this.checkStatusCodeOnError(error, __this))
            );
    }
    update_user_notifications(body) {
        const __this = this;
        const api_endpoint = this.limeproxiesUrl.concat(`user_notification_setting.php`);
        return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
            .pipe(
                map(this.extractData),
                catchError(error => this.checkStatusCodeOnError(error, __this))
            );
    }
	
    delete_team_member(tmid: any, uid: any) {
        const options = {
            headers: LimeProxiesService.authHeaders,
            body: {
                tmid: tmid,
                uid: uid,
            }
        };
        const __this = this;
        const api_endpoint = this.limeproxiesUrl.concat(`user/team`);
        return this.http.delete(api_endpoint, options)
            .pipe(
                map(this.extractData),
                catchError(error => this.checkStatusCodeOnError(error, __this))
            );
    }

    get_team_roles() {
        // team_roles
        const __this = this;
        const api_endpoint = this.limeproxiesUrl.concat(`team_roles.php`);
        return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
            .pipe(
                map(this.extractData),
                catchError(error => this.checkStatusCodeOnError(error, __this))
            );
    }

    showApiLoader(show: any = false) {
      let loader = document.querySelector('#apiLoad');
      if (loader) {
        loader.classList[show ? 'remove' : 'add']('hidden');
      }
    }
	
	validateSignUpEmailFilters(email): boolean {
		
		if (typeof this.environment['signup_filter'] == 'undefined' || typeof this.environment['signup_filter']['emails'] == 'undefined') {
			return true;
		}

		let domain = email.substring(email.lastIndexOf("@"));		
		let exists = this.environment['signup_filter']['emails'].includes(domain);

		if (exists) {
			this.notifyBarNew((typeof this.environment['signup_filter']['emails_message'] == 'undefined') ? domain + ' email address not allowed.' : this.environment['signup_filter']['emails_message'], 'error');
		}

		return !exists;
	}
	
	getThirdPartyConfigurations() {
		return this.environment['third_parties'];
	}

	getInactiveProducts = () => {
		const __this = this;
		if (this.checkExpired()) {
			this.logout();
			return throwError({});
		} else {
			const api_endpoint = this.limeproxiesUrl.concat('product/inactive');
			return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
				.pipe(
					map(this.extractData),
					catchError(error => this.checkStatusCodeOnError(error, __this))
				);
		}
	}
	
	getProductsByUserId = (): Observable<any> => {
		let __this = this;
		const api_endpoint = this.limeproxiesUrl.concat('user/subscriptions/' + JSON.parse(localStorage.getItem('userId')).userId);
		return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
			.pipe(
				map(this.extractData),
				catchError(error => this.checkStatusCodeOnError(error, __this))
			);
	}

	getInactiveProductsByUserId = (): Observable<any> => {
		let __this = this;
    
		// const api_endpoint = this.limeproxiesUrl.concat('user/inactive-subscriptions/' + JSON.parse(localStorage.getItem('userId')).userId);
    const api_endpoint = this.limeproxiesUrl.concat('user_inactive_subscriptions.php?uid=' + JSON.parse(localStorage.getItem('userId')).userId);
		return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
			.pipe(
				map(this.extractData),
				catchError(error => this.checkStatusCodeOnError(error, __this))
			);
	}
	
	getSubnets = () => {
		let __this = this;
		const api_endpoint = this.limeproxiesUrl.concat('subnets.php');
		return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
			.pipe(
				map(this.extractData),
				catchError(error => this.checkStatusCodeOnError(error, __this))
			);
	}

  assignTestProxies = (proxies) => {
    const body = {
      concurrent_connection: "",
      cost: "0",
      name: "Trial Proxies",
      notes: "",
      parent_id: "",
      payment: "credit",
      proxies: proxies,
      source: "test",
      type: "Premium",
      usage: "",
      valid: 3,
      website: ""
    };

    const __this = this;
    const api_endpoint = this.limeproxiesUrl.concat(`custom_proxy.php`);
    return this.http.put(api_endpoint, body, {headers: LimeProxiesService.authHeaders})
        .pipe(
            map(this.extractData),
            catchError(error => this.checkStatusCodeOnError(error, __this))
        );
  }

  public estimatedBudget = [
    {
        value: 'less than 10$',
        text: 'less than 10$',
    },
    {
        value: '10$ to 100$',
        text: '10$ to 100$',
    },
    {
        value: '100$ to 1000$',
        text: '100$ to 1000$',
    },
    {
        value: '1000$ to 5000$',
        text: '1000$ to 5000$',
    },
    {
        value: '5000$ to 25,000$',
        text: '5000$ to 25,000$',
    },
    {
        value: '25,000$ +',
        text: '25,000$ +',
    },
  ];

  public estimatedBandwidthUsageMonth = [
    {
        value: 'Upto 1 GB',
        text: 'Upto 1 GB',
    },
    {
        value: '1 GB to 10 GB',
        text: '1 GB to 10 GB',
    },
    {
        value: '10 GB to 100 GB',
        text: '10 GB to 100 GB',
    },
    {
        value: '100 GB to 1 TB',
        text: '100 GB to 1 TB',
    },
    {
        value: '1 TB to 10 TB',
        text: '1 TB to 10 TB',
    },
    {
        value: '10 TB to 100 TB',
        text: '10 TB to 100 TB',
    },
    {
        value: '100 TB+',
        text: '100 TB+',
    },
    {
        value: 'I dont know',
        text: 'I dont know',
    }
  ];

  public userProductInterested = [
    {
        value: 'Residential Rotating Proxies',
        text: 'Residential Rotating Proxies',
    },
    {
        value: 'Residential Static Proxies',
        text: 'Residential Static Proxies',
    },
    {
        value: 'Data center proxies',
        text: 'Data center proxies',
    },
    {
        value: 'Mobile Proxies',
        text: 'Mobile Proxies',
    },
    {
        value: 'Socks Proxies',
        text: 'Socks Proxies',
    }
  ];
}
