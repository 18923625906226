<div class="notify-top-popup">
</div>
<svg class="logo">
    <use xlink:href="#logo"></use>
</svg>
<div class="thank-you-container">
    <div class="block">
        <h1>Thank you for signing up.</h1>
        <p>Please check your email to activate the account.</p>
        <p>Book your personalized demo with our customer success team below</p>
        <a href="https://calendly.com/limeproxies/personalized-product-demo" target="_blank">Book personalized Demo</a>.
    </div>
</div>