<div class="notifications">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-7">
			<div class="border">
				<div class="add__title">
					<h3 class="title">Configure your Notifications</h3>
				</div>
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
								<th scope="col"></th>
								<th scope="col">SMS</th>
								<th scope="col">Email</th>
							</tr>
						</thead>
						<tbody>
							<ng-template ngFor let-notification [ngForOf]="notifications" let-i="index" >
								<tr>
									<td>{{notification.name}}</td>
									<td>
										<div class="switcher">
											<input type="checkbox" class="checkbox" [(ngModel)]="notification.sms" />
											<label for="sms_outage_alert" class="switch" (click)="setUserNotifications(notification, 'sms');"></label>
										</div>
									</td>
									<td>
										<div class="switcher">
											<input type="checkbox" class="checkbox" [(ngModel)]="notification.email" />
											<label for="email_outage_alert" class="switch" (click)="setUserNotifications(notification, 'email');"></label>
										</div>
									</td>
								</tr>
							</ng-template>
						</tbody>
						<tfoot>
							<tr>
								<th scope="col"></th>
								<th scope="col" colspan="2">
									<div class="authentication_form__btn">
										<button class="btn_orange" data-toggle="modal" type="button" (click)="updateNotifications()" >Update</button>
									</div>
								</th>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>