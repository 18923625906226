import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';

@Component({
    selector: 'shipping-address',
    templateUrl: '../../templates/new-billing/shipping-address.template.html'
})

export class ShippingAddressComponent implements OnInit, OnChanges {
    @Output() shippingResponse: EventEmitter<any> = new EventEmitter();
    @Input() templateType;
    @Input() shippingData;
    public showShipping = false;
    public data = {
        billingCity: '',
        billingState: '',
        billingZip: '',
        billingCountry: ''
    };
    constructor(public limeProxiesService: LimeProxiesService) {
        this.resetData();
    }


    ngOnChanges(changes: any) {
        console.log(changes, 'ShippingAddressComponent');
        this.resetData();
        this.data.billingCity = (typeof this.shippingData !== 'undefined' 
								&& typeof this.shippingData.card !== 'undefined' 
								&& typeof this.shippingData.card['billing_city'] !== 'undefined' ) 
									? this.shippingData.card['billing_city'] 
									: '';

        this.data.billingState = (typeof this.shippingData !== 'undefined' 
								&& typeof this.shippingData.card !== 'undefined' 
								&& typeof this.shippingData.card['billing_state'] !== 'undefined' ) 
									? this.shippingData.card['billing_state'] 
									: '';

        this.data.billingZip =  (typeof this.shippingData !== 'undefined' 
								&& typeof this.shippingData.card !== 'undefined' 
								&& typeof this.shippingData.card['billing_zip'] !== 'undefined' )
									? this.shippingData.card['billing_zip'] 
									: '';

        this.data.billingCountry = (typeof this.shippingData !== 'undefined' 
								&& typeof this.shippingData.card !== 'undefined' 
								&& typeof this.shippingData.card['billing_country'] !== 'undefined' ) 
									? this.shippingData.card['billing_country'] 
									: '';
    }

    ngOnInit() {
        // console.log(this.existingCard);
    }

    fillShippingData(ev: any) {
        this.shippingResponse.emit(this.data);
    }

    toggleMenu() {
        this.showShipping = !this.showShipping;
        if (!this.showShipping) {
            this.resetData();
        }
    }

    resetData() {
        this.data = {
            billingCity: '',
            billingState: '',
            billingZip: '',
            billingCountry: ''
        };
    }
}
