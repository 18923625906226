<div class="main__tabs main_tabs">
    <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link" id="nav-profile-tab" [class.active]="current_tab === 'profile'" (click)="setCurrentTab('profile')" data-toggle="tab"  role="tab" aria-controls="nav-profile" aria-selected="true">Profile</a>
            <a class="nav-item nav-link" id="nav-authentication-tab" [class.active]="current_tab === 'authentication'" (click)="setCurrentTab('authentication')" data-toggle="tab"  role="tab" aria-controls="nav-authentication" aria-selected="false">Authentication</a>
            <a *ngIf="limeProxiesService.environment.show_notifications" class="nav-item nav-link" id="nav-notifications-tab" [class.active]="current_tab === 'notifications'" (click)="setCurrentTab('notifications')" data-toggle="tab"  role="tab" aria-controls="nav-notifications" aria-selected="false">Notifications</a>

            <a class="nav-item nav-link" id="nav-notifications-tab" [class.active]="current_tab === 'personalize'" (click)="setCurrentTab('personalize')" data-toggle="tab"  role="tab" aria-controls="nav-notifications" aria-selected="false">Personalize</a>
        </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show" [class.active]="current_tab === 'profile'" [class.show]="current_tab === 'profile'" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div class="profile">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-6">
                        <div class="border">
                            <div class="add__title">
                                <h3 class="title">Profile Details</h3>
                            </div>
                            <div class="profile__form profile_form">
                                <form>
                                    <!--<input type="text" placeholder="First Name" >-->
                                    <input type="text" placeholder="First Name" [(ngModel)]="user.first_name" [ngModelOptions]="{standalone: true}">
                                    <input type="text" placeholder="Last Name" [(ngModel)]="user.last_name" [ngModelOptions]="{standalone: true}">
                                    <input type="email" placeholder="Email" [(ngModel)]="user.email" [ngModelOptions]="{standalone: true}">
                                    <div class="profile_form__phone" style="margin-bottom: 20px; ">
                                        <input type="tel" id="phone" (keyup)="phone_entered($event)"
                                               (change)="phone_entered($event)">
                                        <!--<div class="otp">-->
                                        <div class="otp" *ngIf="showOtpField">
                                            <input type="text" placeholder="Enter OTP" [(ngModel)]="otpCode" maxlength="4" [ngModelOptions]="{standalone: true}" style="margin-bottom: 0px; ">
                                            <input type="submit" (click)="verifyOTP()" value="Verify" style="margin-bottom: 0px; ">
                                        </div>
                                    </div>
                                    <input type="text" placeholder="Company Name" [(ngModel)]="user.company_name" [ngModelOptions]="{standalone: true}">
                                    <div class="profile_form__btn">
                                        <button type="button" data-toggle="modal" class="btn_orange" (click)="updateProfile()">Update</button>
                                        <!--<button type="button" data-toggle="modal" data-target="#update_email" class="btn_orange">Update</button>-->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade show" [class.active]="current_tab === 'personalize'" [class.show]="current_tab === 'personalize'" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div class="personalize">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-12 col-md-6 col-lg-6">
                        <div class="border">
                            <div class="add__title">
                                <h3 class="title">Product Usage Preference</h3>
                            </div>
                            <div class="profile__form-1 profile_form-1">
                                <form class="personalizeForm">
                                    <div class="col-12">
                                        <label class="col-12 padding-15-0">
                                            <span class="fontWeight600">What do you use the proxies for?*</span>
                                            <br />
                                            <select name="target" [formControl]="validation_step1.controls['target']" required class="col-12">
                                                <option *ngFor="let item of proxy_usage_opt" [value]="item.value">{{ item.text }}</option>
                                            </select>
                                            <br />
                                            <span *ngIf="validation_step1.controls['target'].hasError('required') && validation_step1.controls['target'].touched" class="validation">Please select one of options</span>
                                        </label>
                                    </div>
                                    <div class="col-12 ">
                                        <label *ngIf="validation_step1.controls['target'].value == 'others'" class="col-12 padding-15-0">
                                            <input name="other_target" [formControl]="validation_step1.controls['other_target']" type="text" placeholder="Purpose of use" maxlength="25" class="col-12">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12">
                                        <label class="col-12 padding-15-0">
                                            <span class="fontWeight600">Select the websites you want to use the proxies on?*</span>
                                            <br />
                                            <select name="website" [formControl]="validation_step1.controls['website']" required class="col-12">
                                                <option *ngFor="let item of proxy_website_usage_opt" [value]="item.value">{{ item.text }}</option>
                                            </select>
                                            <br />
                                            <span *ngIf="validation_step1.controls['website'].hasError('required') && validation_step1.controls['website'].touched" class="validation">Please select one of options</span>
                                        </label>
                                    </div>
                                    
                                    <div class="col-12">
                                        <label class="col-12 padding-15-0" *ngIf="validation_step1.controls['website'].value == 'others'">
                                            <input name="other_website" [formControl]="validation_step1.controls['other_website']" type="text" placeholder="Enter domain name or IP" maxlength="250" class="col-12">
                                        </label>
                                    </div>
                                    
                                    <div class="col-12">
                                        <label class="col-12 padding-15-0">
                                            <span class="fontWeight600">What's your estimated bandwidth usage/month? *</span>
                                            <br />
                                            <select [formControl]="validation_step1.controls['estimated_quota']" required class="col-12">
                                                <option *ngFor="let item of estimatedBandwidthUsageMonth" [value]="item.value">{{ item.text }}</option>
                                                <!-- TODO -->
                                            </select>
                                            <br />
                                            <span *ngIf="validation_step1.controls['estimated_quota'].hasError('required') && validation_step1.controls['estimated_quota'].touched" class="validation">Please select one of options</span>
                                        </label>
                                    </div>
                                    
                                    <div class="col-12">
                                        <label class="col-12 padding-15-0">
                                            <span class="fontWeight600">What's your monthly budget for the proxy subscription? *</span>
                                            <br />
                                            <select class="col-12" [formControl]="validation_step1.controls['estimated_budget']" required>
                                                <option *ngFor="let item of estimatedBudget" [value]="item.value">{{ item.text }}</option>
                                                <!-- TODO -->
                                            </select>
                                            <br />
                                            <span *ngIf="validation_step1.controls['estimated_budget'].hasError('required') && validation_step1.controls['estimated_budget'].touched" class="validation">Please select one of options</span>
                                        </label>
                                    </div>
                                    
                                    <div class="col-12">
                                        <label class="col-12 padding-15-0">
                                            <span class="fontWeight600">Choose the products interested *</span>
                                            <br />
                                            <ul>
                                                <li *ngFor="let item of userProductInterested" >
                                                    <input type="checkbox" class="checkbox" [value]="item.value" style="float: left; width: 15px; height: 15px; margin: 5px 10px 0px 0px;" (change)="onCheckboxChange($event)" [checked]="isChecked(item.text)"/> {{ item.text }}
                                                </li>
                                            </ul>
                                            <br />
                                            <span *ngIf="validation_step1.controls['user_product_interested'].hasError('required') && validation_step1.controls['user_product_interested'].touched" class="validation">Please select one of options</span>
                                        </label>
                                    </div>
                                    
                                    <div class="col-12">
                                        <label class="col-12 padding-15-0">
                                            <span class="fontWeight600">List the preferred proxy geo locations/cities</span>
                                            <br />
                                            <textarea class="col-12" [formControl]="validation_step1.controls['preferred_locations']" style="width:100%" ></textarea>
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <label class="col-12 padding-15-0">
                                            <span class="fontWeight600">Share specific project requirements (if any)</span>
                                            <br />
                                            <textarea class="col-12" [formControl]="validation_step1.controls['comments']" style="width:100%" ></textarea>
                                        </label>
                                    </div>
                                    <div class="profile_form__btn">
                                        <button type="button" data-toggle="modal" (click)="savePrefrences()" class="btn_orange">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" [class.active]="current_tab === 'authentication'" [class.show]="current_tab === 'authentication'" id="nav-authentication" role="tabpanel" aria-labelledby="nav-authentication-tab">
            <div class="authentication">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-4">
                        <div class="border">
                            <div class="add__title">
                                <h3 class="title">Change Password</h3>
                            </div>
                            <div class="authentication__form authentication_form">
                                <form>
                                    <input type="password" placeholder="New Password" [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}">
                                    <input type="password" placeholder="Confirm New Password" [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}">
                                    <div class="authentication_form__btn">
                                        <button type="button" data-toggle="modal" (click)="updatePassword()" class="btn_orange">Update</button>
                                        <!--<button type="button" data-toggle="modal" data-target="#update_auth" class="btn_orange">Update</button>-->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" [class.active]="current_tab === 'notifications'" [class.show]="current_tab === 'notifications'" id="nav-notifications" role="tabpanel" aria-labelledby="nav-notifications-tab" *ngIf="limeProxiesService.environment.show_notifications">
			<app-lmp-notifications></app-lmp-notifications>
        </div>
    </div>
</div>

<div class="open-modal" [attr.data-toggle]="'modal'" [attr.data-target]="modalId"></div>

<div class="modal fade modal_canceled" id="update_auth" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <p>Your Password has been updated Successfully.</p>
                <button type="button" class="btn_orange" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade modal_canceled" id="update_phone" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <p>An otp has been sent to your new phone number, please verify it to update the same.</p>
                <button type="button" class="btn_orange" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade modal_canceled" id="update_email" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <p>We will send a verification e-mail to your new id, please confirm to update the same.</p>
                <button type="button" class="btn_orange" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>