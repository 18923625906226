<div class="breadcrumbs">
	<a [routerLink]="['/account/my-account']" class="breadcrumbs__item">My Account</a>
    <a *ngIf="user_auth_method == 'user'" [routerLink]="['/account/auth-user']" class="breadcrumbs__item">Authorized User</a>
    <a *ngIf="user_auth_method == 'ip'" [routerLink]="['/account/auth-ip']" class="breadcrumbs__item">Authorized IPs</a>
    <a *ngIf="user_access_type == 'whitelist'" [routerLink]="['/account/user-whitelist']" class="breadcrumbs__item">User Whitelist</a>
    <a *ngIf="limeProxiesService.environment.show_teams" [routerLink]="['/account/my-team']" class="breadcrumbs__item breadcrumbs__item--active">Users</a>
    <a [routerLink]="['/my-profile-details']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">My Profile</a>
    <a [routerLink]="['/my-profile/notifications']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">Notifications</a>
    <a [routerLink]="['/my-profile/authentication']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">Change Password</a>
</div>
<div class="team-layout-container">
    <div class="table-new-container" *ngIf="members">
        <div class="table-head">
            <h3>Users</h3>
            <!--<a (click)="openAddEditModal(false)"  class="breadcrumbs__item breadcrumbs-action">Add User</a>-->
        </div>
        <table class="table-new-ui">
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role <img src="assets/images/info.png" alt="Role info image"  title="Admin - Has administrative access to all parts of the application &#013; &#013;Standard - Has access to all parts of the product except Accounts & Billing"/></th>
                <th>Joined since</th>
                <th>Action</th>
            </tr>
            <tr *ngFor="let member of members; let i = index;">
                <td>#{{i + 1}}</td>
                <td>{{member.first_name}} {{member.last_name}}</td>
                <td class="email">{{member.email}}</td>
                <td>{{member.role_name}}</td>
                <td *ngIf="member.joined_since">{{member.joined_since | date: 'dd.MM.yyyy'}}</td>
                <td *ngIf="!member.joined_since">
					{{member.status | titlecase }}
				</td>
                <td>
					<div *ngIf="member.role_name == 'Admin' || member.role_name == 'Standard'" class="action-icon" (click)="openEditMember(member)"></div>
				</td>
            </tr>
        </table>
    </div>

    <!--<div class="sidebar">-->
    <!--<div class="page__sidebar-col">-->
    <!---->
    <!--</div>-->
    <!--</div>-->
    <!--<a class="btn btn&#45;&#45;green btn&#45;&#45;full popup-toggle" (click)="resend()" *ngIf="members">-->
    <a class="btn btn--green btn--full popup-toggle" (click)="openAddMember()" *ngIf="members">
        <span class="icon"></span>
        <span class="label">Add User</span>
    </a>
</div>



<div class="edit-team-member-modal" *ngIf="showEditModal">
    <div class="edit-body">
        <div class="head">
            <span *ngIf="!isEditMember">Add</span>
            <span *ngIf="isEditMember">Edit</span>
			&nbsp;User
        </div>
        <form (submit)="addTeamMember()" [formGroup]="teamForm">
            <div class="input-group">
                <label for="">Email</label>
                <input type="text" class="input-field" [formControl]="teamForm.controls['email']" title="">
            </div>
            <div class="input-group">
                <label for="">Role <img src="assets/images/info.png" alt="Role info image"  title="Admin - Has administrative access to all parts of the application &#013; &#013;Standard - Has access to all parts of the product except Accounts & Billing"/></label>
                <select class="input-field" [ngModelOptions]="{standalone: true}" [(ngModel)]="role" title="">
                    <option [selected]="i === 0" value="{{role['rid']}}" *ngFor="let role of userRoles; let i = index;">{{role['name']}}</option>
                </select>
            </div>
            <div style="margin-bottom: 15px; float: left; width: 100%;" [class.single]="!isEditMember">
                <button type="button" class="btn btn--gray pull-left" *ngIf="isEditMember" (click)="openDeleteModal()">Delete</button>
                <button type="submit" class="btn pull-right" >{{isEditMember ? 'Update User' : 'Add User'}}</button>
				<button type="button" class="btn pull-right" style="margin-right: 10px;" *ngIf="isEditMember && memberForEdit.status == 'pending'" (click)="resendInvitation()">Resend Invitation</button>
            </div>
        </form>
        <span class="close-modal" (click)="openAddEditModal(false)">x</span>
    </div>
</div>

<div class="open-modal" [attr.data-toggle]="'modal'" [attr.data-target]="modalId"></div>

<div class="edit-team-member-modal" *ngIf="showDeleteModal">
	<div class="edit-body">
        <div class="head">
            Delete User
        </div>
		<form>
			
			<p>Do you really want to delete this user?</p>
			<div class="btns">
				<button type="button" class="btn  btn--gray" data-dismiss="modal" (click)="deleteTeamMember()">Delete</button>
				<button type="button" class="btn" data-dismiss="modal" (click)="showDeleteModal = !showDeleteModal">Cancel</button>
			</div>
		</form>
        <span class="close-modal" (click)="showDeleteModal = !showDeleteModal">x</span>
    </div>
</div>