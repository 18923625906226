import {Component, HostBinding, ViewChildren, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {LimeProxiesService} from '../../services/limeproxies.service';
import {ActivatedRoute} from '@angular/router';
declare var analytics: any;

@Component({
  selector: 'billing-add-new',
  templateUrl: '../../templates/billing/billing-add-new.template.html'
})

export class BillingAddNewComponent implements OnInit {

  @HostBinding('class') myClass = 'page__main-col';
  @ViewChildren('liPayment') payments;
  fastSpringForm: FormGroup;
  type = 'fastSpring';

  public pay_type_label = {
    'fastSpring' : 'Fast Spring',
    'payPal' : 'PayPal',
    'bitCoin' : 'CryptoCurrency'
  };

  constructor(public fb: FormBuilder, public limeProxiesService: LimeProxiesService, private route: ActivatedRoute) {
    this.fastSpringForm = fb.group({
      num: [5],
      other_num: [''],
      tos: [false, [Validators.required]]
    });
  }

  ngOnInit() {
      if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
          analytics.track('checkout_initiated', {});
      }
  }

  choosePaymentMethod(type) {
    this.type = type;
    analytics.track('checkout_paymentmethod_chosen', {});
  }
}
