import {Component, HostBinding, OnInit} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';

@Component({
    selector: 'billing-card',
    templateUrl: '../../templates/billing/billing-card.template.html'
})

export class BillingCardComponent implements OnInit {

    @HostBinding('class') myClass = 'page__main-col';

    public has_card = false;
    public card_info: any;

    public cc = {
        card_name : {
            value: '',
            has_error: false,
            test: /[a-zA-Z_ ]{2,}/,
            touched: false
        },
        card_number : {
            value: '',
            has_error: false,
            test: /^((67\d{2})|(4\d{3})|(5[1-5]\d{2})|(6011))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7]\d{13}$/,
            touched: false
        },
        card_exp : {
            value: '',
            has_error: false,
            test: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
            touched: false
        },
        cvv : {
            value: '',
            has_error: false,
            test: /^([0-9]{3,4})$/,
            touched: false
        },
        valid : false
    };

    constructor(public limeProxiesService: LimeProxiesService) {

        // this.card_info = {
        //     'brand': 'visa',
        //     'expiry_month': 12,
        //     'expiry_year': 2019,
        //     'funding_type': 'not_known',
        //     'iin': '411111',
        //     'last4': '1111',
        //     'masked_number': '************1111',
        //     'object': 'card'
        // };

        if (this.card_info) {
            this.cc.card_exp.value = this.card_info['expiry_month'] + '/' + this.card_info['expiry_year'];
            this.cc.card_exp.touched = true;
            this.has_card = true;
        }

        this.limeProxiesService.charge_get_card().subscribe(
            success => {
                // if (success['status'] && success['card']) {
                //     this.card_info = success['card'];
                // }
                console.log(success);
            }, error => { console.log(error); }
            // }, error => { console.log('Can\'t fetch card data'); }
        );
    }

    ngOnInit() {}

    cc_entered(key: string) {
        const obj = this.cc[key];
        this.cc[key].touched = true;
        this.cc[key].has_error = !obj.test.test(obj.value);
        const keys = Object.keys(this.cc);
        let valid_c = 0;
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] !== 'valid') {
                if (!this.cc[keys[i]].has_error && this.cc[keys[i]].touched) {
                    valid_c++;
                }
            }
        }
        this.cc.valid = (valid_c === 4);
    }

    update_card() {
        if (this.cc.valid) {
            let exp_obj = this.cc.card_exp.value.split('/');
            const body = {
                'number': this.cc.card_number.value,
                'cvv': this.cc.cvv.value,
                'expiryYear': exp_obj[1],
                'expiryMonth': exp_obj[0]
            };
            this.limeProxiesService.charge_payment_source(body).subscribe(
                success => {
                    this.limeProxiesService.notifyBarNew(success['message'],  success['status'] ? 'success' : 'error');
                }, error => {
                    this.limeProxiesService.notifyBarNew('Unable to ' + (this.has_card ? 'update' : 'add') + ' card information at the moment. Please try again later.', 'error');
                }
            );
        } else {
            return false;
        }
    }
}
