import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ScrollToModule } from 'ng2-scroll-to';
import { CommonModule } from '@angular/common';

import { ApiComponent } from './api.component';


@NgModule({
    declarations: [ApiComponent], // components, pipes and directives that are part of this module
    imports: [CommonModule, RouterModule, ScrollToModule.forRoot()],  // importing other modules
    providers: [] // providers eg services that are part of this module
})

export class ApiModule {

}
