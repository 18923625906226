import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate} from '@angular/router';
import {Observable} from 'rxjs';
import {LimeProxiesService} from './services/limeproxies.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private limeProxiesService: LimeProxiesService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return !this.limeProxiesService.checkExpired();
  }
}
