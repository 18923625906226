import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
    selector: '.dashboard',
    templateUrl: '../../templates/main-frame/dashboard-nav.template.html'
})

export class DashboardNavComponent {

    activePage = 'home';
	role = '';

    isActive = [
        {key: 'home', value: ''},
        {key: 'products', value: ''},
        {key: 'my-account', value: ''},
        {key: 'billing', value: ''},
        {key: 'affiliate', value: ''},
        {key: 'support', value: ''},
        {key: 'my-team', value: ''}
    ];

    constructor(private route: ActivatedRoute, private router: Router, private userService: UserService) {
        router.events.subscribe((val) => {
            if (val['url']) {
                this.routeChanged(val['url']);
            }
        });
    }
	
	ngOnInit() {
		let curObject = this;

		this.userService.getUser().subscribe(user => {
			curObject.role = user.role
		});
	}

    linkActivated(page) {
        this.activePage = page;
    }

    routeChanged(url) {
        let navActive = false;
        for (const pg of this.isActive) {
            pg.value = (url.indexOf(pg.key) !== -1) ? 'dashboard__link--active' : '';
            navActive = (url.indexOf(pg.key) !== -1);
        }
        if (!navActive) {
            this.activePage = '';
        }
    }
}