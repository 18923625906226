import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserResolveService implements Resolve<any> {
	

    constructor(private userService: UserService) {}

    resolve(route: ActivatedRouteSnapshot) : Observable<any> {
		return this.userService.getUser();
	}
}
