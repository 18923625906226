<form class="block" [formGroup]="passwordForm">
    <h4 class="block__title">Your profile password</h4>
    <p class="block__desc">Please set up your profile password and click on 'Save password' button below.</p>
    <div class="">
        <div class="col-xs-12 input-wrapper">
            <input type="password" class="input-field" name="profile_password" placeholder="New Password" #newPasswordField [(ngModel)]="newPassword" [formControl]="passwordForm.controls['newPassword']">
            <div *ngIf="passwordForm.controls['newPassword'].hasError('maxlength') && passwordForm.controls['newPassword'].touched" class="alert-danger">Length must be less than 50</div>
            <div *ngIf="passwordForm.controls['newPassword'].hasError('minlength') && passwordForm.controls['newPassword'].touched" class="alert-danger min-pass-length">Minimum password length is 8 characters</div>
        </div>
        <div class="col-xs-12 input-wrapper">
            <input type="password" class="input-field" name="profile_password_confirmation" placeholder="Confirm Password" [(ngModel)]="confirmPassword" [formControl]="passwordForm.controls['confirmPassword']">
            <div *ngIf="passwordForm.controls['confirmPassword'].hasError('maxlength') && passwordForm.controls['confirmPassword'].touched" class="alert-danger">Length must be less than 50</div>
            <div *ngIf="passwordForm.controls['confirmPassword'].hasError('minlength') && passwordForm.controls['confirmPassword'].touched" class="alert-danger min-pass-length">Minimum password length is 8 characters</div>
        </div>
    </div>
    <div class="end-xs">
        <div class="col-xs-12 input-wrapper update-password" [class.]>
            <button type="submit" class="btn btn--full" (click)="updatePassword()" [disabled]="!passwordForm.valid" [ngClass]="{'btn--gray': !passwordForm.valid}">Save Password</button>
        </div>
    </div>
</form>