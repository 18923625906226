import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import {LimeProxiesService} from '../services/limeproxies.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(private limeProxiesService: LimeProxiesService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.limeProxiesService.showApiLoader(true);
	
        return next.handle(req).pipe(
            finalize(() => this.limeProxiesService.showApiLoader(false))
        );
    }
}