import {Routes} from '@angular/router';

import {BillingCreditHistoryComponent} from './billing-credit-history.component';
import {BillingAddNewComponent} from './billing-add-new.component';
import {BillingCardComponent} from './billing-card.component';
import {AuthGuard} from '../../auth.guard';
import { UserResolveService } from '../../services/user-resolve.service';

export const billingRoutes: Routes = [
  {
    path: 'history',
    component: BillingCreditHistoryComponent,
    canActivate: [AuthGuard],
	resolve : { user : UserResolveService }
  },
  {
    path: 'new',
    component: BillingAddNewComponent,
    canActivate: [AuthGuard],
	resolve : { user : UserResolveService }
  },
  {
    path: 'card',
    component: BillingCardComponent,
    canActivate: [AuthGuard],
	resolve : { user : UserResolveService }
  }
];
