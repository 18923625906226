import {AfterViewInit, Component, HostBinding, NgZone, OnInit} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';
import {AuthService} from '../../services/auth.service';
import {UserCredentials} from '../../entities/user-credentials';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import { switchMap } from 'rxjs/operators'
import {MyValidators} from '../../custom-validators/custom.validator';
declare const gapi: any;
declare var analytics: any;


@Component({
  selector: 'login-or-register',
  templateUrl: '../../templates/login/login-or-register.template.html'
})

export class LoginOrRegisterComponent implements OnInit, AfterViewInit {

  @HostBinding('class') myClass = 'page__content-inner height-100';

  private creds: UserCredentials;
  private username;
  private password;
  private onRequest: any = false;
  public auth2: any;
  public loginOrRegisterForm;
  // public google_logo = require('../../../assets/images/new_signup/icon_google.png').default;
  public partners_logo = [
      // '../../../assets/images/new_signup/logo_ginza.png',
      // '../../../assets/images/new_signup/logo_booking.png',
      // '../../../assets/images/new_signup/logo_hotels.png',
      // '../../../assets/images/new_signup/logo_authoritylabs.png'
  ];

  constructor(private limeProxiesService: LimeProxiesService,
              private authService: AuthService,
              private router: Router,
              private fb: FormBuilder,
              private zone: NgZone) {
    this.loginOrRegisterForm = fb.group({
      'username': ['', Validators.compose([MyValidators.validateEmail(), Validators.required])],
      'password': ['', Validators.compose([Validators.minLength(8), Validators.required])]
    });
    if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
      analytics.track('Registered', {
          page_url : '/login'
      });
    }

  }

  ngOnInit() {
    if (this.limeProxiesService.messageToDisplay.value.message !== 'Nothing to display') {
        console.log('LoginOrRegisterComponent', this.limeProxiesService.messageToDisplay.value.message);
      this.limeProxiesService.notifyBarNew(
        this.limeProxiesService.messageToDisplay.value.message,
        this.limeProxiesService.messageToDisplay.value.success ? 'success' : 'error'
      );
      this.limeProxiesService.messageToDisplay.next({
        message: 'Nothing to display',
        success: false
      });
    }
    this.limeProxiesService.setTitle('Login');
  }

  ngAfterViewInit() {
      this.googleInit();
  }

    googleInit() {
        const selector = document.querySelector('.g-signin');
        const __this = this;
        if (selector) {
            if (typeof gapi !== 'undefined') {
                gapi.load('auth2', () => {
                    __this.auth2 = gapi.auth2.init({
                        client_id: this.limeProxiesService.environment['third_parties']['google_app']['client_id'],
                        cookiepolicy: 'single_host_origin',
                        scope: 'profile email'
                    });
                    __this.attachSignin(selector);
                });
            }
        } else {
            setTimeout(() => {
                __this.googleInit();
            }, 200);
        }
    }

    attachSignin(element) {
        this.auth2.attachClickHandler(element, {},
            (googleUser) => {

                const profile = googleUser.getBasicProfile();
                let given_name = profile.getGivenName();
                let family_name = profile.getFamilyName();

                const pr_obj = {
                    first_name: given_name,
                    last_name: family_name,
                    token: googleUser.getAuthResponse().id_token,
                    email: profile.getEmail(),
                };

                if (!this.onRequest) {
                    this.onRequest = true;
                    this.limeProxiesService.showApiLoader(true);
                    this.limeProxiesService.loginViaGoogle(pr_obj)
                        .subscribe(
                            success => {
                                if (success['token']) {
                                    this.limeProxiesService.setUserProfile();
                                    this.limeProxiesService.getUserNotification().subscribe(
                                        success => {
                                            this.zone.run(() => {
                                                if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
                                                    analytics.track('signin_success', {});
                                                }
                                                if (success['wizard']) {
                                                    this.router.navigate(['/login/setup']);
                                                } else {
                                                    this.router.navigate(['../home']);
                                                }
                                            });
                                        }, error => {
                                            this.limeProxiesService.showMessage(error);
                                        }
                                    );
                                } else if (success['_user_message_type'] && success['_user_message_type'] === 'error') {
                                    this.limeProxiesService.showMessage(success);
                                }
                                this.onRequest = false;
                                this.limeProxiesService.showApiLoader(false);
                            },
                            error => {
                                this.limeProxiesService.showApiLoader(false);
                                this.onRequest = false;
                                this.limeProxiesService.showMessage(error);
                            }
                        );
                } else {
                    this.limeProxiesService.notifyBarNew('Please wait for login response.', 'error');
                }


            }, (error) => {
                if (error.error) {
                    if (error.error === 'popup_closed_by_user') {
                        this.limeProxiesService.notifyBarNew('Authorization failed since you leave authorization flow.', 'error');
                    } else {
                        this.limeProxiesService.notifyBarNew('Authorization failed. Please try alternate ways of authorization.', 'error');
                    }
                } else {
                    this.limeProxiesService.notifyBarNew('Authorization failed. Please try alternate ways of authorization.', 'error');
                }
            });
    }

    click_forgot_password() {
        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
            analytics.track('forgot_password_clicked', {});
        }
    }

  login() {
      if (!this.onRequest) {
          this.onRequest = true;
          this.limeProxiesService.showApiLoader(true);
          this.limeProxiesService.login(this.loginOrRegisterForm.value['username'], this.loginOrRegisterForm.value['password'])
              .subscribe(
                  data => {
                      if ((data['_user_message_type'] && data['_user_message_type'] === 'success') || data['token']) {
                          this.limeProxiesService.setUserProfile();
                          this.limeProxiesService.getUserNotification().subscribe(
                              success => {
                                  if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
                                      analytics.track('signin_success', {});
                                  }
                                  localStorage.setItem('user_email', this.loginOrRegisterForm.controls['username'].value);

                                  this.router.navigate(['../home']);
                                //   if (success['wizard']) {
                                //       this.router.navigate(['/login/setup']);
                                //   } else {
                                //       this.router.navigate(['../home']);
                                //   }
                              }, error => {
                                 this.limeProxiesService.showMessage(error);
                              }
                          );
                      } else if (data['_user_message_type'] && data['_user_message_type'] === 'error') {
                          this.limeProxiesService.showMessage(data);
                      }
                      this.onRequest = false;
                      this.limeProxiesService.showApiLoader(false);
                  },
                  error => {
                      this.limeProxiesService.showMessage(error);
                      this.limeProxiesService.showApiLoader(false);
                      this.onRequest = false;
                  }
              );
      } else {
          this.limeProxiesService.notifyBarNew('Please wait for login response.', 'error');
      }

  }
}
