import {Component, AfterViewInit, HostBinding, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {LimeProxiesService} from '../../services/limeproxies.service';
declare var $: any;
declare var analytics: any;

@Component({
    selector: 'faq',
    templateUrl: '../../templates/help/faq.template.html'
})

export class FaqComponent implements AfterViewInit, OnDestroy {

    @HostBinding('class') myClass = 'page__main-col';

    private subsc: Subscription;

    constructor(private route: ActivatedRoute,
                private _limeService: LimeProxiesService) {
    }

    ngOnDestroy() {
        this.subsc.unsubscribe();
    }

    ngAfterViewInit() {

        $('.toggle-grid__item').on('click', function(e) {
            e.preventDefault();
            let target = $(this),
                grid = target.closest('.toggle-grid'),
                maxSelect = parseInt(grid.attr('data-max-select'), 10) || false;

            // When clicking the input that resets other selected options
            if (target.attr('data-reset-selected') !== undefined) {
                target.siblings().removeClass('toggle-grid__item--active')
                    .find('input').prop('checked', false);
            }

            if (maxSelect) {
                if (maxSelect > target.siblings('.toggle-grid__item--active').length) {
                    let input = target.find('input');
                    target.toggleClass('toggle-grid__item--active');
                    input.prop('checked', !input.prop('checked'));
                }
            } else {
                target.siblings().removeClass('toggle-grid__item--active')
                    .find('input').prop('checked', false);
                target.addClass('toggle-grid__item--active')
                    .find('input').prop('checked', true);
            }
        });

        // Tab functionality
        let tabs = document.querySelectorAll('.tab__toggle');
        tabs.forEach((tab) => {
            tab.addEventListener('click', () => {
                let content = document.querySelector('.tab__content-wrapper');
                let index = 0;
                for (let i = 0; i < tabs.length; i++) {
                    tabs[i].classList[(tabs[i] === tab) ? 'add' : 'remove']('tab__toggle--active');
                    if ((tabs[i] === tab)) { index = i; }
                }

                if (content) {
                    let childs = content.querySelectorAll('.tab__content-block');
                    for (let j = 0; j < childs.length; j++) {
                        childs[j].classList[(j === index) ? 'add' : 'remove']('tab__content-block--active');
                    }
                }
            });
        });

        // FAQ Q&A blocks accordion
        $('.faq-block--answer').on('click', function() {
            $(this).siblings('.faq-block--active').removeClass('faq-block--active').find('.block__desc').slideUp();
            if ($(this).hasClass('faq-block--active')) {
                $(this).removeClass('faq-block--active').find('.block__desc').slideUp();
            } else {
                $(this).addClass('faq-block--active').find('.block__desc').slideDown();
                if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
                    analytics.track('support_check_faq', {});
                }
            }
        });

        this.subsc = this.route.queryParams.subscribe((params: any) => {
            if (params.id) {
                const selector = '.support.' + params.id;
                $(selector).trigger('click');
            }
        });
    }

}
