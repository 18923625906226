<div class="green-background">
    <div class="page-container site-content">
        <h1 class="main-title text-center">Something is technically wrong.</h1>
        <h4 class="text-center title-md">
            Thanks for noticing, we're currently fixing it up and things will be back to normal soon.
        </h4>
        <!--<ul class="lang-list text-center">
            <li>
                <a href="#">English</a>
            </li>
        </ul>-->
        <div class="copyright-box text-center">
            <!--<p class="copyright"> © 2017 limeproxies.com </p>-->
<!--            <ul class="footer-list">
                <li>
                    <a href="#">About</a>
                </li>
                <li>
                    <a href="#">Help</a>
                </li>
                <li>
                    <a href="#">Status</a>
                </li>
            </ul>-->
        </div>
        <div class="bottom-img text-center">
            <img src="./assets/images/505img.png" class="img-resonsive" alt="505 error">
        </div>
    </div>
</div>