import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { UserAccount } from '../../entities/user-account';
import {AccountSidebarComponent} from './account-sidebar.component';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
declare var analytics: any;
import * as tel_input from 'intl-tel-input';
import {MyValidators} from '../../custom-validators/custom.validator';
declare var regxName: any;

@Component({
    selector: 'my-team',
    templateUrl: '../../templates/account/my-team.template.html'
})

export class MyTeamComponent implements OnInit {

    @HostBinding('class') productsClass = 'page__main-col';
    @ViewChild('accountSidebar') accountSidebar: AccountSidebarComponent;

    public members: any;
    public showEditModal = false;
    public isEditMember = false;
    public teamForm: FormGroup;
    public memberForEdit: any;
    public role: any = 2;
    public phone: any;
    public iti: any;
    public modalId: any;
    public validPhone = false;
    public addClicked = false;
    public user_auth_method = '';
    public user_access_type;
    public userRoles: any = [];


    constructor(public limeProxiesService: LimeProxiesService, private router: Router, public fb: FormBuilder) {
        this.teamForm = fb.group({
            // 'first_name': ['', Validators.compose([Validators.maxLength(50), Validators.required])],
            // 'last_name': ['', Validators.compose([Validators.maxLength(50), Validators.required])],
            'email': ['', Validators.compose([Validators.required, MyValidators.validateEmail()])]
        });

        if (!this.limeProxiesService.environment['show_teams']) {
            this.router.navigate(['/']);
        }
    }
    ngOnInit() {
        // this.init_tel_input();
        this.getTeamMembers();
        this.getTeamRoles();

        this.limeProxiesService.getUserAccount().subscribe(
            usr_acc_resp => {
                this.user_auth_method = usr_acc_resp.auth_method;
                this.user_access_type = usr_acc_resp.access_type;
                localStorage.setItem('user_auth_method', this.user_auth_method);
                localStorage.setItem('user_access_type', this.user_access_type);
            },
        );
    }

    getTeamRoles() {
        this.limeProxiesService.get_team_roles().subscribe(
            success => {
                if (success['data']) {
                    this.userRoles = success['data'];
                }
            }, error => {
                this.limeProxiesService.showMessage(error);
            }
        );
    }

    getTeamMembers() {
        this.limeProxiesService.get_team_members_list().subscribe(
            success => {
                this.members = success['data'];
            }, error => {
                this.limeProxiesService.showMessage('Unable to retrieve Team data.', 'error');
            }
        );
    }

    addTeamMember() {	
        this.addClicked = true;

        if (this.isEditMember) {
            this.editMember();
        } else {
            if (this.teamForm.valid) {
                let body = this.teamForm.value;
                body['rid'] = this.role;

				if (!regxName.test(body.first_name)) {
					this.limeProxiesService.notifyBarNew('Enter valid first name.', 'error');
					return false;
				} if (!regxName.test(body.last_name)) {
					this.limeProxiesService.notifyBarNew('Enter valid last name.', 'error');
					return false;
				} else if (this.phone && this.phone.length && !this.validPhone) {
                    this.limeProxiesService.showMessage('Phone number is invalid.', 'error');
                    return false;
                } else {
                    body['phone'] = this.phone;
                }

                this.limeProxiesService.add_team_member(body).subscribe(
                    success => {
						this.limeProxiesService.showMessage(success);
                        this.getTeamMembers();
                        this.openAddEditModal(false);
                        this.teamForm.reset();
                    }, error => {
                        this.limeProxiesService.showMessage(error);
                    }
                );
            } else {
                let errorString = '';
                // if (this.teamForm.controls['first_name'].errors) {
                    // errorString += errorString.length > 0 ? ', First Name' : 'Please enter valid First Name';
                // }
                // if (this.teamForm.controls['last_name'].errors) {
                    // errorString += errorString.length > 0 ? ', Last Name' : 'Please enter valid Last Name';
                // }
                if (this.teamForm.controls['email'].errors) {
                    errorString += errorString.length > 0 ? ', Email' : 'Please enter valid Email';
                }
                this.limeProxiesService.notifyBarNew(errorString, 'error');
				return false;
            }

            this.addClicked = false;
        }
		return false;
    }

    openAddEditModal(edit: boolean) {
        this.showEditModal = !this.showEditModal;
        this.isEditMember = edit;

        if (!edit) {
            this.flashValues();
        }
    }
	
	public showDeleteModal = false;

	openDeleteModal() {
        this.showDeleteModal = !this.showDeleteModal;
		this.showEditModal = false;
    }

    flashValues() {
        this.teamForm.controls['first_name'].setValue('');
        this.teamForm.controls['last_name'].setValue('');
        this.teamForm.controls['email'].setValue('');
    }

	openAddMember() {
		this.memberForEdit = [];
		this.openAddEditModal(false);
	}

    openEditMember(member: any) {
        this.memberForEdit = member;

        setTimeout(() => {
            if (this.iti) {
                this.iti.setNumber(member.phone);
            }
        }, 1000);
        // this.teamForm.controls['first_name'].setValue(member.first_name);
        // this.teamForm.controls['last_name'].setValue(member.last_name);
        this.teamForm.controls['email'].setValue(member.email);
        this.openAddEditModal(true);
    }
	
	resendInvitation() {
		let body = this.teamForm.value;
		body['rid'] = this.role;
		body['tmid'] = this.memberForEdit.tmid;
		body['uid'] = this.memberForEdit.uid;
		
		this.limeProxiesService.resendInvitation(body).subscribe(
			success => {
				this.limeProxiesService.notifyBarNew(success._user_message, 'success');
				this.getTeamMembers();
				this.openAddEditModal(false);
				this.teamForm.reset();
			}, error => {
				this.limeProxiesService.showMessage(error);
			}
		);
	}

    editMember() {	
        if (this.teamForm.valid) {
            let body = this.teamForm.value;
            body['rid'] = this.role;
            body['tmid'] = this.memberForEdit.tmid;
            body['uid'] = this.memberForEdit.uid;

            if (!regxName.test(body.first_name)) {
				this.limeProxiesService.notifyBarNew('Enter valid first name.', 'error');
				return false;
			} if (!regxName.test(body.last_name)) {
				this.limeProxiesService.notifyBarNew('Enter valid last name.', 'error');
				return false;
			} else if (this.phone && this.phone.length && !this.validPhone) {
                this.limeProxiesService.showMessage('Phone number is invalid.', 'error');
                return false;
            } else {
                body['phone'] = this.phone;
            }

            body['member_id'] = this.memberForEdit.uid;

            this.limeProxiesService.edit_team_member(body).subscribe(
                success => {
					this.limeProxiesService.notifyBarNew(success._user_message, 'success');
                    this.getTeamMembers();
                    this.openAddEditModal(false);
                    this.teamForm.reset();
                    
                }, error => {
                    this.limeProxiesService.showMessage(error);
                }
            );
        } else {
            let errorString = '';

            if (this.teamForm.controls['first_name'].errors) {
                errorString += errorString.length > 0 ? ', First Name' : 'Please enter valid First Name';
            }
            if (this.teamForm.controls['last_name'].errors) {
                errorString += errorString.length > 0 ? ', Last Name' : 'Please enter valid Last Name';
            }
            if (this.teamForm.controls['email'].errors) {
                errorString += errorString.length > 0 ? ', Email' : 'Please enter valid Email';
            }

            this.limeProxiesService.notifyBarNew(errorString, 'error');
        }

        this.addClicked = false;

        //
    }

    deleteTeamMember() {
		let curObject = this;
	
        this.limeProxiesService.delete_team_member(this.memberForEdit.tmid, this.memberForEdit.uid).subscribe(
            success => {
                this.limeProxiesService.showMessage(success);
                setTimeout(() => {
					curObject.showDeleteModal = false;
                    curObject.getTeamMembers();
                    curObject.teamForm.reset();
                }, 300);
            }, error => {
                this.limeProxiesService.showMessage(error);
            }
        );
    }

    init_tel_input() {
        const tel_input_selector = document.querySelector('.tel-input');
        if (tel_input_selector) {
            if (this.iti) {
                this.iti.destroy();
            }
            this.iti = tel_input(tel_input_selector,
                {
                    preferredCountries: ['us', 'gb'],
                    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js'
                });
            const __this = this;
            tel_input_selector.addEventListener('countrychange', () => {
                __this.phone_entered(null);
            });
        } else {
            const __this = this;
            setTimeout(() => {
                __this.init_tel_input();
            }, 100);
        }
    }

    openModal(modal: any) {
        this.openAddEditModal(false);
        this.modalId = modal;

        setTimeout(() => {
            let elem = document.querySelector('.open-modal') as HTMLElement;
            if (elem) {
                elem.click();
            }
        }, 300);
    }

    phone_entered(event: any) {
        this.phone = this.iti.getNumber();
        this.validPhone = this.iti.isValidNumber();
    }
}
