<div *ngIf="templateType === 'new'">
    <form>
        <label>
            <span>Name on the card</span>
            <div class="cc-input-group">
                <input type="text" id="cc-name" name="card_name" [(ngModel)]="cc.card_name.value" [ngModelOptions]="{standalone: true}" (keyup)="cc_entered('card_name')" autocomplete="cc-name">
                <span *ngIf="cc.card_name.has_error" class="validation">Please enter Name on card</span>
            </div>
        </label>
        <label>
            <span>Card number</span>
            <div class="cc-input-group">
                <input type="number" name="card_number" id="cc-number" placeholder="{{existingCard ? existingCard['card']['masked_number'] : ''}}" [(ngModel)]="cc.card_number.value" [ngModelOptions]="{standalone: true}" (keyup)="cc_entered('card_number')" autocomplete="cc-number">
                <span *ngIf="cc.card_number.has_error" class="validation">Please enter Card Number</span>
            </div>
        </label>
        <label>
            <span>Card Expiration</span>
            <div class="cc-input-group">
                <input class="card_expiration" type="text" name="card_exp" id="cc-expiration" [(ngModel)]="cc.card_exp.value" [ngModelOptions]="{standalone: true}" (keyup)="cc_entered('card_exp', $event)"  autocomplete="cc-exp">
                <span *ngIf="cc.card_exp.has_error" class="validation">Please enter Expiry date. It can be MM/YY or MM/YYYY</span>
            </div>
        </label>
        <label>
            <span>CVV</span>
            <div class="cc-input-group">
                <input type="text" name="cvv" id="cc-cvv" [(ngModel)]="cc.cvv.value" [ngModelOptions]="{standalone: true}" (keyup)="cc_entered('cvv')" autocomplete="cc-csc">
                <span *ngIf="cc.cvv.has_error" class="validation">Please enter valid CVV</span>
            </div>
        </label>
    </form>
    <shipping-address *ngIf="showShipping" [shippingData]="existingCard" (shippingResponse)="addShippingData($event)" [templateType]="templateType"></shipping-address>
    <div class="update_form__btns update_btns d-flex justify-content-between">
        <button class="btn_grey" (click)="resetCC()">Cancel</button>
        <button class="{{ cc.valid ? 'btn_orange' : 'btn_grey' }}" [disabled]="!cc.valid" (click)="newCardResponse(existingCard)">{{existingCard ? 'Update' : 'Create'}}</button>
    </div>
</div>
<div *ngIf="templateType === 'payment'">
    <div class="">
        <div class="saved_info__form">
            <div>
                <input type="text" name="card_name" placeholder="Card holder’s name" [(ngModel)]="cc.card_name.value" [ngModelOptions]="{standalone: true}" (keyup)="cc_entered('card_name')" autocomplete="cc-name">
                <span *ngIf="cc.card_name.has_error" class="validation">Please enter Name on card</span>
            </div>
            <div>
                <input type="number" name="card_number" placeholder="Card Number" [(ngModel)]="cc.card_number.value" [ngModelOptions]="{standalone: true}" (keyup)="cc_entered('card_number')" autocomplete="cc-number">
                <span *ngIf="cc.card_number.has_error" class="validation">Please enter Card Number</span>
            </div>
            <div>
                <input type="text" placeholder="MM/YY" name="card_exp" [(ngModel)]="cc.card_exp.value" [ngModelOptions]="{standalone: true}" (keyup)="cc_entered('card_exp', $event)" autocomplete="cc-exp">
                <span *ngIf="cc.card_exp.has_error" class="validation">Please enter Expiry date. It can be MM/YY or MM/YYYY</span>
            </div>
            <div>
                <input type="text" name="cvv" placeholder="CVV" [(ngModel)]="cc.cvv.value" [ngModelOptions]="{standalone: true}" (keyup)="cc_entered('cvv')" autocomplete="cc-csc">
                <span *ngIf="cc.cvv.has_error" class="validation">Please enter valid CVV</span>
            </div>
        </div>
        <shipping-address *ngIf="showShipping" (shippingResponse)="addShippingData($event)" [templateType]="templateType"></shipping-address>
        <div class="saved_info__btn">
            <button class="{{ !inProcess && cc.valid ? 'btn_orange' : 'btn_grey' }}" (click)="newCardResponse()">Make Payment</button>
        </div>
    </div>
</div>