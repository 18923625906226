<input type="text" 
    class="input-field"
    placeholder="What do you use proxies for?"
    matInput
    [formControl]="myControl"
    [matAutocomplete]="auto" 
    style="padding: 8px; background: #FFF; border: 1px solid #e1e8ee; " />
<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="usageDisplayfn">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
    {{option.text}}
    </mat-option>
</mat-autocomplete>