import { Component, OnInit } from '@angular/core';
import { LmpNotificationsService } from '../../services/lmp-notifications.service'; 
import { LimeProxiesService } from '../../services/limeproxies.service'; 

@Component({
  selector: 'app-lmp-notifications',
  templateUrl: '../../templates/lmp-notifications/lmp-notifications.component.html',
  styleUrls: ['../../templates/lmp-notifications/lmp-notifications.component.css']
})
export class LmpNotificationsComponent implements OnInit {

	public notifications: any = [];
	public user: any = [];

	constructor(public lmpNotificationsService: LmpNotificationsService, public limeProxiesService: LimeProxiesService) { }

	ngOnInit() {
		this.limeProxiesService.getUserProfile().subscribe(user => {
			this.user = user;
			this.getUserNotifications();
		});

	}
	
	getUserNotifications() {
		let curObject = this;

		
		this.lmpNotificationsService.getUserNotifications().subscribe(notifications => {
			curObject.notifications = notifications;
		});		
	}
	
	setUserNotifications(notification, type) {		
		if ('sms' == type && this.user.sms_verified != false) {
			notification.sms = !notification.sms;
		} else if ('sms' == type) {
			this.limeProxiesService.notifyBarNew('Verify mobile number to update sms notifications.', 'error');
		}
		
		if ('email' == type && this.user.email_verified != false) {
			notification.email = !notification.email;
		} else if ('email' == type) {
			this.limeProxiesService.notifyBarNew('Verify email to update email notifications.', 'error');
		}
	}
	
	updateNotifications() {
		
		let curObject = this;
	
		this.lmpNotificationsService.setUserNotifications(this.notifications).subscribe(notifications => {
			curObject.notifications = notifications;
			curObject.limeProxiesService.notifyBarNew('Notifications configurations has been updated successfully.', 'success');
		});	
	}
}
