import {Component, HostBinding, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {LimeProxiesService} from '../../services/limeproxies.service';
declare var analytics: any;


@Component({
    selector: 'thank-you-page',
    styleUrls: [

        '../../styles/bootstrap/css/bootstrap.min.css',
        '../../styles/login/thank-you.css'
    ],
    templateUrl: '../../templates/login/thank-you-page.template.html'
})

export class ThankYouPageComponent implements OnInit, AfterViewInit {

    @HostBinding('class') myClass = 'page__content-inner';
    @ViewChild('newPasswordField') newPasswordField: any;

    constructor(private router: Router,
                private limeProxiesService: LimeProxiesService) {
    }

    ngOnInit() {

        let beamer = document.createElement('script');
        beamer.innerHTML = "var beamer_config = {\n" +
            "            product_id : 'fyDJncYi4758', //DO NOT CHANGE: This is your product code on Beamer\n" +
            "            selector : '#beamerOverlay', /*Optional: Id, class (or list of both) of the HTML element to use as a button*/\n" +
            "            display : 'left', /*Optional: Choose how to display the Beamer panel in your site*/\n" +
            "        };";
        document.body.appendChild(beamer);

        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
            analytics.track('trial thank you', '');
        }
    }

    ngAfterViewInit() {
        this.newPasswordField.nativeElement.focus();
    }
}