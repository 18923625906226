import {Component, OnInit, Output, Input, EventEmitter, AfterViewInit} from '@angular/core';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyValidators } from '../../custom-validators/custom.validator';
import * as tel_input from 'intl-tel-input';
declare var analytics: any;

@Component({
    selector: '.profile-main',
    templateUrl: '../../templates/profile/profile-main.template.html'
})

export class ProfileMainComponent implements OnInit, AfterViewInit {

    @Input() profile;
    public userProfile;
    public selected_country;
    public selected_phone_code: string;
    public newPassword: string;
    public confirmPassword: string;
    public hasPhone: boolean;
    public canSendSmS = true;
    public phone_verification_code: string;
    public phone_verification_code_label = 'Validate Your Phone Number';
    public valid_phone = false;
    public phone_valid_code = {
        value: '',
        has_error: false,
        touched: false
    };
    public iti: any;

    hide_password_message = false;

    profileForm: FormGroup;
    passwordForm: FormGroup;
    emailForm: FormGroup;

    public successMessage;
    public errorMessage;
    public phone_cc: string;

    private countries = [];

    @Output() closeEvent = new EventEmitter<string>();

    constructor(public limeProxiesService: LimeProxiesService, fb: FormBuilder) {

        this.userProfile = {};

        this.profileForm = fb.group({
            'firstName': ['', Validators.compose([Validators.maxLength(50), Validators.required])],
            'lastName': ['', Validators.compose([Validators.maxLength(50), Validators.required])],
            'address': ['', Validators.maxLength(250)],
            'city': ['', Validators.maxLength(50)],
            'companyName': ['', Validators.maxLength(50)],
            'companyNumber': ['', Validators.maxLength(50)],
            'phone': ['', []],
            'phone_cc': ['', []],
            'zipCode': [''],
        });
        this.profileForm.controls['phone_cc'].disable();

        this.passwordForm = fb.group({
            'newPassword': ['', Validators.compose([Validators.minLength(8), Validators.maxLength(50), Validators.required])],
            'confirmPassword': ['', Validators.compose([Validators.minLength(8), Validators.maxLength(50), Validators.required])]
        }, {validator: MyValidators.MatchPassword});
        this.emailForm = fb.group({
            'email': ['', Validators.compose([Validators.required, MyValidators.validateEmail()])]
        });
    }


    ngOnInit() {
        this.newPassword = '';
        this.confirmPassword = '';
        this.getProfileData();
    }

    getProfileData() {
        if (this.profile) {
            this.limeProxiesService.codeVerificationInputActive = !this.profile.verified && this.profile.phone_code;
            this.userProfile = this.profile;
            this.emailForm.get('email').patchValue(this.profile.email);
            this.phone_cc = this.limeProxiesService.getPhoneCC(this.profile.country);
            this.userProfile.phone = this.userProfile.phone.replace(this.phone_cc, '');
            this.selected_country = this.profile.country;
            this.hasPhone = this.userProfile === '';
            if (this.iti) {
                this.iti.setNumber((this.userProfile.phone.indexOf(this.phone_cc) !== -1) ? this.userProfile.phone : this.phone_cc + '' + this.userProfile.phone);
                this.valid_phone = true;
            }
        } else {
            setTimeout(() => {
                this.getProfileData();
            }, 100);
        }
    }

    ngAfterViewInit() {
        this.init_tel_input();
        let popup__dismiss = document.querySelectorAll('.popup__dismiss');
        if (popup__dismiss) {
            for (let i = 0; i < popup__dismiss.length; i++) {
                popup__dismiss[i].addEventListener('click', (ev) => {
                    let parent = popup__dismiss[i].parentElement;
                    if (parent) {
                        if (parent.classList.contains('popup')) {
                            parent.classList.remove('popup--show');
                        }
                    }
                });
            }
        }
    }

    verify_otp() {

    }

    opt_entered(event: any) {
        this.phone_valid_code.touched = true;
    }

    init_tel_input() {
        const tel_input_selector = document.querySelector('.tel-input');
        if (tel_input_selector) {
            if (this.iti) {
                this.iti.destroy();
            }
            this.iti = tel_input(tel_input_selector,
                {
                    preferredCountries: ['us', 'gb'],
                    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js'
                });
            const __this = this;
            tel_input_selector.addEventListener('countrychange', () => {
                __this.phone_entered(null);
            });
        } else {
            const __this = this;
            setTimeout(() => {
                __this.init_tel_input();
            }, 100);
        }
    }

    phone_entered(event: any) {
        this.phone_cc = '+' + this.iti.getSelectedCountryData().dialCode.toString();
        this.selected_country = this.iti.getSelectedCountryData().name.split(' (')[0];
        this.userProfile.country = this.iti.getSelectedCountryData().name.split(' (')[0];
        this.userProfile.phone = this.iti.getNumber();
        this.valid_phone = this.iti.isValidNumber();
    }

    // sendSMSCode() {
    //     this.canSendSmS = false;
    //     this.limeProxiesService.sendSMSCode()
    //         .subscribe(
    //             success => this.limeProxiesService.showMessage(success),
    //             error => this.limeProxiesService.showMessage(error, 'error')
    //         );
    // }

    selectCountry(event) {
        const country = event.target.value;
        const old_cc = this.phone_cc;
        this.phone_cc = event.target.selectedOptions[0].attributes.code.value;
        this.selected_country = country.split(': ')[1];
        this.userProfile.country = country.split(': ')[1];
        this.userProfile.phone = this.iti.getNumber().replace(old_cc, this.phone_cc);
        this.iti.setNumber((this.userProfile.phone.indexOf(this.phone_cc) !== -1) ? this.userProfile.phone : this.phone_cc + '' + this.userProfile.phone);
        this.valid_phone = this.iti.isValidNumber();
    }
    private checkPhone(profile) {
        if (profile.phone) {
            this.profileForm.controls['phone'].disable();
        } else {
            this.profileForm.controls['phone'].enable();
        }
    }

    updateUserProfile() {
        const clone = Object.assign({}, this.userProfile);
        if (clone.hasOwnProperty('email')) {
            delete clone.email;
        }
        if (clone.phone.indexOf(this.phone_cc) === -1) {
            clone.phone = this.phone_cc + clone.phone;
        }
        delete clone.phone_code;
        delete clone.uid;
        delete clone.verified;
        this.limeProxiesService.updateUserProfile(clone)
            .subscribe(
                profile => {
                    this.limeProxiesService.codeVerificationInputActive = !profile.verified && profile.phone_code;
                    if (this.limeProxiesService.codeVerificationInputActive) {
                        document.querySelector('.phone-verification-modal').classList.add('popup--show');
                    }
                    this.userProfile = profile;
                    this.emailForm.get('email').patchValue(profile.email);
                    this.selected_country = profile.country;
                    this.phone_cc = this.limeProxiesService.getPhoneCC(profile.country);
                    this.userProfile.phone = this.userProfile.phone.replace(this.phone_cc, '');
                    this.canSendSmS = !profile.verified;
                    this.hasPhone = this.userProfile == '';
                    this.limeProxiesService.showMessage(profile);
                    if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
                        analytics.track('account_update', {});
                    }
                },
                error => {
                    this.limeProxiesService.showMessage(error, 'error');
                }
            );
    }

    hide_pass_message() {
        this.hide_password_message = !this.hide_password_message;
        this.closeEvent.emit(this.hide_password_message ? '1' : '0');
    }
}
