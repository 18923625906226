<div class="notify-top-popup">
</div>
<svg class="logo">
    <use xlink:href="#logo"></use>
</svg>
<div class="block">
    <h4 class="page-title">Recover account access</h4>
    <b style="color:green">{{successMessage}}</b>
    <b style="color:red">{{errorMessage}}</b><br />
    <b style="color:gray">{{redirectMessage}}</b>
    <div class="form-wrapper">
        <form [formGroup]="forgotPasswordForm">
            <div class="input-wrapper">
                <div *ngIf="forgotPasswordForm.controls['email'].hasError('validateEmail') && forgotPasswordForm.controls['email'].touched" class="alert alert-danger">Invalid email address</div>
                <input type="email" class="input-field" name="email" required placeholder="Email address" [(ngModel)]="email" [formControl]="forgotPasswordForm.controls['email']">
            </div>
            <div class="input-wrapper">
                <button type="submit" class="btn btn--full" (click)="resetMyPassword()" [disabled]="!forgotPasswordForm.valid" [ngClass]="{'btn--gray': !forgotPasswordForm.valid}">RECOVER ACCESS</button>
            </div>
        </form>
    </div>
    <a routerLink="/login" class="forgot-password">Login</a>
</div>
<a [routerLink]="['/login/signup']" class="btn btn--green btn--shadow btn-cta">Create your account</a>