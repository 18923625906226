import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule, HttpClientJsonpModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {LoginComponent} from './login.component';
import {LoginOrRegisterComponent} from './login-or-register.component';
import {LoginRegisterMenuComponent} from './login-register-menu.component';
import {AccountCreateComponent} from './account-create.component';
import {ForgotPasswordComponent} from './forgot-password.component';
import {TokenValidationComponent} from './token-validation.component';
import {PasswordRecoverComponent} from './password-recover.component';
import {RouterModule} from '@angular/router';
import {TokenRevalidationComponent} from './token-revalidation.component';
import {ThankYouPageComponent} from './thank-you-page.component';
import {SetPasswordComponent} from './set-password.component';
import {LoginWizardComponent} from './login-wizard.component';
import {AfterLogoutComponent} from "./after-logout.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        RouterModule
    ],
    declarations: [
        LoginComponent,
        LoginOrRegisterComponent,
        LoginRegisterMenuComponent,
        AccountCreateComponent,
        ForgotPasswordComponent,
        TokenRevalidationComponent,
        TokenValidationComponent,
        PasswordRecoverComponent,
        ThankYouPageComponent,
        SetPasswordComponent,
        LoginWizardComponent,
        AfterLogoutComponent
    ],
    exports: [LoginComponent]
})
export class LoginModule {

}
