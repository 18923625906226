<form>
  <mat-chip-list
    #chipList
    aria-label="Tags"
    multiple
    [formControl]="formControl"
  >
    <mat-chip
      *ngFor="let keyword of keywords"
      [selected]="keyword"
      [value]="keyword"
      (removed)="removeKeyword(keyword)"
    >
      {{ keyword }}
    </mat-chip>
    <input
      placeholder="Tags"
      [matChipInputFor]="chipList"
      (matChipInputTokenEnd)="addKeywordFromInput($event)"
    />
  </mat-chip-list>
</form>
