import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { LimeProxiesService } from 'src/app/services/limeproxies.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {
  @Input() pid: any;

  keywords = new Set([]);
  formControl = new FormControl([]);
  tags = [];

  constructor(private limeProxiesService: LimeProxiesService) { }

  ngOnInit(): void {
    this.limeProxiesService.getProductTags(this.pid).subscribe(tags => {
      tags.forEach(tag => {
        this.keywords.add(tag.tag);
        this.tags.push(tag.tag);
      });
    });
  }

  addKeywordFromInput(event: MatChipInputEvent) {
    if (event.value) {
      this.keywords.add(event.value);
      this.tags.push(event.value);
    }

    this.setProductTags(this.tags);
  }

  removeKeyword(keyword: string) {
    this.keywords.delete(keyword);
    this.tags = this.tags.filter(tag => tag !== keyword);
    this.setProductTags(this.tags);
  }

  setProductTags = (tags) => {
    this.limeProxiesService.setProductTags(this.pid, tags).subscribe(res => {
      // Tag added
    });
  }
}
