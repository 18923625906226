import { Component, HostBinding, OnInit } from '@angular/core';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from '../../entities/message';
import { switchMap } from 'rxjs/operators';
declare var analytics: any;


@Component({
    selector: 'token-validation',
    templateUrl: '../../templates/login/token-validation.template.html'
})
export class TokenValidationComponent implements OnInit {

    @HostBinding('class') myClass = 'page__content-inner';
    public successMessage: string;
    public errorMessage: string;
    public redirectMessage: string;

    private token: string;
    private subscribtionObject: any;

    constructor(private router: Router, private route: ActivatedRoute, private limeProxiesService: LimeProxiesService) {
        this.subscribtionObject = this.route.params
            .pipe(
                switchMap(params => {
                    this.token = params['token'];
                    return this.limeProxiesService.validateTokenFromEmail(this.token);
                })
            )
            .subscribe(
            (success: any) => {
                if (success.valid) {
                    const time = new Date();
                    time.setTime(time.getTime() + (success.auth_ttl * 60 * 1000));
                    localStorage.setItem('userId', JSON.stringify({
                        userId: success.auth_userid,
                        expire: time.getTime()
                    }));
                    localStorage.setItem('token', JSON.stringify({
                        token: success.auth_token,
                        expire: time.getTime()
                    }));
                    if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
                        analytics.track('signup_email_activated', {});
                    }
                    this.router.navigate(['/login/set-password']);
                } else {
                    this.limeProxiesService.messageToDisplay.next(new Message('Unauthorized', false));
                    // this.router.navigate(['/login']);
                    this.limeProxiesService.logout();
                }
            },
            error => {
                console.log(error);
            },
        );
    }

    ngOnInit() {
        // this.router.navigate(['/profile']);
    }

    redirectUserToPage(router, page) {
        setTimeout(() => {
            router.navigate([page]);
        }, 3000);
    }
}
