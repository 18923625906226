import { Component, OnInit, Input } from '@angular/core';
import { LimeProxiesService } from 'src/app/services/limeproxies.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-websites',
  templateUrl: './websites.component.html',
  styleUrls: ['./websites.component.css']
})
export class WebsitesComponent implements OnInit {
  @Input() pid: any;
  @Input() websitesList: any

  otherWebsite = new FormControl('');

  websites = new FormControl('');

  constructor(private limeProxiesService: LimeProxiesService) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(){
    this.getWebsites();
    this.getOtherWebsite();
  }
  
  selectedItems = [];

  dropdownSettings:IDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false
  };

  onItemSelect(item: any) {
    this.setWebsites(this.selectedItems);
  }
  onSelectAll(items: any) {
    this.setWebsites(items);
  }

  onItemDeSelect(item: any) {
    this.setWebsites(this.selectedItems);
  }
  onDeSelectAll(items: any) {
    this.setWebsites(items);
  }

  getWebsites = () => {
    this.limeProxiesService.getProductWebsites(this.pid).subscribe(websites => {
      websites.forEach(website => {
        if (website.wid > 0) {
          this.selectedItems.push({ item_id: website.wid, item_text: website.name });
        }
      });

      this.websites.setValue(this.selectedItems);
    });
  }

  setWebsites = (items: any) => {
    let websiteIds = [];

    items.forEach(item => {
      websiteIds.push(item.item_id);
    });
  
    this.limeProxiesService.setProductWebsites(this.pid, websiteIds).subscribe(res => {
      
    })
  }

  getOtherWebsite = () => {
    this.limeProxiesService.getOtherWebsite(this.pid).subscribe(res => {
      if (res.length > 0 && res[0].name != undefined) {
        this.otherWebsite.setValue(res[0].name);
      }
    })
  }

  setOtherWebsite = () => {
    this.limeProxiesService.setOtherWebsite(this.pid, this.otherWebsite.value).subscribe(res => {
      // Notes saved
    })
  }
}
