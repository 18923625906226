import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import {LimeProxiesService} from '../../services/limeproxies.service';
declare const $: any;

@Component({
    selector: '.profile-new',
    templateUrl: '../../templates/new-billing/new-billing.template.html'
})

export class NewBillingComponent implements OnInit, AfterViewInit {

    @ViewChild('payPal_form') payPal_form;
    @ViewChild('altCurrencies_form') altCurrencies_form;
    @ViewChild('fastspring_form') fastspring_form;

    public logos: any = {
        visa : '.svg',
        mastercard: '.png',
        american_express: '.png',
        discover: '.svg',
        jcb: '.png',
        diners_club: '.png',
    };
    private onRequest: any = false;
    public onLoadTab = 'payment';
    public tabs = ['payment', 'subscriptions', 'update', 'history'];
    public products;
    public customAmount: any;
    public history = [];
    public historyFull = false;
    public historyEmpty = false;
    public user: any;
    public is_submit = false;
    public nowProdTime: any;
    public billAmount: any = 0;
    public origin: any = window.location['origin'];
    public cards: any = [];
    public _terminate = {
        show: false,
        id: null,
        reason: '',
        date : null
    };
    public selectedCardObject: any;

    public cc = {
        card_name : {
            value: '',
            has_error: false,
            test: /[a-zA-Z_ ]{2,}/,
            touched: false
        },
        card_number : {
            value: '',
            has_error: false,
            test: /^((67\d{2})|(4\d{3})|(5[1-5]\d{2})|(6011))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7]\d{13}$/,
            touched: false
        },
        card_exp : {
            value: '',
            has_error: false,
            test: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
            touched: false
        },
        cvv : {
            value: '',
            has_error: false,
            test: /^([0-9]{3,4})$/,
            touched: false
        },
        valid : false
    };

    public coast: any = [
        {
            label: '$10',
            amount: 10
        },
        {
            label: '$25',
            amount: 25
        },
        {
            label: '$50',
            amount: 50
        },
        {
            label: '$100',
            amount: 100
        },
        {
            label: '$250',
            amount: 250
        },
        {
            label: 'Other',
            amount: null
        }
    ];

	public configuration: any = [];

    constructor(public limeProxiesService: LimeProxiesService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private location: Location) {
        if (!this.limeProxiesService.environment['show_billing']) {
            this.router.navigate(['/']);
        }
    }

    ngAfterViewInit() {
    }

	public currentActiveCard = 'saved';

    setActiveCards(event: any, active: any, toHide: any) {
        let target = document.querySelector('.' + active + '_cards__title');
		
		this.currentActiveCard = active;
		
        if (target) {
            $(target).toggleClass('active');
            $('.' + toHide + '_cards__title').removeClass('active');
            $('.' + toHide + '_cards').find('.saved_info').slideUp();
            $(target).parent('.' + active + '_cards').find('.saved_info').slideToggle();
            $('.saved_info__details').removeClass('active');
        }
    }

    getBillingHistory() {
        this.limeProxiesService.getbillingHistory().subscribe(data => {
            this.history = data as Array<any>;
            if (this.history && this.history.length > 0) {
                this.historyFull = true;
            } else {
                this.historyEmpty = true;
            }
        });
    }

    getCards() {
        this.limeProxiesService.showApiLoader(true);
        this.limeProxiesService.charge_get_card().subscribe(
            success => {
                this.limeProxiesService.showApiLoader(false);
                if (success['_user_message_type'] === 'success') {
                    if (typeof success['card'] !== 'undefined' && typeof success['card'] === 'string') {
                        let parsed = JSON.parse(success['card']);
                        if (parsed) {
                            this.cards = [];
                            for (let source of parsed) {
                                if (source['payment_source']) {
                                    if ((source['payment_source']['type'] === 'card') && (source['payment_source']['status'] === 'valid') && !source['payment_source']['deleted']) {
                                        this.cards.push(source);
                                    }
                                }
                            }
                        }
                    }
                }
            }, error => {
                this.limeProxiesService.showApiLoader(false);
                this.limeProxiesService.showMessage(error);
            }
        );
    }

    setCoast(ev: any, amount: any) {
        $('.add_amount a').removeClass('active');
        $(ev.target).toggleClass('active');
        this.billAmount = amount;
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe( params => {
            if (!params['slug']) {
                this.onLoadTab = 'payment';
                this.location.replaceState('new-billing/' + this.onLoadTab);
            } else {
                if (this.tabs.indexOf(params['slug']) !== -1) {
                    this.onLoadTab = params['slug'];
                } else {
                    this.onLoadTab = 'payment';
                    this.location.replaceState('new-billing/' + this.onLoadTab);
                }
            }
        });
        this.limeProxiesService.getUserProfile().subscribe(success => {
                this.user = success;
                this.user.phone = success.phone;
            },
            error => {}
        );
		
		this.configuration = this.limeProxiesService.getThirdPartyConfigurations();
		
        this.nowProdTime = Math.floor( Date.now() / 1000 );
        this.getBillingHistory();
        this.getCards();
        this.getProducts();
    }

    changeTab(tab: any) {
		this.onLoadTab = tab;
        this.location.replaceState('new-billing/' + tab);
    }

    doPayment(id) {
        if (typeof this.user.uid !== 'undefined') {
            console.log(this[id]);
            if (this.billAmount) {
                console.log(this[id]);
                if (!this.is_submit) {
                    this.is_submit = true;

                    console.log(this[id]);

                    if (this[id]) {
                        this[id].nativeElement.submit();
                    }
                    this.is_submit = false;
                    this.limeProxiesService.completedOrder(
                        this.user.first_name,
                        this.user.last_name,
                        this.user.email,
                        this.billAmount ? this.billAmount : 0
                    );
                }
            } else {
                this.limeProxiesService.notifyBarNew('Please choose amount.', 'error');
            }

        } else {
            this.router.navigate(['#login']);
        }
    }

    cc_entered(key: string) {
        const obj = this.cc[key];
        this.cc[key].touched = true;
        this.cc[key].has_error = !obj.test.test(obj.value);
        const keys = Object.keys(this.cc);
        let valid_c = 0;
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] !== 'valid') {
                if (!this.cc[keys[i]].has_error && this.cc[keys[i]].touched) {
                    valid_c++;
                }
            }
        }
        this.cc.valid = (valid_c === 4);
    }

    handleCardResponse(ev: any) {
        if (typeof ev.type !== 'undefined') {
            if (ev.existingCard !== null) {
                this.updateExistingCard(ev);
            } else {
                this.addNewCard(ev);
            }
        }
    }

    updateExistingCard(ev: any) {
        if (!this.onRequest) {
            this.onRequest = true;
            this.limeProxiesService.showApiLoader(true);
            const data = {
                paymentSourceId: ev.existingCard['id'],
                card: ev.card,
                update: true
            };
            this.limeProxiesService.charge_payment_source(data).subscribe(
                success => {
                    this.onRequest = false;
                    this.limeProxiesService.showApiLoader(false);
                    this.getCards();
                    this.limeProxiesService.showMessage(success);
                }, error => {
					console.log('New Billing');
                    this.onRequest = false;
                    this.limeProxiesService.showApiLoader(false);
                    this.limeProxiesService.showMessage(error);
                }
            );
        }
    }

    addNewCard(ev: any) {
        if (!this.onRequest) {
            this.onRequest = true;
            this.limeProxiesService.showApiLoader(true);
            this.limeProxiesService.charge_payment_source(ev.card).subscribe(
                success => {
                    this.onRequest = false;
                    this.limeProxiesService.showApiLoader(false);
                    this.getCards();
                    if (ev.type === 'payment') {
                        if (success['_user_message_type'] === 'warning' || success['_user_message_type'] === 'error') {
                            this.limeProxiesService.showMessage(success);
                        } else {
                            this.makePayment(true, success['paymentSourceId']);
                        }
                    } else {
                        this.limeProxiesService.showMessage(success);
                    }
                }, error => {
					console.log('New Billing 301');
                    this.onRequest = false;
                    this.limeProxiesService.showApiLoader(false);
                    this.limeProxiesService.showMessage(error.error);
                }
            );
        }
    }
	
	successfullyCharged() {
		this.changeTab('subscriptions');
		this.getProducts();
	}

    getProducts() {
        this.limeProxiesService.getAllProducts().subscribe(
            data => {
                this.products = data;
                if (data.length === 0) {
                    // this.noProducts = true;
                }
            },
            error => {
                this.limeProxiesService.showMessage(error);
            }
        );
    }

    makePayment(withNewCard: any = false, paymentSourceId: any = null) {

        let radio = document.querySelector('[name="charge_payment_source"]:checked');

        if (this.billAmount) {
            if (radio || withNewCard) {
                let payData = {
                    amount: this.billAmount,
                    description: this.billAmount + '$ payment',
                    paymentSourceId: paymentSourceId ? paymentSourceId : radio.getAttribute('psid')
                };

                if (!this.onRequest || withNewCard) {
                    this.onRequest = true;
                    this.limeProxiesService.showApiLoader(true);

                    this.limeProxiesService.charge_make_payment(payData).subscribe(
                        success => {
                            this.onRequest = false;
                            this.limeProxiesService.showApiLoader(false);
                            this.limeProxiesService.showMessage(success);
							
							this.successfullyCharged();
                        }, error => {
                            this.onRequest = false;
                            this.limeProxiesService.showApiLoader(false);
                            this.limeProxiesService.showMessage(error);
                        }
                    );
                }
            } else {
                this.limeProxiesService.notifyBarNew('Please choose card.', 'error');
            }
        } else {
            this.limeProxiesService.notifyBarNew('Please choose amount.', 'error');
        }
    }

    showCancelSubscription(id: number) {
        this._terminate.id = id;
        this._terminate.show = true;
    }

    cancelSubscription() {
        this.limeProxiesService.setRenewal({state: false, id: this._terminate.id}).subscribe(
            success => {
                if (success['_user_message_type'] === 'success') {
                    this.closeTerminate();
                    this.getProducts();
                }
                this.limeProxiesService.showMessage(success);
            }, error => {
                this.limeProxiesService.showMessage(error);
            }
        );
    }

    closeTerminate() {
        this._terminate.show = false;
        this._terminate.reason = '';
    }

    deletePaymentSource(ps: any) {
        if (!this.onRequest) {
            this.onRequest = true;
            this.limeProxiesService.showApiLoader(true);
            this.limeProxiesService.delete_payment_source(ps['id']).subscribe(
                success => {
                    this.onRequest = false;
                    this.limeProxiesService.showApiLoader(false);
                    this.getCards();
                    this.limeProxiesService.showMessage(success);
                }, error => {
                    this.onRequest = false;
                    this.limeProxiesService.showApiLoader(false);
                    this.limeProxiesService.showMessage(error);
                }
            );
        }
    }

    editPaymentSource(ps: any) {
        this.selectedCardObject = ps;
    }

    addNewRestore() {
        this.selectedCardObject = null;
    }
	
	downloadInvoice(item) {
		if (item.reference) {
			this.limeProxiesService.downloadInvoice(item.reference).subscribe(
				response => {
                    if (response.url !== null) {
                        window.open(response.url, '_blank');
                    } else {
                        this.limeProxiesService.notifyBarNew('Receipt is not available, kindly contact at <a href="mailto:support@Limeproxies.com">support@Limeproxies.com</a>', 'error');
                    }
				}, error => {
                    this.limeProxiesService.notifyBarNew(error, 'error');
                }
			);
		}
	}
}
