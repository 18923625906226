import { Component, HostBinding } from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';

@Component({
    selector: 'billing',
    templateUrl: '../../templates/billing/billing.template.html'
})

export class BillingComponent {

    @HostBinding('class') myClass = 'page__content-inner';

    constructor(public limeProxiesService: LimeProxiesService) {
        limeProxiesService.setTitle('Billing');
    }
}