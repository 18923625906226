import { Injectable } from '@angular/core';
@Injectable()
export class HelpersService {
  constructor() {}
  dateFormatChange(date) {
      let mm = date.getMonth() + 1; // getMonth() is zero-based
      let dd = date.getDate();

      return [date.getFullYear() + '-' +
      (mm > 9 ? '' : '0') + mm + '-' +
      (dd > 9 ? '' : '0') + dd
      ].join('');
  }
}
