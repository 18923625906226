import {Component, OnInit} from '@angular/core';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare const $: any;

@Component({
    selector: 'invoice-main',
    templateUrl: '../../templates/invoice/invoice.template.html'
})

export class InvoiceComponent implements OnInit {

    invoiceData: any;
    user: any;

    constructor(private limeProxiesService: LimeProxiesService, private router: Router) {
    }

    ngOnInit() {
        if (this.limeProxiesService.getCachedInvoice()) {
            this.invoiceData = this.limeProxiesService.getCachedInvoice();
        } else {
            this.router.navigate(['/billing/', 'history']);
        }
        this.limeProxiesService.setTitle('Receipt');
        this.limeProxiesService.getUserProfile()
            .subscribe(
                profile => {
                  this.user = profile;
                }
            );
    }

    printInvoice() {
        $('.btn-invoice-print').hide();
        window.print();
        $('.btn-invoice-print').show();
    }

    formatDate(date: any) {
        return moment(date).format('LL');
    }

    getInvoiceId(date: any) {
        return moment(date).unix();
    }
}
