import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

declare const Env: any;

if (environment.production) {
  enableProdMode();
}

if (Env.third_parties.Sentry !== undefined && Env.third_parties.Sentry.dsn !== undefined ) {
  console.log('Sentry configurations', Env.third_parties.Sentry);
  Sentry.init({
    dsn: Env.third_parties.Sentry.dsn,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      // new Integrations.BrowserTracing({
      //   tracingOrigins: ["localhost", "https://yourserver.io/api"],
      //   routingInstrumentation: Sentry.routingInstrumentation,
  
      // }),
    ],
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));
