<main class="page__content invoice__content" role="main">
    <div class="invoice-table">
        <div class="info-block">
            <div class="company-info">
                <div class="invoice-logo-header">
                    <img src="assets/images/limeproxies-logo-grey.png">
                </div>
                <div class="company-address">
                    <p>340 S lemon ave # 9836, Walnut</p>
                    <p>California 91789</p>
                    <p>USA</p>
                </div>
            </div>
            <div class="invoice-date-id">
                <p>Date Issued: {{formatDate(invoiceData?.date)}}</p>
                <p>Invoice Id: {{getInvoiceId(invoiceData?.date)}}</p>
            </div>
        </div>
        <div class="table-container">
            <table class="table-responsive">
                <tr>
                    <th>Description</th>
                    <th>USD</th>
                </tr>
                <tr>
                    <td>{{ invoiceData?.transaction }}</td>
                    <td [innerHtml]="(invoiceData?.value < 0 ? '&#36;' + (invoiceData?.value * -1).toFixed(2) : '&#36;' + invoiceData?.value.toFixed(2))"></td>
                </tr>
            </table>
            <div *ngIf="user">
                <p class="text-right" [innerHtml]="(invoiceData?.value < 0 ? 'Total: &#36;' + (invoiceData?.value * -1).toFixed(2) : 'Total: &#36;' + invoiceData?.value.toFixed(2))"></p>
                <p>Bill To: {{user?.first_name + ' ' + user?.last_name + ' ' + ' <' + user?.email + '>'}}</p>
            </div>
            <button class="btn-invoice-print" (click)="printInvoice()">Print Invoice</button>
        </div>
    </div>
</main>