<div class="breadcrumbs">
  <a [routerLink]="['/billing/new']" class="breadcrumbs__item" routerLinkActive="breadcrumbs__item--active">Add</a>
  <span class="separator separator--bullet"></span>
  <a [routerLink]="['/billing/history']" class="breadcrumbs__item"
     routerLinkActive="breadcrumbs__item--active">History</a>
</div>
<div class="block block--with-header page_block_inner">
  <ul class="block__header tab__nav">
    <li #liPayment id="fastspring" class="block__title tab__toggle"
        [ngClass]="{'tab__toggle--active': type == 'fastSpring'}" (click)="choosePaymentMethod('fastSpring')">
      Fast Spring
    </li>
    <li #liPayment id="paypal" class="block__title tab__toggle" [ngClass]="{'tab__toggle--active': type == 'payPal'}"
        (click)="choosePaymentMethod('payPal')">PayPal
    </li>
    <li #liPayment id="bitcoin" class="block__title tab__toggle"
        [ngClass]="{'tab__toggle&#45;&#45;active': type == 'bitCoin'}"
        (click)="choosePaymentMethod('bitCoin')">
      CryptoCurrency
    </li>
  </ul>
  <div class="block__inner">
    <div class="tab__content-wrapper">
      <form [formGroup]="fastSpringForm" class="tab__content-block tab__content-block--active">
        <h4 class="block__title">Make a payment with {{ pay_type_label[type] || 'Fast Spring'}}</h4>
        <p class="block__desc">
          This is a one-time payment that will not recur. Payment may take up to 5 minutes to process. <br>
        </p>
        <div class="toggle-grid">
          <label [ngClass]="{'toggle-grid__item--active': num === fastSpringForm.get('num').value}"
                 *ngFor="let num of [5,10,25,50,100,250]"
                 class="toggle-grid__item toggle-grid__item--small input-field">
            <input type="radio" formControlName="num" class="hide" [value]="num">
            <span>${{num}}</span>
          </label>
          <div (click)="fastSpringForm.get('num').patchValue(null)" class="toggle-grid__item toggle-grid__item--text"
               data-reset-selected>
            <input type="text" maxlength="10" name="ft_credit_value" (keypress)="limeProxiesService._keyPress($event)" (change)="limeProxiesService._onlyInt($event)"
                   formControlName="other_num" class="input-field" placeholder="Other credit value">
          </div>
        </div>
      </form>

      <img src="assets/images/feature_payment-method-and-security_credit-debit-cards_2.png" *ngIf="false">
      <img src="assets/images/feature_payment-method-and-security_credit-debit-cards_1.png" *ngIf="false">

      <div class="payment-logos" *ngIf="type == 'fastSpring'">
        <img src="assets/images/feature_payment-method-and-security_credit-debit-cards_1.png">
      </div>
      <div class="payment-logos" *ngIf="type == 'payPal'">
        <img src="assets/images/payment/paypal.png">
      </div>
      <div class="payment-logos" *ngIf="type == 'bitCoin'">
        <img src="assets/images/payment/bitcoin.png">
        <img src="assets/images/payment/ethereum.png">
        <img src="assets/images/payment/bitcoincash.png">
        <img src="assets/images/payment/litecoin-logo.png">
      </div>
    </div>
  </div>
</div>
<div class="sidebar big-sidebar">
  <add-new-sidebar [type]="type" [productForm]="fastSpringForm"></add-new-sidebar>
</div>
<!--<card-payment [type]="type" [productForm]="fastSpringForm"></card-payment>-->