<div class="logo-wrapper">
    <a [routerLink]="['/home']" class="logo">
        <svg><use xlink:href="#logo" /></svg>
    </a>
    <span class="hamburger">
        <span class="top"></span>
        <span class="middle"></span>
        <span class="bottom"></span>
    </span>
</div>
<div class="page__content-inner header__bar">
    <div class="header--price">
        <a [routerLink]="['/new-billing/payment']" class="logo">
            <span class="header__price">$<span>{{limeProxiesService.userNotifications.credit}}</span></span>
            <span class="header__price-desc">Available Credit</span>
        </a>
    </div>
    <div class="notify-top-popup">
    </div>
    <div class="header__user">
        <div #notification class="header__user-col dropdown notifications-dropdown" [ngClass]="{'dropdown--show': showNotification}">
            <div class="tooltips bottom bell" (click)="this.showNotification = !this.showNotification;">
                <svg class="icon icon-notification icon-notification--unread">
                    <use *ngIf="limeProxiesService.userNotifications.notifications?.length != 0" xlink:href="#notification" />
                    <use *ngIf="limeProxiesService.userNotifications.notifications?.length == 0" xlink:href="#notificationNone" />
                </svg>
                <span class="tooltip">Notifications</span>
            </div>
            <div class="dropdown__inner">
                <ul>
                    <li *ngFor="let notify of limeProxiesService.userNotifications.notifications">
                      <div (click)="openNotification(notify)" class="notifications-box" *ngIf="limeProxiesService.userNotifications.notifications?.length > 0" [ngClass]="{pointer: (!!notify.id || !!notify.link)}" [ngStyle]="getTypeColor(notify.type)" style="color:black;padding:2%;">
                                {{notify.message}}
                            <small>{{getDiff(notify.date)}}</small>
                            <button type="button" class="btn btn--close" (click)="removeNotification(notify, $event)"></button>
                       </div>
                    </li>
                </ul>
                <div *ngIf="limeProxiesService.userNotifications.notifications?.length == 0" style="padding:2%;text-align:center;">
                            No new notifications...
                </div>
            </div>
        </div>
        <!-- <a *ngIf="limeProxiesService.userNotifications['wizard']" (click)="limeProxiesService.showQuizPage = true" class="header__user-col tooltips bottom" style="cursor: pointer">
            <img src="assets/images/wizard.png" style="width: 25px;height: 25px;max-width: none !important">
            <span class="tooltip">Wizard</span>
        </a> -->
        <a data-canny-changelog class="header__user-col tooltips bottom">
            <svg class="icon icon-support"><use xlink:href="#support" /></svg>
            <span class="tooltip">Latest Updates</span>
        </a>
        <span class="separator separator--bullet"></span>
        <div #userDropdown class="dropdown dropdown--right header__user-col user-dropdown" [class.dropdown--show]="showProfile">
            <span class="dropdown-toggle header__name" (click)="showProfileMethod()">
                Welcome, {{limeProxiesService.userNotifications.full_name}}
                <svg class="icon-arrow"><use xlink:href="#arrow" /></svg>
            </span>
            <div class="dropdown__inner">
                <ul>
                    <li><a routerLink="/my-profile" (click)="this.showProfile = false">My Profile</a></li>
                    <li><a routerLink="/my-profile/authentication" (click)="this.showProfile = false">Change Password</a></li>
                    <li><a *ngIf="limeProxiesService.environment.show_notifications" routerLink="/my-profile/notifications" (click)="this.showProfile = false">Notifications</a></li>
                    <li *ngIf="role == 'Admin' || role == 'Owner'"><a routerLink="/referrals" (click)="this.showProfile = false">Referrals</a></li>
                    <li *ngIf="role == 'Admin' || role == 'Owner'"><a routerLink="/new-billing" (click)="this.showProfile = false">Billing</a></li>
                    <!--<li><a routerLink="/account" (click)="this.showProfile = false">Authentication</a></li>-->
                    <li><a routerLink="/api" (click)="this.showProfile = false">API</a></li>
                    <li><a href="http://feedback.limeproxies.com/" target="_blank">Submit Feedback</a></li>
                    <li><a href="http://feedback.limeproxies.com/bug-reports/create" target="_blank">Report a Bug</a></li>
                    <li><a (click)="logOut()">Sign Out</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
