<div class="row">
    <div class="col-6" style="padding-left: 10px; ">
        <form >
            <ng-multiselect-dropdown 
                [formControl]="websites"
                [placeholder]="'What sites do you use proxies on?'"
                [settings]="dropdownSettings"
                [data]="websitesList"
                [(ngModel)]="selectedItems"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)" 
                (onDeSelect)="onItemDeSelect($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
                name="Websites"
                >
            </ng-multiselect-dropdown>
        </form>
    </div>
    <div class="col-6" style="padding-left: 10px; ">
        <input class="input-field" placeholder="Other websites" type="text" style="padding: 8px;" [formControl]="otherWebsite" (focusout)="setOtherWebsite()"  />
    </div>
</div>