<div class="notify-top-popup"></div>
<div class="new_signup height-100">
    <div class="main_wrapper">
        <section class="main_block">
            <div class="container">
                <div class="main_block__wrapper main_block_wrap">
                    <div class="main_block_wrap__title">
                        <h1 class="title">Sign in to Limeproxies</h1>
                    </div>
                    <div class="lg-cont" style="min-height:170px">
                        <svg class="logo">
                            <use xlink:href="#logo"></use>
                        </svg>
                    </div>
                    <div class="main_block_wrap__item main_block_item">
                        <div class="main_block_item__title">
                            <h2 class="title">Using your Google account</h2>
                        </div>
                        <div class="main_block_item__link">
                            <button class="g-signin"><img src="assets/images/new_signup/icon_google.png" alt="Sign up"></button>
                        </div>
                    </div>
                    <div class="main_block_wrap__line">
                        <span>Or</span>
                    </div>
                    <div class="main_block_wrap__item main_block_item">
                        <div class="main_block_item__title">
                            <h2 class="title">Using your work email</h2>
                        </div>
                        <div class="main_block_item__form">
                            <form [formGroup]="loginOrRegisterForm" (ngSubmit)="login()">
                                <label>
                                    Email address *
                                    <input name="username"
                                           [formControl]="loginOrRegisterForm.controls['username']" type="text">
                                    <span *ngIf="loginOrRegisterForm.controls['username'].hasError('required') && loginOrRegisterForm.controls['username'].touched" class="validation">Email Address is required</span>
                                    <span *ngIf="!loginOrRegisterForm.controls['username'].hasError('required') && loginOrRegisterForm.controls['username'].hasError('validateEmail') && loginOrRegisterForm.controls['username'].touched && loginOrRegisterForm.controls['username'].dirty" class="validation">Please enter a valid email address.</span>
                                </label>
                                <label>
                                    Password *
                                    <input type="password"
                                           [formControl]="loginOrRegisterForm.controls['password']">
                                    <span *ngIf="loginOrRegisterForm.controls['password'].hasError('required') && loginOrRegisterForm.controls['password'].touched" class="validation">Password is required</span>
                                    <span *ngIf="loginOrRegisterForm.controls['password'].hasError('minLength') &&  loginOrRegisterForm.controls['password'].dirty" class="validation">Please enter a valid password.</span>
                                </label>
                                <label>
                                    <a routerLink="/login/forgot-password" (click)="click_forgot_password()">Forgot your password</a>
                                </label>
                                <button [disabled]="!loginOrRegisterForm.valid" [class.btn--gray]="!loginOrRegisterForm.valid" type="submit">Sign in</button>
                            </form>
                        </div>
                    </div>
                    <div class="main_block_wrap__text">
                        <p>You do not have an account - <a [routerLink]="['signup']">Sign up</a></p>
                    </div>
                </div>
                <div class="main_block__bottom">
                    <ul>
                        <ul>
							<li><img src="assets/images/new_signup/logo_ginza.png" alt="Logo"></li>
							<li><img src="assets/images/new_signup/logo_booking.png" alt="Logo"></li>
							<li><img src="assets/images/new_signup/logo_hotels.png" alt="Logo"></li>
							<li><img src="assets/images/new_signup/logo_authoritylabs.png" alt="Logo"></li>            
						  </ul>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</div>

