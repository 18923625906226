import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';

import { ProductsAddNewComponent } from './products-add-new.component';
import { ProductsManageComponent } from './products-manage.component';
import { ProductsSidebarComponent } from './products-sidebar.component';

import { OrderReviewService } from '../../services/order-review.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { CustomComponent } from './custom/custom.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NotesComponent } from './notes/notes.component';
import { UsageComponent } from './usage/usage.component';
import { WebsitesComponent } from './websites/websites.component';
import { TagsComponent } from './tags/tags.component';
import { MatChipsModule } from '@angular/material/chips';
import { AccountModule } from '../account/account.module';

@NgModule({
    declarations: [ProductsAddNewComponent, ProductsManageComponent, ProductsSidebarComponent, CustomComponent, NotesComponent, UsageComponent, WebsitesComponent, TagsComponent],
    imports: [
		CommonModule, 
		RouterModule, 
		FormsModule, 
		ReactiveFormsModule, 
		SharedModule, 
		MatAutocompleteModule, 
		MatFormFieldModule,
		MatInputModule,
		MatChipsModule,
		NgMultiSelectDropDownModule.forRoot(),
		AccountModule
	],
    providers: [OrderReviewService]
})

export class ProductsModule {

}