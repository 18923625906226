import { Component, HostBinding, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { LimeProxiesService } from '../../services/limeproxies.service';

@Component({
    selector: 'user-whitelist',
    templateUrl: '../../templates/account/user-whitelist.template.html'
})

export class UserWhitelistComponent implements OnInit {

    @HostBinding('class') productsClass = 'page__main-col';
    public errorMessage;
    public successMessage;
    public user_auth_method = 'ip';
    public user_access_type;
    public whitelist = [];
    public tmpWhitelist = [];
    public isWhitelistEmpty;

    constructor(public limeProxiesService: LimeProxiesService, public userService: UserService) {
    }

    ngOnInit() {
        this.limeProxiesService.userSubject.subscribe(
            user => {
                this.user_auth_method = user.auth_method;
                this.user_access_type = user.access_type;
            },
            error => this.errorMessage = error
        );
        this.getMyWhitelist();
        this.limeProxiesService.getProxyAuthorizedIps()
            .subscribe(
                ips => {
                    const arr = ips.authorized_list.split(' ');
                    if (arr) {this.limeProxiesService.userSubjectIPUpdate.emit({type: 'authorized_ips', value: arr.length,}); }
                }
            );
    }

    addToWhiteList(address) {
        if (this.limeProxiesService.domainValidate(address) || this.limeProxiesService.validateIPaddress(address)) {
            let exists = false;
            for (let obj of this.whitelist) {
                if (obj.key === address) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                this.whitelist.push(
                    {
                        key: address,
                        initialColor: '#1b4',
                        currentColor: '#1b4'
                    }
                );
                this.checkWhitelist();
                this.errorMessage = '';
            } else {this.errorMessage = address + ' already exists in your list!'; }
        } else {this.errorMessage = 'Invalid address!'; }
    }

    removeItem(obj) {
        let ip_address = obj.key;
        for (let element of this.whitelist) {
            let key = element.key;
            if (key === ip_address) {
                element.currentColor = (element.currentColor === '#f66') ? element.initialColor : '#f66';
            }
        }
    }

    updateWhitelist() {
        let arr = [];
        for (let element of this.whitelist) {
            if (element.currentColor !== '#f66') {arr.push(element.key); }
        }

        this.limeProxiesService.updateProxyWhitelist(arr)
            .subscribe(
                success => {
                    this.limeProxiesService.showMessage(success);
                    this.whitelist = [];
                    let arr = success.white_list.split(' ');
                    arr.forEach(element => {
                        this.whitelist.push(
                            {
                                key: element,
                                initialColor: '#f0f4f7',
                                currentColor: '#f0f4f7'
                            }
                        );
                    });
                    this.checkWhitelist();
                },
                error => {
                    this.limeProxiesService.showMessage(error);
                }
            );
    }


    getMyWhitelist() {
        this.limeProxiesService.getMyWhitelist()
            .subscribe(
                obj => {
                    let arr = obj.white_list.split(' ');
                    arr.forEach(element => {
                        this.whitelist.push(
                            {
                                key: element,
                                initialColor: '#f0f4f7',
                                currentColor: '#f0f4f7'
                            }
                        );
                    });
                    this.checkWhitelist();
                }
            );
    }

    checkWhitelist() {
        this.tmpWhitelist = [];
        for (let i = 0; i < this.whitelist.length; ++i) {
            if (this.whitelist[i].key) {
                this.tmpWhitelist.push(this.whitelist[i]);
                this.isWhitelistEmpty = 'whitelistIsNotEmpty';
            }
        }
        if (!this.tmpWhitelist.length) {
            this.isWhitelistEmpty = 'whitelistIsEmpty';
        }
    }
}