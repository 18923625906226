import { Component, OnInit } from '@angular/core';
import { StatsService } from './stats.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

  public primaryXAxis: Object;
  public chartData: Object[];
  public primaryYAxis: Object;
  public legendSettings: Object;
  public title: string;
  public marker: Object;
  public tooltip: Object;

  constructor(public statsService: StatsService) {
    this.statsService.get().subscribe(chartData => this.chartData =chartData);
  }

  ngOnInit(): void {
    this.marker = { 
      dataLabel:{
        visible: true
      }
    };

    this.tooltip = {
      enable: true
    }

    this.legendSettings = {
      visible: true
    }
    
    this.primaryXAxis = {
      valueType: 'Category'
    };
  
    this.primaryYAxis = {
      labelFormat: '{value}'
    };
  
    this.title = '';
  }
}
