import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InvoiceComponent } from './invoice.component';
import { RouterModule } from '@angular/router';


@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, HttpClientJsonpModule, RouterModule],
    declarations: [InvoiceComponent],
    exports: [InvoiceComponent]
})
export class InvoiceModule {

}