import {Component, OnInit, AfterViewInit, ViewChild, Input} from '@angular/core';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyValidators } from '../../custom-validators/custom.validator';

@Component({
    selector: '.profile-sidebar',
    templateUrl: '../../templates/profile/profile-sidebar.template.html'
})

export class ProfileSidebarComponent implements OnInit, AfterViewInit {
    @ViewChild('newPasswordField', { static: true }) newPasswordField: any;
    @Input() profile;
    public userProfile;
    public selected_country;
    public newPassword: string;
    public confirmPassword: string;
    public hasPhone: boolean;
    public oldPassFalse = false;
    public oldPassFalseButton = true;
    oldPassword  = '';

    profileForm: FormGroup;
    passwordForm: FormGroup;
    emailForm: FormGroup;

    public phone_cc: string;

    constructor(public limeProxiesService: LimeProxiesService, fb: FormBuilder) {

        this.userProfile = {};

        this.profileForm = fb.group({
            'firstName': ['', Validators.compose([Validators.maxLength(50), Validators.required])],
            'lastName': ['', Validators.compose([Validators.maxLength(50), Validators.required])],
            'address': ['', Validators.maxLength(250)],
            'city': ['', Validators.maxLength(50)],
            'companyName': ['', Validators.maxLength(50)],
            'companyNumber': ['', Validators.maxLength(50)],
            'phone': ['', Validators.compose([Validators.required, MyValidators.validatePhone()])],
            'phone_cc': ['', [Validators.required]],
            'zipCode': [''],
        });
        this.profileForm.controls['phone_cc'].disable();

        this.passwordForm = fb.group({
            'oldPassword': ['', Validators.compose([Validators.minLength(8), Validators.maxLength(50), Validators.required])],
            'newPassword': ['', Validators.compose([Validators.minLength(8), Validators.maxLength(50), Validators.required])],
            'confirmPassword': ['', Validators.compose([Validators.minLength(8), Validators.maxLength(50), Validators.required])]
        }, {validator: MyValidators.MatchPassword});
        this.emailForm = fb.group({
            'email': ['', Validators.compose([Validators.required, MyValidators.validateEmail()])]
        });
    }

    ngOnInit() {
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.getProfileData();
        // this.limeProxiesService.getUserProfile()
        //     .subscribe(
        //         profile => {
        //             this.limeProxiesService.codeVerificationInputActive = !profile.verified && profile.phone_code;
        //             this.userProfile = profile;
        //             this.emailForm.get('email').patchValue(profile.email);
        //             this.phone_cc = this.limeProxiesService.getPhoneCC(profile.country);
        //             this.userProfile.phone = this.userProfile.phone.replace(this.phone_cc, '');
        //             this.selected_country = profile.country;
        //             this.hasPhone = this.userProfile === '';
        //         }
        //     );
    }

    getProfileData() {
        if (this.profile) {
            this.limeProxiesService.codeVerificationInputActive = !this.profile.verified && this.profile.phone_code;
            this.userProfile = this.profile;
            this.emailForm.get('email').patchValue(this.profile.email);
            this.phone_cc = this.limeProxiesService.getPhoneCC(this.profile.country);
            this.userProfile.phone = this.userProfile.phone.replace(this.phone_cc, '');
            this.selected_country = this.profile.country;
            this.hasPhone = this.userProfile === '';
        } else {
            setTimeout(() => {
                this.getProfileData();
            }, 100);
        }
    }

    ngAfterViewInit() {
        if (this.limeProxiesService.getFocusOnNewPassword()) {
            this.newPasswordField.nativeElement.focus();
        }
    }

    emailFormIsValid(){
        return (this.emailForm.valid && this.emailForm.get('email').touched);
    }

    updatePassword() {
        if (this.newPassword.length === 0) {
            this.limeProxiesService.notifyBarNew('Minimum password length is 8 characters', 'error');
        } else if (this.newPassword !== this.confirmPassword) {
            this.limeProxiesService.notifyBarNew('New and confirmation password must be the same', 'error');
        } else {
            const obj = {old_password: this.oldPassword, password: this.newPassword, confirm_password: this.confirmPassword};
            this.limeProxiesService.updateUserProfile(obj)
                .subscribe(
                    success => {
                        this.limeProxiesService.showMessage(success);
                        this.limeProxiesService.getUserNotification()
                            .subscribe(
                                success => {
                                    this.limeProxiesService.userNotifications = success;
                                },
                                error => this.limeProxiesService.checkStatusCodeOnError(error, this)
                            );
                        this.newPassword = '';
                        this.confirmPassword = '';
                    },
                    error => {
                        this.limeProxiesService.showMessage(error, 'error');
                    }
                );
        }
    }
    updateEmail() {
        const email = this.emailForm.get('email').value;
        this.limeProxiesService.updateUserProfile({email})
            .subscribe(
                data => {
                    this.limeProxiesService.showMessage(data);

                }
            );
    }
    private checkPhone(profile) {
        if (profile.phone) {
            this.profileForm.controls['phone'].disable();
        } else {
            this.profileForm.controls['phone'].enable();
        }
    }
}
