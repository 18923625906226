import {Component, HostBinding, OnInit} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';
import {UserCredentials} from '../../entities/user-credentials';
import {Router} from '@angular/router';

@Component({
  selector: 'login-register-menu',
  styleUrls: [
    '../../styles/bootstrap/css/bootstrap.min.css',
    '../../styles/login/login-register-menu.css'
  ],
  templateUrl: '../../templates/login/login-register-menu.template.html'
})

export class LoginRegisterMenuComponent implements OnInit {

  @HostBinding('class') myClass = 'page__content-inner';
  constructor(private limeProxiesService: LimeProxiesService,
              private router: Router) {
  }

  ngOnInit() {
    if (this.limeProxiesService.messageToDisplay.value.message !== 'Nothing to display') {
      this.limeProxiesService.notifyBarNew(
        this.limeProxiesService.messageToDisplay.value.message,
        this.limeProxiesService.messageToDisplay.value.success ? 'success' : 'error'
      );
      this.limeProxiesService.messageToDisplay.next({
        message: 'Nothing to display',
        success: false
      });
    }
  }

  signup() {
    this.router.navigate(['/login/signup']);
  }

}
