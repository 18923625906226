<div class="main__tabs main_tabs">
    <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <span class="nav-item nav-link" [class.active]="activeTab === 1" (click)="setTab(1)">Refer</span>
            <span class="nav-item nav-link" [class.active]="activeTab === 2" (click)="setTab(2)">Stats</span>
            <span class="nav-item nav-link" [class.active]="activeTab === 3" (click)="setTab(3)">Payouts</span>
        </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade" 
            [class.show]="activeTab === 1" 
            [class.active]="activeTab === 1" >
            <app-refer></app-refer>
        </div>
        <div class="tab-pane fade" 
            [class.show]="activeTab === 2" 
            [class.active]="activeTab === 2" >
            <app-stats></app-stats>
        </div>
        <div class="tab-pane fade" 
            [class.show]="activeTab === 3" 
            [class.active]="activeTab === 3" >
            <app-payouts></app-payouts>
        </div>
    </div>
</div>