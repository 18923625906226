import {Component, HostBinding, OnInit, NgZone} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Product} from '../../entities/product';
import {Router} from '@angular/router';
declare var analytics: any;

@Component({
  selector: '.home-component',
  templateUrl: '../../templates/home/home.template.html'
})

export class HomeComponent implements OnInit {

  @HostBinding('class') homeClass = 'page__content-inner';
  numberOfDegreesForFunc: any;
  numberOfDegreesToShow: any;
  transformString: any;
  chartClass = 'usage-stats__chart';

  private quota;
  private traffic;

  public active;
    public credit;
    public renewal;
    public verified = true;
    public products: Product[];
    public Object: object;

  constructor(private limeProxiesService: LimeProxiesService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private zone: NgZone) {
      if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
          analytics.page('Home');
      }
        this.getOverview();
  }

  calculate() {
    this.numberOfDegreesToShow = (this.traffic / this.quota) * 100;
    this.numberOfDegreesToShow = this.numberOfDegreesToShow.toFixed(1);

    this.numberOfDegreesForFunc = ((360 * ((this.traffic/this.quota) * 100)) / 100);
  
    // if (this.traffic / this.quota > 0.5) {
    //   this.chartClass = 'usage-stats__chart usage-stats__chart--over-50';
    // }

    this.transformString = 'transform: rotate(' + this.numberOfDegreesForFunc.toFixed(1) + 'deg)';
    this.transformString = this.sanitizer.bypassSecurityTrustStyle(this.transformString);
  }

  ngOnInit() {
    this.getUserTraffic();
    this.limeProxiesService.setTitle('Home');
  }

  getOverview() {
    this.limeProxiesService.getUserOverview()
      .subscribe(
        data => {
            this.zone.run(() => {
                this.active = data.active;
                this.credit = data.credit;
                this.renewal = data.renewal;
                this.verified = data.verified;

                for (let i = 0; i < data.products.length; i++) {
                    if (data.products[i]['location']) {
                        const temp_location = data.products[i]['location'];
                        data.products[i]['location'] = [];
                        for (let j = 0; j < Object.keys(temp_location).length; j++) {
                            const key = Object.keys(temp_location)[j];
                            data.products[i]['location'].push(temp_location[key]);
                        }
						data.products[i]['showLocation'] = false;
                    }
                }

                this.products = data.products;

                // if (!this.verified) {
                //     this.router.navigate(['/login/setup']);
                // }
            });
        }
      );
  }

  getUserTraffic() {
    this.limeProxiesService.getUserTraffic()
      .subscribe(
        result => {
          this.quota = result.quota;
          this.traffic = result.traffic;
          this.calculate();
        },
      );
  }

}
