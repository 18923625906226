<div class="block block--with-header profile-stats products-products-scrollbar scrollbar">
  <div class="block__header">
    <h4 class="block__title block__title--counter">
      Order review
    </h4>
  </div>
  <div class="block__inner">
    <ul class="profile-stats__list">
      <li class="profile-stats__item" *ngIf="productForm?.value?.proxy_num?.type != 'residential'">
        <span class="profile-stats__label" >{{getCharacters(productForm?.value?.proxy_num?.name)}}</span>
        <!-- <span class="profile-stats__label" *ngIf="pageName=='premium'">Premium Proxies</span> -->
        <span class="profile-stats__value">{{getNumbers(productForm?.value?.proxy_num?.name)[0] || 0}}</span>
      </li>
      <li class="profile-stats__item" *ngIf="productForm?.value?.proxy_num?.type == 'residential'">
        <span class="profile-stats__label" >{{getCharacters(productForm?.value?.proxy_num?.name)}} Residential Proxy</span>
      </li>
      <li class="profile-stats__item" *ngIf="!productForm?.value?.residential">
        <span class="profile-stats__label">
            <span>Proxies Usage</span>
            <!-- {{productForm?.value?.usage}} -->
            <span *ngIf="productForm?.value?.usage != 'others'" >
				<span *ngFor="let item of proxyUsageOpt" >
				  <small *ngIf="productForm?.value?.usage == item.value">
					{{ item.text }}
				  </small>
				</span>
            </span>
			<small *ngIf="productForm?.value?.usage == 'others'">
        {{ (productForm?.value?.otherUsage !== null && productForm?.value?.otherUsage.trim() !== '') ? productForm?.value?.otherUsage : 'Others' }}
			</small>
        </span>
      </li>
      <li class="profile-stats__item" *ngIf="!productForm?.value?.residential">
        <span class="profile-stats__label">
            <span>Server Locations</span>
            <small>{{ getShowLocations() }}</small>
        </span>
        <span class="profile-stats__value">{{locationCount || 0}}</span>
      </li>
      <li class="profile-stats__item" *ngIf="productForm?.value?.residential">
        <span class="profile-stats__label">
          <span>Bandwidth</span>
          <!-- <small>{{ getShowLocations() }}</small> -->
        </span>
        <span class="profile-stats__value">{{productForm?.value?.proxy_num?.bandwidth}}</span>
      </li>
      <li class="profile-stats__item">
                    <span class="profile-stats__label">
                        <span>Total</span>
                    </span>
        <span class="profile-stats__value">{{productForm?.value?.proxy_num?.price | currency:'USD':true}}</span>
      </li>
      <li *ngIf="productForm?.value?.locations && productForm?.value?.locations?.length > 0" class="profile-stats__item">
        <form [formGroup]="productForm" class="block-group products-sidebar-form">
          <h4 class="block__title block__title--counter highlight">
            Select Payment Method
          </h4>
          <div>
            <div *ngIf="availableCredits < productForm?.value.proxy_num.price" class="sidebar-payment-type toggle-grid">
              <ng-template ngFor let-paymentType [ngForOf]="paymentTypes" let-i="index" [ngForTrackBy]="trackById">
                <ng-container *ngIf="productForm.value.custom == false || (productForm.value.custom == true &&  paymentType.value != 'fastspring')" >
                  <label class="toggle-grid__item input-field" [ngClass]="{'toggle-grid__item--active': paymentType == productForm.get('payment').value}">
                    <input formControlName="payment" type="radio" class="hide" [value]="paymentType">
                    <span class="toggle-grid__inner" (click)="disCheckout(paymentType.title)">
                      <span class="toggle-grid__image">
                        <img [src]="paymentType.img">
                      </span>
                      <span>
                        <span class="toggle-grid__title">{{paymentType.title}}</span>
                      </span>
                    </span>
                  </label>
                </ng-container>
              </ng-template>
            </div>
            <div *ngIf="availableCredits >= productForm?.value.proxy_num.price" class="sidebar-payment-type toggle-grid">
              
              <ng-template ngFor let-paymentType [ngForOf]="paymentTypesWithCredits" let-i="index" [ngForTrackBy]="trackById">
                <ng-container *ngIf="productForm.value.custom == false || (productForm.value.custom == true &&  paymentType.value != 'fastspring')" >
                  <label class="toggle-grid__item input-field" [ngClass]="{'toggle-grid__item--active': paymentType == productForm.get('payment').value}">
                    <input formControlName="payment" type="radio" class="hide" [value]="paymentType">
                    <span class="toggle-grid__inner" (click)="disCheckout(paymentType.title)">
                      <span class="toggle-grid__image">
                        <img [src]="paymentType.img">
                      </span>
                      <span>
                        <span class="toggle-grid__title">{{paymentType.title}}</span>
                      </span>
                    </span>
                  </label>
                </ng-container>
              </ng-template>
            </div>
          </div>
        </form>
      </li>
    </ul>
  </div>
</div>
<div class="block__border-top" [ngSwitch]="productForm?.value?.payment?.value" >
  <form *ngSwitchCase="'paypal'" id="paypalFormElement" ngNoForm action="{{configuration.paypal.action}}" method="post" target="_top" >
    <input type="hidden" name="custom" [ngModel]="'uid:' + userInfo.uid + ',oid:' + productInvoice + ',v2'">
    <input type="hidden" name="invoice" [ngModel]="nowProdTime">
    <input type="hidden" name="return" value="{{origin}}/#/billing/history?paypal=success">
    <input type="hidden" name="cancel_return" value="{{origin}}/#/billing/history?paypal=cancel">
    <input type="hidden" name="hosted_button_id" value="{{configuration.paypal.hosted_button_id}}">
    <input type="hidden" name="cmd" value="_s-xclick">
    <input *ngIf="productForm.value.proxy_num" type="hidden" name="quantity"
           [ngModel]="(productForm.value.proxy_num.price ? productForm?.value?.proxy_num?.price : 0)">
    <button id="paypalForm"
            (click)="newProduct('paypalForm', userInfo, (productForm.value.proxy_num.price ? productForm?.value?.proxy_num?.price : 0),$event)"
            [disabled]="!productForm.valid" class="btn btn--full" name="submitPaypal" type="button">Checkout
    </button>
  </form>
  <div >
    <form *ngSwitchCase="'fastspring'" ngNoForm method="post" class="formBox" action="{{configuration.fastspring.action}}" target="_top">
      <input type="hidden" name="operation" value="create"/>
      <input type="hidden" name="destination" value="checkout"/>
      <input type="hidden" name="referrer"
             [ngModel]="'uid:' + userInfo.uid + ',oid:' + productInvoice + ',v2'">
      <input type="hidden" name="product_1_path" [(ngModel)]="product_path">
      <input type="hidden" name="product_1_quantity" value="1"/>
      <input type="hidden" name="contact_fname" [ngModel]="userInfo.first_name">
      <input type="hidden" name="contact_lname" [ngModel]="userInfo.last_name">
      <input type="hidden" name="contact_company" [ngModel]="userInfo.company_name">
      <input type="hidden" name="contact_email" [ngModel]="userInfo.email">
      <input type="hidden" name="contact_phone" [ngModel]="userInfo.phone">
      <button (click)="newProduct('fastspringForm', userInfo, (productForm.value.proxy_num.price ? productForm?.value?.proxy_num?.price : 0), $event)"
              id="fastspringForm" class="btn btn--full" name="submitFastSpring"  type="button" value="Order Now">Checkout</button>

    </form>
    <form *ngSwitchCase="'chargebee'" id="chargeBeeForm">
		<!-- div *ngFor="let premium of this.chargebee_products.premium.packages">
			<button  type="button" class="hidden btn btn&#45;&#45;full" data-cb-type="checkout" [attr.data-cb-plan-id]="premium.charge_id" >Checkout</button>
		</div>
		<div *ngFor="let private of this.chargebee_products.private.packages">
			<button  type="button" class="hidden btn btn&#45;&#45;full" data-cb-type="checkout" [attr.data-cb-plan-id]="private.charge_id" >Checkout</button>
		</div -->
		<div class="edit-team-member-modal" *ngIf="chargeeModel" >
			<div class="edit-body">
				<div class="head">
					<span *ngIf="cards?.length != 0">
						<span (click)="activeChargbeeSection('savedCard')" class="breadcrumbs__item mouseOver" [ngClass]="{'breadcrumbs__item--active': currentActiveChargbeeSection == 'savedCard'}">Saved Card</span>&nbsp;|&nbsp;
					</span>
					<span (click)="activeChargbeeSection('newCard')" class="breadcrumbs__item mouseOver" style="margin-left: 15px;" [ngClass]="{'breadcrumbs__item--active': currentActiveChargbeeSection == 'newCard'}">Add a New Card</span>
					
					<div class="pull-right mouseOver" (click)="closeChargebeeCheckout()">x</div>
				</div>
				<div class="body" >
					<div class="block-inner">
						<div class="tab-pane add__content" id="nav-card" role="tabpanel" aria-labelledby="nav-card-tab">
							<div class="saved_cards" [class.hidden]="!cards || cards?.length === 0" *ngIf="currentActiveChargbeeSection == 'savedCard'">
								<div style="max-height: 300px; overflow-y: auto;">
									<div class="saved_info__details d-flex justify-content-between align-items-center" *ngFor="let source of cards; let i = index;">
										<div class="col-md-12" style="padding: 0px; ">
											<div class=" pull-left" style="padding: 5px; margin-right: 5px; ">
												<input name="charge_payment_source" [attr.psid]="source['payment_source']['id']" id="source_{{i}}" type="radio" title="" style="display: block;">
											</div>
											<div class=" pull-left">
												<h3 class="title">
													XXXX-XXXX-XXXX-{{source['payment_source']['card']['last4']}}
												</h3>
											</div>
											<div class=" pull-right">
												<img src="assets/img/logo-{{source['payment_source']['card']['brand'] + '' + logos[source['payment_source']['card']['brand']]}}" alt="Logo" />
											</div>
										</div>
									</div>
								</div>
								<div class="saved_info__btn">
									<button [disabled]="inProcess" class="{{ !inProcess ? 'btn_orange' : 'btn_grey' }}" (click)="makePayment()">Make Payment</button>
								</div>
							</div>
							<div class="new_cards" *ngIf="currentActiveChargbeeSection == 'newCard'">
								<div style="max-height: 400px; overflow-y: auto;">
									<new-card [templateType]="'payment'" [billAmount]="productForm.value.proxy_num.price ? productForm?.value?.proxy_num?.price : 0" (cardResponse)="chargebeeCheckoutNewCard($event)"></new-card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
		<button type="button" class="btn btn--full" (click)="chargebeeCheckout()">Checkout</button>
    </form>
</div>
  <form *ngSwitchCase="'altcurrencies'" ngNoForm class="formBox" action="https://www.coinpayments.net/index.php"
        method="post" target="_top">
    <input type="hidden" name="key" value="2411623929ab8bae728712515693d18804d1350b3c432deea1edb4462b69c215">
    <input type="hidden" name="cmd" value="_pay">
    <input type="hidden" name="reset" value="12">
    <input type="hidden" name="custom"
           [ngModel]="(productInvoice ? 'uid:' + userInfo.uid + ',oid:' + productInvoice + ',v2' : null)">
    <input type="hidden" name="first_name" [ngModel]="userInfo.first_name">
    <input type="hidden" name="last_name" [ngModel]="userInfo.last_name">
    <input type="hidden" name="email" [ngModel]="userInfo.email">
    <input type="hidden" name="phone" [ngModel]="userInfo.phone">
    <input type="hidden" name="address1" [ngModel]="userInfo.address">
    <input type="hidden" name="zip" [ngModel]="userInfo.zip">
    <input type="hidden" name="country" value="{{limeProxiesService.getCountriesAcronym(userInfo.country)}}">
    <input type="hidden" name="city" [ngModel]="userInfo.city">
    <input type="hidden" name="ipn_mode" value="hmac">
    <input type="hidden" name="merchant" value="0dce993e5742269dd7e6517379fe0cf3">
    <input type="hidden" name="currency" value="USD">
    <input *ngIf="productForm.value.proxy_num" type="hidden" name="amountf"
           [ngModel]="(productForm.value.proxy_num.price ? productForm?.value?.proxy_num?.price : 0)">
    <input type="hidden" name="item_name" value="1 Proxy Credit">
    <button id="altcurrenciesForm"
            (click)="newProduct('altcurrenciesForm', userInfo, (productForm.value.proxy_num.price ? productForm?.value?.proxy_num?.price : 0), $event)"
            [disabled]="!productForm.valid" class="btn btn--full" name="submitaltcurrencies" type="submit">Checkout
    </button>
  </form>

  <div *ngSwitchCase="'bitcoin'">
    <button id="openBitcoinPopupBtn" [disabled]="!productForm.valid" class="btn btn--full popup-toggle"
            data-popup-target=".open-bitcoin-iframe">Checkout
    </button>
  </div>

  <div *ngSwitchCase="'credit'"  >
    <!-- <button *ngIf="isPushed" id="creditsForm" (click)="newCreditProduct(productForm?.value?.proxy_num?.price)"
            [disabled]="!productForm.valid" class="btn btn--full" >Checkout
    </button> -->

    <div *ngIf="isPushed" >
      <button  class="btn btn--full btn--gray btn--shadow " disabled>Checkout
      </button>
    </div>
    <div *ngIf="!isPushed" >
      <button  id="creditsForm" (click)="newCreditProduct(productForm?.value?.proxy_num?.price)"
               [disabled]="!productForm.valid" class="btn btn--full" >Checkout
      </button>
    </div>

  </div>
  <button style="margin-top: 10px;" type="button" class="btn btn--big btn--full btn--dark-gray btn--shadow" (click)="clearForm.emit()">Clear
  </button>
</div>
