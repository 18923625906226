import {Component, HostBinding, OnInit} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';
@Component({
    selector: 'account-create',
    styleUrls: [
        '../../styles/bootstrap/css/bootstrap.min.css',
    ],
    templateUrl: '../../templates/login/after-logout.template.html'
})

export class AfterLogoutComponent implements OnInit {

    @HostBinding('class') myClass = 'page__content-inner height-100';

    constructor(private limeProxiesService: LimeProxiesService) {

    }

    ngOnInit() {

    }

}
