<div class="page__main-col">
  <div class="breadcrumbs">
    <a routerLink="/api" class="breadcrumbs__item breadcrumbs__item--active" style="cursor: pointer" routerlinkactive="breadcrumbs__item--active">API</a>
  </div>
  <div class="links_block">
    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-3 left">
        <ul id="link_menu_ul">
          <li>
            <strong>Lime Proxies API</strong>
          </li>
          <li>
            <a scrollTo href="#overview">Overview</a>
          </li>
          <li>
            <a scrollTo href="#auth">Auth</a>
          </li>
          <li>
            <a scrollTo href="#proxy_data"><span>Proxy</span><span class="space">Data</span></a>
          </li>
          <li class="textIndent">
            <a scrollTo href="#get_proxy_authorized_iPs"><span>Get</span><span class="space">Proxy</span><span class="space">Authorized</span><span class="space">IPs</span></a>
          </li>
          <li class="textIndent">
            <a scrollTo href="#update_proxy_authorized_iPs"><span>Update</span><span class="space">Proxy</span><span class="space">Authorized</span><span class="space">IPs</span></a>
          </li>
        </ul>
      </div>
    <div class="block col-xs-12 col-sm-8 col-md-9">
      <div>
        <h2 id="overview" class="api block__title">Overview</h2>
        <ul class="block__overview">
          <li>
            <div class="api_overview">
              <span>API Endpoint:</span>
            </div>
            <span>https://api.limeproxies.com/</span>
          </li>
          <li>
            <div class="api_overview">
              <span>API Format:</span>
            </div>
            <span>JSON</span>
          </li>
          <li>
            <div class="api_overview">
              <span>API Token:</span>
            </div>
            <span>{{apiToken}}</span>
          </li>
        </ul>
      </div>
      <div>
        <table class="table_api table-bordered">
          <colgroup>
            <col style="width: 30%">
            <col style="width: 70%">
          </colgroup>
          <thead>
          <tr>
            <th>HTTP Response Code</th>
            <th>Description</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>200</td>
            <td>OK. Function successfully executed.</td>
          </tr>
          <tr>
            <td>400</td>
            <td>Bad Request. Check the URL and Method you are using.</td>
          </tr>
          <tr>
            <td>401</td>
            <td>Unauthorized. Wrong or missing authorization token.</td>
          </tr>
          <tr>
            <td>415</td>
            <td>Unsupported Media Type. Wrong or missing Content-Type, invalid input data.</td>
          </tr>
          <tr>
            <td>500</td>
            <td>Internal server error. Try again at a later time.</td>
          </tr>
          </tbody>
        </table>
      </div><br>
      <div>
        <h2 id="auth" class="api block__title">Auth</h2>
        <p>
          All API calls need to be authenticated and authorized.
          You need to send the 'Authorization: Bearer &#36;&#123;TOKEN&#125;' header with every call.
        </p>
        <p>
          We take security seriously, we don't store your API Token so there is no way to
          retrieve it (we only save the first 5 characters for an easy identification).
          If you've lost your Token, just request a new one.
        </p>
        <button (click)="getNewToken()" class="btn api_popup-toggle" data-popup-target=".open-popup">Get new Token</button>
        <p></p>
        <div class="curl_example">
          <span class="proxy_data_title grid">cURL example:</span>
          <code class="curl_example_txt">
            &#36; curl -X GET -H 'Content-type: application/json' -H 'Authorization: Bearer
            <span>{{apiToken}}</span>'
            "https://api.limeproxies.com/v2/proxy/authorized"
            &#123;"authorized_ips":6,"authorized_list":"4.1.1.2","authorized_updates":10,
            "updates_left":10&#125;
          </code>
        </div>
      </div><br>
      <div>
        <br>
        <h2 id="proxy_data" class="api block__title">Proxy data</h2>
        <ul class="proxy_data_block block__overview">
          <li>
            <span id="get_proxy_authorized_iPs" class="proxy_data_title">Get Proxy Authorized IPs</span>
            <div class="proxy_data">
              <div>
                <strong>Method: </strong>
                <div class="api_json">PUT</div>
              </div>
              <div>
                <strong>Path: </strong>
                <div class="api_json">
                  &#36;&#123;API_ENDPOINT&#125;/v2/proxy/authorized
                </div>
              </div>
              <div>
                <strong pretty_json>Request body: </strong>
              </div>
              <div>
                <strong class="pretty_json_strong">Response body: </strong>
                <div class="api_json pretty_json">
                  &#123;
                  <span class="pretty_json_body">"authorized_ips": 9,</span>
                  <span class="pretty_json_body">"authorized_list": "1.1.1.1 2.2.2.2 3.3.3.3",</span>
                  <span class="pretty_json_body">"authorized_updates": 5,</span>
                  <span class="pretty_json_body">"updates_left": 5</span>
                  &#125;
                </div>
              </div>
            </div>
          </li>
          <li>
            <span id="update_proxy_authorized_iPs" class="proxy_data_title">Update Proxy Authorized IPs</span>
            <div class="proxy_data">
              <div>
                <strong>Method: </strong>
                <div class="api_json">PUT</div>
              </div>
              <div>
                <strong>Path: </strong>
                <div class="api_json">
                  &#36;&#123; API_ENDPOINT &#125;/v2/proxy/authorized
                </div>
              </div>
              <div>
                <strong class="pretty_json_strong">Request body: </strong>
                <div class="api_json pretty_json">
                  &#123;
                  <span class="pretty_json_body">"authorized_list": "1.1.1.1 2.2.2.2 3.3.3.3 4.4.4.4"</span>
                  &#125;
                </div>
              </div>
              <div>
                <strong class="pretty_json_strong">Response body: </strong>
                <div class="api_json pretty_json">
                  &#123;
                  <span class="pretty_json_body">"_user_message": "added 4.4.4.4",</span>
                  <span class="pretty_json_body">"_user_message_type": "success",</span>
                  <span class="pretty_json_body">"authorized_ips": 9,</span>
                  <span class="pretty_json_body">"authorized_list": "1.1.1.1 2.2.2.2 3.3.3.3 4.4.4.4",</span>
                  <span class="pretty_json_body">"authorized_updates": 5,</span>
                  <span class="pretty_json_body">"updates_left": 4</span>
                  &#125;
                </div>
              </div>
            </div>
            <span class="proxy_data">Notes: you need to pass the full list, not only the changes</span>
          </li>
        </ul>
      </div><br>
    </div>
    </div>
  </div>
</div>
<div class="popup open-popup">
  <div class="popup__overlay api_popup__dismiss"></div>
  <div class="popup__inner api_popup_background api_popup_width1">
    <div class="modal-dialog1">

      <!-- Modal content-->
      <div class="api_modal-content">
        <div class="api_modal-header">
          <button type="button" class="api_popup_close api_popup__dismiss" data-dismiss="modal" >×</button>
          <h4 class="api_modal-title">Create New Token</h4>
        </div>
        <div class="api_modal-body">
          <div *ngIf="apiTokenCallSuccess" class="api_modal__intro api_modal__intro_success">
            <p class="api_call_res_msg sucess_message"><span class="api_toggle-grid__image"></span><span>Success</span></p>
            <p class="secret_access_message">
              This is the <span>only </span>time that the secret tokens can be viewed.
              You cannot recover them later. However, you can create new tokens at any time.
            </p>
          </div>
          <div class="api_modal__outro">
             <span class="key_id_mes">
              Token
             </span>
          </div>
          <div class="api_token">
            <p id="token_text" class="last_message token_id_text">{{clipboardText}}</p>
          </div>
        </div>
        <div class="api_modal-footer">
          <button id="copyToClipboard" (click)="copyTextToClipboard()" type="button" class="api_btn api_btn-default api_copy_btn api_popup__dismiss" disabled="true">Copy to clipboard</button>
          <button type="button" class="api_btn api_btn-default api_popup__dismiss" >Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
