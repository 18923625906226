import { Routes } from "@angular/router";

import { ProductsAddNewComponent } from "./products-add-new.component";
import { ProductsManageComponent } from "./products-manage.component";
import { AuthGuard } from "../../auth.guard";
import { UserResolveService } from "../../services/user-resolve.service";

export const productRoutes: Routes = [
  {
    path: "",
    redirectTo: "new",
    pathMatch: "full",
  },
  {
    path: "new",
    component: ProductsAddNewComponent,
    canActivate: [AuthGuard],
    resolve: { user: UserResolveService },
  },
  {
    path: "manage",
    component: ProductsManageComponent,
    canActivate: [AuthGuard],
    resolve: { user: UserResolveService },
  },
  {
    path: "manage/:type",
    component: ProductsManageComponent,
    canActivate: [AuthGuard],
    resolve: { user: UserResolveService },
  },
  {
    path: "repurchase/:productId",
    component: ProductsAddNewComponent,
    canActivate: [AuthGuard],
    resolve: { user: UserResolveService },
  },
];
