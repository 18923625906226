<div class="block block--with-header profile-stats">
    <div class="block__header">
        <h4 class="block__title">Order review</h4>
    </div>
    <div class="block__inner">
        <ul class="profile-stats__list">
            <li class="profile-stats__item">
                <span class="profile-stats__label">
                    <span>Earnings</span>
                    <small>Older than 30 days</small>
                </span>
                <span class="profile-stats__value">$0.00</span>
            </li>
            <li class="profile-stats__item">
                <span class="profile-stats__label">
                    <span>Payout type</span>
                    <small>Credit Card</small>
                </span>
                <span class="profile-stats__value">
                    <svg class="icon-arrow">
                        <use xlink:href="#arrow" />
                    </svg>
                </span>
            </li>
        </ul>
        <div class="block__border-top">
            <button type="button" class="btn btn--full">Update Payout Settings</button>
        </div>
    </div>
</div>