import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LimeProxiesService } from '../../services/limeproxies.service';
import {Router} from '@angular/router';

declare const $: any;

@Component({
    selector: 'add-new-sidebar',
    templateUrl: '../../templates/billing/add-new-sidebar.template.html'
})

export class BillingAddNewSidebarComponent implements OnInit {

    @HostBinding('class') sidebarClass = 'page__sidebar-col';

    @Input() productForm: FormGroup;
    @Input() type = 'fastspring';

    public count;
    public nowProdTime: any;

    public user: any = {};

    is_submit = false;

    constructor(public limeProxiesService: LimeProxiesService, public http: HttpClient, private router: Router) {}

    ngOnInit() {
        this.limeProxiesService.getUserProfile().subscribe(success => {
                this.user = success;
                this.user.phone = success.phone;
            },
            error => {}
         );
        this.nowProdTime = Math.floor( Date.now() / 1000 );
    }

    doPayment(id) {
        if (typeof this.user.uid !== 'undefined') {
            if (!this.is_submit) {
                this.is_submit = true;
                const selector = $('#' + id);
                selector[0].setAttribute('type', 'submit');
                selector.click();
                this.is_submit = false;
                this.limeProxiesService.completedOrder(this.user.first_name, this.user.last_name, this.user.email, this.productForm.get('num').value || this.productForm.get('other_num').value || 0);
            }
        } else {
            this.router.navigate(['#login']);
        }
    }
}
