<div class="history">
    <div class="row">    
        <div class="col-12 col-md-12 col-lg-12">
            <div class="border">
                <div class="add__title"><h3 class="title">Payouts</h3></div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Registered</th>
                                <th scope="col">Status</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template ngFor let-item [ngForOf]="data" let-i="index" >
                                <tr>
                                    <td scope="col">{{item.name}}</td>
                                    <td scope="col">{{item.registered | date:'d MMM, y'}}</td>
                                    <td scope="col">{{item.userStatus}}</td>
                                    <td scope="col" style="text-align: right;">
                                        <span *ngIf="item.payoutStatus === 'Earned'">{{item.earned | currency }}</span>
                                        <span *ngIf="item.payoutStatus !== 'Earned'">{{item.payoutStatus}}</span>
                                    </td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>