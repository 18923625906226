<div *ngIf="shouldBeVisible" class="home-grid home-grid--actions">
  <a [routerLink]="['/products/new']" class="home-grid__block btn btn--big btn--dark-gray btn--shadow">
    Order New Proxies
  </a>
  <a *ngIf="canAssignTestProxies() == false" [routerLink]="['/login/setup']" class="home-grid__block btn btn--big btn--gray btn--shadow">
    Assign Test Proxies
  </a>
  <a *ngIf="canAssignTestProxies() == true" (click)="assignTestProxies()" href="#/home" class="home-grid__block btn btn--big btn--gray btn--shadow">
    Assign Test Proxies
  </a>
  <a [routerLink]="['/products/manage']" class="home-grid__block btn btn--big btn--gray btn--shadow">
    Manage Proxies
  </a>
</div>
<div class="block block--with-header">
  <div class="block__header">
    <h4 class="block__title">Current Activity</h4>
  </div>
  <div class="block__inner">
    <div class="block-group">
      <h4 class="block__title">General Overview</h4>
      <div class="home-grid home-grid--overview">
        <div class="home-grid__block text-center">
          <a [routerLink]="['/new-billing/payment']" class="logo" >
            <span class="overview-stat">${{credit}}</span>
            <small class="overview-desc">Account Credits</small>
          </a>
        </div>
        <div class="home-grid__block text-center">
          <a [routerLink]="['/products/manage']" class="logo" >
            <span class="overview-stat">{{active}}</span>
            <small class="overview-desc">Active Products</small>
          </a>
        </div>
        <div class="home-grid__block text-center">
          <a [routerLink]="['/products/manage']" class="logo" >
            <span class="overview-stat">{{renewal}}</span>
            <small class="overview-desc">Upcoming Renewal</small>
          </a>
        </div>
      </div>
    </div>
    <div class="block-group">
      <h4 class="block__title">Current Products</h4>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table--highlight current-products">
      <thead class="table__header table__header--small">
      <tr>
        <th class="table__cell">Product #</th>
        <th class="table__cell">Product Name</th>
        <th class="table__cell">Due Date</th>
        <th class="table__cell table__cell--fit">Manage</th>
      </tr>
      </thead>
      <tbody class="table__body">
      <tr *ngIf="!products || !products?.length" class="text-center no-products">
        <td colspan="4">no active products, click <a routerLink="/products/new">here</a> to add one</td>
      </tr>
      <tr *ngFor="let product of products">
        <td class="table__cell">{{product.id}}</td>
        <td class="table__cell">{{product.product}}</td>
        <td class="table__cell">{{product.expiry | date}}</td>
        <!-- td class="table__cell table__cell--fit">
			<div class="flags-wrapper">
				<ng-template ngFor let-flag [ngForOf]="product.location" let-i="index" [ngForTrackBy]="trackById">
					<div class="flag-icon" *ngIf="i < 2 || product.location?.length <= 3">
						<img *ngIf="flag" src="assets/images/countries/{{flag.country_code.toLowerCase()}}.png" [attr.title]="flag.location">
					</div>
				</ng-template>
				<ng-template [ngIf]="product.location?.length > 3">
					<div  class="user-dropdown" [class.dropdown--show]="product.showLocation" style="position:relative;">
						<div class="dropdown dropdown--right flag-icon more-flags" (click)="showProductLocation(products, product)">
							+{{product.location?.length - 2}}
						</div>	
						<div class="dropdown__inner" style="min-width: 184px; left: -135px; margin-top: 4px; width: auto; float: left; padding: 5px;">
							<ng-template ngFor let-flag [ngForOf]="product.location" let-i="index" [ngForTrackBy]="trackById">
								<div class="flag-icon" *ngIf="i >= 2">
									<img *ngIf="flag" src="assets/images/countries/{{flag.country_code.toLowerCase()}}.png" [attr.title]="flag.location" >
								</div>
							</ng-template>
						</div>
					</div>
				</ng-template>
			</div>
        </td -->
        <td class="table__cell table__cell--fit">
          <a routerLink="/products/manage" class="btn btn--green btn--small">Manage</a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
