// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    google_app_client_id: 'http://434826632076-e3md8fi30sr3nstj09bqqvi3ka9lc4j9.apps.googleusercontent.com',
    google_app_client_secret: 'xjcLgXswYgJwrda7nXPGt9g_'
};
