import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import {LimeProxiesService} from '../../services/limeproxies.service';
declare var analytics: any;

@Component({
    selector: 'main-frame',
    templateUrl: '../../templates/main-frame/main-frame.template.html'
})

export class MainFrameComponent {
    @HostBinding('class') mainFrameClass = 'page';
    private referrer = '';

    constructor(router: Router,
                private limeProxiesService: LimeProxiesService) {
        router.events.subscribe(() => {
            let seg_info = this.getSegmentInfo();
            let queryString = seg_info.queryString;
            let href = seg_info.href;
            let path = seg_info.path;

            if (this.referrer !== href) {
                if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
                    analytics.page({
                        path: path,
                        referrer: this.referrer,
                        search: queryString,
                        url: href
                    });
                }
                this.referrer = href;
            }
        });
    }

    getSegmentInfo() {
        let queryString = window.location.search.replace('?', ''),
            href = window.location.href,
            hashIndex = href.indexOf('/#/') + 3,
            path = href.substr(hashIndex),
            questionMark = path.indexOf('?');
        if (questionMark !== -1) {
            path = path.substr(0, questionMark);
        }

        return {
            queryString: queryString,
            href: href,
            path: path
        };
    }


}
