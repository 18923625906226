import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css']
})
export class ReferralsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public activeTab = 1;

  setTab = (id: number) => this.activeTab = id;
}
