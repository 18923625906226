import { Component, OnInit, Input } from '@angular/core';
import { LimeProxiesService } from 'src/app/services/limeproxies.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  @Input() pid: any;

  notes = new FormControl('');

  constructor(private limeProxiesService: LimeProxiesService) { }

  ngOnInit(): void {
    this.getNotes();
  }

  getNotes = () => {
    this.limeProxiesService.getProductNotes(this.pid).subscribe(res => {
      if (res.notes !== undefined && res.notes !== null) {
        this.notes.setValue(res.notes);
      }
    });
  }

  setNotes = () => {
    this.limeProxiesService.setProductNotes(this.pid, this.notes.value).subscribe(res => {
      // Notes saved
    })
  }
}
