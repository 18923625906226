import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export class MyValidators {
    static validatePhone(): ValidatorFn {
        return (c: FormControl) => {
            const PHONE_REGEXP = /^\d{7,}$/;
            return PHONE_REGEXP.test(c.value === undefined ? '' : c.value.replace(/[\s()+\-\.]|ext/gi, '')) ? null : {
                    validatePhone: {
                        valid: false
                    }
                };
        };
    }
    static MatchPassword(AC: AbstractControl) {
        const password = AC.get('newPassword').value;
        const confirmPassword = AC.get('confirmPassword').value;
        if (password !== confirmPassword) {
            AC.get('confirmPassword').setErrors( {MatchPassword: true});
        } else {
            return null;
        }
    }
    static pattern(pattern: RegExp): ValidatorFn {
        return (control: FormControl): {[key: string]: any} => {
            return control.value.match(pattern) ? null : {pattern: true};
        };
    }
    static validateIpAddress(): ValidatorFn {
        return (c: FormControl) => {
            const IPADDRESS_REGEXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            return IPADDRESS_REGEXP.test(c.value) ? null : {
                    validateIpAddress: {
                        valid: false
                    }
                };
        };
    }
    static validateEmail(): ValidatorFn {
        return MyValidators.validatePattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'validateEmail');
    }
    static validateName(): ValidatorFn {
        return MyValidators.validatePattern(/^[A-Za-z ]*$/, 'validateName');
    }
    static validateCCNumber(): ValidatorFn {
        return MyValidators.validatePattern(/^((67\d{2})|(4\d{3})|(5[1-5]\d{2})|(6011))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7]\d{13}$/, 'validateNumber');
    }
    static validateExpDate(): ValidatorFn {
        return MyValidators.validatePattern(/^((0[1-9])|(1[0-2]))[\/\.\-]*((0[8-9])|(1[1-9]))$/, 'validateExpDate');
    }
    static validatePattern(regexp, regString) {
        return (control: AbstractControl): {[key: string]: any} => {
            return regexp.test(control.value) ? null : {
                [regString]: {
                    valid: false
                }
            };
        };
    }
}
