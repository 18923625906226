import { Component, OnInit } from '@angular/core';
import { PayoutsService } from './payouts.service';

@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.css']
})
export class PayoutsComponent implements OnInit {

  public data = [];

  constructor(public payoutsService: PayoutsService) {
    console.log("data ,data")
   }

  ngOnInit(): void {
    this.payoutsService.get().subscribe(result => this.data = result);
  }
}
