import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';

@Component({
    selector: 'new-card',
    templateUrl: '../../templates/new-billing/new-card.template.html'
})

export class NewCardComponent implements OnInit, OnChanges {

    @HostBinding('class') myClass = 'page__main-col';
    @Output() cardResponse: EventEmitter<any> = new EventEmitter();
    @Input() templateType;
    @Input() billAmount;
    @Input() existingCard;

    public has_card = false;
    public card_info: any;
    public shippingData: any = {};
    public showShipping = true;

    public cc = {
        card_name : {
            value: '',
            has_error: false,
            test: /[a-zA-Z_ ]{2,}/,
            touched: false
        },
        card_number : {
            value: '',
            has_error: false,
            // test: /^((67\d{2})|(4\d{3})|(5[1-5]\d{2})|(6011))-?\s?\d{4}-?\s?\d{4}-?\s?\d{4}|3[4,7]\d{13}$/,
            test: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
            touched: false
        },
        card_exp : {
            value: '',
            has_error: false,
            test: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/,
            touched: false
        },
        cvv : {
            value: '',
            has_error: false,
            test: /^([0-9]{3,4})$/,
            touched: false
        },
        valid : false
    };

    constructor(public limeProxiesService: LimeProxiesService) {
        if (this.card_info) {
            this.cc.card_exp.value = this.card_info['expiry_month'] + '/' + this.card_info['expiry_year'];
            this.cc.card_exp.touched = true;
            this.has_card = true;
        }
    }

    ngOnChanges(changes: any) {
        this.cc.card_exp.value = (this.existingCard && this.existingCard['card']) ? this.existingCard['card']['expiry_month'] + '/' + this.existingCard['card']['expiry_year'] : '';
        this.cc.card_exp.touched = true;

        let name = '';
        if (this.existingCard && this.existingCard['card'] && this.existingCard['card']['first_name']) {
            name += this.existingCard['card']['first_name'];
        }
        if (this.existingCard && this.existingCard['card'] && this.existingCard['card']['last_name']) {
            name += (name.length > 0 ? ' ' : '') + this.existingCard['card']['last_name'];
        }
        this.cc.card_name.value = name;
    }

    ngOnInit() {}

    cc_entered(key: string, event: any = null) {
	
        if (key === 'card_number') {
            this.cc.card_number.value = this.cc.card_number.value ? (this.cc.card_number.value + '').slice(0, 16) : '';
        } else if (key === 'cvv') {
            this.cc.cvv.value = (this.cc.cvv.value !== '') ? (this.cc.cvv.value + '').slice(0, 4) : '';
        }
		
		if (key === 'card_exp') {
			if (event.key >= 0 && event.key <= 9 && /^\d\d$/.test(event.target.value + '')) {
			  this.cc.card_exp.value = this.cc.card_exp.value + "/";
			} else if (/^\d\d \/ $/.test(event.target.value + '') && event.key === 'Backspace') {
			  this.cc.card_exp.value = event.target.value.slice(0, -3);
			} else {
				this.cc.card_exp.value = event.target.value;
			}
			
			if (!this.cc.card_exp.value.includes('/') && this.cc.card_exp.value.length >= 3) {
				this.cc.card_exp.value = this.cc.card_exp.value.slice(0,2) + '/'+ this.cc.card_exp.value.slice(2)
			}
		}
		
		
        const obj = this.cc[key];
        this.cc[key].touched = true;
        this.cc[key].has_error = !obj.test.test(obj.value);
		
		
        const keys = Object.keys(this.cc);
        let valid_c = 0;
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] !== 'valid') {
                if (this.cc[keys[i]].value != '' && !this.cc[keys[i]].has_error && this.cc[keys[i]].touched) {
                    valid_c++;
                }
            }
        }
        this.cc.valid = (valid_c === 4);

        this.inProcess = false;
    }

    public inProcess = false;

    newCardResponse(existingCard: any = null) {
        if (this.cc.valid) {    
            if ((this.billAmount && this.templateType === 'payment') || this.templateType !== 'payment') {
                let exp_obj = this.cc.card_exp.value.split('/');
                let name = this.cc.card_name.value.split(' ');
                const body = {
                    firstName: typeof name[0] !== 'undefined' ? name[0] : '',
                    lastName: typeof name[1] !== 'undefined' ? name[1] : '',
                    number: this.cc.card_number.value,
                    cvv: this.cc.cvv.value,
                    expiryYear: exp_obj[1],
                    expiryMonth: exp_obj[0]
                };
                Object.assign(body, this.shippingData);
				
                this.inProcess = true;

                this.cardResponse.next({
                    card: body,
                    type: this.templateType,
                    existingCard
                });
                this.resetCC();
            } else {
                this.limeProxiesService.notifyBarNew('Please choose amount.', 'error');
            }
        }
    }

    resetCC() {
        const keys = Object.keys(this.cc);
		
        for (const key of keys) {
            if (key !== 'valid') {
                this.cc[key].value = '';
            }
        }
        this.cc.valid = false;
        this.showShipping = false;
        setTimeout(() => {
            this.showShipping = true;
        }, 1);
    }

    addShippingData(ev: any) {
        this.shippingData = ev;
    }
}
