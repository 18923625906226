import { Component, HostBinding, AfterViewInit, ViewChild } from '@angular/core';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { CustomComponent } from './custom/custom.component';

declare const $: any;
declare var analytics: any;

@Component({
    selector: 'products-add-new',
    templateUrl: '../../templates/products/products-add-new.template.html'
})
export class ProductsAddNewComponent implements AfterViewInit {

	@ViewChild(CustomComponent) child:CustomComponent;
    @HostBinding('class') myClass = 'page__main-col';

    pageName = 'private';
    availableCredits: number;
    userInfo: any = {};

    premium_locs_max_number = 2;
    private_locs_max_number = 2;

    locs_max_number = 2;
    newProductForm: FormGroup;
    canAddLocation: boolean;
    locations = [];
    proxyUsageOpt = [];
	currentUser: [];
    products = [];
	public otherUsage = '';

    constructor(public limeProxiesService: LimeProxiesService,
                public _fb: FormBuilder,
                public router: Router,
				public activatedRoute: ActivatedRoute) {

        this.newProductForm = _fb.group({
            payment: [null, [Validators.required]],
            proxy_num: [null, [ Validators.required ]],
            usage: [null, [ Validators.required ]],
            otherUsage: [null, []],
            payment_method: [null],
            locations: [null, [ Validators.required ]],
            proxies_value: [''],
            proxies: [''],
            parent_id: [''],
            discount: [0],
            custom: [false, []],
            residential: [false, []]
        });

        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
            analytics.page('Products');
        }
    }

    ngOnInit() {
        this.currentUser = this.activatedRoute.snapshot.data['user'];

        this.limeProxiesService.getUserProfile().pipe(
            switchMap(success => {
                this.userInfo = success;
                this.userInfo.phone = success['phone'];
                return this.limeProxiesService.getUserNotification();
            })
        ).subscribe(
            data => {
                this.availableCredits = data['credit'];
            },
            error => {
                this.limeProxiesService.logout();
            }
        );

        this.limeProxiesService.get_proxy_usage_opt().subscribe(
            data => {
                let array = [];
                data.data.forEach(value => {
                    array.push({text: value.name, value: value.slug});
                });
                array.push({text: 'Others', value: 'others'});
                this.proxyUsageOpt = array;
				
				this.getInactiveProducts();	

            }, error => {
                console.log(error, 'get_proxy_usage_opt');
				this.getInactiveProducts();	
            }
        );

        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
            analytics.page('Products');
        }

        this.limeProxiesService.getProducts().subscribe((data) => {
            this.products = data;
            this.limeProxiesService.getLocations().subscribe((locations) => {
                this.products['private']['locations'] =  locations['private']['locations'].filter(obj => obj.state != '' || obj.country != '');
                this.products['premium']['locations'] =  locations['premium']['locations'].filter(obj => obj.state != '' || obj.country != '');
            });

            this.limeProxiesService.getResidentialProxies().subscribe((data) => {
                this.products['residential'] = data;
            });
        });	
    }
	
	public inactiveProducts: any = [];
	
	getInactiveProducts = (): void => {		
		this.limeProxiesService.getInactiveProductsByUserId().subscribe(products => {
			this.inactiveProducts = products.data
				.filter(obj => obj.status === 'inactive')
				.map((product, pi) => {
					product.no = 0;
					
					let usaCase = this.proxyUsageOpt.filter(obj => obj.value === product.usage).shift();
					
					if (usaCase === undefined) {
						product.otherUsage = product.usage;
						product.usage = 'others';
					} else {
						product.otherUsage = usaCase.text;
					}
					
					product.proxies.forEach(subnet => {
						product.no = product.no + subnet.proxies.length;
					});
					
					return product;
				})
				.filter(obj => obj.no > 0 )
				.sort(function(a, b) {
					return a.product_id < b.product_id ? 1 : -1;
				});
		});
	}
	
	public showProxiesProduct = 0;
	
	showProxies = (product) => {
		if (this.showProxiesProduct === product.product_id) {
			this.showProxiesProduct = 0;
		} else {
			this.showProxiesProduct = product.product_id;
		}
	}
	
	repurchase = (product) => {
		
		let locations 	= [];
		
		product.proxies.forEach(subnet => {
			
			let locationExists = locations.filter(location => location.country === subnet.location).shift();
			
			if (locationExists === undefined) {
				locations.push({ "country": subnet.location, "state": subnet.location });
			}
		});

		this.newProductForm.controls['proxy_num'].setValue({
				"name": product.name, 
				"price": product.cost, 
				"locations": locations.length
			});
			
		this.newProductForm.controls['custom'].setValue(true);
	
		this.newProductForm.controls['usage'].setValue(product.usage);
		this.newProductForm.controls['otherUsage'].setValue(product.otherUsage);
		this.newProductForm.controls['proxies'].setValue(product.proxies);
		this.newProductForm.controls['parent_id'].setValue(product.product_id);
		
		this.newProductForm.controls['locations'].setValue(locations); 
	}
	
	customProduct = (product) => {

		let no 			= 0;
		let locations 	= [];
		
		product.proxies.forEach(subnet => {
			no = (no) + parseInt(subnet.no);
			
			let locationExists = locations.filter(location => location.country === subnet.geo_mm_cc).shift();
			
			if (locationExists === undefined) {
				locations.push({ "country": subnet.geo_mm_cc, "state": subnet.geo_mm_cc });
			}
		});
		
		// 1 proxy - 10$
		// 2 to 50 at 4 $ / proxy
		// 50+ 3$ / proxy
		// 1000+ 2$ / proxy
	
		var tierPricing = [
			{min: 1, max: 1, price: 10},
			{min: 2, max: 50, price: 4},
			{min: 50, max: 999, price: 3},
			{min: 1000, max: 100000, price: 2}
		];
		
		let cost = tierPricing.filter(obj => (obj.min) <= (no) && (no) <= (obj.max)).shift();
		
		let price = 4;
		
		if (cost !== undefined) {
			price = cost.price;
		}
		
		this.newProductForm.controls['proxy_num'].setValue({
				"name": no + " Custom Proxies", 
				"price": (no) * (price), 
				"locations": locations.length
			});
		
		this.newProductForm.controls['custom'].setValue(true);
	
		this.newProductForm.controls['usage'].setValue(product.usage);
		this.newProductForm.controls['otherUsage'].setValue(product.otherUsage);
		this.newProductForm.controls['proxies'].setValue(product.proxies);
		this.newProductForm.controls['parent_id'].setValue("");
		
		this.newProductForm.controls['locations'].setValue(locations); 
	}

    residential = (product) => {
        let locations = [];
        locations.push({ "country": 'Residential', "state": 'Residential' });
        this.newProductForm.controls['proxy_num'].setValue(product);
        this.newProductForm.controls['custom'].setValue(false);
        this.newProductForm.controls['residential'].setValue(true);
        this.newProductForm.controls['usage'].setValue('others');
		this.newProductForm.controls['otherUsage'].setValue('Residential');
        this.newProductForm.controls['locations'].setValue(locations); 
    }

    clearForm() {
        $('.locations-list input[type=checkbox]').prop('checked', false);
        this.newProductForm.reset();
        this.child.resetAddProxies();
        this.child.resetProxies();
        this.newProductForm.controls['proxy_num'].setValue(null);
        this.newProductForm.controls['usage'].setValue(null);
		this.newProductForm.controls['otherUsage'].setValue(null);
        this.showOtherUsage = false;
		this.newProductForm.controls['proxies'].setValue('');
		this.newProductForm.controls['parent_id'].setValue("");
        this.locations = [];
    }

    updateCredit($emit) {
        this.availableCredits = $emit;
    }

    clearPayment() {
        this.newProductForm.controls['payment'].setValue(null);
    }

    canShowStep3() {
        let usage = this.newProductForm.controls['usage'].value;
        let otherUsage = this.newProductForm.controls['otherUsage'].value;

        return ((usage != 0 && usage != null && usage !== 'others') || (usage === 'others'));
        // return ((usage != 0 && usage != null && usage !== 'others') || (usage === 'others' && otherUsage !== null && otherUsage.trim() !== ''));
    }

    hasLocations(location) {
        return this.locations.find((value) => {
            return value === location;
        });
    }
	
	public showOtherUsage = false;

    selectOtherUsage(usage) {
		if (usage === 'others') {
			this.newProductForm.controls['usage'].setValue('others');
			this.newProductForm.controls['otherUsage'].setValue(usage);
		} else {
            this.locations = [];
            this.newProductForm.controls['locations'].setValue(null);
        }
	}

    selectUsage(usage) {
        console.log(usage, 'usage');
        if (usage !== null && usage !== '' && usage !== '0' && usage === 'others') {
            this.showOtherUsage = true;
            this.newProductForm.controls['otherUsage'].setValue(null);
            this.newProductForm.controls['locations'].setValue(null);
        } else {
            this.showOtherUsage = false;
        }
	
		this.child.updateSubnets();
    }

    addLocations(location, event) {
        if (event.target.checked) {
            if (this.canCheck()) {
                this.locations.push(location);
            } else {
                event.target.checked = false;
            }
        }else {
            this.locations.splice(this.locations.indexOf(location), 1);
        }
        this.newProductForm.get('locations').patchValue(this.locations);
    }

    getImage(lang: string) {
        return `assets/images/countries/${lang.toLowerCase()}.png`;
    }

    getValue(value: any) {
        return value.toLowerCase().replace(/ /g, '_');
    }

    getNumbers(value: any) {
        return value.match(/\d+/g);
    }

    ngAfterViewInit() {
        this.limeProxiesService.showProductsSidebar = true;
        // const sideBar = $('.products-sidebar');
        // const headerHeight = $('.page__header').height();
        // $(window).scroll(function(event){
            // const st = $(this).scrollTop();
            // if ($(window).width() > 1196) {
                // if (st > headerHeight + 90) {
                    // sideBar.offset({ top: st });
                // } else {
                    // sideBar.css({'position': 'static'});
                // }
            // } else {
                // sideBar.css({'position': 'static'});
            // }
        // });
        // $(window).resize(() => {
            // const scrollbarBlock = $('.products-products-scrollbar');
            // if ($(window).width() < 1196) {
                // sideBar.css({'position': 'static'});
                // scrollbarBlock.removeClass('scrollbar');
            // } else {
                // scrollbarBlock.addClass('scrollbar');
            // }
        // });
    }

    canCheck() {
        return this.locations.length < this.locs_max_number;
    }

    openPage(page: string) {
		this.child.resetProxies();
        this.newProductForm.reset();
        this.pageName = page;
        this.locations = [];
    }

    selectPremiumProxiesNumber(prem_prox_num) {
		this.newProductForm.controls['custom'].setValue(false);
        this.newProductForm.controls['residential'].setValue(false);
        this.newProductForm.patchValue({proxies_value: ''});
        $('.locations-list input[type=checkbox]').prop('checked', false);
        this.locations = [];
        this.premium_locs_max_number = prem_prox_num.locations;
        this.locs_max_number = prem_prox_num.locations;
        this.newProductForm.controls['locations'].setValue(null);
    }

    selectPrivateProxiesNumber(priv_prox_num) {
		this.newProductForm.controls['custom'].setValue(false);
        this.newProductForm.controls['residential'].setValue(false);
        this.newProductForm.patchValue({proxies_value: ''});
        $('.locations-list input[type=checkbox]').prop('checked', false);
        this.locations = [];
        this.private_locs_max_number = priv_prox_num.locations;
        this.locs_max_number = priv_prox_num.locations;
        this.newProductForm.controls['locations'].setValue(null);
    }

    showChargebeeCards(ev: any) {

    }
}