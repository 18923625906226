import {AfterViewInit, Component, HostBinding, NgZone} from '@angular/core';
//import service
import {LimeProxiesService} from '../../services/limeproxies.service';
import {countryList} from '../../services/countryList.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MyValidators} from '../../custom-validators/custom.validator';
import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';
declare const gapi: any;
declare var analytics: any;
declare var regxName: any;

@Component({
    selector: 'account-create',
    styleUrls: [

        '../../styles/bootstrap/css/bootstrap.min.css',
        '../../styles/login/account-create.css'
    ],
    templateUrl: '../../templates/login/account-create.template.html'
})

export class AccountCreateComponent implements AfterViewInit {

    @HostBinding('class') myClass = 'page__content-inner height-100';

    public signUpForm: FormGroup;
    public auth2: any;
    // public google_logo = require('../../../assets/images/new_signup/signup_google.png').default;
    public partners_logo = [
        // require('../../../assets/images/new_signup/logo_ginza.png').default,
        // require('../../../assets/images/new_signup/logo_booking.png').default,
        // require('../../../assets/images/new_signup/logo_hotels.png').default,
        // require('../../../assets/images/new_signup/logo_authoritylabs.png').default
    ];

    private signUpData = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        country: undefined,
        city: undefined,
        zipCode: undefined,
        address: undefined,
        phone: undefined,
        password: undefined
    };
    private onRequest: any = false;
    private countries = [];
    public submited = false;
    validField: boolean;
    private text_reg_exp = /^[A-Za-z]+$/;

    private track_object = {
      'first_name': 'signup_fname_completed',
      'last_name': 'signup_sname_completed',
      'email': 'signup_email_completed'
    };

    constructor(private route: ActivatedRoute,
                private limeProxiesService: LimeProxiesService,
                private router: Router,
                fb: FormBuilder,
                private zone: NgZone) {
        this.signUpForm = fb.group({
            'first_name': ['', Validators.required],
            'last_name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, MyValidators.validateEmail()])],
			'token': [''],
            'referrerToken' : [''],
			'signup_token': ['']
        });

        this.route.queryParams.subscribe((params: any) => {
            if (params.first_name && params.last_name && params.email) {
                this.signUpForm.setValue({
                    'first_name': params.first_name,
                    'last_name': params.last_name,
                    'email': params.email,
                });
                if (this.signUpForm.valid) {
                    this.submited = true;
                    this.sendValidationLinkToEmail();
                }
            }
        });

        this.countries.push({display: 'Country', value: ''});
        countryList.forEach(country => {
            this.countries.push({
                display: country,
                value: country.split(' ').map(c => c.toLowerCase()).join('_')
            });
        });

        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
            analytics.track('Registered', {
                page_url: '/login/signup'
            });
            analytics.page('Signup');
        }
        this.route.queryParams.subscribe(params => {
            let email = params['email'];
            if (email) {
                this.signUpForm.get('email').setValue(email);
            }
        });

        this.addRiskScript();
    }
	
	token = '';
    referrerToken  = '';
	emailReadOnly = false;

	ngOnInit() {
		this.token = this.route.snapshot.paramMap.get('token');
		this.signUpForm.get('token').setValue(this.token);
		this.signUpForm.get('signup_token').setValue(this.token);
		
		if (this.token !== '' && this.token !== null) {
			this.emailReadOnly = true;
			this.signUpForm.get('email').setValue(atob(this.token).split('/')[0]);
		}

        this.referrerToken = this.route.snapshot.paramMap.get('referrerToken');
		this.signUpForm.get('referrerToken').setValue(this.referrerToken);
	}

    addRiskScript() {
        let script = document.createElement('script');
        script.async = true;
        script.src = 'https://risk.clearbit.com/v1/risk.js';
        let parent = document.getElementsByTagName('script')[0];
        parent.parentNode.insertBefore(script, parent);
    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe((data: any) => {
            switch (parseInt(data.error, null)) {
                case 1:
                    this.limeProxiesService.notifyBarNew('Invalid Email or Name', 'error');
                    break;
            }
        });
        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
            analytics.track('signup_started', {
                'page_url': window.location.href
            });
        }
        this.googleInit();
    }

    goNext() {
        this.saveData();
    }

    googleInit() {
        const selector = document.querySelector('.g-signin');
        const __this = this;
        if (selector) {
            if (typeof gapi !== 'undefined') {
                gapi.load('auth2', () => {
                    __this.auth2 = gapi.auth2.init({
                        client_id: this.limeProxiesService.environment['third_parties']['google_app']['client_id'],
                        cookiepolicy: 'single_host_origin',
                        scope: 'profile email'
                    });
                    __this.attachSignin(selector);
                });
            }
        } else {
            setTimeout(() => {
                __this.googleInit();
            }, 200);
        }
    }

    attachSignin(element) {
        this.auth2.attachClickHandler(element, {},
            (googleUser) => {

                const profile = googleUser.getBasicProfile();
                let given_name = profile.getGivenName();
                let family_name = profile.getFamilyName();

                const pr_obj = {
                    first_name: given_name,
                    last_name: family_name,
                    token: googleUser.getAuthResponse().id_token,
                    email: profile.getEmail(),
					signup_token: this.token,
                    referrerToken: this.referrerToken
                };

                if (!this.onRequest) {
                    this.onRequest = true;
                    this.limeProxiesService.showApiLoader(true);
                    this.limeProxiesService.loginViaGoogle(pr_obj)
                        .subscribe(
                            success => {
                                if (success['token']) {
                                    this.limeProxiesService.setUserProfile();
                                    this.limeProxiesService.getUserNotification().subscribe(
                                        success => {
                                            this.zone.run(() => {
                                                if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
                                                    analytics.track('signin_success', {});
                                                }
                                                if (success['wizard']) {
                                                    this.router.navigate(['/login/setup']);
                                                } else {
                                                    this.router.navigate(['../home']);
                                                }
                                            });
                                        }, error => {
                                            this.limeProxiesService.showMessage(error);
                                        }
                                    );
                                } else if (success['_user_message_type'] && success['_user_message_type'] === 'error') {
                                    this.limeProxiesService.showMessage(success);
                                }
                                this.onRequest = false;
                                this.limeProxiesService.showApiLoader(false);
                            },
                            error => {
                                this.limeProxiesService.showApiLoader(false);
                                this.onRequest = false;
                                this.limeProxiesService.showMessage(error);
                            }
                        );
                }
            }, (error) => {
                if (error.error) {
                    if (error.error === 'popup_closed_by_user') {
                        this.limeProxiesService.notifyBarNew('Registration failed since you leave Google\'s authorization flow.', 'error');
                    } else {
                        this.limeProxiesService.notifyBarNew('Registration failed. Please try alternate ways of registration.', 'error');
                    }
                } else {
                    this.limeProxiesService.notifyBarNew('Registration failed. Please try alternate ways of registration.', 'error');
                }
            });
    }


    saveData() {
        const profileUpdateObj = {};
        for (const property in this.signUpData) {
            if (this.signUpData.hasOwnProperty(property)) {
                if (property !== undefined) {
                    profileUpdateObj[property] = this.signUpData[property];
                }
            }
        }
        this.limeProxiesService.updateUserProfile(profileUpdateObj)
            .subscribe();
    }

    saveDataToLocalStorage() {
        const profileUpdateObj = {};
        for (const property in this.signUpData) {
            if (this.signUpData.hasOwnProperty(property)) {
                if (this.signUpData[property] !== undefined) {
                    profileUpdateObj[property] = this.signUpData[property];
                }
            }
        }
        localStorage.setItem('signUpData', JSON.stringify(profileUpdateObj));
    }

    sendValidationLinkToEmail() {
        if (this.signUpForm.controls['email'].value.indexOf('@gmail.com') !== -1) {
            this.limeProxiesService.notifyBarNew('Please use the "Sign in with Google" button', 'warning');
        } else if (!regxName.test(this.signUpForm.controls['first_name'].value)) {
			this.limeProxiesService.notifyBarNew('Enter valid first name.', 'error');
			return false;
		} if (!regxName.test(this.signUpForm.controls['last_name'].value)) {
			this.limeProxiesService.notifyBarNew('Enter valid last name.', 'error');
			return false;
		} else if (!this.limeProxiesService.validateSignUpEmailFilters(this.signUpForm.controls['email'].value)) {
			// Invalid email
		} else {
            if (!this.onRequest) {
                this.onRequest = true;
                this.limeProxiesService.showApiLoader(true);
                this.limeProxiesService.signUpUserAndSendValidationTokenToEmail(this.signUpForm.value)
                    .subscribe(
                        success => {							
                            this.limeProxiesService.showApiLoader(false);
                            this.onRequest = false;
                            this.limeProxiesService.showMessage(success);
                            if (success['_user_message_type'] === 'success') {								
								if (this.signUpForm.controls['signup_token'].value !== '' && this.signUpForm.controls['signup_token'].value !== null) {
									LimeProxiesService.saveTokenAndAuthID(success.auth_userid, success.auth_token, success.auth_ttl);

									this.limeProxiesService.setUserProfile();
									this.limeProxiesService.getUserNotification().subscribe(
										success => {
											if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
												analytics.track('signin_success', {});
											}
								
											localStorage.setItem('user_email', this.signUpForm.controls['email'].value);
											
											this.router.navigate(['../home']);
										}, error => {
											this.limeProxiesService.showMessage(error);
										}
									);
								} else {
									this.saveDataToLocalStorage();
									this.submited = false;
									LimeProxiesService.saveTokenAndAuthID(success['auth_userid'], success['auth_token'], success['ttl']);
									const segmentData = this.signUpForm.value;
									if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
										analytics.track('signup_form_submitted', {});
									}

									localStorage.setItem('segmentData', JSON.stringify(segmentData));
									localStorage.setItem('user_email', this.signUpForm.controls['email'].value);
									this.limeProxiesService.showLoader(true);
									this.limeProxiesService.on_register = true;
									this.redirectUserToPage(this.router, ['/login/setup'], this.limeProxiesService);
								}
                            }
                        },
                        error => {
                            this.limeProxiesService.showApiLoader(false);
                            this.onRequest = false;
                            this.limeProxiesService.showMessage(error);
                        }
                    );
            }
        }
    }

    updateProfileInfo() {
        this.limeProxiesService.updateUserProfile(JSON.parse(localStorage.getItem('signUpData')))
            .subscribe(

            );
        this.saveData();
        this.limeProxiesService.notifyBarNew('Account successfully created! You will be redirected.', 'success');
        this.redirectUserToPage(this.router, '/', '');
    }

    selectCountry(country) {
        this.signUpData.country = country.split(': ')[1];
    }

    redirectUserToPage(router, page, service) {
        let route_page = (typeof page === 'string') ? [page] : page;
        setTimeout(() => {
            service.showLoader(false);
            router.navigate(route_page);
        }, 3000);
    }
    onKey(event: any) {
        if (this.text_reg_exp.test(event.target.value)) {
            this.validField = true;
        } else {
            this.validField = false;
        }
    }

    onChange(event: any) {}

    focusOutFunction() {
        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
            analytics.track('Registered', {
                page_url: '/login/signup'
            });
        }
    }

}
