<main class="receipt__content" role="main">
    <div class="top__receipt">
        <div class="top__receipt__left">
            <h3>LimeProxies.com</h3>
            <div class="receipt__company__address">
                <p>340 S lemon ave # 9836, Walnut</p>
                <p>California 91789</p>
                <p>USA</p>
            </div>
        </div>
        <div class="top__receipt__right text-right">
            <h3>Receipt of Payment</h3>
            <div class="receipt__user__info">
                <p>Account: {{user?.email}}</p>
                <p>{{user?.first_name + ' ' + user?.last_name}}</p>
            </div>
        </div>
    </div>
    <div class="bottom__receipt">
        <p>
            <b>Payment ID:</b>
            <span>{{ invoiceData?.transaction }}</span>
        </p>
        <p>
            <b>Payment Amount:</b>
            <span [innerHtml]="(invoiceData?.value < 0 ? '&#36;' + (invoiceData?.value * -1).toFixed(2) : '&#36;' + invoiceData?.value.toFixed(2))"></span>
        </p>
        <p>
            <b>Payment Date:</b>
            <span>{{ formatDate(invoiceData?.date) }}</span>
        </p>
        <p>
            <b>Payment Type:</b>
            <span>{{ invoiceData?.method }}</span>
        </p>
        <button class="btn-receipt-print" (click)="printReceipt()">Print Receipt</button>
    </div>
</main>