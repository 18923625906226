<div>
  <div class="breadcrumbs">
    <!-- <a href="https://feedback.limeproxies.com/bug-reports/create" target="_blank"
       class="breadcrumbs__item" routerLinkActive="breadcrumbs__item--active">Tickets</a> -->
    <a [routerLink]="['/support/','tickets']"
       class="breadcrumbs__item" routerLinkActive="breadcrumbs__item--active">Tickets</a>
    <span class="separator separator--bullet"></span>
    
       <a [routerLink]="['/support/','faq']"
       class="breadcrumbs__item" routerLinkActive="breadcrumbs__item--active">FAQ</a>
  </div>
  <div class="block block--with-header page_block_inner">
    <ul class="block__header tab__nav">
      <li class="block__title tab__toggle tab__toggle--active">Getting Started for New Accounts</li>
      <li class="block__title tab__toggle">Troubleshooting & Setup Guides</li>
      <li class="block__title tab__toggle">Technical</li>
      <li class="block__title tab__toggle">Account and Billing</li>
      <li class="block__title tab__toggle">General</li>
    </ul>
    <div class="tab__content-wrapper">
      <div class="tab__content-block tab__content-block--active">
        <div class="faq-block faq-block--answer">
          <h4 class="block__title support s-1">Must Read for New Accounts</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium"><strong>By default the following technical limitations will apply for all new accounts. These can be modified on request.</strong></span>
          </p>
            <p>&nbsp;</p>
            <p><span class="wysiwyg-font-size-medium">Authentication Mode - IP &nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium">Concurrent Connections - 50</span></p>
            <p><span class="wysiwyg-font-size-medium">Socks5 - Disabled</span></p>
            <p><span class="wysiwyg-font-size-medium">API - Enabled</span></p>
            <p><span class="wysiwyg-font-size-medium">Full Web Access - Enabled</span></p>
            <p><span class="wysiwyg-font-size-medium">Proxy Refresh - Automatic refresh limited to once per month</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">Remote Support on request&nbsp;</span></p>
            <p><span
                    class="wysiwyg-font-size-medium">Proxy trial with choice of location ( contact sales)</span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p><span class="wysiwyg-font-size-medium"><strong>Features Available On Request</strong></span></p>
            <p><span class="wysiwyg-font-size-medium">The following can be enabled by contacting <strong>support@Limeproxies.com&nbsp;</strong></span>
            </p>
            <ul>
              <li><span class="wysiwyg-font-size-medium">On-Demand IP Refresh &nbsp; (Special Package required)</span>
              </li>
              <li><span class="wysiwyg-font-size-medium">Blocked site IP Refresh</span></li>
              <li><span class="wysiwyg-font-size-medium">Socks5 / Socks4</span></li>
              <li><span class="wysiwyg-font-size-medium">Reverse Proxy</span></li>
              <li><span class="wysiwyg-font-size-medium">Custom Locations worldwide (100$ setup fee per location)</span>
              </li>
              <li><span class="wysiwyg-font-size-medium">Username authentication&nbsp;</span></li>
              <li><span class="wysiwyg-font-size-medium">Concurrent connections upto 10,000</span></li>
              <li><span class="wysiwyg-font-size-medium">Custom proxies for specific websites / apps</span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p><span
                    class="wysiwyg-font-size-medium"><strong>Lime Proxies</strong><strong>&nbsp;Support Guarantee&nbsp;</strong></span>
            </p>
            <p><span
                    class="wysiwyg-font-size-medium">Average technical support response time - 30 minutes &nbsp;(24/7)</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">Average Sales response time - 12 hours&nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium">Average Billing response time - 24 hours</span></p>
            <p><span class="wysiwyg-font-size-medium">If you are not satisifed with our customer support or require a special package. please reach out to admin@limeproxies.com for quick resolution. Our goal is to ensure 100% customer satisfaction.</span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title support s-2">How do I get Proxy IP’S via API?</h4>
          <div class="block__desc">
            <p>
              <span class="wysiwyg-font-size-medium">
                  Log into the dashboard:
              </span>
            </p>
            <p>
              <strong>
                <span class="wysiwyg-font-size-large"><img
                        src="assets/images/faq/log-into-dashboard.png"
                        alt="">
                </span>
              </strong>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium">
                  Press the API button on the menu and follow the instructions on the page.
              </span>
            </p>
            <p>
              <strong>
                <span class="wysiwyg-font-size-large"><img
                        src="assets/images/faq/press-api-button.png"
                        alt="">
                </span>
              </strong>
            </p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title support s-3">How do I add credits to my account?</h4>
          <div class="block__desc">
            <p>Click on the “Add credits” Tab</p>
            <p>
              <span class="wysiwyg-font-size-medium">
                  <strong><img
                          src="assets/images/faq/click-on-add-credits-tab.png"
                          alt="">
                  </strong>
              </span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium">
                  Choose the option to add credits ( Fast Spring or PayPal)
              </span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium"><strong><img
                      src="assets/images/faq/choose-option-to-add-credits.png"
                      alt=""></strong>
              </span>
            </p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title support s-5">How can I get a free test proxy?</h4>
          <div class="block__desc">
            <p>
              <span class="wysiwyg-font-size-medium"> </span>
              <span
                      class="wysiwyg-font-size-medium">In the dashboard you will notice “Validate your phone Number”</span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium">&nbsp;<img
                      src="assets/images/faq/validate-phone-number.png"
                      alt="">
              </span>

            <p>
              <span
                      class="wysiwyg-font-size-medium">It will then ask you to authorize the internet IP, click on next</span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium"><img
                      src="assets/images/faq/authorize-ip.jpg" alt=""></span>
            </p>

            <p>
              <span class="wysiwyg-font-size-medium">&nbsp;</span>
              <span
                      class="wysiwyg-font-size-medium">Enter your phone number and click on next</span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium"><img
                      src="assets/images/faq/enter-phone-number.jpg" alt=""></span>
            </p>

            <p>
              <span class="wysiwyg-font-size-medium">&nbsp;</span>
              <span
                      class="wysiwyg-font-size-medium">Enter the code received and get a free proxy</span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium"><img
                      src="assets/images/faq/enter-code.jpg" alt=""></span>
            </p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title support s-6">How do I add/remove a URL under Whitelist ?</h4>
          <div class="block__desc">
            <p>Click on Accounts and then User Whitelist</p>
            <p>
              <span class="wysiwyg-font-size-medium"><img
                      src="assets/images/faq/user-whitelist.jpg"
                      alt="">
              </span>
            </p>

            <p>
              Under “User Whitelist”, enter the website name that needs to be whitelisted and click on “Add to List”
            </p>
            <p>
              <span class="wysiwyg-font-size-medium"><img
                      src="assets/images/faq/add-to-list.jpg" alt=""></span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium">
                  To remove the website, click on the “x” sign and click on “Update”
              </span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium">
                  <img
                          src="assets/images/faq/remove-website.jpg" alt="">
              </span>
            </p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to add authorized IPs?</h4>
          <div class="block__desc">
            <p>
              <span class="wysiwyg-font-size-medium">
              Click on “Account” and the click on “Authorized IPs”
              </span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium">&nbsp;<img
                      src="assets/images/faq/click-on-account.jpg"
                      alt="">
              </span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium">&nbsp;
                  Enter the IP address, click on “Add” and then “Update”
              </span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium"><img
                      src="assets/images/faq/click-on-add-update.jpg" alt="">
              </span>
            </p>
          </div>
        </div>

        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How do I download proxies list?</h4>
          <div class="block__desc">
            <p><span
                    class="wysiwyg-font-size-medium">Click on “Products”</span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium"><img
                      src="assets/images/faq/click-on-products.jpg"
                      alt="">
              </span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium">&nbsp;&nbsp;
              Click on “Download” to download the list of proxies
              </span>
            </p>
            <p>
              <span class="wysiwyg-font-size-medium"><img
                      src="assets/images/faq/download-list-of-proxies.jpg"
                      alt="">
              </span>
            </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span><span class="wysiwyg-font-size-medium">You can download the proxies list </span>from
              here<span class="wysiwyg-font-size-medium">.</span></p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span></p></div>
        </div>
      </div>
      <div class="tab__content-block">
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">My application doesn't have proxy settings. How can I configure it to work
            through a proxy server?</h4>
          <div class="block__desc"><p>If your application doesn't have proxy settings, you need special software
            to configure your application to work through proxy servers. You can use freeware called
            SocksCap.</p>
            <p>Using SocksCap you can configure any application that doesn't support proxies (has no proxy
              settings) to work through proxy servers. If SocksCap is configured for the proxy server, you do
              not need to configure any of your Internet programs. Simply click and drag your application into
              the SocksCap program control window, start the program and surf through the proxy.</p>
            <p><strong>To configure an application that doesn't have proxy settings, follow the below
              steps:</strong></p>
            <p>Download SocksCap free of charge.</p>
            <p>Run SocksCap.</p>
            <p><img src="assets/images/download1.png"></p>
            <p>Click on Proxy</p>
            <p>Enter your proxy address in the "SOCKS Server" and proxy port in "Port" field.</p>
            <p>Select SOCKS Version 4 or SOCKS Version 5 2 on your needs.</p>
            <p><img src="assets/images/download2.png"></p>
            <p>Click OK.</p>
            <p>Now you should add your application to SocksCap (create application profile)</p>
            <p><img src="assets/images/download3.png"></p>
            <p>Select File -&gt; New in the SocksCap menu and using "Browse" button select your application or
              simply click and drag your application shortcut icon into the SocksCap window and select "New
              application profile" (the profile name and working directory will be filled in automatically)
              and click "OK."</p>
            <p><img src="assets/images/download4.png"></p>
            <p>Now you should run your application from SocksCap and it will work through the proxy server.</p>
            <p><img src="assets/images/download5.png"></p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to configure proxy settings in Windows Media Player 9.x</h4>
          <div class="block__desc"><p>Start Windows Media Player.</p>
            <p>Select Organize &nbsp;=&gt; Options.</p>
            <p><img src="assets/images/download6.png"></p>
            <p>Select the Network tab =&gt; Configure</p>
            <p><img src="assets/images/download7.png"></p>
            <p>Click on “use the following proxy” and enter the details.</p>
            <p>Click OK.</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to configure proxy settings in Kazaa</h4>
          <div class="block__desc"><p>Select Tools =&gt; Options.</p>
            <p><img src="assets/images/download8.png"></p>
            <p>"Kaaza options" window appears.</p>
            <p>Open the "Firewall" tab.</p>
            <p>Select "Use SOCKS5 Proxy Server."</p>
            <p>Set proxy address in "Hostname" field and proxy port in "Port" field.</p>
            <p><img src="assets/images/download9.png"></p>
            <p>Click "OK."</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How configure proxy settings in Emule</h4>
          <div class="block__desc"><p>Open the Options menu</p>
            <p><img src="assets/images/download10.png"></p>
            <p>Select Proxy tab.</p>
            <p>Put the check mark on "Enable proxy."</p>
            <p>In Proxy type choose the Socks 4, Socks 4a, Socks 5, HTTP 1.1.</p>
            <p>In "Proxy host" field enter the proxy and under port enter the port number</p>
            <p><img src="assets/images/download11.png"></p>
            <p>Click OK.</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to configure proxy server settings in mIRC</h4>
          <div class="block__desc"><p>Run mIRC and click "Tools" then "Options", then go to "Connect"</p>
            <p><img src="assets/images/download12.png"></p>
            <p>Under “Connect”, select “Proxy .Open Connect -&gt; Firewall.</p>
            <p>Open Connect -&gt; Firewall.Fill in Hostname with IP address of socks and Port with</p>
            <p>Fill in Hostname with IP address of socks and Port with port of socks. Also, choose a protocol
              (Socks4 or Socks5).</p>
            <p><img src="assets/images/download13.png"></p>
            <p>Click "OK."</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to configure Mozilla Thunderbird proxy settings</h4>
          <div class="block__desc"><p>Select Tools =&gt; Options.</p>
            <p><img src="assets/images/download14.png"></p>
            <p>Select Advanced =&gt; Settings</p>
            <p><img src="assets/images/download15.png"></p>
            <p>Select the Manual Proxy Configuration radio button.</p>
            <p>Also select the option “Use this proxy for all protocols”</p>
            <p><img src="assets/images/download16.png"></p>
            <p>Click OK.</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to use HTTP Proxies in Opera</h4>
          <div class="block__desc"><p>Select Edit -&gt; Preference.</p>
            <p><img src="assets/images/download17.png"></p>
            <p>Select "Advance."</p>
            <p>Under Advance, select Proxies</p>
            <p><img src="assets/images/download18.png"></p>
            <p>Select the Manual Proxy Configuration radio button.</p>
            <p>In the necessary proxy field(s), set Proxy Server address and proxy configuration port.</p>
            <p>Click OK.</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to configure proxy settings in Netscape Browser</h4>
          <div class="block__desc"><p>Select Menu =&gt; Settings.</p>
            <p><img src="assets/images/download19.png"></p>
            <p>Under Settings, click on Browser.</p>
            <p>Under the sub-menu, there is an option “Network”, under that click on “Change proxy
              settings”.</p>
            <p><img src="assets/images/download20.png"></p>
            <p>Select the Connections tab.</p>
            <p>If you are using LAN, click "LAN Settings" button.</p>
            <p><img src="assets/images/download21.png"></p>
            <p>In the "Proxy Server" area, click the check box next to <em>Use a proxy server for this
              connection</em>.</p>
            <p><img src="assets/images/download22.png"></p>
            <p>If necessary, enable "bypass proxy server for local addresses".</p>
            <p>Set Proxy Server address (proxy IP), proxy server port.</p>
            <p>Click OK.</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to use HTTP Proxies in Opera</h4>
          <div class="block__desc"><p>Select Menu =&gt; Settings.</p>
            <p><img src="assets/images/download23.png"></p>
            <p>Under Settings, click on Browser.</p>
            <p>Under the sub-menu, there is an option “Network”, under that click on “Change proxy
              settings”.</p>
            <p><img src="assets/images/download24.png"></p>
            <p>Select the Connections tab.</p>
            <p>If you are using LAN, click "LAN Settings" button.</p>
            <p><img src="assets/images/download25.png"></p>
            <p>In the "Proxy Server" area, click the check box next to <em>Use a proxy server for this
              connection</em>.</p>
            <p><img src="assets/images/download26.png"></p>
            <p>If necessary, enable "bypass proxy server for local addresses".</p>
            <p>Set Proxy Server address (proxy IP), proxy server port.</p>
            <p>Click OK.</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to use our proxies in GSA Search Engine Ranker</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Inside GSA Search Engine Ranker, click Options, and click Configure next to "Use proxies"</span>
          </p>
            <p><img src="https://downloads.intercomcdn.com/i/o/61338273/6cfee861c9522f5dee620b41/1.png" alt=""
                    width="564" height="303"></p>
            <p>&nbsp;<br> <span class="wysiwyg-font-size-medium">Copy proxies from the&nbsp;VIP panel&nbsp;and click Add Proxies &gt; Import from Clipboard (our proxies are just in </span>host:
              port<span class="wysiwyg-font-size-medium"> format, compatible with either format listed)</span>
            </p>
            <p><img src="https://downloads.intercomcdn.com/i/o/61338275/a423ccf522d5fc203fc25c5c/2.png" alt=""
                    width="562" height="303"></p>
            <p>&nbsp;<br> <span class="wysiwyg-font-size-medium">Click Test Proxies &gt; Against Anonymous Test URL first to ensure proxies are authorized and working.</span>
            </p>
            <p><img src="https://downloads.intercomcdn.com/i/o/61338277/cf2edff16cc39af17d5e01e1/3.png" alt=""
                    width="562" height="319"></p>
            <p><br><span
                    class="wysiwyg-font-size-medium">Then try testing against the other options next.</span>&nbsp;
            </p>
            <p><img src="https://downloads.intercomcdn.com/i/o/61338278/0740af791f033ffc5678f769/4.png" alt=""
                    width="563" height="318"></p>
            <p>&nbsp;<br> <strong><span class="wysiwyg-font-size-medium">Troubleshooting</span></strong><br>
              <br> <span class="wysiwyg-font-size-medium">If&nbsp;all or some proxies fail the GSA Anonymous URL Test, first please&nbsp;ensure your IP is </span>authorized
              and<span
                      class="wysiwyg-font-size-medium"> test a single proxy&nbsp;manually in a browser.</span><br>
              <br><span class="wysiwyg-font-size-medium"> If proxies pass for the&nbsp;GSA Anonymous URL Test but they fail for another test (e.g., the Google test), please export the proxies which&nbsp;fail and</span><br><span
                      class="wysiwyg-font-size-medium"> send a message to support&nbsp;requesting those proxies be replaced with "Google Proxies for GSA".</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to use HTTP Proxies in the Firefox Browser</h4>
          <div class="block__desc"><p>Select Tools =&gt; Options.1</p>
            <h2><img src="assets/images/download27.png"></h2>
            <p>Select the "Advanced" tab.</p>
            <p>Open the "Network" tab.</p>
            <p>Click the "Settings" button in the "Connections" area</p>
            <h2><img src="assets/images/download28.png">&nbsp;</h2>
            <p>Select the Manual Proxy Configuration radio button.</p>
            <p>Also select the option “Use this proxy for all protocols.</p>
            <p><img src="assets/images/download29.png"></p>
            <p>Click OK.</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to use HTTP Proxies in the Chrome Browser</h4>
          <div class="block__desc">
            <div class="block__desc"><p>1. Open Chrome and navigate to chrome://chrome/settings (or select
              Tools &gt; Settings)</p>
              <p><img src="https://downloads.intercomcdn.com/i/o/61338707/579cac7dc381c585a3b819fc/imageedit_2_7878615904.jpg"
                      alt="" width="399" height="345"></p>
              <p>2. Within&nbsp;Advanced settings..., click&nbsp;Change proxy settings...</p>
              <h2><img src="https://downloads.intercomcdn.com/i/o/61338708/1f4462c836d50841f06ffac4/imageedit_2_7878615905.JPG"
                       alt=""></h2>
              <p>3. Within the Connections tab, click&nbsp;LAN Settings</p>
              <h2><img src="https://downloads.intercomcdn.com/i/o/61338710/d18d99439441cbb7292c7220/ch_3.png"
                       alt=""></h2>
              <p>4. Check&nbsp;Proxy server, and click&nbsp;Advanced</p>
              <h2><img src="https://downloads.intercomcdn.com/i/o/61338711/8b16f0f7b48410c9937e19e0/ch_4.png"
                       alt=""></h2>
              <p>5. Enter the proxy IP with port&nbsp;1212&nbsp;next to&nbsp;HTTP&nbsp;and&nbsp;Secure:</p>
              <p><img src="https://downloads.intercomcdn.com/i/o/61338713/1d7ccc5cde542bf67a44bcd5/blobid0.png"></p></div>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to use HTTP Proxies in Internet Explorer</h4>
          <div class="block__desc"><p>Select Tools =&gt; Internet Options.</p>
            <p><img src="assets/images/download31.png"></p>
            <p>Select the Connections tab.</p>
            <p>If you are using LAN, click "LAN Settings" button</p>
            <p><img src="assets/images/download32.png"></p>
            <p>&nbsp;Make sure the "automatically detect proxy settings" and "use a proxy automatic
              configuration script" options are not checked.</p>
            <p><img src="assets/images/download33.png"></p>
            <p>In the "Proxy Server" area, click the check box next to <em>Use a proxy server for this
              connection.</em></p>
            <p>If necessary, enable "bypass proxy server for local addresses".</p>
            <p>Set Proxy Server address (proxy IP), proxy server port.</p>
            <p>Click OK.</p>
            <p>&nbsp;</p>
            <h2>&nbsp;</h2></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to use our proxies in PHP code</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Our proxies can be used in PHP code as long as the CURL library is enabled.</span>
          </p>
            <p><strong><span
                    class="wysiwyg-font-size-medium">Create a file called test_proxy.php:</span></strong></p>
            <p><span class="wysiwyg-font-size-medium">$proxy = '1.1.1.1:1212';&nbsp; // &lt;-- Replace 1.1.1.1:1212 with your proxy IP:PORT</span><br><span
                    class="wysiwyg-font-size-medium"> $url = 'http://www.ipcheckit.com';</span><br><span
                    class="wysiwyg-font-size-medium"> $content = getUrlContents($url, $proxy);</span><br><span
                    class="wysiwyg-font-size-medium"> print $content;</span></p>
            <p><br><span class="wysiwyg-font-size-medium"> function getUrlContents($url, $proxy = NULL, $post_data = NULL, $timeout = 30, $userpass = NULL) {{ '{' }}</span><br><span
                    class="wysiwyg-font-size-medium"> $curl = curl_init();</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_URL, $url);</span><br><span
                    class="wysiwyg-font-size-medium"> if ($post_data !== NULL) {{ '{' }}</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_POST, TRUE);</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_POSTFIELDS, $post_data);</span><br><span
                    class="wysiwyg-font-size-medium"> }</span><br><span class="wysiwyg-font-size-medium"> if ($proxy != NULL) {{ '{' }}</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_PROXY, $proxy);</span><br><span
                    class="wysiwyg-font-size-medium"> }</span><br><span class="wysiwyg-font-size-medium"> if ($userpass != NULL) {{ '{' }}</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_USERPWD, $userpass);</span><br><span
                    class="wysiwyg-font-size-medium"> }</span><br><span class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_RETURNTRANSFER, TRUE);</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_SSL_VERIFYPEER, FALSE);</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_SSL_VERIFYHOST, FALSE);</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_TIMEOUT, $timeout);</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_MAXREDIRS, 5);</span><br><span
                    class="wysiwyg-font-size-medium"> curl_setopt($curl, CURLOPT_FOLLOWLOCATION, FALSE);</span><br><span
                    class="wysiwyg-font-size-medium"> $contents = curl_exec($curl);</span><br><span
                    class="wysiwyg-font-size-medium"> curl_close($curl);</span><br><span
                    class="wysiwyg-font-size-medium"> return $contents;</span><br><span
                    class="wysiwyg-font-size-medium"> }</span><br> <br><span class="wysiwyg-font-size-medium"> ?&gt;</span>
            </p>
            <p><strong><span
                    class="wysiwyg-font-size-medium">Upload and navigate to test_proxy.php</span></strong></p>
            <p><span
                    class="wysiwyg-font-size-medium">-- If it works, you should see the contents of the specified $url</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">-- If you get an access denied page, ensure your server's IP is added as an authorized IP in our Control panel</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">-- If you see CURL errors, contact your web host about CURL support</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to use HTTP Proxies in PHP Code</h4>
          <div class="block__desc"><h5><span class="wysiwyg-font-size-medium"><strong>Before using our proxies, ensure that your server IP address is authorized via the proxy panel.</strong></span>
          </h5>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span><span
                    class="wysiwyg-font-size-medium"><strong><span class="wysiwyg-underline">Sample code for using our proxies in PHP Code:</span></strong></span>
            </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span><span
                    class="wysiwyg-font-size-medium">&lt;?php</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium">$url = 'http://limeproxies.com/anonymity.php/';</span></p>
            <p><span
                    class="wysiwyg-font-size-medium">$proxy = '1.1.1.1:1212'; &nbsp;// &lt;-- set your proxy</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">$content = requestUrl($url, $proxy);</span></p>
            <p><span class="wysiwyg-font-size-medium">print $content;</span></p>
            <p><span class="wysiwyg-font-size-medium">print 'Done requesting '.$url.'...';</span></p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium">functionrequestUrl($url, $proxy = NULL) {{ '{' }}</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; $curl = curl_init();</span></p>
            <p><span
                    class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; curl_setopt($curl, CURLOPT_URL, $url);</span>
            </p>
            <p><span
                    class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; if ($proxy != NULL) {{ '{' }}</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; curl_setopt($curl, CURLOPT_PROXY, $proxy);</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; }</span></p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; curl_setopt($curl, CURLOPT_RETURNTRANSFER, TRUE);</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; curl_setopt($curl, CURLOPT_SSL_VERIFYPEER, FALSE);</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; curl_setopt($curl, CURLOPT_SSL_VERIFYHOST, FALSE);</span>
            </p>
            <p><span
                    class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; $contents = curl_exec($curl);</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; curl_close($curl);</span></p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;&nbsp;&nbsp;&nbsp; return $contents;</span></p>
            <p><span class="wysiwyg-font-size-medium">}</span><span
                    class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium">?&gt;</span></p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to use HTTP Proxies in Java code</h4>
          <div class="block__desc"><h5><span class="wysiwyg-font-size-medium"><strong>Before using our proxies, ensure that your server IP address is authorized via the proxy control panel.</strong></span><br>
            <br><span class="wysiwyg-underline wysiwyg-font-size-medium"> <strong>Sample code for using proxies in Java code:</strong></span><span
                    class="wysiwyg-font-size-medium">&nbsp;</span></h5>
            <p><span class="wysiwyg-font-size-medium">import java.net.*;</span><br><span
                    class="wysiwyg-font-size-medium"> import java.util.Scanner;</span></p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium">classProxyTest {{ '{' }}</span><br><span
                    class="wysiwyg-font-size-medium"> &nbsp; public static void main(String[] args) throws Exception {{ '{' }}</span><br><span
                    class="wysiwyg-font-size-medium"> &nbsp; &nbsp; InetSocketAddressproxyAddress =&nbsp;new InetSocketAddress("1.1.1.1", 1212); // Set proxy IP/port.</span><br><span
                    class="wysiwyg-font-size-medium"> &nbsp; &nbsp; Proxy proxy = new Proxy(Proxy.Type.HTTP, proxyAddress);</span><br><span
                    class="wysiwyg-font-size-medium"> &nbsp; &nbsp; URL url = new URL("http://checkip.limeproxies.com/");</span><br><span
                    class="wysiwyg-font-size-medium"> &nbsp; &nbsp; URLConnectionurlConnection = url.openConnection(proxy);</span><br><span
                    class="wysiwyg-font-size-medium"> &nbsp; &nbsp; Scanner scanner = new Scanner(urlConnection.getInputStream());</span><br><span
                    class="wysiwyg-font-size-medium"> &nbsp; &nbsp; System.out.println(scanner.next());</span><br><span
                    class="wysiwyg-font-size-medium"> &nbsp; &nbsp; scanner.close();</span><br><span
                    class="wysiwyg-font-size-medium"> &nbsp; }</span><br><span class="wysiwyg-font-size-medium"> }</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Proxy Troubleshooting Guide</h4>
          <div class="block__desc"><p>&nbsp;<span class="wysiwyg-font-size-medium">Having trouble getting proxies to work? Please try following these steps to identify the problem.</span><span
                  class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium"><strong>Test in your browser:</strong></span></p>
            <p><br><span class="wysiwyg-font-size-medium"> 1. Configure your browser to use your HTTP proxy. Ensure the port is 1212.&nbsp;</span>
            </p>
            <p><br><span class="wysiwyg-font-size-medium"> 2. Go to "http://www.whatismyip.com" in your browser. If the page loads, then you can connect through the proxy.</span><span
                    class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><span
                    class="wysiwyg-font-size-medium">If you're still having issues, proceed to the following steps.</span><span
                    class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><strong>Do you see an access denied error?</strong></p>
            <p><br><span class="wysiwyg-font-size-medium"> 1. Ensure that your IP is authorized in our proxy Control Panel (http://panel.limeproxies.com )</span>
            </p>
            <p><br><span class="wysiwyg-font-size-medium"> 2. On the "access denied" page, sometimes the IP listed in "Please ensure this IP is set in your authorized IP list: " is different from the one that was set on your proxy control panel. Please ensure that the </span>IP<span
                    class="wysiwyg-font-size-medium"> listed in the "access denied" page is authorized too.</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">In the Connection Settings window, click on Manual proxy configuration.</span>
            </p>
            <p><span
                    class="wysiwyg-font-size-medium">Enter the IP in HTTP Proxy and&nbsp;SSL Proxy&nbsp;field.</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">Enter 1212&nbsp;in Port field.</span></p>
            <p><span class="wysiwyg-font-size-medium">This is how your proxy settings should look like.</span>
            </p>
            <p>&nbsp;</p>
            <p><span class="wysiwyg-font-size-medium">Click Ok, then try to browse.</span></p>
            <p>&nbsp;</p>
            <p><span
                    class="wysiwyg-font-size-medium">Please contact support@limeproxies.com if you are have issues.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How to troubleshoot HTTP proxy connectivity issues</h4>
          <div class="block__desc"><h5>&nbsp;</h5>
    
            <p>Many different things can cause HTTP proxies to fail to work: &nbsp;Client configuration, server
              configuration, firewalls, routers, network issues, etc.</p>
            <p><span
                    class="wysiwyg-font-size-medium"><strong>Please follow these steps to troubleshoot the issue.</strong></span><br>
              <br><span class="wysiwyg-font-size-medium"> 1. Ensure your current IP is authorized:</span><span
                      class="wysiwyg-font-size-medium">&nbsp; &nbsp;&nbsp;</span></p>
            <p>&nbsp; &nbsp; &nbsp; <span class="wysiwyg-color-black"><a
                    href="https://help.limeproxies.com/en/articles/1696526-how-to-add-authorized-ips">How to authorize your IP</a></span>
            </p>
            <p><br><strong><span class="wysiwyg-font-size-medium"> 2. Test going to <a
                    href="http://limeproxies.com/anonymity.php">http://limeproxies.com/anonymity.php</a> manually in a browser:</span></strong>&nbsp;
              &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;</p>
            <p class="wysiwyg-text-align-left">&nbsp; &nbsp;&nbsp;<a
                    href="https://help.limeproxies.com/en/articles/1696550-how-to-use-http-proxies-in-internet-explorer-using-limeproxies">How
              to use proxies in Internet Explorer</a><br> <strong>&nbsp; &nbsp;&nbsp;</strong><a
                    href="https://help.limeproxies.com/en/articles/1696551-how-to-use-http-proxies-in-the-firefox-browser-using-limeproxiesr">How to
              use proxies in Firefox</a><br> &nbsp; &nbsp;&nbsp;<a
                    href="https://help.limeproxies.com/en/articles/1696529-how-to-use-http-proxies-in-the-chrome-browser-using-limeproxies">How to use
              proxies in Chrome</a></p>
            <p class="wysiwyg-text-align-left"><br><span class="wysiwyg-font-size-medium"> 3. Test different URLs to determine if the issue only occurs on a specific website.</span><br>
              <br><span class="wysiwyg-font-size-medium"> 4. Test from another computer on a different network if possible (ensure that computer's IP is authorized too).</span>
            </p>
            <p>5. Test with an alternate proxy port: port 1212 is&nbsp;supported.</p>
            <h5><br><span class="wysiwyg-font-size-medium"> <strong>If the steps fail to resolve the issue, please&nbsp;submit a ticket&nbsp;noting:</strong></span>
            </h5>
            <p>1. The proxy IP and port</p>
            <p>2. Which websites you experience issues on (and whether other websites work fine)</p>
            <p>3. Whether the error occurs 100% of the time or only occasionally</p>
            <p>4. A screenshot of the error you see.</p>
            <h5><br><span class="wysiwyg-font-size-medium"> Many different issues can cause a proxy to fail to work, so these details are critical for us to help identify any problems you experience.</span>
            </h5>
            <p><span class="wysiwyg-font-size-medium">&nbsp;</span></p></div>
        </div>
      </div>
      <div class="tab__content-block">

        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Can I use the proxies for Pokemon Go bots?</h4>
          <div class="block__desc">
            <p>Our proxies do not support Pokemon Go
            </p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Do you provide userbased authentication for Socks Proxies?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">No, we do not provide user based authentication for Socks proxies.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">Socks proxies are IP authenticated.</span></p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Is there any Bandwidth limitation?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We provide a&nbsp;fair usage policy of up to 500GB; however we can increase this on request.</span>
          </p>
            <p><span
                    class="wysiwyg-font-size-medium">The Bandwidth limit is per account and not per proxy.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Do we provide Username and Password authentication for Trial Proxy?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">No, we do not provide Username and Password authentication for Trial Proxy.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">Username and Password authentication can be enabled for 10 or more Premium proxies only.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Do I always need to manually add the URLs of the websites I would like to visit
            through the proxies?</h4>
          <div class="block__desc"><p>&nbsp;<span class="wysiwyg-font-size-medium">You need not add the URLs of the websites you would like to visit manually.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">&nbsp;You can <a
                    href="https://limeproxies.zendesk.com/hc/en-us/requests/new" target="_blank" target="_blank">submit a request </a>to the support team&nbsp;to&nbsp;allow full web access.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Do you allow access on port 80 or 8080?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-large"><span
                  class="wysiwyg-font-size-medium">Unfortunately,&nbsp;we do not allow access on port 80 and 8080</span>.</span>
          </p>
            <p>&nbsp;</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">IP Address not Valid/ Duplicate IP</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">It means that the IP is being used by another customer.</span>
          </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">IP Authentication Failure</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">You would have to wait after entering your IP in the authorized list.&nbsp; </span>
          </p>
            <p><span class="wysiwyg-font-size-medium">It takes 30 minutes for authentication to work.</span></p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Do you support username based authentication?</h4>
          <div class="block__desc">
            <p>
              <span class="wysiwyg-font-size-medium">Yes, we provide username authentication with minimum 10 Premium proxies </span>
            </p>
          </div>
        </div>

        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How many concurrent connections can I use?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">By </span>default<span
                  class="wysiwyg-font-size-medium">, we allow 50 concurrent connections. </span><span
                  class="wysiwyg-font-size-medium">This can be increased depending on the proxy package. </span>
          </p>
            <p><span class="wysiwyg-font-size-medium">For </span>instance<span class="wysiwyg-font-size-medium">, for 1000 proxies pack, we can allow 1000 connections.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What is the speed of the proxy?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">All proxies are guaranteed to offer a minimum of 500 </span>Mbps<span
                  class="wysiwyg-font-size-medium"> dedicated speed. </span></p>
            <p><span class="wysiwyg-font-size-medium">Our network is connected to a 1GB core which means we will have enough bandwidth to support any application.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Do Limeproxies support SOCKS?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Only premium packages with minimum 10 proxies and above support socks.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">To activate SOCKS - &nbsp;go to Assigned Proxies tab and click on "enable" SOCKS option.</span>
            </p>
            <p><span
                    class="wysiwyg-font-size-medium">The SOCKS information will be sent to your email address.</span>
            </p>
            <p>&nbsp;</p></div>
        </div>

        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How can I Change the proxy location?</h4>
          <div class="block__desc">Please contact support to request for a change in proxy location.</div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Empty list of proxies</h4>
          <div class="block__desc">
            <p class="no-margin">Some users have reported that after downloading the proxies list assigned to their accounts, the list was empty. If you have encountered this issue, please get in touch with us through chat or by email at <a href="mailto:support@limeproxies.com" rel="nofollow noopener noreferrer" target="_blank">support@limeproxies.com</a></p>   
            <p class="no-margin">Please include the following details into your query:</p>
            <ul>
              <li>1. Username</li>
              <li>2. Product Number</li>
              <li>3. Geo locations that you need for your product</li>
            </ul>
            <p class="no-margin">Once the list is updated from our side, you can download it from the Limeproxies <a href="https://app.limeproxies.com/" rel="nofollow noopener noreferrer" target="_blank">Dashboard</a>.</p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Proxy timeout / fail</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">When you're connected to our proxies, your network traffic flows from your computer through one of our servers to your </span>destination
            <span class="wysiwyg-font-size-medium">and the same way back. </span></p>
            <p><span class="wysiwyg-font-size-medium">The distance itself shouldn't significantly affect your connection speed, but network congestion at any point can slow things down. Our servers are never the bottleneck. </span>
            </p>
            <p><span class="wysiwyg-font-size-medium">We monitor their performance and ensure that they have sufficient extra bandwidth and processing power even during peak times.</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">If your connection seems consistently slow, when connecting to different web sites, your ISP might have a slow connection to our server (</span>possibly<span
                    class="wysiwyg-font-size-medium"> intentionally). </span></p>
            <p><span class="wysiwyg-font-size-medium">Try the following options to resolve the problem:</span></p>
            <ul>
              <li><span class="wysiwyg-font-size-medium">Check your Monthly Quota.</span></li>
            </ul>
            <p><span class="wysiwyg-font-size-medium">&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Monthly Quota:&nbsp;500 GB, but additional bandwidth is available on request</span>
            </p>
            <ul>
              <li><span
                      class="wysiwyg-font-size-medium">Test all the proxies to know if just a certain subnet are slow.</span>
              </li>
            </ul>
            <ul>
              <li><span
                      class="wysiwyg-font-size-medium">Check if you are not doing more than 50 concurrent connections.</span>
              </li>
            </ul>
            <ul>
              <li><span class="wysiwyg-font-size-medium">If after </span>all<span class="wysiwyg-font-size-medium">, you keep having a slow connection report the issue through a <a
                      href="/hc/en-us/requests/new">ticket</a> or via <a class="open-chat-window"
                                                                         href="http://limeproxies.com/support-proxy-timeout-fail.php">live chat</a>.</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="faq-block faq-block--answer">
          <h4 class="block__title">Website blocked</h4>
          <div class="block__desc">
            <p class="no-margin">If upon using the proxies you come across an “Access Restricted” error or a blocked website, please be advised that the website you are trying to access has not been added to the allowed list in the user panel.</p>
            <p class="no-margin"></p>
            <p class="no-margin">As a first step to troubleshoot this issue, please ensure you are able to access <a href="https://myipadd.com/" rel="nofollow noopener noreferrer" target="_blank">https://myipadd.com/</a> with your proxy. If you receive an access denied error message on ipchicken.com, this means your IP is not authorized.</p>
            <p class="no-margin"></p>
            <div class="intercom-container intercom-align-center"><img src="https://downloads.intercomcdn.com/i/o/61343882/31e9b6d9c8439bbeac14349b/blobid0.png"></div>
            <p class="no-margin">In this case, you will have to log in to your proxy <a href="https://app.limeproxies.com/" rel="nofollow noopener noreferrer" target="_blank">Dashboard</a> and add your new IPs under the authorized IP link.</p>
            <p class="no-margin"></p>
            <div class="intercom-container intercom-align-center"><a class="intercom-h2b-button" href="https://help.limeproxies.com/technical/faq/how-do-i-handle-a-407-error-message-proxy-authentication-required">AUTHORIZE IP</a></div>
            <p class="no-margin">If you are able to access <a href="http://www.ipchicken.com" rel="nofollow noopener noreferrer" target="_blank">www.ipchicken.com</a>, but unable to access other URLs, that means the website URLs are not added to our allowed list.</p>
            <p class="no-margin"></p>
            <p class="no-margin">Please login to the proxy control panel and add the website URLs that you need to access by clicking on WHITELIST in the Dashboard.</p>
            <p class="no-margin"></p>
            <div class="intercom-container intercom-align-center"><a class="intercom-h2b-button" href="https://help.limeproxies.com/getting-started-for-new-accounts/control-panel-setup-information/how-do-i-addremove-a-url-under-whitelist">WHITELIST </a></div>
            <p class="no-margin"><b>NOTE: </b>Please note that we have certain limits for test proxies. If you are not able to test your trial proxy for your requirement specifically, please contact our Support Team to remove the restrictions. Our Support Team can be reached via chat or by raising a ticket by sending an email to <a href="mailto:support@limeproxies.com" rel="nofollow noopener noreferrer" target="_blank">support@limeproxies.com</a></p>
          </div>
        </div> -->
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Unable to connect</h4>
          <div class="block__desc">
            <p class="intercom-align-left">If you are unable to connect, please follow these steps in order to fix the issue:</p>
            <ol>
              <li>
                <p class="no-margin">1. Set up the proxy correctly in your browser. Refer the below link for setup instructions for browsers and various applications:<br><a target="_blank" href="https://help.limeproxies.com/how-to-use-guides">http://help.limeproxies.com/how-to-use-guides</a></p>
              </li>
              <li>
                <p class="no-margin">2. Add your internet IP source to the Authorized IP's list. Once the IP is added, please wait 30 minutes before using the proxies.</p>
              </li>
            </ol>
            <div class="intercom-container intercom-align-left">
              <a class="intercom-h2b-button" target="_blank" href="https://help.limeproxies.com/getting-started-for-new-accounts/control-panel-setup-information/how-to-add-authorized-ips">AUTHORIZE IP</a>
            </div>
            <p class="intercom-align-left">
              If the issue still persists<a href="mailto:support@limeproxies.com" rel="nofollow noopener noreferrer" target="_blank"> submit a request</a> for technical support for assistance with this issue.
            </p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Proxy authentication required</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">If you already have&nbsp;the list of proxies for your use, but you can't browse any websites at all, follow these troubleshooting steps.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">In order to start using the proxies, you need to add your source IPs from which you will be accessing the proxies, otherwise you will be asked to enter your username and password.</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">To avoid this:</span></p>
            <ol>
              <li><span class="wysiwyg-font-size-medium">&nbsp;Please click on Authorized IPs link and add your IPs to the list.</span>
              </li>
              <li><span class="wysiwyg-font-size-medium">After you add the source IPs wait 30 minutes and try using your proxies again.</span>
              </li>
            </ol>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Website blocked / Access restricted error</h4>
          <div class="block__desc">
            <p class="no-margin">If upon using the proxies you come across an “Access Restricted” error or a blocked website, please be advised that the website you are trying to access has not been added to the allowed list in the user panel.</p>
            <p class="no-margin"></p>
            <p class="no-margin">As a first step to troubleshoot this issue, please ensure you are able to access <a href="https://myipadd.com/" rel="nofollow noopener noreferrer" target="_blank">https://myipadd.com/</a> with your proxy. If you receive an access denied error message on ipchicken.com, this means your IP is not authorized.</p>
            <p class="no-margin"></p>
            <div class="intercom-container intercom-align-center"><img src="https://downloads.intercomcdn.com/i/o/61343882/31e9b6d9c8439bbeac14349b/blobid0.png"></div>
            <p class="no-margin">In this case, you will have to log in to your proxy <a href="https://app.limeproxies.com/" rel="nofollow noopener noreferrer" target="_blank">Dashboard</a> and add your new IPs under the authorized IP link.</p>
            <p class="no-margin"></p>
            <div class="intercom-container intercom-align-center"><a class="intercom-h2b-button" target="_blank" href="https://help.limeproxies.com/technical/faq/how-do-i-handle-a-407-error-message-proxy-authentication-required">AUTHORIZE IP</a></div>
            <p class="no-margin">If you are able to access <a href="http://www.ipchicken.com" rel="nofollow noopener noreferrer" target="_blank">www.ipchicken.com</a>, but unable to access other URLs, that means the website URLs are not added to our allowed list.</p>
            <p class="no-margin"></p>
            <p class="no-margin">Please login to the proxy control panel and add the website URLs that you need to access by clicking on WHITELIST in the Dashboard.</p>
            <p class="no-margin"></p>
            <div class="intercom-container intercom-align-center"><a class="intercom-h2b-button" target="_blank" href="https://help.limeproxies.com/getting-started-for-new-accounts/control-panel-setup-information/how-do-i-addremove-a-url-under-whitelist">WHITELIST </a></div>
            <p class="no-margin"><b>NOTE: </b>Please note that we have certain limits for test proxies. If you are not able to test your trial proxy for your requirement specifically, please contact our Support Team to remove the restrictions. Our Support Team can be reached via chat or by raising a ticket by sending an email to <a href="mailto:support@limeproxies.com" rel="nofollow noopener noreferrer" target="_blank">support@limeproxies.com</a></p>
          </div>
        </div>
        <!-- <div class="faq-block faq-block--answer">
          <h4 class="block__title"></h4>
          <div class="block__desc"></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title"></h4>
          <div class="block__desc"></div>
        </div> -->
      </div>
      <div class="tab__content-block">
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How do I setup auto subscription for my account?</h4>
          <div class="block__desc"><p>There are two ways to set up auto-renewal:</p>
            <ol>
              <li>&nbsp;&nbsp;&nbsp;1. By adding credits in the dashboard in advance for your proxy product.
              </li>
            </ol>
            <ul>
              <li>&nbsp;&nbsp;&nbsp;- &nbsp; Go to “Billing”</li>
            </ul>
            <p>
              <img src="assets/images/download42.jpg" alt="" width="508" height="261">
            </p>
            <p>
              Add the credits you want to in the credits page and click on “Make Payment Now”. For example, if you had
              bought 10 proxies at 50$, you can add 100$ in advance and the product will auto renew.
            </p>
            <ul>
              <li>- Also make sure auto-renewal is enabled on the product</li>
            </ul>
            <p>
              <img src="assets/images/download43.jpg" alt="" width="508" height="223">
            </p>
            <ol>
              <li>
                2. If you would like to setup automatic charge on your credit card each month, please sign up for a new
                product through our website <a href="http://www.limeproxies.com">limeproxies.com</a> and choose monthly
                recurring. For existing customers, this is the only way to setup auto recurring.
              </li>
            </ol>
            <ol>
              <li>
                &nbsp;&nbsp;&nbsp;&bull; &nbsp; Select the Package from our website.
              </li>
            </ol>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How can I access my Test Account?</h4>
          <div class="block__desc">
            <ul>
              <li>
                &nbsp;&nbsp;&nbsp;- &nbsp; Once logged in, go to “Products”, the test proxy will be added there
              </li>
            </ul>
            <p><img src="assets/images/download44.jpg"></p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Can I buy proxies through Biticon?</h4>
          <div class="block__desc">
            <p>Yes, we accept payments through Bitcon</p>
            <ul>
              <li>
                &nbsp;&nbsp;&nbsp;- &nbsp; Click on “Billing” and select “Bitcoin”, select the amount needed and click
                on “Make Payment Now”
              </li>
            </ul>
            <p><img src="assets/images/download45.jpg"></p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How do I reactivate my suspended account?</h4>
          <div class="block__desc">
            <p class="intercom-align-left">In case your account has been suspended, please <a href="https://limeproxies.zendesk.com/hc/en-us/requests/new" rel="nofollow noopener noreferrer" target="_blank"><b>submit a request</b> </a>to our support team and you will be assisted further.</p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">My Credit Card was charged, but the transaction shows incomplete.</h4>
          <div class="block__desc">
            <p class="intercom-align-left">If you have made a purchase with us and your credit card has been charged, but the transaction is displayed as “Incomplete”, your payment could be held for verification. Please wait for 24 hours and check if your account has been activated.</p>
            <p class="intercom-align-left">If you have made the payment with a different email address, you need to confirm the payment from that email address. Please note that the payment will be automatically refunded to your account if the verification fails.</p>
            <p class="intercom-align-left">In case your payment is rejected, we would advise you to place your order using a different <a href="http://limeproxies.com/payment.php" rel="nofollow noopener noreferrer" target="_blank"><b>payment gateway</b></a>.</p>
            <p class="intercom-align-left">In case you have issues with placing your order, please contact our <a href="https://limeproxies.zendesk.com/hc/en-us/requests/new" rel="nofollow noopener noreferrer" target="_blank"><b>Support Team</b></a> for further assistance.</p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Where do I find my login information after ordering proxies?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Your Login information will be sent to your registered email address.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">Please ensure our email did not end up in your spam folder.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Why am I not able to make the payment?</h4>
          <div class="block__desc">
            <p class="intercom-align-left">If you are unable to make your payment, the issue might be due to the gateway that doesn’t support the payment method. Please try submitting your payment using a different gateway available on our website. The available payment methods can be found <a href="http://www.limeproxies.com/payment.php" rel="nofollow noopener noreferrer" target="_blank">here</a>.</p>
            <p class="intercom-align-left">Please make sure that you are not using a VPN connection or Proxy server to process the payment as the payment gateways have high-security standards.<br><br>If you are still unable to submit your payment, please <a href="https://limeproxies.zendesk.com/hc/en-us/requests/new" rel="nofollow noopener noreferrer" target="_blank"><b>submit a request</b></a><b> </b>to our Support Team for further assistance.<br></p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Are there any contracts?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">All accounts are setup for a minimum of 30 days unless specified.</span>
          </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How do I cancel my subscription?</h4>
          <div class="block__desc"><p>Our proxies are set to auto cancel unless renewed. We do not auto renew the
            accounts.</p>
            <p>If in case you get charged for the subscription even after cancellation then, please <a
                    href="https://limeproxies.zendesk.com/hc/en-us/requests/new" target="_blank">submit a request</a><span
                    class="wysiwyg-font-size-large">&nbsp;</span>and we will stop the auto subscription for you.</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What is your money back guarantee?</h4>
          <div class="block__desc">
            <ul>
              <li><span class="wysiwyg-font-size-medium">Limeproxies offer three&nbsp;days money back guarantee.</span>
              </li>
            </ul>
            <ul>
              <li><span class="wysiwyg-font-size-medium">In the event of network failure or service failure after the three-day guarantee period, LimeProxies will only provide credits to the customers, which can be redeemed for other services we offer.</span>
              </li>
            </ul>
            <ul>
              <li><span class="wysiwyg-font-size-medium">It may take up to three business days to process refunds via credit card.</span>
              </li>
            </ul>
            <ul>
              <li><span class="wysiwyg-font-size-medium">For bank wire / offline payment methods, the refund may take up to ten business days.</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What forms of payment are accepted?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Limeproxies ensure that every customer is able to make the payment without any trouble. Limeproxies accept payments made through Visa, Master Card, American Express, Discover, PayPal, Wire Transfer, Western Union and Bitcoin.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">We only accept bank wire for payments above 2000$.</span></p>
            <p>&nbsp;</p></div>
        </div>
      </div>
      <div class="tab__content-block">
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Are there any service restrictions?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We do not allow the following software/usage:</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">Proxies cannot be used for e-mailing, spam, abuse; accessing e-commerce sites such PayPal, eBay.&nbsp;</span><span
                    class="wysiwyg-font-size-medium">Access to mailing sites will be blocked. </span></p>
            <p><span class="wysiwyg-font-size-medium">Scrapebox for Spam, </span>Xrumer<span
                    class="wysiwyg-font-size-medium"> and/or any forum commenting software usage is not allowed.</span></p>
            <p><span class="wysiwyg-font-size-medium">Access to ALL website URLs: We only allow access to </span>the&nbsp;<span
                    class="wysiwyg-font-size-medium">list of website addresses you request. Any new addresses must be added to the whitelist before being able to access the site. This is being followed to avoid SPAM and network abuse.</span>
            </p>
            <p><span class="wysiwyg-font-size-medium">Sneakers sites, nike sites and similars are not allowed. Pokemon sites as well are not allowed. No replacement or refunds are allowed for it.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Can I ask for a trial proxy for a particular location?</h4>
          <div class="block__desc">
            <p class="no-margin">All our trial proxies are assigned randomly. However, if you require testing a proxy from a particular region, please contact our Support Team via Live Chat or by raising a ticket by sending an email to <a href="mailto:support@limeproxies.com" rel="nofollow noopener noreferrer" target="_blank">support@limeproxies.com</a>.</p>
            <p class="no-margin"></p>
            <p class="no-margin">You can also create a ticket from Limeproxies <a href="https://app.limeproxies.com/#/support/faq" rel="nofollow noopener noreferrer" target="_blank">Dashboard</a>.</p>
            <p class="no-margin"></p>
            <div class="intercom-container intercom-align-center"><img src="https://limeproxies-bf07671a074d.intercom-attachments-7.com/i/o/140939128/93503ca614ae3640a4bad782/YzMLxF5wQmOFFoU8JInHCSBjs97euEwRu2n-6goe1L9X-wkXE31M5LhsBicKrcmYI1Y4Znk2sicu27vlw2nY9lQ5Ls5fefZbIBggISg7DW4K1wHqih0tO54DYtaOK7saFrPVsCCf"></div>
            <br />
            <div class="intercom-container intercom-align-center"><img src="https://limeproxies-bf07671a074d.intercom-attachments-7.com/i/o/140939130/226952bbbb13ef8faaccad9b/VrDUea8nIU0l1BqYETnRYa1KAo2Xz04nsX3bvfrnimCdzlugK79CI_BmHddJ4Ngr8U2K11xHdfGTA8b8Kcesn31ezIcXKeu_GoPR2WIoDNfNujSEpQfqoBt3f-CM8cLH9yyVucLi"></div>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Do our Proxies support Nike.com?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We&nbsp;do not provide proxies that support Nike.com.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">Proxies purchased for Nike.com are not eligible for a refund or replacement.</span>
            </p>
            <p>&nbsp;</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What is a subnet?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">A subnet is an </span>identifiable,<span
                  class="wysiwyg-font-size-medium"> separate part of an organization's network. Typically, a subnet may represent all the machines at one geographic location.</span>
          </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Where can I set up black list of websites?</h4>
          <div class="block__desc">
            <p class="intercom-align-left">We allow all URLs for websites on request.</p>
            
            <p class="intercom-align-left">Please <a href="https://limeproxies.zendesk.com/hc/en-us/requests/new" rel="nofollow noopener noreferrer" target="_blank">contact support</a> with your request.</p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What is the Response Time of your proxies?</h4>
          <div class="block__desc"><p><span
                  class="wysiwyg-font-size-medium">Our response time is under 100MS.&nbsp; </span></p>
            <p><span class="wysiwyg-font-size-medium">You will not receive any performance issue with us.</span></p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Required Geo location is not available.</h4>
          <div class="block__desc">
            <p class="intercom-align-left">We can provide custom setup for a location of your choice at a nominal fee.</p>
            <p class="intercom-align-left">Please <a href="http://limeproxies.com/custom.php" rel="nofollow noopener noreferrer" target="_blank">submit a request </a>to our support team with your desired geolocation.</p>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How often can the proxies be refreshed?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We provide IP refresh once a month.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">Please&nbsp;<a
                    href="https://limeproxies.zendesk.com/hc/en-us/requests/new" target="_blank">contact support</a>&nbsp;with your&nbsp;request for the same.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What do I need to do if I want to reinstate the proxies?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-large"><span class="wysiwyg-font-size-medium">You will have to first add credits and then give us the list of proxies that have expired&nbsp;to<strong>&nbsp;</strong>reinstate <span
                  class="wysiwyg-font-size-medium">t</span>hem.</span></span></p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Is it possible to change the IP addresses of the proxies?</h4>
          <div class="block__desc"><p>Yes,<span class="wysiwyg-font-size-medium"> it is possible to replace the IP addresses of the proxies with your desired Geo Locations.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">Please place a&nbsp;<a
                    href="https://limeproxies.zendesk.com/hc/en-us/requests/new" target="_blank">support</a>&nbsp;request to change the IP addresses of your proxies.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How do I know if my proxies are blocked from webmail?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">You will be unable to login to your email account despite having the correct login details. </span>
          </p>
            <p><span class="wysiwyg-font-size-medium">Before switching your IP we will manually verify that they are indeed being blocked as often people mistake a bot error or banned email account as proxies being blocked.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Can I upgrade proxies anytime?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">The upgrade can be done at any time. You can keep the existing proxies, we will just add additional new proxies. </span>
          </p>
            <p><span class="wysiwyg-font-size-medium">If you wish to get all new proxies, let us know. </span></p>
            <p><span class="wysiwyg-font-size-medium">Please <a
                    href="https://limeproxies.zendesk.com/hc/en-us/requests/new" target="_blank">contact support</a>&nbsp;for an upgrade request.&nbsp;</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What is the thread limit?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We offer up to 500 simultaneous (threads) connections per proxy. </span>
          </p>
            <p><span class="wysiwyg-font-size-large"><span class="wysiwyg-font-size-medium">However, this can be increased upon request.</span></span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Can the proxies be used with Craigslist?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We cannot offer a guarantee for Craigslist, the rate of successful usage varies due to its dynamic environment. </span>
          </p>
            <p><span
                    class="wysiwyg-font-size-medium">In this case, we recommend a 10 proxies package for testing. </span></p>
            <p><span class="wysiwyg-font-size-medium">A full refund can be requested within 3 days if they don't work for the intended purpose. </span>
            </p>
            <p><span class="wysiwyg-font-size-medium">Be aware that RDNS look ups don't always match the server location and we can't guarantee it will show the same location with Craiglist.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Can I use your proxies with any browser?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Limeproxies can be used&nbsp;with almost every application that supports HTTP/HTTPS proxies. </span>
          </p>
            <p><span class="wysiwyg-font-size-large"><span class="wysiwyg-font-size-medium">If you require any assistance on how to setup your proxies on your browser please visit&nbsp;<a
                    href="https://help.limeproxies.com/how-to-use-guides">How to Use Guides</a>&nbsp;section from our FAQ for tutorials.</span></span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Can I use your proxies if I have a dynamic IP?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We offer both authentication methods; IP and&nbsp;username/password so you will not have to worry about your IP changing. </span>
          </p>
            <p><span class="wysiwyg-font-size-medium">Please <a
                    href="https://limeproxies.zendesk.com/hc/en-us/requests/new" target="_blank">contact support</a>&nbsp;to request username authentication for dynamic IP.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Why do you offer so many IP addresses?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">The ability to switch to a new IP address gives you finer grain control over your web identity: you can start a fresh, independent browsing session </span>anytime<span
                  class="wysiwyg-font-size-medium"> you like. </span></p>
            <p><span class="wysiwyg-font-size-medium">It also makes our service more reliable: if one IP is down, just connect through another.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What type of proxies do you provide?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We provide HTTP/HTTPS/SOCKS proxies.</span>
          </p>
            <p><span
                    class="wysiwyg-font-size-medium">These HTTP/HTTPS proxies run on high-end dedicated proxy servers.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What is the difference between shared and dedicated proxies?</h4>
          <div class="block__desc">
            <div>
              <p><span class="Apple-style-span wysiwyg-font-size-medium"><span class="style3">Shared proxies are simultaneously used by multiple clients. </span></span>
              </p>
              <p><span class="Apple-style-span wysiwyg-font-size-medium"><span class="style3">Dedicated proxies are only accessible by you, while you maintain your subscription. </span></span>
              </p>
              <p><span class="Apple-style-span wysiwyg-font-size-medium"><span class="style3">However, at this point, we only offer dedicated proxies.</span></span>
              </p>
            </div>
            <p><span class="wysiwyg-font-size-large">&nbsp;</span></p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">What does Exclusive Proxy mean?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Exclusive Proxies mean that you are the only one who can use your IPs.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">We guarantee that we do not share your exclusive IPs with any other customer!</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How long is the setup time?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Accounts are setup instantly on payment. With some cases, if our software detects unusual triggers, your order might be flagged for manual verification and are typically setup within 24 hours, Monday through Saturday. </span>
          </p>
            <p><span class="wysiwyg-font-size-medium">When your account is ready, your proxies will be delivered via email.</span>
            </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">How exactly does LimeProxies.com protect my privacy?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Normally, when you browse a website, the website can access your IP address (the website needs it to deliver the </span>web
            page<span class="wysiwyg-font-size-medium">) and it is usually logged for tracking purposes.</span></p>
            <p><span class="wysiwyg-font-size-medium">Your IP address is often assigned uniquely to your computer by your ISP, making browsing non-anonymous in the sense that your activities under your IP may be linked to your computer.</span>
            </p>
            <p>When browsing through LimeProxies.com our servers make the requests and forward the contents to you.
              Therefore, the<span
                      class="wysiwyg-font-size-medium"> external websites see our IP address and not yours. </span></p>
            <p><span class="wysiwyg-font-size-medium">As our IP addresses are public and shared, your browsing is effectively anonymized.</span>
            </p>
            <p>Be advised LimeProxies.com &nbsp;does not protect you from unintentional leak of your IP address or other
              identifiable information through malicious scripts or embedded objects.</p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Do you block access to any specific websites?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We attempt to allow access to all websites. Unfortunately, we have to enforce some restrictions on a small set of websites.</span>
          </p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Do you refresh proxies?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">We support allocating new IP addresses to your account at the beginning of each billing cycle. </span>
          </p>
            <p><span class="wysiwyg-font-size-medium">You only get new IPs if you request them; </span>otherwise<span
                    class="wysiwyg-font-size-medium">, you keep your existing IPs.</span></p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Can I use the proxies on Classified ad sites?</h4>
          <div class="block__desc">
            <p class="no-margin">Absolutely, we do not place any restrictions on classified Ad websites. Our Premium Proxies work well for the Classified Ad purpose. However, we always recommend going for the trial before the purchase of the proxies to make sure it works for your purpose.</p>
            <p class="no-margin"></p>
            <div class="intercom-container intercom-align-center"><a class="intercom-h2b-button" href="https://help.limeproxies.com/en/articles/1696514-how-can-i-get-a-free-test-proxy">How can I get a free test proxy?</a></div>
            <p class="no-margin"></p>
            <p class="no-margin"><b>Please note: </b>You will need to order classified Ad proxies to use these on Craigslist.</p>
            <p class="no-margin"><a href="https://www.limeproxies.com/pricing" rel="nofollow noopener noreferrer" target="_blank">Click</a> to purchase Premium proxies that can be used for Ad posting</p>
            <p class="no-margin"></p>
            <div class="intercom-container intercom-align-center"><a class="intercom-h2b-button" target="_blank" href="https://www.limeproxies.com/pricing" rel="nofollow noopener noreferrer">Premium Proxies</a></div>
          </div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Can I switch IP addresses?</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Yes, once per month, upon remittance of your renewal payment, you are allowed to switch your proxies.&nbsp;</span>
          </p>
            <p><span class="wysiwyg-font-size-medium">This&nbsp;can be done only on request.</span></p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">Ad does not go live on classified ad sites error</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">The most common reason is because you are posting in a location which is different from the proxy location. Please ensure you post in the location of the proxies. </span>
          </p>
            <p><span class="wysiwyg-font-size-medium">There could also be other reasons which </span>include<span
                    class="wysiwyg-font-size-medium"> PVAs, Ad content etc. We suggest you try in a </span>different<span
                    class="wysiwyg-font-size-medium"> location, section or with different PVAs. </span></p>
            <p><span class="wysiwyg-font-size-medium">If you continue to experience issues, please <a
                    href="https://limeproxies.zendesk.com/hc/en-us/requests/new" target="_blank">contact</a> us.</span></p></div>
        </div>
        <div class="faq-block faq-block--answer">
          <h4 class="block__title">My proxy location is different from what is shown on the site</h4>
          <div class="block__desc"><p><span class="wysiwyg-font-size-medium">Despite the complexity and scientific approach to </span>IP-based<span
                  class="wysiwyg-font-size-medium"> geolocation identification, there are </span>well-known<span
                  class="wysiwyg-font-size-medium"> limitations and inaccuracies with the current methodology. While geolocation data is usually extremely accurate down to the state or city level, as services demand more granular data, many of the current geolocation services start to break down. The loss in overall coverage is quite small, but accuracy can be another story.</span>
          </p>
            <p><span class="wysiwyg-font-size-medium"><strong>Server Location vs. Machine Location<br><br></strong>The IP address cannot be technically mapped to </span>an
              accurate<span class="wysiwyg-font-size-medium"> location. The reason is that each time we query IP whois, the location&nbsp;</span>shown
              is<span class="wysiwyg-font-size-medium">&nbsp;from a database. Depending on which database provider is used, the location data may change. For instance, if Google shows the IP to be from </span>Sweden<span
                      class="wysiwyg-font-size-medium">, Yahoo could show it to be </span>Australia<span
                      class="wysiwyg-font-size-medium">. </span>However<span class="wysiwyg-font-size-medium">, this happens only for about 20% of IP addresses which is due to using of outdated IP G</span>eo-databases<span
                      class="wysiwyg-font-size-medium">.</span></p>
            <p><span class="wysiwyg-font-size-medium"><strong>Source Data Corruption</strong></span><span
                    class="wysiwyg-font-size-medium">&nbsp;</span></p>
            <p><span class="wysiwyg-font-size-medium">The WHOIS directly also has some notable downsides – registrants aren’t forced to keep their information current with their domain registrar, so the information can quickly lose accuracy as businesses move, open new locations, or even sell to a different owner.</span>
            </p></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sidebar">
  <help-sidebar></help-sidebar>
</div>