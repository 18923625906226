import {Component, AfterViewInit, OnInit, ViewChild, ElementRef, OnDestroy, NgZone, HostListener} from '@angular/core';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { DialogService } from '../../services/dialog.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { interval, Subscription, fromEvent } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
declare const $: any;
declare var analytics: any;

@Component({
    selector: '.page__header',
    templateUrl: '../../templates/main-frame/dashboard-header.template.html',
    styleUrls: ['../../styles/dashboard-header.css']
})
export class DashboardHeaderComponent implements AfterViewInit, OnInit, OnDestroy {

    private errorMessage;
    private warning;
    private error;
    private onMobile = false;
    private info;
    private globalClick: Subscription[] = [];
	role = '';

    private messageToUser: string;
    @ViewChild('notification', { static: true }) notification: ElementRef;
    @ViewChild('userDropdown', { static: true }) userDropdown: ElementRef;
    public showNotification = false;
    public showProfile = false;

    constructor(public limeProxiesService: LimeProxiesService,
                private router: Router,
                private dialogService: DialogService,
                private zone: NgZone,
                public userService: UserService) {
    }

    ngOnDestroy() {
        this.globalClick.forEach(row => row.unsubscribe());
    }

    openNotification(notify) {
        if (notify.id) {
            this.limeProxiesService.getNotification(notify.id).subscribe((data) => {
                this.dialogService.confirm('Notification', data.message, {
                    isConfirm: false,
                    withEnter: true,
                    style: {width: 900 + 'px', height: 410 + 'px'}
                });
                this.removeNotification(notify, null);
            });
        } else if (notify.link) {
            this.showNotification = false;
            this.router.navigateByUrl(`/${notify.link}`);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.matchMedia('(max-width: 768px)').matches) {
            if (!this.onMobile) {
                this.onMobile = true;
                if (typeof window['Canny'] !== 'undefined') {
                    window['Canny']('initChangelog', {
                        appID: '5c06b941b253a93667128270',
                        position: 'bottom',
                        align: 'left',
                    });
                }
            }
        } else {
            if (this.onMobile) {
                this.onMobile = false;
                if (typeof window['Canny'] !== 'undefined') {
                    window['Canny']('initChangelog', {
                        appID: '5c06b941b253a93667128270',
                        position: 'bottom',
                        align: 'right',
                    });
                }
            }
        }
    }

    getDiff(date) {
        let diff;
        const start = moment(new Date());
        const end = moment(date);
        if (diff = start.diff(end, 'weeks')) {
          return `${diff} week(s) ago`;
        } else if (diff = start.diff(end, 'days')) {
            return `${diff} days(s) ago`;
        } else if (diff = start.diff(end, 'hours')) {
            return `${diff} hour(s) ago`;
        } else if (diff = start.diff(end, 'minutes')) {
            return `${diff} minute(s) ago`;
        } else if (diff = start.diff(end, 'seconds')) {
            return `${diff} second(s) ago`;
        }
    }

    ngAfterViewInit() {
        this.globalClick[0] = fromEvent(document, 'click')
            .subscribe((event: MouseEvent) => {
                this.zone.run(() => {
                    if (!this.notification.nativeElement.contains(event.target)) {
                        this.showNotification = false;
                    }
                    if (!this.userDropdown.nativeElement.contains(event.target)) {
                       // console.log('globalClick', 'showProfile');
                        this.showProfile = false;
                    }
                });

            });
        $('.page__header .hamburger').on('click', (e) => {
            e.preventDefault();
            $('body').toggleClass('dashboard--show');
        });
        if (typeof window['Canny'] !== 'undefined') {
            window['Canny']('initChangelog', {
                appID: '5c06b941b253a93667128270',
                position: 'bottom',
                align: 'right',
            });
        }
    }

    ngOnInit() {
	  let curObject = this;

		this.userService.getUser().subscribe(user => {
			curObject.role = user.role
		});
		
      if (this.limeProxiesService.messageToDisplay.value.message !== 'Nothing to display') {
        this.limeProxiesService.notifyBarNew(
          this.limeProxiesService.messageToDisplay.value.message,
          this.limeProxiesService.messageToDisplay.value.success ? 'success' : 'error'
        );
        this.limeProxiesService.messageToDisplay.next({
          message: 'Nothing to display',
          success: false
        });

      }
      this.setUserNotifications();
      this.globalClick[1] = this.limeProxiesService._updateUserNotifications.subscribe(() => {
          // this.zone.run(() => {
              this.setUserNotifications();
          // });
      });

      // interval(10000).subscribe((val) => {
        // this.setUserNotifications();
      // });
    }

    setUserNotifications() {
        this.limeProxiesService.getUserNotification()
            .subscribe(
              success => {
                  // this.zone.run(() => {
                      this.limeProxiesService.userNotifications = success;
                     // console.log(this.limeProxiesService.userNotifications , 'this.limeProxiesService.userNotifications');
                  // });
                },
                error => this.errorMessage = error
            );
    }

    getTypeColor(type) {
        let color, background;
        switch (type) {
            case 'info':
                background = '#aaece3';
                color = '#000';
                break;
            case 'warning':
                background = '#E67E22';
                color = '#fff';
                break;
            case 'error':
                background = '#d83333';
                color = '#fff';
                break;
            case 'success':
                background = '#58ad30';
                color = '#fff';
                break;
            default:
                background = '#888';
                color = '#fff';
        }
        return {background, color};
    }

    removeNotification(notif, event: Event) {
        if (event) {
            event.stopPropagation();
        }
        if (notif.id) {
            this.limeProxiesService.deleteUserNotification(notif.id).subscribe((done) => {
            }, (error) => {
                this.limeProxiesService.notifyBarNew(error, 'error');
            });
        }
        this.limeProxiesService.userNotifications.notifications = this.limeProxiesService.userNotifications.notifications.filter((value) => value !== notif);
    }

    logOut() {
        this.showProfile = false;
        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
            analytics.track('signout_success', {});
            analytics.reset();
        }
        this.limeProxiesService.logout(true);
    }

    showProfileMethod() {
        this.showProfile = !this.showProfile;
    }
}
