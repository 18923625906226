import { Component, HostBinding } from '@angular/core';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyValidators } from '../../custom-validators/custom.validator';
declare var analytics: any;

@Component({
    selector: 'forgot-password',
    templateUrl: '../../templates/login/forgot-password.template.html'
})

export class ForgotPasswordComponent {

    @HostBinding('class') myClass = 'page__content-inner';
    public successMessage: string;
    public errorMessage: string;
    public redirectMessage: string;
    public forgotPasswordForm: FormGroup;
    public email;
    private onRequest: any = false;

    constructor(private router: Router, private limeProxiesService: LimeProxiesService, private fb: FormBuilder) {
        this.forgotPasswordForm = fb.group({
            'email': ['', Validators.compose([MyValidators.validateEmail(), Validators.required])]
        });
    }

    resetMyPassword() {
        if (!this.onRequest) {
            this.onRequest = true;
            this.limeProxiesService.showApiLoader(true);
            this.limeProxiesService.userPasswordRecover(this.email)
                .subscribe(
                    message => {
                        if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
                            analytics.track('forgot_password_recovered', {});
                        }
                        this.limeProxiesService.showMessage(message);
                        this.redirectUserToLoginPage(this.router);
                    },
                    error => {
                        this.limeProxiesService.showMessage(error);
                        this.redirectUserToLoginPage(this.router);
                    }
                );
        } else {
            this.limeProxiesService.notifyBarNew('Please wait for recover response.', 'error');
        }
    }

    redirectUserToLoginPage(router) {
        setTimeout(() => {
            this.limeProxiesService.showApiLoader(false);
            this.onRequest = false;
            router.navigate(['/login']);
        }, 5000);

    }

}