import {Component, HostBinding, OnInit} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';
import {Message} from '../../entities/message';
import {Router, ActivatedRoute} from '@angular/router';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'password-recover',
  templateUrl: '../../templates/login/password-recover.template.html'
})

export class PasswordRecoverComponent implements OnInit {

  @HostBinding('class') myClass = 'page__content-inner';
  public successMessage: string;
  public errorMessage: string;
  public redirectMessage: string;

  public token: string;
  public autoLogin: number = 0;
  public page: string = '';
  private subscribtionObject: any;

  constructor(private router: Router, private route: ActivatedRoute, private limeProxiesService: LimeProxiesService) {
    this.token = '';
  }

  ngOnInit() {
    
    this.subscribtionObject = this.route.params
        .pipe(
            switchMap((params) => {
              this.token = params['token'];
              this.autoLogin = (params['autoLogin'] !== undefined) ? params['autoLogin'] : 0;
              this.page = (params['page'] !== undefined) ? params['page'] : '';
              
              return this.limeProxiesService.loginWithToken(this.token);
            })
        ).subscribe(
      (creds) => {
        if (this.autoLogin > 0) {
          if (this.page != '') {
            this.router.navigate(['../products/new'])
          } else {
            this.router.navigate(['../home']);
          }
        } else {
          this.router.navigate(['../login/set-password']);
        }
      },
      (error) => {
        this.limeProxiesService.messageToDisplay.next(new Message('Unauthorized', false));
        this.router.navigate(['../']);
      }
    );
  }

  redirectUserToPage(router, page) {
    setTimeout(() => {
      router.navigate([page]);
    }, 3000);

  }

}
