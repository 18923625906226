<div class="breadcrumbs">
    <a [routerLink]="['/account/my-account']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">My Account</a>
    <!--<span class="separator separator&#45;&#45;bullet"></span>-->
    <a *ngIf="user_auth_method == 'user'" routerLinkActive="breadcrumbs__item--active" [routerLink]="['/account/auth-user']" class="breadcrumbs__item">Authorized User</a>
    <a *ngIf="user_auth_method == 'ip'" routerLinkActive="breadcrumbs__item--active" [routerLink]="['/account/auth-ip']" class="breadcrumbs__item">Authorized IPs</a>
    <!--<span *ngIf="user_access_type == 'whitelist'" class="separator separator&#45;&#45;bullet"></span>-->
    <a *ngIf="user_access_type == 'whitelist'" routerLinkActive="breadcrumbs__item--active" [routerLink]="['/account/user-whitelist']" class="breadcrumbs__item">User Whitelist</a>
    <a *ngIf="limeProxiesService.environment.show_teams && (userService.currentUser.role == 'Admin' || userService.currentUser.role == 'Owner' )" [routerLink]="['/account/my-team']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">Users</a>
    <a [routerLink]="['/my-profile-details']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">My Profile</a>
    <a [routerLink]="['/my-profile/notifications']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">Notifications</a>
    <a [routerLink]="['/my-profile/authentication']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">Change Password</a>
</div>
<form action="#" method="post" class="block page_block_inner">
    <h4 class="block__title">Your whitelist</h4>
    <p class="block__desc">Add a site to the list only if you receive a site blocked error - the sites included in the whitelists (both your personal and a generic one) will be allowed.</p>
    <div class="whitelist-grid">
        <div *ngIf="isWhitelistEmpty == 'whitelistIsNotEmpty'" class="row">
            <div class="col-xxs col-xs-6 col-sm-4" *ngFor="let item of tmpWhitelist">
                <div class="whitelist-grid__item btn" [style.background-color]="item.currentColor">
                    <span [attr.title]="item" class="whitelist-grid__label">{{item.key}}</span>
                    <button class="btn btn--remove" (click)="removeItem(item)">
                        <svg><use xlink:href="#close" /></svg>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="isWhitelistEmpty == 'whitelistIsEmpty'" class="row">
            <div class="col-xxs col-xs-6 col-sm-5">
                <div class="whitelist-grid__item btn">
                    <span class="whitelist-grid__label">No whitelisted sites configured yet</span>
                </div>
            </div>
        </div>
    </div>
    <div [innerHTML]="errorMessage"></div>
    <div class="row">
        <div class="col-xxs col-xs-6 input-wrapper">
            <input type="text" class="input-field" name="whitelist" value="google.com" #address>
        </div>
        <div class="col-xxs col-xs-6 col-lg-4 input-wrapper">
            <button type="submit" class="btn btn--full" (click)="addToWhiteList(address.value)">Add to List</button>
        </div>
    </div>
    <div class="row">
        <div class="col-xxs col-xs-6 col-lg-4 input-wrapper">
            <button type="submit" class="btn btn--full" (click)="updateWhitelist()">Update</button>
        </div>
    </div>
</form>
<div class="sidebar big-sidebar"><account-sidebar></account-sidebar></div>
