import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {LimeProxiesService} from '../../services/limeproxies.service';

@Component({
    templateUrl: '../../templates/profile/profile.template.html'
})

export class ProfileComponent implements OnInit {

    @HostBinding('class') homeClass = 'page__content-inner';

    public pass_change = false;
    public child_info : string;
    public profile: any;


    constructor(public limeProxiesService: LimeProxiesService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        limeProxiesService.setTitle('Profile');
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
            this.pass_change = (typeof params['r'] !== 'undefined');
        });
        this.limeProxiesService.getUserProfile()
            .subscribe(
                profile => {
                    this.profile = profile;
                    // this.limeProxiesService.codeVerificationInputActive = !profile.verified && profile.phone_code;
                    // this.userProfile = profile;
                    // this.emailForm.get('email').patchValue(profile.email);
                    // this.phone_cc = this.limeProxiesService.getPhoneCC(profile.country);
                    // this.userProfile.phone = this.userProfile.phone.replace(this.phone_cc, '');
                    // this.selected_country = profile.country;
                    // this.hasPhone = this.userProfile == '';
                    //
                    // if (this.iti) {
                    //     this.iti.setNumber((this.userProfile.phone.indexOf(this.phone_cc) !== -1) ? this.userProfile.phone : this.phone_cc + '' + this.userProfile.phone);
                    //     this.valid_phone = true;
                    // }
                }
            );
    }

    hide_pass_message($event) {
        this.pass_change = !($event === '1');
    }
}
