<div class="main__tabs main_tabs">
    <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link" [class.active]="onLoadTab && onLoadTab === 'payment'" (click)="changeTab('payment')" id="nav-payment-tab" data-toggle="tab" href="#nav-payment" role="tab" aria-controls="nav-payment" aria-selected="false">Add Payment</a>
            <a class="nav-item nav-link" [class.active]="onLoadTab && onLoadTab === 'subscriptions'" (click)="changeTab('subscriptions')" id="nav-subscriptions-tab" data-toggle="tab" href="#nav-subscriptions" role="tab" aria-controls="nav-subscriptions" aria-selected="false">Manage Subscriptions</a>
            <a *ngIf="configuration.chargebee.enabled" class="nav-item nav-link" [class.active]="onLoadTab && onLoadTab === 'update'" (click)="changeTab('update')" id="nav-update-tab" data-toggle="tab" href="#nav-update" role="tab" aria-controls="nav-update" aria-selected="true">Manage Card</a>
            <a class="nav-item nav-link" [class.active]="onLoadTab && onLoadTab === 'history'" (click)="changeTab('history')" id="nav-history-tab" data-toggle="tab" href="#nav-history" role="tab" aria-controls="nav-history" aria-selected="false">History</a>
        </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade {{onLoadTab && onLoadTab === 'payment' ? 'show active' : ''}}"  id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
            <div class="add">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12 mb-4">
                        <div class="border">
                            <div class="add__title">
                                <h3 class="title">Add Credits to your account</h3>
                            </div>
                            <div class="add__content add_content">
                                <p style="max-width: 100%">This is a one-time payment that will not recur. Payment may take up to 5 minutes to process.</p>
                                <h4 class="title">Choose amount</h4>
                                <div class="add_content__amount add_amount">
                                    <ul class="p-0 m-0 d-flex flex-wrap">
                                        <li *ngFor="let coast_amount of coast">
                                            <a *ngIf="coast_amount.amount" (click)="setCoast($event, coast_amount.amount)">{{coast_amount.label}}</a>
                                            <input placeholder="Other" type="number" title="" [(ngModel)]="customAmount" (click)="setCoast($event, customAmount)" (change)="setCoast($event, customAmount)" *ngIf="!coast_amount.amount">
                                        </li>
                                    </ul>
                                </div>
                                <h4 class="title">Choose Payment Method</h4>
                                <div class="add_content__method add_method">
                                    <nav>
                                        <div class="nav nav-tabs d-flex flex-wrap" id="nav-tab2" role="tablist">
                                            <a class="nav-item nav-link" id="nav-card-tab" data-toggle="tab" href="#nav-card" role="tab" aria-controls="nav-card" aria-selected="true" *ngIf="configuration.chargebee.enabled">
                                                        <span>
                                                            <img src="assets/img/logo_card_grey.svg" alt="Logo"/>
                                                            <img src="assets/img/logo_card_color.svg" alt="Logo"/>
                                                        </span>
                                                Credit card
                                            </a>
                                            <a class="nav-item nav-link active" id="nav-bitcoin-tab" data-toggle="tab" href="#nav-fastspring" role="tab" aria-controls="nav-fastspring" aria-selected="false" >
                                                <span>
                                                    <img src="assets/images/payment/fastspring.png" alt="Logo"/>
                                                    <img src="assets/images/payment/fastspring.png" alt="Logo"/>
                                                </span>
                                                Credit Card / Bank
                                            </a>
                                            <a class="nav-item nav-link" id="nav-paypal-tab" data-toggle="tab" href="#nav-paypal" role="tab" aria-controls="nav-paypal" aria-selected="false">
                                                        <span>
                                                            <img src="assets/img/logo_paypal_grey.svg" alt="Logo"/>
                                                            <img src="assets/img/logo_paypal_color.svg" alt="Logo"/>
                                                        </span>
                                                PayPal
                                            </a>
                                            <a class="nav-item nav-link" id="nav-bitcoin-tab" data-toggle="tab" href="#nav-bitcoin" role="tab" aria-controls="nav-bitcoin" aria-selected="false">
                                                        <span>
                                                            <img src="assets/img/logo_bitcoin_grey.svg" alt="Logo"/>
                                                            <img src="assets/img/logo_bitcoin_color.svg" alt="Logo"/>
                                                        </span>
                                                Crypto Currency 
                                            </a>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent2">
                                        <div class="tab-pane fade show" id="nav-card" role="tabpanel" aria-labelledby="nav-card-tab" *ngIf="configuration.chargebee.enabled">
                                            <div class="saved_cards" [class.hidden]="!cards || cards?.length === 0">
                                                <div class="saved_cards__title d-flex justify-content-between align-items-center" (click)="setActiveCards($event, 'saved', 'new')">
                                                    <div class="check">
                                                        <span></span>
                                                        <h3 class="title">Saved Cards</h3>
                                                    </div>
                                                    <img src="assets/img/icon_arrow_open.svg" alt="Icon" />
                                                </div>
                                                <div class="saved_cards__info saved_info">
                                                    <div class="saved_info__details d-flex justify-content-between align-items-center" *ngFor="let source of cards; let i = index;">
                                                        <div class="check">
                                                            <span>
                                                                <input name="charge_payment_source" [attr.psid]="source['payment_source']['id']" id="source_{{i}}" type="radio" title="">
                                                                <label for="source_{{i}}"></label>
                                                            </span>
                                                            <h3 class="title">XXXX-XXXX-XXXX-{{source['payment_source']['card']['last4']}}</h3>
                                                        </div>
                                                        <img src="assets/img/logo-{{source['payment_source']['card']['brand'] + '' + logos[source['payment_source']['card']['brand']]}}" alt="Logo" />
                                                    </div>
                                                    <div class="saved_info__btn">
                                                        <button class="btn_orange" (click)="makePayment()">Make Payment</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="line" [class.hidden]="!cards || cards?.length === 0"><span>Or</span></div>
                                            <div class="new_cards">
                                                <div class="new_cards__title d-flex justify-content-between align-items-center"  (click)="setActiveCards($event, 'new', 'saved')">
                                                    <div class="check">
                                                        <span></span>
                                                        <h3 class="title">Add a new Card</h3>
                                                    </div>
                                                    <img src="assets/img/icon_arrow_open.svg" alt="Icon" />
                                                </div>
                                                <new-card *ngIf="currentActiveCard == 'new'" [templateType]="'payment'" [billAmount]="billAmount" (cardResponse)="handleCardResponse($event)"></new-card>
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="nav-paypal" role="tabpanel" aria-labelledby="nav-paypal-tab">
                                            <div class="payment_method">
                                                <div class="saved_info__btn">
                                                    <form *ngIf="user" #payPal_form action="{{configuration.paypal.action}}" method="post" target="_top">
                                                        <input type="hidden" name="custom" [ngModel]="('uid:' + user.uid + ',credit,v2')">
                                                        <input type="hidden" name="invoice" [ngModel]="nowProdTime">
                                                        <input type="hidden" name="return" value="{{origin}}/#/billing/history?paypal=success">
                                                        <input type="hidden" name="cancel_return"
                                                               value="{{origin}}/#/billing/history?paypal=cancel">
                                                        <input type="hidden" name="hosted_button_id" value="{{configuration.paypal.hosted_button_id}}">
                                                        <input type="hidden" name="cmd" value="_s-xclick">
                                                        <input type="hidden" name="quantity"
                                                               [ngModel]="billAmount ? billAmount : 0">
                                                        <button id="payPal_form" class="btn_orange" type="button"
                                                                (click)="doPayment('payPal_form')">
                                                            Pay with Paypal
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                        
                                        <div class="tab-pane show active" id="nav-fastspring" role="tabpanel" aria-labelledby="nav-fastspring-tab">
                                            <div class="payment_method">
                                                <div class="saved_info__btn" >
                                                    <form *ngIf="user" #fastspring_form ngNoForm method="post" class="formBox" action="{{configuration.fastspring.action}}" target="_top">
                                                        <input type="hidden" name="operation" value="create"/>
                                                        <input type="hidden" name="destination" value="checkout"/>
                                                        <input type="hidden" name="referrer"
                                                               [ngModel]="'uid:' + user.uid + ',oid:1credit,v2'">
                                                        <input type="hidden" name="product_1_path" value="/1credit">
                                                        <input type="hidden" name="product_1_quantity" [ngModel]="billAmount ? billAmount : 1" />
                                                        <input type="hidden" name="contact_fname" [ngModel]="user.first_name">
                                                        <input type="hidden" name="contact_lname" [ngModel]="user.last_name">
                                                        <input type="hidden" name="contact_company" [ngModel]="user.company_name">
                                                        <input type="hidden" name="contact_email" [ngModel]="user.email">
                                                        <input type="hidden" name="contact_phone" [ngModel]="user.phone">
                                                        <button id="fastspring_form" (click)="doPayment('fastspring_form')"
                                                                id="fastspringForm" class="btn btn--full" name="submitFastSpring"  type="button" value="Order Now">Pay with Credit Card / Bank</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-bitcoin" role="tabpanel" aria-labelledby="nav-bitcoin-tab">
                                            <div class="payment_method">
                                                <div class="saved_info__btn">
                                                    <form *ngIf="user" #altCurrencies_form class="formBox" action="{{configuration.coinpayments.action}}"
                                                          method="post" target="_top">
                                                        <input type="hidden" name="key" value="{{configuration.coinpayments.key}}">
                                                        <input type="hidden" name="cmd" value="_pay">
                                                        <input type="hidden" name="reset" value="12">
                                                        <input type="hidden" name="custom" [ngModel]="('uid:' + user.uid + ',credit,v2')">
                                                        <input type="hidden" name="first_name" [ngModel]="user.first_name">
                                                        <input type="hidden" name="last_name" [ngModel]="user.last_name">
                                                        <input type="hidden" name="email" [ngModel]="user.email">
                                                        <input type="hidden" name="phone" [ngModel]="user.phone">
                                                        <input type="hidden" name="address1" [ngModel]="user.address">
                                                        <input type="hidden" name="zip" [ngModel]="user.zip">
                                                        <input type="hidden" name="country" value="{{limeProxiesService.getCountriesAcronym(user.country)}}">
                                                        <input type="hidden" name="city" [ngModel]="user.city">
                                                        <input type="hidden" name="ipn_mode" value="{{configuration.coinpayments.ipn_mode}}">
                                                        <input type="hidden" name="ipn_type" value="{{configuration.coinpayments.ipn_type}}">
                                                        <input type="hidden" name="ipn_url" value="{{configuration.coinpayments.ipn_url}}">
                                                        <input type="hidden" name="merchant" value="{{configuration.coinpayments.merchant}}">
                                                        <input type="hidden" name="currency" value="USD">
                                                        <input type="hidden" name="amountf"
                                                               [ngModel]="billAmount">
                                                        <input type="hidden" name="item_name" value="1 Proxy Credit">
                                                        <button id="altCurrencies_form" class="btn_orange" type="button"
                                                                (click)="doPayment('altCurrencies_form')">
                                                            Pay with Bitcoin
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade {{onLoadTab && onLoadTab === 'subscriptions' ? 'show active' : ''}}" id="nav-subscriptions" role="tabpanel" aria-labelledby="nav-subscriptions-tab">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-7 available_credits">
                    <div class="border d-flex justify-content-between flex-wrap">
                        <div class="available_credits__price">
                            <h3 class="title">Available Credits</h3>
                            <span>${{limeProxiesService.userNotifications.credit}}</span>
                        </div>
                        <div class="available_credits__btn">
                            <a class="btn_green" routerLink="/new-billing/payment" (click)="changeTab('payment')">Add Credits</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-7 current_plans">
                    <div class="border">
                        <div class="add__title">
                            <h3 class="title">Your current active subscription</h3>
                        </div>
                        <div class="row current_plans__wrapper current_plans_wrap" *ngFor="let plan of products; let i = index;" >
                            <div class="col-6 col-md-3 col-lg-3 current_plans_wrap__item">
                                <h3 class="title">Plan {{i + 1}} {{plan['type']}}</h3>
                                <span class="current_plans_wrap__undertitle">#{{plan['id']}}</span>
								<br />
								<span class="current_plans_wrap__undertitle">{{plan['product']}}</span>
                                <p>{{plan['no']}} {{plan['no'] === 1 ? 'proxy' : 'proxies'}}</p>
                            </div>
                            <div class="col-6 col-md-3 col-lg-3 current_plans_wrap__item">
                                <h3 class="title">Valid till</h3>
                                <span class="current_plans_wrap__undertitle">{{plan['expiry']}}</span>
                            </div>
                            <div class="col-6 col-md-3 col-lg-3 current_plans_wrap__item">
                                <h3 class="title">Payment Method</h3>
                                <span class="current_plans_wrap__method">Credit card</span>
                            </div>
                            <div class="col-6 col-md-3 col-lg-3 current_plans_wrap__item d-flex align-items-center">
                                <button data-toggle="modal" *ngIf="plan.auto" (click)="showCancelSubscription(plan.id)" data-target="#cancel_subscription" class="btn_orange">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade {{onLoadTab && onLoadTab === 'update' ? 'show active' : ''}}" id="nav-update" role="tabpanel" aria-labelledby="nav-update-tab">
            <div class="update">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-7 update_edit" style="display: block;">
                        <div class="border">
                            <div class="add__title">
                                <h3 class="title">Manage Card</h3>
                                <!-- <h3 class="title new-card-toggle" (click)="addNewRestore()">+ Add new Card</h3> -->
                            </div>
                            <div class="cards-list">
                                <div class="saved_info__details d-flex justify-content-between align-items-center" *ngFor="let source of cards; let i = index;">
                                    <div class="check">
                                        <!--<span>-->
                                        <!--<input name="charge_payment_source" [attr.psid]="source['payment_source']['id']" id="source_edit_{{i}}" type="radio" title="">-->
                                        <!--<label for="source_edit_{{i}}"></label>-->
                                        <!--</span>-->
                                        <h3 class="title">XXXX-XXXX-XXXX-{{source['payment_source']['card']['last4']}}</h3>
                                    </div>
                                    <img src="assets/img/logo-{{source['payment_source']['card']['brand'] + '' + logos[source['payment_source']['card']['brand']]}}" alt="Logo" />
                                    <div class="action-buttons">
                                        <!-- div class="action-edit" (click)="editPaymentSource(source['payment_source'])"></div -->
                                        <div class="action-delete" (click)="deletePaymentSource(source['payment_source'])"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="update__form update_form">
                                <new-card [existingCard]="selectedCardObject" [templateType]="'new'" (cardResponse)="handleCardResponse($event)"></new-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade {{onLoadTab && onLoadTab === 'history' ? 'show active' : ''}}" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
            <div class="history">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12">
                        <div class="border">
                            <div class="add__title">
                                <h3 class="title">History</h3>
                            </div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Payment method</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Transaction ID</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Payment Recipt</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr  *ngFor="let item of history">
                                        <td>{{item.date}}</td>
                                        <td class="card_number">{{item.method}}</td>
                                        <td>{{item.type}}</td>
                                        <td>{{item.transaction}}</td>
                                        <td>{{(item.value > 0 ? '&#36;' + item.value : '-&#36;' + item.value * -1)}}</td>
                                        <td><span  *ngIf="item.reference" class="mouseOver" (click)="downloadInvoice(item)" ><img src="assets/img/icon_history_invoice.svg" alt="Icon" /></span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="_terminate.show" [style.display]="_terminate.show?'block':none" class="modal-overlay display">
    <div class="modal modal-cancel-sub">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Please provide the reason for cancelling</h4>
            </div>
            <div class="modal-body">
                <div class="modal-txt">
                    <textarea [(ngModel)]="_terminate.reason" style="height: 200px;" maxlength="250" class="form-control" title=""></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <a (click)="closeTerminate()" style="width: 50%" class="modal-action modal-close waves-effect waves-green btn-flat">Close</a>
            <a (click)="cancelSubscription()" style="width: 50%" class="modal-action modal-close waves-effect waves-green btn-flat">Submit</a>
        </div>
    </div>
</div>