import { Routes } from '@angular/router';
import { MyAccountComponent } from './my-account.component';
import { IpAuthComponent } from './auth-ip.component';
import { UserWhitelistComponent } from './user-whitelist.component';
import { AuthorizedUserComponent } from './auth-user.component';
import { AccountSidebarComponent } from './account-sidebar.component';
import {AuthGuard} from '../../auth.guard';
import {MyTeamComponent} from './my-team.component';


export const accountRoutes: Routes = [
    {path: '', redirectTo: 'my-account', pathMatch: 'full'},
    {path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard]},
    {path: 'my-team', component: MyTeamComponent, canActivate: [AuthGuard]},
    {path: 'auth-ip', component: IpAuthComponent, canActivate: [AuthGuard]},
    {path: 'user-whitelist', component: UserWhitelistComponent, canActivate: [AuthGuard]},
    {path: 'auth-user', component: AuthorizedUserComponent, canActivate: [AuthGuard]},
    {path: 'sidebar', component: AccountSidebarComponent, canActivate: [AuthGuard]}
];