import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LimeProxiesService } from './limeproxies.service';
import { catchError, map } from 'rxjs/operators';

declare const Env: any;

@Injectable({
	providedIn: 'root'
})
export class UserService {
	public environment 		= Env;
	public role 			= 'Standard';
	private limeproxiesUrl 	= this.environment['api']['url'];
	public currentUser: any = [];

    constructor(private httpClient: HttpClient) {}

    getUser() : Observable<any> {
		return this.httpClient.get(this.limeproxiesUrl.concat('user/profile'), {headers: LimeProxiesService.authHeaders});
	}
}