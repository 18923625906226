import {Component, HostBinding, OnInit} from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MyValidators} from '../../custom-validators/custom.validator';
declare var analytics: any;


@Component({
    selector: 'set-password',
    templateUrl: '../../templates/login/set-password.template.html'
})

export class SetPasswordComponent implements OnInit {

    @HostBinding('class') myClass = 'page__content-inner';

    public newPassword: string;
    public confirmPassword: string;

    passwordForm: FormGroup;

    public phone_cc: string;

    constructor(private route: ActivatedRoute,
                private limeProxiesService: LimeProxiesService,
                private router: Router,
                fb: FormBuilder) {

        this.passwordForm = fb.group({
            'newPassword': ['', Validators.compose([Validators.minLength(8), Validators.maxLength(50), Validators.required])],
            'confirmPassword': ['', Validators.compose([Validators.minLength(8), Validators.maxLength(50), Validators.required])]
        }, {validator: MyValidators.MatchPassword});

    }
    ngOnInit() {
        this.newPassword = '';
        this.confirmPassword = '';
    }

    updatePassword() {
        if (this.newPassword.length === 0) {
            this.limeProxiesService.notifyBarNew('Minimum password length is 8 characters', 'error');
        } else if (this.newPassword !== this.confirmPassword) {
            this.limeProxiesService.notifyBarNew('New and confirmation password must be the same', 'error');
        } else {
            const obj = {password: this.newPassword};
            this.limeProxiesService.updateUserProfile(obj)
                .subscribe(
                    success => {
                        this.limeProxiesService.showMessage(success);
			
                        // this.limeProxiesService.showQuizPage = true;
                        // if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
                            // analytics.track('signup_password_completed', {});
                        // }
                        this.router.navigate(['login']);
                    },
                    error => {
                        this.limeProxiesService.showMessage(error, 'error');
                    }
                );
        }
    }

}
