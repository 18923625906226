import {Routes} from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {ProductsComponent} from '../products/products.component';
import {BillingComponent} from '../billing/billing.component';
import {AccountComponent} from '../account/account.component';
import {HelpComponent} from '../help/help.component';
import {ProfileComponent} from '../profile/profile.component';
import {productRoutes} from '../products/products.routing';
import {accountRoutes} from '../account/account.routing';
import {billingRoutes} from '../billing/billing.routing';
import {ApiComponent} from '../api/api.component';
import {helpRoutes} from '../help/help.routing';
import {AuthGuard} from '../../auth.guard';
import {NewProfileComponent} from '../new_profile/new-profile.component';
import {NewBillingComponent} from '../new-billing/new-billing.component';
import { UserResolveService } from '../../services/user-resolve.service';
import { ReferralsComponent } from '../referrals/referrals.component';

export const mainFrameRoutes: Routes = [{
    path: '',
    resolve: { user: UserResolveService },
    children: [{
            path: '',
            redirectTo: 'home',
            pathMatch: 'full'
        },
        {
            path: 'home',
            component: HomeComponent,
            canActivate: [AuthGuard]
        },
        {
            path: 'profile',
            component: ProfileComponent,
            canActivate: [AuthGuard]
        },
        {
            path: 'api',
            component: ApiComponent,
            canActivate: [AuthGuard]
        },
        {
            path: 'products',
            component: ProductsComponent,
            children: productRoutes
        },
        {
            path: 'account',
            component: AccountComponent,
            children: accountRoutes,
        },
        {
            path: 'billing',
            component: BillingComponent,
            children: billingRoutes
        },
        // {
        //     path: 'affiliate',
        //     component: AffiliateComponent,
        //     children: affiliateRoutes
        // },
        {
            path: 'support',
            component: HelpComponent,
            children: helpRoutes
        },
        {
            path: 'my-profile',
            component: NewProfileComponent
        },
        {
            path: 'my-profile-details',
            component: NewProfileComponent
        },
        {
            path: 'my-profile/:slug',
            component: NewProfileComponent
        },
        {
            path: 'new-billing',
            component: NewBillingComponent
        },
        {
            path: 'new-billing/:slug',
            component: NewBillingComponent
        },
        {
            path: 'referrals',
            component: ReferralsComponent
        }
    ]
}];