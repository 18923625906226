<nav class="navbar navbar-default custom-navbar">
	<div class="container">
        <div class="mobile-button hidden-sm hidden-md hidden-lg">
            <span class="bar bar-one"></span>
            <span class="bar bar-two"></span>
            <span class="bar bar-three"></span>
        </div>
		<div class="navbar-form navbar-right">
		  <div class="signup-form">
			  <div class="form-group">
				<input type="text" class="form-control" placeholder="Email">
			  </div>
			  <button type="button" class="btn signup-btn" (click)="signup()">Sign up</button>
		  </div>
		</div>
		<ul class="nav navbar-form navbar-right">
			<li>
				<a routerLink="/login" class="btn login-btn"><span class="login">Log in</span></a>
			</li>
		</ul>
    </div>
</nav>
