<div class="block page_block_inner">
    <h4 class="block__title">How much a user can earn ?</h4>
    <div class="row">
        <ol style="list-style: auto; margin-left: 25px;">
            <li>$10 earned for every new unique paid user you refer.</li>
            <li>Referred users must be active for 30+ days and use at least $10 in payments to be counted as verified sales.</li>
            <li>Payouts are finalized and issued on the business day following the 1st and 15th of each month.</li>
            <li>Your referral link below uniquely identifies your account. Use this code when linking to Limeproxies.com and start earning today!</li>
        </ol>
    </div>
    <div class="row">
        <div class="col-xxs col-xs-8 input-wrapper">
            <input type="text" class="input-field" [ngModel]="referrerUrl" disabled />
        </div>
        <div class="col-xxs col-xs-4 col-lg-4 input-wrapper">
            <button (click)="copyText()" class="btn btn--full">Copy</button>
        </div>
    </div>
</div>