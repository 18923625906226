import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LimeProxiesService } from './limeproxies.service';
import * as auth0 from '../../../node_modules/auth0-js';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthService {
    private redirect_uri = window.location.origin + '/#/';

    auth0 = new auth0.WebAuth({
        clientID: 'BFnK2d3GFyF41ccK7XAmRwc5o_3r2UBr',
        domain: 'limeproxies.auth0.com',
        responseType: 'id_token',
        audience: 'https://limeproxies.auth0.com/userinfo',
        redirectUri: this.redirect_uri + 'auth0.callback',
        scope: 'openid'
    });

    constructor(public router: Router, private limeProxiesService: LimeProxiesService) {}

    public handleAuthentication(): void {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.idToken) {
                window.location.hash = '';
                this.limeProxiesService.authLogin({ 'id_token': authResult.idToken })
                    .pipe(
                        switchMap(
                            creds => {
                                this.limeProxiesService.setUserProfile();
                                return this.limeProxiesService.getUserNotification();
                            },
                        )
                    ).subscribe(
                    data => {
                        if (data['wizard']) {
                            this.limeProxiesService.showQuizPage = true;
                        }
                        this.router.navigateByUrl('/home');
                    },
                    error => {
                        this.limeProxiesService.notifyBarNew('Unauthorized', 'error');
                        this.limeProxiesService.logout();
                        // this.router.navigateByUrl('/login');

                    }
                );
            } else if (err) {
                this.limeProxiesService.notifyBarNew('Something went wrong', 'error');
                // this.router.navigateByUrl('/login');
                this.limeProxiesService.logout();
            }
        });
    }

    public login(): void {
        this.auth0.authorize();
    }

}
