<form class="block" [formGroup]="passwordForm">
    <div class="">
        <div class="col-xs-12 input-wrapper">
            <input type="password" class="input-field" name="old_profile_password" #oldPasswordField placeholder="Old Password" [(ngModel)]="oldPassword" [formControl]="passwordForm.controls['oldPassword']">
            <div *ngIf="passwordForm.controls['oldPassword'].hasError('maxlength') && passwordForm.controls['oldPassword'].touched" class="alert-danger">Length must be less than 50</div>
            <div *ngIf="passwordForm.controls['oldPassword'].hasError('minlength') && passwordForm.controls['oldPassword'].touched" class="alert-danger min-pass-length">Minimum password length is 8 characters</div>
        </div>
        <div class="col-xs-12 input-wrapper">
            <input type="password" class="input-field" name="profile_password" #newPasswordField placeholder="New Password" [(ngModel)]="newPassword" [formControl]="passwordForm.controls['newPassword']">
            <div *ngIf="passwordForm.controls['newPassword'].hasError('maxlength') && passwordForm.controls['newPassword'].touched" class="alert-danger">Length must be less than 50</div>
            <div *ngIf="passwordForm.controls['newPassword'].hasError('minlength') && passwordForm.controls['newPassword'].touched" class="alert-danger min-pass-length">Minimum password length is 8 characters</div>
        </div>
        <div class="col-xs-12 input-wrapper">
            <input type="password" class="input-field" name="profile_password_confirmation" placeholder="Confirm Password" [(ngModel)]="confirmPassword" [formControl]="passwordForm.controls['confirmPassword']">
            <div *ngIf="passwordForm.controls['confirmPassword'].hasError('maxlength') && passwordForm.controls['confirmPassword'].touched" class="alert-danger">Length must be less than 50</div>
            <div *ngIf="passwordForm.controls['confirmPassword'].hasError('minlength') && passwordForm.controls['confirmPassword'].touched" class="alert-danger min-pass-length">Minimum password length is 8 characters</div>
        </div>
    </div>
    <div class="end-xs">
        <div class="col-xs-12 input-wrapper update-password">
            <button type="submit" class="btn btn--full" (click)="updatePassword()" [disabled]="!passwordForm.valid" [ngClass]="{'btn--gray': !passwordForm.valid}">Update Password</button>
        </div>
    </div>
</form>
<form class="block" [formGroup]="emailForm">
    <div class="">
        <div class="col-xs-12 input-wrapper">
            <input type="email" class="input-field" name="profile_email" placeholder="Email address" [formControl]="emailForm.controls['email']">
        </div>
    </div>
    <div class="end-xs">
        <div class="col-xs-6 input-wrapper update-password">
            <button type="submit" class="btn btn--full" (click)="updateEmail()" [disabled]="!emailFormIsValid()" [ngClass]="{'btn--gray': !emailFormIsValid()}">UPDATE EMAIL ADDRESS</button>
        </div>
    </div>
</form>