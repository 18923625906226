import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';

declare var delighted: { survey: (arg0: { email: any; name: any; createdAt: string; properties: { plan: string; company: any; }; }) => void; };
declare var LogRocket: { identify: (arg0: any, arg1: { name: string; email: any; }) => void; };

@Component({
    selector: 'dashboard-footer',
    templateUrl: '../../templates/main-frame/dashboard-footer.template.html'
})

export class DashboardFooterComponent {
    public user: any = [];

    constructor(private userService: UserService) {
    
    }

    ngOnInit() {
        this.user = this.userService.currentUser;

        if (this.user) {
            this.delightedScript();
            // this.logRocketScript();
        }
    }

    delightedScript() {
        delighted.survey({
            email: this.user.email,
            name: this.user.first_name,
            createdAt: "2016-01-01T12:00:00Z",
            properties: {
                plan: "Medium",
                company: this.user.company_name
            }
        });
    }

    logRocketScript() {
        LogRocket.identify(
            this.user.uid, 
            {
                name: this.user.first_name + ' ' + this.user.last_name,
                email: this.user.email
            }
        );
    }
}