import {Routes} from '@angular/router';

import {LoginOrRegisterComponent} from './login-or-register.component';
import {AccountCreateComponent} from './account-create.component';
import {ForgotPasswordComponent} from './forgot-password.component';
import {TokenValidationComponent} from './token-validation.component';
import {PasswordRecoverComponent} from './password-recover.component';
import {TokenRevalidationComponent} from './token-revalidation.component';
import {ThankYouPageComponent} from './thank-you-page.component';
import {SetPasswordComponent} from './set-password.component';
import {LoginWizardComponent} from './login-wizard.component';
import { AuthGuard } from '../../auth.guard';
import {AfterLogoutComponent} from "./after-logout.component";

export const routes: Routes = [
    {
        path: '',
        component: LoginOrRegisterComponent,
        pathMatch: 'full'
    },
    {
        path: 'after',
        component: AfterLogoutComponent
    },
    {
        path: 'signup',
        component: AccountCreateComponent
    },
	{
        path: 'signup/:token',
        component: AccountCreateComponent
    },
    {
        path: 'referrer/:referrerToken',
        component: AccountCreateComponent
    },
    {
        path: 'set-password',
        component: SetPasswordComponent
    },
    {
        path: 'thank-you-page/:token',
        component: ThankYouPageComponent
    },
    {
        path: 'setup',
        component: LoginWizardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'validate/:token',
        component: TokenValidationComponent
    },
    {
        path: 'revalidate/:token',
        component: TokenRevalidationComponent
    },
    {
        path: 'recover/:token',
        component: PasswordRecoverComponent
    },
	{
        path: 'recover/:token/:autoLogin',
        component: PasswordRecoverComponent
    },
    {
        path: 'purcahse-page/:token/:autoLogin/:page',
        component: PasswordRecoverComponent
    },
];
