<div class="notify-top-popup">
</div>
<div class="new_signup height-100">
  <div class="main_wrapper">
    <section class="main_block">
      <div class="container">
        <div class="main_block__wrapper main_block_wrap">
          <div class="main_block_wrap__title">
            <h1 class="title">Sign up for Limeproxies</h1>
          </div>
          <div class="lg-cont" style="min-height:170px">
            <svg class="logo">
              <use xlink:href="#logo"></use>
            </svg>
          </div>
          <div class="main_block_wrap__item main_block_item">
            <div class="main_block_item__title">
              <h2 class="title">Using your Google account</h2>
            </div>
            <div class="main_block_item__link">
              <button class="g-signin"><img src="assets/images/new_signup/signup_google.png" alt="Sign up"></button>
            </div>
          </div>
          <div class="main_block_wrap__line">
            <span>Or</span>
          </div>
          <div class="main_block_wrap__item main_block_item">
            <div class="main_block_item__title">
              <h2 class="title">Using your work email</h2>
            </div>
            <div class="main_block_item__form">
              <form (ngSubmit)="sendValidationLinkToEmail()" [formGroup]="signUpForm">
                <label>
                  First name *
                  <input name="name"
                         [formControl]="signUpForm.controls['first_name']" (keyup)="onKey($event)" (change)="onChange($event)" (focusout)="focusOutFunction()" type="text">
                  <span *ngIf="signUpForm.controls['first_name'].hasError('required') && signUpForm.controls['first_name'].touched" class="validation">Please enter your First Name</span>
                  <span *ngIf="signUpForm.controls['first_name'].hasError('minlength') && signUpForm.controls['first_name'].touched" class="validation">Must be at least 4 characters</span>
                </label>
                <label>
                  Last name *
                  <input name="name"
                         [formControl]="signUpForm.controls['last_name']" (keyup)="onKey($event)" (change)="onChange($event)" (focusout)="focusOutFunction()" type="text">
                  <span *ngIf="signUpForm.controls['last_name'].hasError('required') && signUpForm.controls['last_name'].touched" class="validation">Please enter your Last Name</span>
                  <span *ngIf="signUpForm.controls['last_name'].hasError('minlength') && signUpForm.controls['last_name'].touched" class="validation">Must be at least 4 characters</span>
                </label>
                <label>
                  Email address *
				  
                  <input *ngIf="!emailReadOnly" name="email" type="email" [formControl]="signUpForm.controls['email']" (change)="onChange($event)"  (focusout)="focusOutFunction()">
				  <input *ngIf="emailReadOnly" name="email" type="email" [formControl]="signUpForm.controls['email']" disabled (change)="onChange($event)"  (focusout)="focusOutFunction()">
                  <span *ngIf="signUpForm.controls['email'].hasError('required') && signUpForm.controls['email'].touched" class="validation">Email Address is required</span>
                  <span *ngIf="!signUpForm.controls['email'].hasError('required') && signUpForm.controls['email'].hasError('validateEmail') && signUpForm.controls['email'].touched && signUpForm.controls['email'].dirty" class="validation">Please enter a valid email address.</span>
                </label>
                <button [disabled]="!signUpForm.valid" [class.btn--gray]="!signUpForm.valid" type="submit">Create</button>
              </form>
            </div>
          </div>
          <div class="main_block_wrap__text">
            <p>By signing up, you agree to limeproxies <a href="#/">License Agreement</a>.</p>
            <p>Already have an account - <a [routerLink]="['/login']">Login</a></p>
          </div>
        </div>
        <div class="main_block__bottom">
          <ul>
            <li><img src="assets/images/new_signup/logo_ginza.png" alt="Logo"></li>
            <li><img src="assets/images/new_signup/logo_booking.png" alt="Logo"></li>
            <li><img src="assets/images/new_signup/logo_hotels.png" alt="Logo"></li>
            <li><img src="assets/images/new_signup/logo_authoritylabs.png" alt="Logo"></li>            
          </ul>
        </div>
      </div>
    </section>
  </div>
</div>
