import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LimeProxiesService } from '../../../services/limeproxies.service'; 
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.css']
})
export class CustomComponent implements OnInit {
	@Input() productForm: FormGroup;
	@Output() customProduct = new EventEmitter<any>();

	myControl = new FormControl();
	mySubnetControl = new FormControl();
	filteredOptions: Observable<string[]>;
	// filteredSubnetOptions: Observable<string[]>;
	filteredSubnetOptions: any = [];
	subnets: any = [];
	locations: string[] = [];
	selectedLocationVal: string[] = [];
	selectedSubnetVal: any = [];
	filteredSubnets: any = [];
	noOfProxies: number = 0;
	proxies: any = [];
	selectedSubnetValue: any = 0;

	constructor(public limeProxiesService: LimeProxiesService) { }

	ngOnInit() {
		this.getSubnets();
		
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value))
		);
	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.locations.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
	}

	selectedLocation = (locationVal) => {	
		this.selectedLocationVal = locationVal;
		this.setFilteredSubnetOptions();
		return locationVal;
	}
	
	setFilteredSubnetOptions = () => {
		this.filteredSubnetOptions = [];
		let filteredSubnetOptions = [];
		
		this.filteredSubnets
			.filter(subnet => subnet.geo_maxmind === this.selectedLocationVal)
			.sort(function(a, b) {
				return (a.subnet < b.subnet) ? 1 : ((a.subnet === b.subnet) ? 0 : -1);
			})
			.forEach(subnet => {
				let yes = false;
				if (this.productForm.value.usage === null || this.productForm.value.usage === 'others') {
					yes = true;
				} else {
					subnet.use_cases.forEach(useCase => {
						if (useCase.slug === this.productForm.value.usage) {
							yes = true;
						}
					});
				}

				if (yes === true) {
					filteredSubnetOptions.push(subnet);
				}
			});
			
		this.filteredSubnetOptions = filteredSubnetOptions;
	}
	
	displaySubnet = (subnet) => {
		return subnet ? subnet.subnet : '';
	}
	
	selectedSubnet = (subnet) => {
		this.selectedSubnetVal = subnet;
		return subnet;
	}
	
	getSubnets = (): void => {
		this.limeProxiesService.getSubnets().subscribe(subnets => {
			this.subnets = subnets;
			this.setLocations();
			
		});
	}
	
	setLocations = (): void => {
		this.locations = [];
	
		this.subnets.forEach(subnet => {
			let yes = false;

			if (this.productForm.value.usage === null || this.productForm.value.usage === 'others') {
				yes = true;
			} else {
				subnet.use_cases.forEach(useCase => {
					if (useCase.slug === this.productForm.value.usage) {
						yes = true;
					}
				});
			}

			if (yes === true) {
				this.filteredSubnets.push(subnet);		
				
				if (!this.locations.includes(subnet.geo_maxmind)) {
					this.locations.push(subnet.geo_maxmind);
				}
			}
		});
			
		this.locations = this.locations.sort(function(a, b) {
			return (a < b) ? 1 : ((a === b) ? 0 : -1);
		});
	}
	
	getImage(lang: string) {
        return `assets/images/countries/${lang.toLowerCase()}.png`;
    }
	
	getAvailableProxies = () => {
		let proxies = this.subnets.filter(subnet => subnet.geo_maxmind == this.myControl.value);

		let availableProxies = 0;
	
		proxies.forEach(obj => {
			availableProxies = availableProxies + parseInt(obj.proxies.available_proxies);
		});

		let subnet = this.subnets.filter(subnet => subnet.sid == this.selectedSubnetVal).shift();
		
		if (subnet !== undefined) {
			availableProxies = subnet.proxies.available_proxies;
		}
		
		return availableProxies;
	}
	
	add = () => {
		if (this.myControl.value == undefined || this.myControl.value == null || this.myControl.value.trim() == '') {
			this.limeProxiesService.showMessage({'_user_message': 'Please select location.'}, 'error');
		} else if (isNaN(this.noOfProxies) || this.noOfProxies <= 0) {
			this.limeProxiesService.showMessage({'_user_message': 'Invalid number of proxies.'}, 'error');
		} else {
			if (this.selectedSubnetVal) {
				let subnet = this.subnets.filter(subnet => subnet.sid == this.selectedSubnetVal).shift();
				
				if (subnet !== undefined) {
					if (subnet.proxies.available_proxies < this.noOfProxies) {
						this.limeProxiesService.showMessage({'_user_message': 'Insufficent proxies under subnet ' + subnet.subnet + ' (Available proxies : ' + subnet.proxies.available_proxies + ')'}, 'error');
					} else {
						subnet.no = this.noOfProxies;
						subnet.isSubnet = 1;
						this.proxies.push(subnet);
						this.productForm.controls['proxies'].setValue(this.proxies);
						this.resetAddProxies();
						this.customProduct.emit(this.productForm.value);
						this.selectedSubnetValue = 0;
						this.myControl.setValue('');
					}
				} else {
					this.limeProxiesService.showMessage({'_user_message': 'Please select the subnet and proxies.'}, 'error');
				}
			} else {
				let proxies = this.subnets.filter(subnet => subnet.geo_maxmind == this.myControl.value);

				let availableProxies = 0;
			
				proxies.forEach(obj => {
					availableProxies = availableProxies + parseInt(obj.proxies.available_proxies);
				});

				if (availableProxies < this.noOfProxies) {
					this.limeProxiesService.showMessage({'_user_message': 'Insufficent proxies under location ' + this.myControl.value + ' (Available proxies : ' + availableProxies + ')'}, 'error');
				} else {
					let location = proxies.shift();
					this.proxies.push({
						geo_maxmind: location.geo_maxmind,
						geo_mm_cc: location.geo_mm_cc,
						lid: location.lid, 
						no: this.noOfProxies,
						subnet: 'xxx.xxx.xxx.xxx',
						isSubnet: 0
					});
					this.productForm.controls['proxies'].setValue(this.proxies);
					this.resetAddProxies();
					this.customProduct.emit(this.productForm.value);
					this.selectedSubnetValue = 0;
					this.myControl.setValue('');
				}
			}
			console.log('selectedSubnetVal', this.selectedSubnetVal, this.subnets);
			
		}
	}
	
	resetProxies = () => {
		this.proxies = [];
		this.productForm.controls['proxies'].setValue(this.proxies);
		this.resetAddProxies();
		this.customProduct.emit(this.productForm.value);
	}
	
	remove = (proxy) => {
		this.proxies = this.proxies.filter(obj => obj.sid !== proxy.sid);
		this.productForm.controls['proxies'].setValue(this.proxies);
		this.customProduct.emit(this.productForm.value);
	}
	
	updateSubnets = () => {
		this.proxies = [];
		this.setLocations();
		this.setFilteredSubnetOptions();
	}

	resetAddProxies = () => {
		this.selectedSubnetVal 	= '';
		this.noOfProxies 		= 0;
	
		this.setFilteredSubnetOptions();
	}
}