import { Component, OnInit } from '@angular/core';
import { LimeProxiesService } from 'src/app/services/limeproxies.service';

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.css']
})
export class ReferComponent implements OnInit {

  public referrerUrl = '';

  constructor(public limeProxiesService: LimeProxiesService,) { }

  ngOnInit(): void {
    this.generateReferalLink();
  }

  generateReferalLink = () => {
    this.limeProxiesService.getUserProfile().subscribe(
      profile => this.referrerUrl = location.origin + '/#/login/referrer/lmprx-' + profile.uid
    );
  }

  copyText = () => {
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = this.referrerUrl;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

    this.limeProxiesService.notifyBarNew('Copied', 'success');
  }
}
