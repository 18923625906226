<!-- <a 
    class="btn btn--green btn--full btn-open-ticket " 
    href="https://feedback.limeproxies.com/bug-reports/create" 
    target="_blank">
    <span class="icon"></span>
    <span class="label">Open a new ticket</span>
</a> -->
<!-- <a 
    class="btn btn--green btn--full btn-open-ticket " 
    href="https://limeproxies.zendesk.com/hc/en-us/requests/new" 
    target="_blank">
    <span class="icon"></span>
    <span class="label">Open a new ticket</span>
    
</a> -->
<a
  class="btn btn--green btn--full btn-open-ticket popup-toggle"
  data-popup-target=".open-ticket"
>
  <span class="icon"></span>
  <span class="label">Open a new ticket</span>
</a>
