import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LimeProxiesService } from './limeproxies.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LmpNotificationsService {
	
	constructor(private httpClient: HttpClient, public limeProxiesService: LimeProxiesService) {
	}
  
	getUserNotifications(): Observable<any> {
		return this.httpClient.get(
			this.limeProxiesService.limeproxiesUrl.concat('user/notification/settings'),
			{
				headers: LimeProxiesService.authHeaders
			}
		);
	}
	
	setUserNotifications(notifications): Observable<any> {
		return this.httpClient.put(
			this.limeProxiesService.limeproxiesUrl.concat('user/notification/settings'),
			{'events' : notifications},
			{
				headers: LimeProxiesService.authHeaders
			}
		);
	}
}
