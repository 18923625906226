<div class="breadcrumbs">
    <a [routerLink]="['/account/my-account']" class="breadcrumbs__item">My Account</a>
    <a *ngIf="user_auth_method == 'user'" [routerLink]="['/account/auth-user']" class="breadcrumbs__item breadcrumbs__item--active">Authorized User</a>
    <a *ngIf="user_auth_method == 'ip'" [routerLink]="['/account/auth-ip']" class="breadcrumbs__item">Authorized IPs</a>
    <a *ngIf="user_access_type == 'whitelist'" [routerLink]="['/account/user-whitelist']" class="breadcrumbs__item">User Whitelist</a>
    <a *ngIf="limeProxiesService.environment.show_teams" [routerLink]="['/account/my-team']" class="breadcrumbs__item">Users</a>
    <a [routerLink]="['/my-profile-details']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">My Profile</a>
    <a [routerLink]="['/my-profile/notifications']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">Notifications</a>
    <a [routerLink]="['/my-profile/authentication']" routerLinkActive="breadcrumbs__item--active" class="breadcrumbs__item">Change Password</a>
</div>
<form class="block page_block_inner">
    <h4 class="block__title">Your authorized user</h4>
    <p class="block__desc">Manage your proxy login here.</p>
    <div [innerHTML]="errorMessage" style="color:red;"></div>
    <div class="row">
        <label class="col-xs-12 col-sm-2" style="padding-top: 3.5%;">
           <b>Username:</b>
        </label>
         <div class="col-xxs col-xs-6 input-wrapper">
            <input type="text" class="input-field" name="username" [value]="username" disabled>
        </div>
    </div>
    <div class="row">
          <label class="col-xs-12 col-sm-2" style="padding-top: 3.5%;">
           <b>Password:</b>
        </label>
        <div class="col-xxs col-xs-6 input-wrapper">
            <input type="text" class="input-field" name="password" [(ngModel)]="proxy_password">
        </div>
        <div class="col-xxs col-xs-5 col-lg-4 input-wrapper">
            <button type="submit" class="btn btn--full" (click)="updateProxyPassword()">Update Password</button>
        </div>
    </div>
</form>
<div class="sidebar big-sidebar"><account-sidebar></account-sidebar></div>
