import { Component, HostBinding, AfterViewInit } from '@angular/core';
declare const $: any;

@Component({
    selector: 'help-sidebar',
    templateUrl: '../../templates/help/help-sidebar.template.html'
})

export class HelpSidebarComponent implements AfterViewInit {

    @HostBinding('class') sidebarClass = 'page__sidebar-col';

    ngAfterViewInit() {
        const main_frame = document.querySelector('main-frame');
        // Popup toggler functionality
        $('.popup-toggle').on('click', function(e) {
            e.preventDefault();
            const toggler = $(this),
                target = $(toggler.attr('data-popup-target'));
            main_frame.classList.add('ticket-no-scroll');
            target.addClass('popup--show');
        });

        $('.popup__dismiss').on('click', (e) => {
            e.preventDefault();
            $(e.target).closest('.popup').removeClass('popup--show');
            main_frame.classList.remove('ticket-no-scroll');
        });
    }
}
