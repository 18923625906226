import { Component, HostBinding } from '@angular/core';
import {LimeProxiesService} from '../../services/limeproxies.service';

@Component({
    selector: 'referral-sidebar',
    templateUrl: '../../templates/affiliate/sidebar-referral.template.html'
})

export class ReferralSidebarComponent {

    @HostBinding('class') sidebarClass = 'page__sidebar-col';

    constructor(public limeProxiesService: LimeProxiesService) {
    }

    copyTextToClipboard(text) {
    const textArea: HTMLTextAreaElement = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
        this.limeProxiesService.notifyBarNew('Oops, unable to copy, Copy to clipboard: Ctrl+C', 'info');
    }

    document.body.removeChild(textArea);
    }
}
