import { Component } from '@angular/core';


@Component({
    templateUrl: '../templates/error.template.html'
})


export class ErrorComponent {

}