<div class="notify-top-popup"></div>
<div class="new_signup height-100" *ngIf="showPage">
  <div class="main_wrapper">
    <section class="main_block">
      <div class="container">
        <div class="main_block__wrapper main_block_wrap">
          <div class="lg-cont">
            <svg class="logo">
              <use xlink:href="#logo"></use>
            </svg>
          </div>
          <div class="main_block_wrap__title">
            <h1 class="title" [innerHTML]="step_title"></h1>
          </div>
          <div class="page_counter" *ngIf="limeProxiesService.quizPage < 4">
            {{limeProxiesService.quizPage}}/3
          </div>
          <div
            *ngIf="limeProxiesService.quizPage == 1"
            class="step_first_form main_block_item__form"
          >
            <div class="main_block_wrap__text m-t-0">
              <p>
                Please fill the following wizard to help us assign you the right
                working proxies
              </p>
            </div>
            <form>
              <label>
                What do you use the proxies for?*
                <select
                  name="target"
                  [formControl]="validation_step1.controls['target']"
                  required
                >
                  <option
                    *ngFor="let item of proxy_usage_opt"
                    [value]="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
                <span
                  *ngIf="validation_step1.controls['target'].hasError('required') && validation_step1.controls['target'].touched"
                  class="validation"
                  >Please select one of options</span
                >
              </label>
              <label
                *ngIf="validation_step1.controls['target'].value == 'others'"
              >
                <input
                  name="other_target"
                  [formControl]="validation_step1.controls['other_target']"
                  type="text"
                  placeholder="Purpose of use"
                  maxlength="25"
                />
              </label>
              <p></p>
              <label>
                Select the websites you want to use the proxies on?*
                <select
                  name="website"
                  [formControl]="validation_step1.controls['website']"
                  required
                >
                  <option
                    *ngFor="let item of proxy_website_usage_opt"
                    [value]="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
                <span
                  *ngIf="validation_step1.controls['website'].hasError('required') && validation_step1.controls['website'].touched"
                  class="validation"
                  >Please select one of options</span
                >
              </label>
              <label
                *ngIf="validation_step1.controls['website'].value == 'others'"
              >
                <input
                  name="other_website"
                  [formControl]="validation_step1.controls['other_website']"
                  type="text"
                  placeholder="Enter domain name or IP"
                  maxlength="250"
                />
              </label>
              <label>
                What's your estimated bandwidth usage/month? *
                <select
                  [formControl]="validation_step1.controls['estimated_quota']"
                  required
                >
                  <option
                    *ngFor="let item of estimatedBandwidthUsageMonth"
                    [value]="item.value"
                  >
                    {{ item.text }}
                  </option>
                  <!-- TODO -->
                </select>
                <span
                  *ngIf="validation_step1.controls['estimated_quota'].hasError('required') && validation_step1.controls['estimated_quota'].touched"
                  class="validation"
                  >Please select one of options</span
                >
              </label>
              <label>
                What's your monthly budget for the proxy subscription? *
                <select
                  [formControl]="validation_step1.controls['estimated_budget']"
                  required
                >
                  <option
                    *ngFor="let item of estimatedBudget"
                    [value]="item.value"
                  >
                    {{ item.text }}
                  </option>
                  <!-- TODO -->
                </select>
                <span
                  *ngIf="validation_step1.controls['estimated_budget'].hasError('required') && validation_step1.controls['estimated_budget'].touched"
                  class="validation"
                  >Please select one of options</span
                >
              </label>
              <label>
                Choose the products interested *
                <ul>
                  <li *ngFor="let item of userProductInterested">
                    <input
                      type="checkbox"
                      [value]="item.value"
                      style="
                        float: left;
                        width: 15px;
                        height: 15px;
                        margin: 5px 0px 0px;
                      "
                      (change)="onCheckboxChange($event)"
                    />
                    {{ item.text }}
                  </li>
                </ul>
                <span
                  *ngIf="validation_step1.controls['user_product_interested'].hasError('required') && validation_step1.controls['user_product_interested'].touched"
                  class="validation"
                  >Please select one of options</span
                >
              </label>
              <label>
                Share specific project requirements (if any)
                <textarea
                  [formControl]="validation_step1.controls['comments']"
                  style="width: 100%"
                ></textarea>
              </label>
              <button (click)="next()" type="button">Continue</button>
            </form>
          </div>
          <div
            *ngIf="limeProxiesService.quizPage == 2"
            class="step_first_form main_block_item__form"
          >
            <div class="main_block_wrap__text m-t-0">
              <select
                id="sectionSelector"
                style="padding: 5px; width: 100%"
                (change)="toggleSection($event.target.value)"
              >
                <option value="ip" selected>IP Based</option>
                <option value="user">Username Based</option>
              </select>
            </div>
            <div
              class="main_block_wrap__text m-t-0"
              *ngIf="showSection === 'ip'"
            >
              <p>
                Enter upto three of your IPs from where you want to access the
                proxies from. (You can switch this later to username
                authentication)
              </p>
            </div>
            <form>
              <div *ngIf="showSection === 'ip'">
                <div
                  class="ip-container"
                  *ngFor="let ip of ips; let i = index"
                  style="display: flex"
                >
                  <div style="width: 60%; margin: 0px">
                    <input
                      [(ngModel)]="ips[i].value"
                      [ngModelOptions]="{standalone: true}"
                      (keyup)="validate_ip($event)"
                      type="text"
                      placeholder="e.g. - 192.168.0.1"
                      style="padding: 5px 0 5px 10px"
                    />
                    <span *ngIf="ips[i].error?.length > 0" class="validation"
                      >{{ ips[i].error }}</span
                    >
                  </div>
                  <div style="width: 30%; margin: 0px; display: flex">
                    <button
                      *ngIf="ips?.length > 1"
                      type="button"
                      (click)="removeIp(i)"
                      style="width: 60%; margin: 0 0 0 10px"
                    >
                      Remove
                    </button>
                    <button
                      *ngIf="(i === ips?.length - 1) && (i !== 2)"
                      type="button"
                      (click)="addIp()"
                      style="width: 40%; margin: 0 0 0 10px"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <button (click)="next()" type="button">Continue</button>
            </form>
          </div>
          <div
            *ngIf="limeProxiesService.quizPage == 3"
            class="step_third_form main_block_item__form"
          >
            <div *ngIf="phone_validation_stage == 0">
              <div class="main_block_wrap__text">
                <img
                  src="/assets/images/new_signup/icon_validate_phone.svg"
                  alt="Icon"
                />
                <p>
                  To prevent abuse, we need to verify your account to activate
                  your trial. Please enter your mobile number to verify your
                  phone via SMS or phone call
                </p>
              </div>
              <form>
                <label>
                  Phone number
                  <div class="input-group">
                    <input
                      type="tel"
                      class="tel-input"
                      (keyup)="phone_entered($event)"
                      (change)="phone_entered($event)"
                    />
                  </div>
                  <span *ngIf="phone_number.has_error" class="validation"
                    >Please enter your Phone number</span
                  >
                </label>
                <button
                  (click)="request_otp()"
                  [disabled]="(phone_number.has_error && phone_number.touched) || (!phone_number.touched)"
                  type="button"
                >
                  Request OTP
                </button>
              </form>
            </div>
            <div *ngIf="phone_validation_stage == 1">
              <div class="main_block_wrap__text">
                <p>Enter the 4 digit code we sent you via sms to continue</p>
              </div>
              <form>
                <label>
                  OTP
                  <input
                    [(ngModel)]="phone_valid_code.value"
                    [ngModelOptions]="{standalone: true}"
                    type="text"
                    maxlength="4"
                    (keyup)="opt_entered($event)"
                    (change)="opt_entered($event)"
                    placeholder="Enter your code"
                  />
                  <span *ngIf="phone_valid_code.has_error" class="validation"
                    >Please enter your OTP</span
                  >
                </label>
                <button
                  (click)="verify_otp()"
                  [disabled]="(phone_valid_code.has_error && phone_valid_code.touched) || (!phone_valid_code.touched)"
                  type="button"
                >
                  Verify and Continue
                </button>
                <p class="label">Code expires in 120secs</p>
                <button (click)="request_otp(true)" type="button">
                  Resend SMS
                </button>
                <button
                  *ngIf="valid_fails"
                  (click)="fail_validation()"
                  type="button"
                >
                  Back
                </button>
              </form>
            </div>
            <div class="main_block_wrap__text">
              <a href="#/home">Skip</a>
            </div>
          </div>
          <div
            *ngIf="limeProxiesService.quizPage == 4"
            class="step_third_form main_block_item__form"
          >
            <div>
              <p><b>Proxy IP</b> - {{ getted_proxy.ip }}</p>
            </div>
            <div>
              <p><b>Port</b> - {{ getted_proxy.port }}</p>
            </div>
            <button class="continue_button" (click)="to_dashboard()">
              Continue to Dashboard
            </button>
          </div>
        </div>
        <div
          *ngIf="limeProxiesService.quizPage != 4"
          class="main_block__bottom step_bottom"
        >
          <ul [class.w-100]="1">
            <!-- limeProxiesService.quizPage === 3 -->
            <li [class.step_active]="limeProxiesService.quizPage >= 1">
              <span></span>
            </li>
            <li [class.step_active]="limeProxiesService.quizPage >= 2">
              <span></span>
            </li>
            <li [class.step_active]="limeProxiesService.quizPage >= 3">
              <span></span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</div>
