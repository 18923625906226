import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LimeProxiesService } from '../../services/limeproxies.service';
import { AuthService } from '../../services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
declare const $: any;
declare var analytics: any;
declare var Chargebee: any;

@Component({
    selector: 'products-sidebar',
    templateUrl: '../../templates/products/products-sidebar.template.html'
})

export class ProductsSidebarComponent implements OnInit, AfterViewInit  {

    @HostBinding('class') sidebarClass = 'page__sidebar-col';
    @Input() productForm: FormGroup;
    @Output() clearForm = new EventEmitter();
    @Output() updateCredit = new EventEmitter();
    @Output() showCards = new EventEmitter();
    availableCredits: number;
    public userInfo: any = {};
    public nowProdTime: number;
    productInvoice: any = '';
    public productInvoiceGettted = false;
    public product_path: string;
    public locationCount: any;
    public product_submit_selector: string;
    public chargebee_products = {
        private: {
            packages: []
        },
        premium: {
            packages: []
        }
    };
    public logos: any = {
        visa : '.svg',
        mastercard: '.png',
        american_express: '.png',
        discover: '.svg',
        jcb: '.png',
        diners_club: '.png',
    };
    public cards: any = [];
    public configuration: any = [];
    cbInstance: any;
    loading: boolean;
    showCheckout = false;
    isPushed = false;
    errMsg: boolean;
    proxyUsageOpt: any = [];

    public payment_method = [
        {
            value: 'recurring',
            name: 'Recurring'
        }, {
            value: 'one_time',
            name: 'One time'
        },
    ];

    paymentTypes = [
        {
            value: 'fastspring',
            title: 'Credit Card / Bank',
            img: 'assets/images/payment/fastspring.png'
        },
        /*
        {
            value: 'bitcoin',
            title: 'BitCoin',
            img: 'assets/images/payment/bitcoin.png'
        },
        */
        {
            value: 'paypal',
            title: 'PayPal',
            img: 'assets/images/payment/paypal.png'
        },
        // {
        //     value: 'chargebee',
        //     title: 'Chargebee',
        //     img: 'assets/images/payment/chargebee.svg'
        // }
        // {
        //     value: 'altcurrencies',
        //     title: 'AltCurrencies',
        //     img: 'assets/images/payment/coinpayments.png'
        // }
    ];
    paymentTypesWithCredits = [
        {
            value: 'credit',
            title: 'Credit',
            img: 'assets/images/payment/credit.png'
        },
        {
            value: 'fastspring',
            title: 'Credit Card / Bank',
            img: 'assets/images/payment/fastspring.png'
        },
        // {
        //     value: 'bitcoin',
        //     title: 'BitCoin',
        //     img: 'assets/images/payment/bitcoin.png'
        // },
        {
            value: 'paypal',
            title: 'PayPal',
            img: 'assets/images/payment/paypal.png'
        },
        // {
        //     value: 'chargebee',
        //     title: 'Chargebee',
        //     img: 'assets/images/payment/chargebee.svg'
        // }
        // {
        //   value: 'altcurrencies',
        //   title: 'AltCurrencies',
        //   img: 'assets/images/payment/coinpayments.png'
        // }
    ];

    constructor(public limeProxiesService: LimeProxiesService,
                public ref: ChangeDetectorRef,
                public http: HttpClient,
                public router: Router) {
        if (this.limeProxiesService.environment && this.limeProxiesService.environment['third_parties'] && this.limeProxiesService.environment['third_parties']['chargebee']) {
            this.charge_init();
        }
    }

    ngOnInit() {
		this.configuration = this.limeProxiesService.getThirdPartyConfigurations();
		
        this.limeProxiesService.getUserProfile()
            .pipe(
                switchMap(success => {
                    this.userInfo = success;
                    this.userInfo.phone = success['phone'];
                    return this.limeProxiesService.getUserNotification();
                })
            ).subscribe(
            data => {
                this.availableCredits = data['credit'];
            },
            error => {
                this.limeProxiesService.logout();
            }
        );
        this.nowProdTime = Math.floor( Date.now() / 1000 );
        /* this.limeProxiesService.charge_get_card().subscribe(
            success => {
                //console.log(success);
            }, error => {
                console.log(error);
            }
        ); */


        this.limeProxiesService.get_proxy_usage_opt().subscribe(
            data => {
                let array = [];
                data.data.forEach(value => {
                    array.push({text: value.name, value: value.slug});
                });
                array.push({text: 'Others', value: 'others'});
                this.proxyUsageOpt = array;
            }, error => {
                console.log(error, 'get_proxy_usage_opt');
            }
        );
		
		this.getCards();
    }

    charge_init() {
        const __this = this;
        if (typeof Chargebee !== 'undefined' && Chargebee.inited) {
            this.init_chargebee();
            this.cbInstance = Chargebee.getInstance();
            this.cbInstance.setCheckoutCallbacks((cart) => {
                return {
                    loaded: () => {},
                    close: () => {},
                    success: (hostedPageId) => {
                        __this.limeProxiesService.chargeebee_request({

                        }).subscribe(
                            success => {
                                __this.limeProxiesService.notifyBarNew('Congratulations! We have successfully added proxies in your account', 'success');
                                setTimeout(() => {
                                    __this.router.navigate(['/']);
                                }, 1500);
                            }, error => {
                                __this.limeProxiesService.notifyBarNew('Unable to finish checkout. Please contact support.', 'error');
                            }
                        );
                    },
                    step: (value) => {}
                };
            });
        } else {
            setTimeout(() => {
                __this.charge_init();
            }, 100);
        }
    }

    sendCoupon(coupon: HTMLInputElement) {
        this.limeProxiesService.sendCoupon(this.productForm.get('proxy_num').value.name, coupon.value).subscribe((data) => {
            if (data.valid) {
                this.productForm.patchValue({discount: data.value});
            } else {
                this.limeProxiesService.notifyBarNew('Coupon code is not valid', 'error');
            }
        }, error => console.log(error), () => coupon.value = '');

    }

    clearPayment() {
        this.productForm.controls['payment'].setValue(null);
    }

    public inProcess = false;

    newProduct(selector, userInfo, amount, event) {		
		let curObject = this;
		let curEvent = event;
	
        if (typeof this.userInfo.uid !== 'undefined') {
            

            this.productInvoice = '';
            let block: any;
            if (selector !== 'chargebee') {
                block = $('#' + selector);
            }
            this.limeProxiesService.completedOrder(userInfo.first_name, userInfo.last_name, userInfo.email, amount);
			
            this.product_submit_selector = selector;
            if (this.productInvoiceGettted === false) {
                this.productInvoiceGettted = true;

                const newProduct = this.productForm.value;
                if (newProduct.proxy_num && this.productForm.value.locations.length > 0) {
		
					
                    this.product_path = '/' + newProduct.proxy_num.link;

                    const proxy_num = newProduct.proxy_num.name;
                    let no = 1;
                    let type = '';
                    let locationsString = this.getLocationsCodes();

                    if (newProduct.residential == true) {
                        no = 1;
                        type = 'Residential';
                        locationsString = 'Residential';
                    } else {
                        no = proxy_num.split(' ')[0];
                        type = proxy_num.split(' ')[1].toLowerCase();
                    }
                    					
                    const payment = newProduct.payment.value;
                    
                    const usage = (newProduct.usage == 'others') ? ((newProduct.otherUsage !== null && newProduct.otherUsage.trim() !== '') ? newProduct.otherUsage: 'Others') : newProduct.usage;
                    
                    const custom = newProduct.custom;
					
                    this.limeProxiesService.orderProduct(no, type, locationsString, payment, usage, newProduct.proxies,  newProduct.parent_id, custom, newProduct.proxy_num).subscribe(data => {

                        this.inProcess = true;

                            // checkout event
                            this.productInvoice = data['oid'];
                            curObject.productInvoice = data['oid'];
							
                            const temp_class = this;
                            if (this.productInvoice.length > 0) {
								if (this.limeProxiesService.environment['third_parties']['analytics']['enabled']) {
									analytics.track('Checkout Started', proxy_num);
								}
								
								if (selector == 'paypalForm' || selector == 'fastspringForm') {
                                    setTimeout(() => {
                                       event.target.parentElement.submit();
                                    }, 2000);
								}
								
								if (selector == 'chargeBee') {
									if (typeof event != 'undefined') {
										if (typeof event.card != 'undefined') {
											curObject.chargebeeCheckoutNewCardPayment(event);
										}
									} else {
										curObject.chargeBeeCapturePayment(curObject.productInvoice, curObject.productForm.value.proxy_num.price, curObject.chargeBeePaymentSource);
									}
								}
								temp_class.productInvoiceGettted = false;
                            }
                        },
                        error => {
                            this.inProcess = false;
                            this.productInvoiceGettted = false;
                            if (error._user_message !== undefined){
                                this.limeProxiesService.notifyBarNew(error._user_message, 'error');
                            } else if (typeof error === 'string') {
                                this.limeProxiesService.notifyBarNew(error, 'error');
                            } else if (typeof error.json() !== 'undefined') {
                                this.limeProxiesService.notifyBarNew(error.json().error, 'error');
                            } else if (typeof error.error !== 'undefined') {
                                this.limeProxiesService.notifyBarNew(error.error.message, 'error');
                            }
                        }
                    );
                }
            }
        } else {
            this.router.navigate(['#login']);
        }
    }

    newCreditProduct(value: any) {
        this.isPushed = true;
        const newProduct = this.productForm.value;
        if (newProduct.proxy_num && this.productForm.value.locations.length > 0) {
            
            let proxy_num = newProduct.proxy_num.name;
            let no = 1;
            let type = '';

            console.log(newProduct.proxy_num, 'proxy_num');
            console.log(newProduct.proxy_num.type, 'proxy_num');
            console.log(newProduct.proxy_num.type != undefined);
            console.log(newProduct.proxy_num.type == "residential");

            if (newProduct.proxy_num.type != undefined && newProduct.proxy_num.type == "residential") {
                type = newProduct.proxy_num.type;
            } else {
                no = proxy_num.split(' ')[0];
                type = proxy_num.split(' ')[1].toLowerCase();
            }
            
            const payment = newProduct.payment.value;
            const locationsString = this.getLocationsCodes();
			const usage = (newProduct.usage == 'others') ? newProduct.otherUsage : newProduct.usage;

            this.limeProxiesService.orderProduct(no, type, locationsString, payment, usage, newProduct.proxies, newProduct.parent_id, newProduct.custom, newProduct.proxy_num).pipe(
                switchMap(data => {
                    return this.limeProxiesService.orderCreditProduct(data['oid']);
                })
            ).subscribe(success => {
                if (success['_user_message_type'] === 'success') {
                    const currentCredits = $('.header__price span');
                    const newCredits = currentCredits.text() - value;
                    currentCredits.text(newCredits);
                    this.updateCredit.emit(newCredits);
                    this.limeProxiesService.notifyBarNew('Congratulations! We have successfully added proxies in your account', 'success');
                    this.router.navigate(['/products/', 'manage']);
                    this.limeProxiesService.showMessage(success);
                }
            },
            error => {
                this.limeProxiesService.notifyBarNew(error._user_message, 'error');
            });
        }
    }

    disCheckout(get) {	
        // this.isPushed = false;
        // if (get === 'Chargebee') {
        //     this.init_chargebee_user_info();
        //     document.querySelector('#chargeBeeForm').classList.remove('hidden');
        //     Chargebee.registerAgain();
        // } else {
        //     document.querySelector('#chargeBeeForm').classList.add('hidden');
        // }
    }

    init_chargebee_user_info() {
        const cart = this.cbInstance.getCart();
        const customer = {
            first_name: this.userInfo.first_name,
            last_name: this.userInfo.last_name,
            email: this.userInfo.email,
            phone: this.userInfo.phone,
            billing_address:
                {
                    first_name: this.userInfo.first_name,
                    last_name: this.userInfo.last_name,
                    company: this.userInfo.company_name,
                    phone: this.userInfo.phone,
                    email: this.userInfo.email,
                    city: this.userInfo.city,
                    country: '',
                    line1: '',
                    line2: ''
                }
        };
        cart.setCustomer(customer);
    }

    init_chargebee() {
        this.limeProxiesService.getProducts().subscribe((data) => {
            this.chargebee_products.premium.packages = data['premium.packages'];
            this.chargebee_products.private.packages = data['private.packages'];
        });
    }

    choose_chargebee_plan() {

        let modals = document.querySelectorAll('[data-cb-plan-id]');
        for (let index = 0; index < modals.length; index++) {
            if (modals[index].getAttribute('data-cb-plan-id') === this.productForm.value.proxy_num.charge_id) {
                modals[index].classList.remove('hidden');

            } else {
                modals[index].classList.add('hidden');
            }
        }
    }

    getShowLocations() {
        this.locationCount = this.productForm.value.locations ? this.productForm.value.locations.length : null;
        if (this.productForm.value.locations) {
            return this.productForm.value.locations.map(row => {
                return row.state ? row.state : this.limeProxiesService.getFullCountryName(row.country);
            }).join(', ');
        } else {
            return '';
        }
    }

    getLocationsCodes() {

        if (this.productForm.value.locations) {
            return this.productForm.value.locations.map(row => {
                if (row.country === 'US') {
                    return row.country + ' ' + row.state;
                } else {
                    return row.country;
                }
            }).join();
        } else {
            return '';
        }
    }

    getNumbers(value: string) {
        return value ? value.match(/\d+/g) : 0;
    }

    getCharacters(value: string) {
        return value ? value.replace(/\d+/g, '') : 'Private Proxies';
    }

    ngAfterViewInit() {

        $(document).on('click', '.popup-toggle', (e) => {
            const target = $('.open-bitcoin-iframe');
            target.addClass('popup--show');
        });

        $(document).on('click', '.popup__dismiss', (e) => {
            e.preventDefault();
            $(e.target).closest('.popup').removeClass('popup--show');
        });
    }

    // doPayment(id) {
    //     if (typeof this.user.uid !== 'undefined') {
    //         if (!this.is_submit) {
    //             this.is_submit = true;
    //             const selector = $('#' + id);
    //             selector[0].setAttribute('type', 'submit');
    //             selector.click();
    //             this.is_submit = false;
    //             this.limeProxiesService.completedOrder(this.user.first_name, this.user.last_name, this.user.email, this.productForm.get('num').value || this.productForm.get('other_num').value || 0);
    //         }
    //     } else {
    //         this.router.navigate(['#login']);
    //     }
    // }

    getCards() {
        this.limeProxiesService.charge_get_card().subscribe(
            success => {
                if (success['_user_message_type'] === 'success') {
                    if (typeof success['card'] !== 'undefined' && typeof success['card'] === 'string') {
                        let parsed = JSON.parse(success['card']);					
                        if (parsed) {
                            this.cards = [];
                            for (let source of parsed) {
                                if (source['payment_source']) {
                                    if ((source['payment_source']['type'] === 'card') && (source['payment_source']['status'] === 'valid') && !source['payment_source']['deleted']) {
                                        this.cards.push(source);
                                    }
                                }
                            }
                        }
                    }
                }
            }, error => {
                this.limeProxiesService.showMessage(error);
            }
        );
    }

	public chargeeModel = false;
	public currentActiveChargbeeSection = 'savedCard';
	public chargeBeePaymentSource = '';

    chargebeeCheckout() {
		
		this.chargeeModel = true;
		
		if (this.cards.length === 0) {
			this.activeChargbeeSection('newCard');
		}
    }
	
	closeChargebeeCheckout() {
		this.chargeeModel = false;
	}

	activeChargbeeSection(section) {
		let curObj = this;
		
		this.currentActiveChargbeeSection = section;
	
		if (section == 'savedCard') {
		
		}
		
		if (section == 'newCard') {
			
		}
	}

    makePayment(event: any) {

        let radio = document.querySelector('[name="charge_payment_source"]:checked');

        if (radio == null) {
			this.limeProxiesService.notifyBarNew('Please choose card.', 'error');
        } 

		this.chargeBeePaymentSource = document.querySelector('[name="charge_payment_source"]:checked').getAttribute('psid');
		
		this.newProduct('chargeBee', this.userInfo, this.productForm.value.proxy_num.price, event);
    }
	
	chargeBeeCapturePayment(orderId, billAmount, paymentSourceId) {
		let curObject = this;
		let payData = {
			amount: billAmount,
			description: billAmount + '$ add product',
			paymentSourceId: paymentSourceId,
			orderId: orderId
		};

		this.limeProxiesService.showApiLoader(true);
		
		this.limeProxiesService.charge_make_payment(payData).subscribe(success => {				
			this.limeProxiesService.showApiLoader(true);
			curObject.limeProxiesService.addProduct(curObject.productInvoice, success.subscription).subscribe(success => {
                this.inProcess = false;
                if (success['_user_message_type'] === 'success') {
                    curObject.limeProxiesService.notifyBarNew('Congratulations! We have successfully added proxies in your account', 'success');
                    curObject.router.navigate(['/products/', 'manage']);
                    curObject.limeProxiesService.showMessage(success);
                }
            }, error => {
                this.inProcess = false;
                this.limeProxiesService.showMessage(error._user_message, 'error');
            });
		}, error => {
            this.inProcess = false;
			curObject.limeProxiesService.showMessage(error, 'error');
		});
	}
	
	chargebeeCheckoutNewCard(event) {
		this.limeProxiesService.showApiLoader(true);
		this.newProduct('chargeBee', this.userInfo, this.productForm.value.proxy_num.price, event);
	}
	
	chargebeeCheckoutNewCardPayment(event) {
		let curObject = this;

		this.limeProxiesService.charge_payment_source(event.card).subscribe(
			success => {
				curObject.chargeBeeCapturePayment(curObject.productInvoice, curObject.productForm.value.proxy_num.price, success.paymentSourceId);
			}, error => {
				curObject.limeProxiesService.showMessage(error.error, 'error');
			}
		);
	}
	

    setActiveCards(event: any, active: any, toHide: any) {
        let target = document.querySelector('.' + active + '_cards__title');
        if (target) {
            $(target).toggleClass('active');
            $('.' + toHide + '_cards__title').removeClass('active');
            $('.' + toHide + '_cards').find('.saved_info').slideUp();
            $(target).parent('.' + active + '_cards').find('.saved_info').slideToggle();
            $('.saved_info__details').removeClass('active');
        }
    }
}
