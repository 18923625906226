<div class="breadcrumbs">
  <a
    routerLink="./"
    class="breadcrumbs__item"
    routerLinkActive="breadcrumbs__item--active"
    >Add New</a
  >
  <span class="separator separator--bullet"></span>
  <a
    routerLink="/products/manage"
    class="breadcrumbs__item"
    routerLinkActive="breadcrumbs__item--active"
    >Manage</a
  >
</div>
<div *ngIf="!products" class="load-comments block page_block_inner">
  <h4>Loading products...</h4>
  <div class="loading"></div>
</div>
<div *ngIf="products" class="block block--with-header page_block_inner">
  <ul class="block__header tab__nav">
    <li
      class="block__title tab__toggle"
      [ngClass]="{'tab__toggle--active': pageName=='private'}"
      (click)="openPage('private')"
    >
      Private Proxies
    </li>
    <li
      class="block__title tab__toggle"
      [ngClass]="{'tab__toggle--active': pageName=='premium'}"
      (click)="openPage('premium')"
    >
      Premium Proxies
    </li>
    <!-- <li class="block__title tab__toggle" [ngClass]="{'tab__toggle--active': pageName=='Custom'}" (click)="openPage('Custom')">Custom Proxies</li> -->
    <li
      class="block__title tab__toggle"
      [ngClass]="{'tab__toggle--active': pageName=='residential'}"
      (click)="openPage('residential')"
    >
      Residential Proxies
    </li>
    <!-- <li class="block__title tab__toggle" *ngIf="inactiveProducts.length > 0" [ngClass]="{'tab__toggle--active': pageName=='inactiveProducts'}" (click)="openPage('inactiveProducts')">Restore Proxies</li> -->
  </ul>
  <div class="block__inner">
    <div class="tab__content-wrapper">
      <form
        [formGroup]="newProductForm"
        (submit)="newProduct()"
        [ngClass]="{'tab__content-block--active': pageName=='private'}"
        class="tab__content-block"
      >
        <div class="block-group">
          <h4 class="block__title block__title--counter">
            <span class="block__title-counter">1</span> Select Proxies Number
          </h4>
          <div class="toggle-grid">
            <label
              (click)="selectPrivateProxiesNumber(proxy_num)"
              [ngClass]="{'toggle-grid__item--active': proxy_num === newProductForm.get('proxy_num').value}"
              *ngFor="let proxy_num of products?.private?.packages"
              class="toggle-grid__item toggle-grid__item--small input-field"
            >
              <input
                type="radio"
                formControlName="proxy_num"
                class="hide"
                [value]="proxy_num"
              />
              <span>{{getNumbers(proxy_num.name)[0]}}</span>
            </label>
          </div>
        </div>
        <div *ngIf="newProductForm.value.proxy_num" class="block-group">
          <h4 class="block__title block__title--counter">
            <span class="block__title-counter">2</span> What do you use the
            proxies for?
          </h4>
          <div class="toggle-grid proxyUsageOpt-list">
            <label class="col-12 col-md-12 col-lg-12 padding0">
              <select
                formControlName="usage"
                (change)="selectUsage($event.target.value)"
                required
                class="col-12 col-md-12 col-lg-12"
              >
                <option [value]="0">Select usage</option>
                <option *ngFor="let item of proxyUsageOpt" [value]="item.value">
                  {{ item.text }}
                </option>
              </select>
            </label>
          </div>
          <div *ngIf="showOtherUsage" class="toggle-grid proxyUsageOpt-list">
            <label class="col-12 col-md-12 col-lg-12 padding0">
              <input
                formControlName="otherUsage"
                type="text"
                maxlength="50"
                class="input-field"
                (keyup)="selectOtherUsage($event.target.value)"
                maxlength="50"
              />
            </label>
          </div>
        </div>
        <div *ngIf="canShowStep3()" class="block-group">
          <h4 class="block__title block__title--counter">
            <span class="block__title-counter">3</span> Select Server Location
          </h4>
          <p class="block__desc">
            You can choose {{private_locs_max_number}} server locations, if you
            need to select more choose
            <strong id="linkToPremium" (click)="openPage('premium')"
              ><u>Premium Package</u></strong
            >.
          </p>
          <div class="toggle-grid toggle-grid--3-per-row locations-list" id="2">
            <label
              *ngFor="let prvLoc of products.private.locations; let i = index"
              [ngClass]="{'toggle-grid__item--active':hasLocations(prvLoc)}"
              class="toggle-grid__item input-field"
            >
              <input
                type="checkbox"
                (change)="addLocations(prvLoc, $event)"
                class="hide"
                [value]="prvLoc"
              />
              <span class="toggle-grid__inner">
                <span class="toggle-grid__image">
                  <img [src]="getImage(prvLoc.country)" />
                </span>
                <span class="product-country-name">
                  <span class="toggle-grid__title">{{prvLoc.state}}</span>
                  <span class="toggle-grid__desc"
                    >{{limeProxiesService.getFullCountryName(prvLoc.country)}}</span
                  >
                </span>
              </span>
            </label>
          </div>
        </div>
      </form>
      <form
        [formGroup]="newProductForm"
        class="tab__content-block"
        [ngClass]="{'tab__content-block--active': pageName=='premium'}"
      >
        <div class="block-group">
          <h4 class="block__title block__title--counter">
            <span class="block__title-counter">1</span> Select Proxies Number
          </h4>
          <div class="toggle-grid">
            <label
              (click)="selectPremiumProxiesNumber(proxy_num)"
              [ngClass]="{'toggle-grid__item--active': proxy_num === newProductForm.get('proxy_num').value}"
              *ngFor="let proxy_num of products?.premium?.packages"
              class="toggle-grid__item toggle-grid__item--small input-field"
            >
              <input
                type="radio"
                formControlName="proxy_num"
                class="hide"
                [value]="proxy_num"
              />
              <span>{{getNumbers(proxy_num.name)[0]}}</span>
            </label>
          </div>
        </div>
        <div *ngIf="newProductForm.value.proxy_num" class="block-group">
          <h4 class="block__title block__title--counter">
            <span class="block__title-counter">2</span> What do you use the
            proxies for?
          </h4>
          <div class="toggle-grid proxyUsageOpt-list">
            <label class="col-12 col-md-12 col-lg-12 padding0">
              <select
                formControlName="usage"
                (change)="selectUsage($event.target.value)"
                required
                class="col-12 col-md-12 col-lg-12"
              >
                <option [value]="0">Select usage</option>
                <option *ngFor="let item of proxyUsageOpt" [value]="item.value">
                  {{ item.text }}
                </option>
              </select>
            </label>
          </div>
          <div *ngIf="showOtherUsage" class="toggle-grid proxyUsageOpt-list">
            <label class="col-12 col-md-12 col-lg-12 padding0">
              <input
                formControlName="otherUsage"
                type="text"
                maxlength="50"
                class="input-field"
                (keyup)="selectOtherUsage($event.target.value)"
                maxlength="50"
              />
            </label>
          </div>
        </div>
        <div *ngIf="canShowStep3()" class="block-group">
          <h4 class="block__title block__title--counter">
            <span class="block__title-counter">3</span> Select Server Location
          </h4>
          <p class="block__desc">
            You can choose {{premium_locs_max_number}} server locations, if you
            need to select more choose another plan.
          </p>
          <div
            class="toggle-grid toggle-grid--3-per-row locations-list"
            id="{{premium_locs_max_number}}"
          >
            <label
              *ngFor="let premServerLoc of products.premium.locations"
              [ngClass]="{'toggle-grid__item--active':hasLocations(premServerLoc)}"
              class="toggle-grid__item input-field"
            >
              <input
                type="checkbox"
                (change)="addLocations(premServerLoc, $event)"
                class="hide"
                [value]="premServerLoc"
              />
              <span class="toggle-grid__inner">
                <span class="toggle-grid__image">
                  <img [src]="getImage(premServerLoc.country)" />
                </span>
                <span class="product-country-name">
                  <span class="toggle-grid__title"
                    >{{premServerLoc.state}}</span
                  >
                  <span class="toggle-grid__desc"
                    >{{limeProxiesService.getFullCountryName(premServerLoc.country)}}</span
                  >
                </span>
              </span>
            </label>
          </div>
        </div>
      </form>
      <form
        [formGroup]="newProductForm"
        class="tab__content-block"
        [ngClass]="{'tab__content-block--active': pageName=='inactiveProducts'}"
      >
        <table class="block table table-responsive products-manage">
          <thead class="table__header table__header--small">
            <tr>
              <!-- <th class="table__cell">Product #</th> -->
              <th class="table__cell block__title">Product</th>
              <th class="table__cell block__title" style="text-align: right">
                Exiry Date
              </th>
              <th class="table__cell block__title" style="text-align: right">
                Cost
              </th>
              <th class="table__cell table__cell--fit"></th>
            </tr>
          </thead>
          <tbody>
            <ng-template
              ngFor
              let-product
              [ngForOf]="inactiveProducts"
              let-i="index"
              [ngForTrackBy]="trackById"
            >
              <tr>
                <td class="table__cell">{{product.name}}</td>
                <td class="table__cell" style="text-align: right">
                  {{product.valid_till | date}}
                </td>
                <td class="table__cell" style="text-align: right">
                  ${{product.cost}}
                </td>
                <td class="table__cell table__cell--fit">
                  <button
                    class="btn btn--small btn--dark-gray"
                    type="button"
                    (click)="showProxies(product)"
                  >
                    Proxies
                  </button>
                  <button
                    class="btn btn--small"
                    type="button"
                    (click)="repurchase(product)"
                  >
                    Restore
                  </button>
                </td>
              </tr>
              <tr
                style="background: #ccc; font-size: 14px"
                *ngIf="showProxiesProduct === product.product_id"
              >
                <td class="table__cell" colspan="5" style="padding: 5px">
                  <table class="block table table-responsive products-manage">
                    <thead class="table__header table__header--small">
                      <tr>
                        <!-- <th class="table__cell">Product #</th> -->
                        <th>Location</th>
                        <th>Subnet</th>
                        <th>Proxies</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-template
                        ngFor
                        let-subnet
                        [ngForOf]="product.proxies"
                        let-i="sindex"
                        [ngForTrackBy]="trackById"
                      >
                        <tr>
                          <td width="15%">{{subnet.location}}</td>
                          <td width="35%">{{subnet.subnet}}</td>
                          <td width="60%">{{subnet.proxies.join(', ')}}</td>
                        </tr>
                      </ng-template>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </form>
      <form
        [formGroup]="newProductForm"
        class="tab__content-block"
        [ngClass]="{'tab__content-block--active': pageName=='Custom'}"
      >
        <div class="block-group">
          <h4 class="block__title block__title--counter">
            <span class="block__title-counter">1</span>
            What do you use the proxies for?
          </h4>
          <div class="toggle-grid proxyUsageOpt-list">
            <label class="col-12 col-md-12 col-lg-12 padding0">
              <select
                formControlName="usage"
                (change)="selectUsage($event.target.value)"
                required
                class="col-12 col-md-12 col-lg-12"
              >
                <option [value]="0">Select usage</option>
                <option *ngFor="let item of proxyUsageOpt" [value]="item.value">
                  {{ item.text }}
                </option>
              </select>
            </label>
          </div>
          <div
            *ngIf="newProductForm.value.usage === 'others' "
            class="toggle-grid proxyUsageOpt-list"
          >
            <label class="col-12 col-md-12 col-lg-12 padding0">
              <input
                type="text"
                maxlength="50"
                class="input-field"
                formControlName="otherUsage"
                (keyup)="selectOtherUsage($event.target.value)"
                maxlength="50"
              />
            </label>
          </div>
        </div>

        <app-custom
          #child
          [productForm]="newProductForm"
          (customProduct)="customProduct($event)"
        ></app-custom>
      </form>
      <form
        [formGroup]="newProductForm"
        class="tab__content-block"
        [ngClass]="{'tab__content-block--active': pageName=='residential'}"
      >
        <div class="block-group">
          <div class="col-12 col-md-12 col-lg-12 padding0">
            <div class="row">
              <ng-template
                ngFor
                let-product
                [ngForOf]="products['residential']"
                let-i="index"
                [ngForTrackBy]="rTrackById"
              >
                <div
                  class="row selectize-input"
                  style="margin: 0px 0px 10px 0px"
                >
                  <div class="col-6 col-md-6 col-lg-6">
                    <span class="toggle-grid__inner">
                      <span class="product-country-name">
                        <span class="toggle-grid__title">
                          {{product.name}}
                        </span>
                        <br />
                        <small>{{product.bandwidth}} Bandwidth</small>
                      </span>
                    </span>
                  </div>
                  <div class="col-4 col-md-4 col-lg-4">
                    <span class="toggle-grid__inner">
                      <span class="product-country-name">
                        <span class="toggle-grid__title"
                          >{{product.price | currency}} / mo</span
                        >
                      </span>
                    </span>
                  </div>
                  <div class="col-2 col-md-2 col-lg-2">
                    <button
                      class="btn btn--small"
                      type="button"
                      (click)="residential(product)"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="sidebar big-sidebar products-sidebar">
  <products-sidebar
    [productForm]="newProductForm"
    (clearForm)="clearForm()"
    (showCards)="showChargebeeCards($event)"
    (updateCredit)="updateCredit($event)"
  ></products-sidebar>
</div>
