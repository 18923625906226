import { Routes } from '@angular/router';
import { FaqComponent } from './faq.component';
import { TicketsComponent } from './tickets.component';
import { ChatComponent } from './chat.component';
import {AuthGuard} from '../../auth.guard';

export const helpRoutes: Routes = [
    {path: '', redirectTo: 'faq', pathMatch: 'full'},
    {path: 'faq', component: FaqComponent, canActivate: [AuthGuard]},
    {path: 'tickets', component: TicketsComponent, canActivate: [AuthGuard]},
    {path: 'chat', component: ChatComponent, canActivate: [AuthGuard]}
];