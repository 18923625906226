import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LimeProxiesService } from 'src/app/services/limeproxies.service';
import { catchError, map } from 'rxjs/operators';

declare const Env: any;

@Injectable({
  providedIn: 'root'
})
export class PayoutsService {
  constructor(private http: HttpClient, private limeProxiesService: LimeProxiesService) { }

  get = () => {
    const api_endpoint =  Env['api']['url'].concat(`referrals.php?action=ReferralsPayouts`);

    return this.http.get(api_endpoint, {headers: LimeProxiesService.authHeaders})
      .pipe(
          map((res: any) => {
            return res || {};
          }),
          catchError(error => this.limeProxiesService.checkStatusCodeOnError(error, this.limeProxiesService))
      );
  }
}