<div *ngIf="(productForm.value.usage !== null && productForm.value.usage !== '' && productForm.value.usage !== '0' && productForm.value.usage !== 'others') || (productForm.value.usage === 'others' && productForm.value.otherUsage !== null && productForm.value.otherUsage.trim() !== '') ">
<div class="block-group">
	<h4 class="block__title block__title--counter">
		<span class="block__title-counter">2</span> 
		Select proxies
	</h4>
	<div class="toggle-grid proxyUsageOpt-list">
		<div class="col-12 col-md-12 col-lg-12 padding0">
			<div class="row">
				<div class="col-4 col-md-4 col-lg-4 ">
					<input type="text" 
						class="input-field"
						placeholder="Select Location"
						matInput
						[formControl]="myControl"
						[matAutocomplete]="auto1" 
						style="padding: 10px;" />
					<mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" (optionSelected)="selectedLocation($event.option.value)">
						<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
							{{option}}
						</mat-option>
					</mat-autocomplete>
				</div>
				<div class="col-3 col-md-3 col-lg-3 ">
					<select name="usage" [(ngModel)]="selectedSubnetValue" (change)="selectedSubnet($event.target.value)" class="input-field" required class="col-12 col-md-12 col-lg-12"  >
						<option [value]="0">Select Subnet</option>
						<option *ngFor="let item of filteredSubnetOptions"  [value]="item.sid">
							xxx.xxx.xxx{{ item.subnet.substring(item.subnet.lastIndexOf('.')) }}
							
						</option>
					</select>
				</div>
				<div class="col-3 col-md-3 col-lg-3 ">
					<label class="col-12 col-md-12 col-lg-12 ">
						<input 
							type="text" 
							min="0" 
							max="500" 
							class="input-field" 
							[(ngModel)]="noOfProxies" 
							maxlength="100" 
							placeholder="Number of Proxies" 
							style="padding: 10px;" />
						<i *ngIf="myControl.value"><small>( Available Proxies : {{getAvailableProxies()}} )</small></i>
					</label>
				</div>
				<div class="col-2 col-md-2 col-lg-2">
					<button class="btn btn--small" type="button" (click)="add()">Add</button>
				</div>
			</div>
			<ng-template ngFor let-proxy [ngForOf]="proxies" let-i="index" [ngForTrackBy]="trackById">
				<div class="row selectize-input" style="margin: 0px 0px 10px 0px;" >
					<div class="col-4 col-md-4 col-lg-4">
						<span class="toggle-grid__inner">
							<span class="toggle-grid__image">
								<img [src]="getImage(proxy.geo_mm_cc)">
							</span>
							<span class="product-country-name">
								<span class="toggle-grid__title">{{proxy.geo_maxmind}}</span>
								<span class="toggle-grid__desc">{{limeProxiesService.getFullCountryName(proxy.geo_mm_cc)}}</span>
							</span>
						</span>
					</div>
					<div class="col-3 col-md-3 col-lg-3 " style="text-align: center;" >
						<span *ngIf="proxy.isSubnet === 1" >
							xxx.xxx.xxx.{{ proxy.subnet.substring(proxy.subnet.lastIndexOf('.')) }}
						</span>
					</div>
					<div class="col-3 col-md-3 col-lg-3 " style="text-align: center;">
						{{ proxy.no }}
					</div>
					<div class="col-2 col-md-2 col-lg-2">
						<button class="btn btn--small" type="button" (click)="remove(proxy)">Remove</button>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>
</div>